import React, { FC } from "react";

import {
  List,
  ListItemButton,
  ListItemText,
  ListItemAvatar,
} from "@mui/material";

import FilledIcon from "assets/icons/filled.svg";
import OutlinedIcon from "assets/icons/outlined.svg";
import StandardIcon from "assets/icons/standard.svg";
import { ImgIcon } from "./ImgIcon";

export const DialogThemeInput: FC<{
  onClick: (inputType: "filled" | "outlined" | "standard") => void;
  hideDialog: () => void;
  selected: "filled" | "outlined" | "standard";
}> = ({ onClick, hideDialog, selected }) => {
  return (
    <List>
      <ListItemButton
        selected={selected == "filled"}
        onClick={() => {
          hideDialog();
          onClick("filled");
        }}
      >
        <ListItemAvatar>
          <ImgIcon src={FilledIcon} />
        </ListItemAvatar>
        <ListItemText primary="Preenchido" />
      </ListItemButton>
      <ListItemButton
        selected={selected == "outlined"}
        onClick={() => {
          hideDialog();
          onClick("outlined");
        }}
      >
        <ListItemAvatar>
          <ImgIcon src={OutlinedIcon} />
        </ListItemAvatar>
        <ListItemText primary="Contorno" />
      </ListItemButton>
      <ListItemButton
        selected={selected == "standard"}
        onClick={() => {
          hideDialog();
          onClick("standard");
        }}
      >
        <ListItemAvatar>
          <ImgIcon src={StandardIcon} />
        </ListItemAvatar>
        <ListItemText primary="Padrão" />
      </ListItemButton>
    </List>
  );
};
