import React, { FC, useEffect, useState } from "react";
import {
  IDNTable,
  utils,
  IAvaliacao,
  IAvaCtr,
  IDNRown,
  EUserType,
  DadosGrafico,
  IFiltroTurma,
  IFiltroMateria,
  IDNColumn,
  IIniOnlineSGE2,
} from "@deltasge/marauders-map";

import { Filter as FilterIcon } from "mdi-material-ui";

import { snack } from "components/GlobalSnackbar";
import { Environment, api, apiRelatorio, history } from "configs";
import { IFilterDigitacao } from "interfaces";
import { Main } from "pages/_layouts/Main";
import { CadastroAvaliacao } from "pages/Avaliacao/Cadastro/CadastroAvaliacao";
import { useDialog } from "hooks/useDialog";
import { useAppDispatch, useAppSelector } from "store";
import { request as getSistema } from "store/modules/sistema-avaliacao";
import { request as getCalculos } from "store/modules/calculo-avaliacoes";
import { getError } from "utils";

import { Filter } from "./components/Filter";
import { Table } from "./components/Table";
import { Grafico } from "./components/GraficoTurma";
import { useFilter } from "hooks/useFilter";
import { EmptyState } from "components/EmptyStates";
import { parseISO } from "date-fns";

const setDisabled = ({
  configuracoes,
  avaCtr,
}: {
  configuracoes: IIniOnlineSGE2;
  avaCtr?: IAvaCtr;
}): boolean => {
  if (avaCtr && avaCtr.concluido) return true;

  const dataIni = parseISO(configuracoes.digi_ini.toString());
  const dataFim = parseISO(configuracoes.digi_fim.toString());
  const agora = new Date();
  return (
    agora.getTime() < dataIni.getTime() || agora.getTime() > dataFim.getTime()
  );
};

const getSubtitle = ({
  turma,
  materia,
}: {
  turma?: IFiltroTurma;
  materia?: IFiltroMateria;
}): string => {
  if (!turma) return "Nenhuma turma selecionada";
  return ` Turma ${turma.tituloTurma}`.concat(
    ` - ${materia?.nome ?? "Todas as matérias"}`
  );
};

export const Notas: FC = () => {
  const {
    calculoAvaliacoes: { data: calculos },
    configuracoes: {
      configOnline: { configuracoes },
    },
    sistemaAvaliacao: { data: sistemaAvaliacao },
    layout: {
      rightbar: { isOpen: rightbarIsOpen },
    },
    escola,
    usuario: { usuario },
  } = useAppSelector((state) => state);
  const dispatch = useAppDispatch();

  const handleFilterCurso = (idCurso?: number) =>
    navigate({
      ...filter,
      idCurso,
      idSerie: undefined,
      idTurma: undefined,
      idMateria: undefined,
    });

  const handleFilterSerie = (idSerie?: number) =>
    navigate({
      ...filter,
      idSerie,
      idTurma: undefined,
      idMateria: undefined,
    });

  const handleFilterTurma = (idTurma?: number) =>
    navigate({
      ...filter,
      idTurma,
    });

  const handleFilterMateria = (idMateria?: number) =>
    navigate({
      ...filter,
      idMateria,
    });

  const handleFilterEtapa = (etapa?: number) =>
    navigate({
      ...filter,
      etapa: etapa ?? configuracoes.bim_site,
    });

  const {
    Component,
    navigate,
    filter,
    selecionados: { materia, turma, serie, etapa },
  } = useFilter<IFilterDigitacao>({
    pathname: "/notas/",
    queryKeys: ["idCurso", "idSerie", "idTurma", "idMateria", "etapa"],
    initialValue: {
      etapa: configuracoes.bim_site,
    },
    options: {
      curso: {
        onClick: handleFilterCurso,
      },
      serie: {
        onClick: handleFilterSerie,
      },
      turma: {
        onClick: handleFilterTurma,
      },
      materia: {
        onClick: handleFilterMateria,
      },
      etapa: {
        onClick: handleFilterEtapa,
      },
    },
  });

  const [loading, setLoading] = useState(false);
  const [digitacao, setDigitacao] = useState<IDNTable>();
  const [avaliacao, setAvaliacao] = useState<Partial<IAvaliacao>>();
  const [row, setRow] = useState<IDNRown>();
  const [grafico, setGrafico] = useState<DadosGrafico>();
  const [columns, setColumns] = useState<IDNColumn[]>([]);

  const disabled = setDisabled({ configuracoes, avaCtr: digitacao?.avaCtr });

  const {
    show: showCadastro,
    RenderDialog: DialogCadastro,
    hide: hideCadastro,
  } = useDialog({
    title: "Cadastro de Avaliações",
    options: {
      scroll: "paper",
    },
  });

  const { show: showGrafico, RenderDialog: DialogGrafico } = useDialog({
    title: "Análise de notas",
    options: {
      scroll: "paper",
      fullScreen: true,
    },
  });

  const handleFilter = async (filter: IFilterDigitacao) => {
    try {
      if (filter.idTurma && filter.idMateria) {
        setLoading(true);

        if (!serie || !serie.idSistemaAvaliacao) {
          throw new Error("ID do sistema de avaliação não encontrado");
        }

        dispatch(getSistema(serie.idSistemaAvaliacao));

        const { data } = await api.get<IDNTable | null | undefined>(
          `turma/digitacao/${filter.idTurma}/materia/${filter.idMateria}/etapa/${filter.etapa}`,
          {
            cache: { ignoreCache: true },
          }
        );

        setDigitacao(data ?? undefined);
        if (data) setColumns(data.columns);
        setLoading(false);
      } else {
        setDigitacao(undefined);
        setColumns([]);
      }
    } catch (error) {
      setLoading(false);
      snack.error(getError(error));
    }
  };

  const handleAdd = async () => {
    try {
      if (calculos.length == 0) dispatch(getCalculos());

      if (!serie) {
        throw new Error("Serie não encontrada");
      }
      if (!serie.idSistemaAvaliacao) {
        throw new Error("ID do sistema de avaliação não encontrado");
      }

      const { data } = await api.get<{ codigo?: string }>(
        `avaliacao/proximo-codigo/${filter.idTurma}/bimestre/${filter.etapa}/materia/${filter.idMateria}`,
        { cache: { ignoreCache: true } }
      );
      if (!data || !data.codigo) {
        throw new Error("Erro ao obter o código da avaliação");
      }
      setAvaliacao({
        idCurso: filter.idCurso,
        idSerie: filter.idSerie,
        idTurma: filter.idTurma,
        idMateria: filter.idMateria,
        bimestre: filter.etapa,
        anoLetivo: configuracoes.ano_site.toString(),
        codigo: data.codigo,
      });

      showCadastro();
    } catch (error) {
      snack.error(getError(error));
    }
  };

  const handleCloseCadastro = (reload?: boolean) => {
    setAvaliacao(undefined);
    hideCadastro();
    if (reload) {
      handleFilter(filter);
    }
  };

  const handleConcluir = async (avaCtr: IAvaCtr) => {
    try {
      if (
        columns.find((f) => f.avaliacao && f.avaliacao.liberado == false) !=
        undefined
      ) {
        throw new Error("Você deve publicar as avaliações primeiro");
      }
      await api.post("controle-avaliacao/concluir", {
        id: avaCtr.id,
        concluido: true,
      });
      avaCtr.concluido = true;
      setDigitacao((p) => {
        if (p) return { ...p, avaCtr };
        return p;
      });
    } catch (error) {
      snack.error(getError(error));
    }
  };

  const handleColar = async ({
    copiar,
    colar,
  }: {
    colar: IAvaliacao;
    copiar: IAvaliacao;
  }) => {
    try {
      if (
        copiar &&
        (copiar.valor != colar.valor || copiar.peso != colar.peso)
      ) {
        throw new Error(
          `A avaliação deve ter o mesmo peso (${copiar.peso}) e valor (${copiar.valor}) da avaliação ${copiar.descricao}`
        );
      }
      await api.get(`nota-avaliacao/copiar/${copiar.uid}/colar/${colar.uid}`);
      snack.success("Notas coladas com sucesso!");
      await handleFilter(filter);
    } catch (error) {
      snack.error(getError(error));
    }
  };

  const handleImportar = async ({
    file,
    idAvaliacao,
  }: {
    file: File;
    idAvaliacao: number;
  }) => {
    try {
      const formData = new FormData();
      formData.append("notas", file);
      formData.append("idAvaliacao", idAvaliacao.toString());
      const { data } = await api.post<number>(
        "nota-avaliacao/importar-notas",
        formData
      );
      if (data && data > 0) {
        snack.success(`${data} nota${data > 1 ? "s" : ""} importadas`);
      }
      await handleFilter(filter);
    } catch (error) {
      snack.error(getError(error));
    }
  };

  const handleBoletim = () => {
    if (row) {
      const baseUrl = escola.onlineExt;
      const params = {
        aluno: row.codigo,
        bimestre: filter.etapa,
        ano: configuracoes.ano_site,
        professor: escola.profWeb ? 1 : 0,
        codEscola: escola.codigo,
        etapas: escola.etapas,
      };
      let path = "/a/boletim.php?params=".concat(
        utils.base64Encode(JSON.stringify(params))
      );
      if (escola.codigo == "0347") {
        path = "/professor/api/relatorios/boletim_aluno_gamaliel.php?codAluno="
          .concat(row.codigo)
          .concat("&ano=")
          .concat(configuracoes.ano_site.toString())
          .concat("&professor=1");
      } else if (escola.codigo == "0252") {
        path = "/a/boletim_instEduInf.php?params=".concat(
          utils.base64Encode(JSON.stringify(params))
        );
      } else if (escola.codigo == "0416") {
        path = "/a/boletim_tiradentes.php?codAluno="
          .concat(row.codigo)
          .concat("&ano=")
          .concat(configuracoes.ano_site.toString())
          .concat("&bimestre=")
          .concat(filter.etapa.toString())
          .concat("&liberado=1");
      }
      window.open(baseUrl.concat(path));
    }
  };

  const handleProvas = () => {
    if (row) {
      const baseUrl = escola.onlineExt;
      const params = {
        aluno: row.codigo,
        bimestre: filter.etapa,
        ano: configuracoes.ano_site,
        professor: escola.profWeb ? 1 : 0,
        codEscola: escola.codigo,
        etapas: escola.etapas,
      };
      let path = "/a/calendario.php?params=".concat(
        utils.base64Encode(JSON.stringify(params))
      );
      if (escola.codigo) {
        path = "/professor/api/relatorios/fichaIndividual.php?params=".concat(
          utils.base64Encode(JSON.stringify(params))
        );
      }
      window.open(baseUrl.concat(path));
    }
  };

  const handleRelNotas = (
    tipo: "porMateria" | "gerais" | "gerais_bimestre"
  ) => {
    const codProfessor =
      usuario?.tipoUsuario == EUserType.Professor ? usuario.codigo : "000";
    const cst = `${turma?.cursoDbf}${turma?.serieDbf}${turma?.codigoDbf}`;
    const location = encodeURIComponent(
      `${escola.onlineExt}professor/api/relatorios/${tipo}.php?turma=${cst}&materia=${materia?.codigo}&bimestre=${filter.etapa}&professor=${codProfessor}`
    );
    setLoading(false);
    window.open(
      `${escola.onlineExt}api/autorizacao/?Location=${location}&Authorization=${usuario?.token}`
    );
  };

  const handleAvaliacoes = () => {
    history.push(
      `/avaliacao?idCurso=${filter.idCurso}&idSerie=${filter.idSerie}&idTurma=${filter.idTurma}&idMateria=${filter.idMateria}`
    );
  };

  const handleCorrigirRemanejados = async () => {
    try {
      await api.post("avaliacao/corrigir-remanejados", {
        anoLetivo: configuracoes.ano_site,
        bimestre: filter.etapa,
        idMateria: filter.idMateria,
        idTurma: filter.idTurma,
      });
      snack.success("Remanejamento finalizado com sucesso");
      await handleFilter(filter);
    } catch (error) {
      snack.error(getError(error));
    }
  };

  const handleRecalcular = async () => {
    try {
      await api.post("/calculo-medias", {
        anoLetivo: configuracoes.ano_site,
        bimestre: filter.etapa,
        idMateria: filter.idMateria,
        idTurma: filter.idTurma,
      });
      snack.success("Recalculo finalizado com sucesso");
      await handleFilter(filter);
    } catch (error) {
      snack.error(getError(error));
    }
  };

  const handleGrafico = async (isAluno?: boolean) => {
    try {
      let data: DadosGrafico | undefined = undefined;
      if (isAluno && row) {
        const result = await apiRelatorio.get<DadosGrafico>(
          `graficos/medias/turma/${filter.idTurma}/aluno/${row.idAluno}`
        );
        data = result.data;
      } else {
        const result = await apiRelatorio.get<DadosGrafico>(
          `graficos/medias/turma/${filter.idTurma}`
        );
        data = result.data;
      }
      if (!data || !data.series || !data.options?.xaxis?.categories) {
        throw new Error("Nenhum dado a ser apresentado");
      }
      setGrafico(data);
      showGrafico();
    } catch (error) {
      snack.error(getError(error));
    }
  };

  useEffect(() => {
    handleFilter(filter);
  }, [
    filter.etapa,
    filter.idCurso,
    filter.idSerie,
    filter.idTurma,
    filter.idMateria,
  ]);

  return (
    <Main
      useScroll={false}
      maxWidth={false}
      title="Digitação de notas"
      subtitle={getSubtitle({ turma, materia })}
      loading={loading}
      rightbarIcon={<FilterIcon />}
      rightbarChildren={
        <Filter
          columns={columns}
          setColumns={setColumns}
          filter={filter}
          Component={Component}
          avaCtr={digitacao?.avaCtr}
          handleReaload={() => handleFilter(filter)}
          handleConcluir={handleConcluir}
          handleBoletim={handleBoletim}
          handleProvas={handleProvas}
          handleRelNotas={handleRelNotas}
          handleAvaliacoes={handleAvaliacoes}
          handleCorrigirRemanejados={handleCorrigirRemanejados}
          handleGrafico={handleGrafico}
          handleRecalcular={handleRecalcular}
        />
      }
    >
      <DialogGrafico>{grafico && <Grafico data={grafico} />}</DialogGrafico>
      <DialogCadastro>
        {sistemaAvaliacao && etapa && avaliacao && (
          <CadastroAvaliacao
            handleClose={handleCloseCadastro}
            item={avaliacao}
            etapa={etapa}
            sistemaAvaliacao={sistemaAvaliacao}
          />
        )}
      </DialogCadastro>
      {digitacao && sistemaAvaliacao && (
        <Table
          table={digitacao}
          sistemaAvaliacao={sistemaAvaliacao}
          etapa={filter.etapa}
          key={`filtro_${columns.filter((f) => f.visivel).length}`}
          updateTable={setDigitacao}
          setRow={setRow}
          handleColar={handleColar}
          handleImportar={handleImportar}
          handleAdd={{
            disabled,
            onAdd: handleAdd,
            title: "Adicionar avaliação",
            sx: ({ spacing }) => ({
              position: "fixed",
              bottom: spacing(2),
              right: !rightbarIsOpen
                ? spacing(2)
                : spacing(Environment.DRAWER_WIDTH + 2),
            }),
          }}
        />
      )}
      {!digitacao &&
        (!filter.idCurso ||
          !filter.idSerie ||
          !!filter.idTurma ||
          !filter.idMateria) &&
        !loading && (
          <EmptyState
            type="search"
            title="Selecione no filtro"
            subtitle="Selecione ao menos uma turma, matéria e uma etapa para apresentar as avaliações"
          />
        )}
      {!digitacao &&
        !loading &&
        filter.idCurso &&
        filter.idSerie &&
        filter.idTurma &&
        filter.idMateria && (
          <EmptyState
            type="404"
            title="Nenhuma avaliação encontrada!"
            subtitle="O Filtro selecionado não encontrou nenhuma avaliação"
          />
        )}
    </Main>
  );
};
