import React, { FC, useState, useCallback } from "react";

import { Breakpoint } from "@mui/material";

import { DialogWrapper, PropsToolbarDialog } from "./DialogWrapper";

export interface UseDialog {
  show: () => void;
  hide: () => void;
  RenderDialog: Function;
}

interface Options {
  maxWidth?: Breakpoint | false;
  modal?: boolean;
  autoWidth?: boolean;
  scroll?: "paper" | "body" | undefined;
  fullScreen?: boolean;
}

export interface UseDialogParams {
  title?: string;
  customClose?: (hide: () => void) => void;
  options?: Options;
  RenderToolbar?: FC<PropsToolbarDialog>;
}

export const useDialog = ({
  title = "",
  customClose,
  options = {},
  RenderToolbar,
}: UseDialogParams): UseDialog => {
  const [visible, setVisible] = useState(false);

  const show = useCallback(() => setVisible(true), []);
  const hide = useCallback(() => {
    if (customClose) {
      const hidden = () => setVisible(false);
      customClose(hidden);
    } else {
      setVisible(false);
    }
  }, [customClose]);

  const RenderDialog = ({
    children,
  }: {
    children: React.ReactChild;
  }): JSX.Element | null => {
    if (!visible) {
      return null;
    }

    return (
      <DialogWrapper
        hide={hide}
        title={title}
        RenderToolbar={RenderToolbar}
        {...options}
      >
        {children}
      </DialogWrapper>
    );
  };

  return {
    show,
    hide,
    RenderDialog,
  };
};
