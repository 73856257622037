import React, { FC } from "react";

import { Drawer, Box } from "@mui/material";

import { useAppSelector, useAppDispatch } from "store";
import { toggleSidebar } from "store/modules/layout";
import { useMobile } from "hooks/useMobile";
import PerfectScrollbar from "react-perfect-scrollbar";

import { Profile } from "../Profile";
import { SidebarList } from "./components/SidebarList";
import { routes } from "configs";
import { Footer } from "./components/Footer";
import * as styles from "assets/styles/drawer";
import { ToolbarWrapper } from "assets/styles/drawer";

export const Sidebar: FC = () => {
  const isMobile = useMobile("md");
  const open = useAppSelector((state) => state.layout.sidebar.isOpen);
  const dispatch = useAppDispatch();

  const drawerVariant = isMobile ? "temporary" : "persistent";

  return (
    <Drawer
      open={open}
      onClose={() => dispatch(toggleSidebar())}
      sx={styles.drawer}
      variant={drawerVariant}
      ModalProps={{
        keepMounted: true,
      }}
    >
      {!isMobile && <ToolbarWrapper />}
      <Profile />
      <PerfectScrollbar>
        <Box sx={styles.container} role="presentation">
          <SidebarList routes={routes} leftSpace={0} />
        </Box>
      </PerfectScrollbar>
      <Footer />
    </Drawer>
  );
};
