import { IListaDiarioClasse } from "@deltasge/marauders-map";

import { createActionWithPayload } from "store/helpers";
import { ActionTypes } from "./types";
import { createAction } from "@reduxjs/toolkit";

export const request = createAction<void>(ActionTypes.REQUEST);
export const success = createActionWithPayload<IListaDiarioClasse[]>(
  ActionTypes.SUCCESS
);
export const failure = createAction<void>(ActionTypes.FAILURE);
