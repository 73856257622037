import React, { FC } from "react";

import { IMensagem } from "@deltasge/marauders-map";
import { history } from "configs";

import { List, Paper } from "@mui/material";

import { ListItem } from "./ListItem";
import { Pagination } from "components/Pagination";
import { EmptyState } from "components/EmptyStates";

export const TabMensagemEnviada: FC<{
  loading: boolean;
  mensagens: [IMensagem[], number];
  handleEdit: (idMensagem?: number | undefined) => void;
  handleDelete: (idMensagem: number) => void;
  handlePublicar: (idMensagem: number, publicar: boolean) => void;
  rowsPerPageOptions: number[];
  rowsPerPage: number;
  page: number;
  onChangePage: (event: unknown, newPage: number) => void;
  onChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void;
}> = ({
  loading,
  mensagens,
  handleEdit,
  handleDelete,
  handlePublicar,
  rowsPerPageOptions,
  rowsPerPage,
  page,
  onChangeRowsPerPage,
  onChangePage,
}) => {
  const handleDetalhes = (idMensagem?: number) =>
    history.push(`/publicacoes/detalhes/${idMensagem}/tab/1`);
  return (
    <>
      {mensagens[0].length > 0 && (
        <List component={Paper}>
          {mensagens[0].map((item, i) => (
            <ListItem
              key={`mensagem-${i}`}
              item={item}
              divider={true}
              handleEdit={handleEdit}
              handleDetalhes={handleDetalhes}
              handleDelete={handleDelete}
              handlePublicar={handlePublicar}
            />
          ))}
          <Pagination
            rowsPerPageOptions={rowsPerPageOptions}
            count={mensagens[1]}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={onChangePage}
            onChangeRowsPerPage={onChangeRowsPerPage}
          />
        </List>
      )}
      {!loading && mensagens[0].length === 0 && (
        <EmptyState
          type="404"
          title="Nenhuma publicação enviada!"
          subtitle="O Filtro selecionado não encontrou nenhuma publicação"
        />
      )}
    </>
  );
};
