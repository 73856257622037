import React, { FC, useState } from "react";

import { SpeedDial, SpeedDialAction, SpeedDialIcon } from "@mui/material";
import {
  ImageAlbum as FotoIcon,
  PlaylistCheck as TarefaIcon,
  ClipboardClock as AvaliacaoIcon,
  AccountGroup as ReuniaoIcon,
  Information as MensagensGeraisIcon,
} from "mdi-material-ui";

import { ZoomOnScroll } from "components/ZoomOnScroll";
import { Environment } from "configs";
import { useAppSelector } from "store";
import {
  EMensagemCategoriaTipo,
  IMensagemCategoria,
} from "@deltasge/marauders-map";
import { Float } from "components/Buttons/Float";

export const Icon: FC<{ categoria: IMensagemCategoria }> = ({ categoria }) => {
  return categoria.tipo ==
    EMensagemCategoriaTipo["Conteúdo para avaliações"] ? (
    <AvaliacaoIcon />
  ) : categoria.tipo == EMensagemCategoriaTipo.Fotos ? (
    <FotoIcon />
  ) : categoria.tipo == EMensagemCategoriaTipo["Tarefas de Casa"] ? (
    <TarefaIcon />
  ) : categoria.tipo == EMensagemCategoriaTipo.Reuniões ? (
    <ReuniaoIcon />
  ) : (
    <MensagensGeraisIcon />
  );
};

export const Add: FC<{
  handleAddMensagem: (
    type: "normal" | "pergunta" | "programada",
    idMensagem: number
  ) => void;
  categoria?: IMensagemCategoria;
  categorias: IMensagemCategoria[];
}> = ({ handleAddMensagem, categoria, categorias }) => {
  const [speedDialOpen, setSpeedDialOpen] = useState(false);

  const rightbarIsOpen = useAppSelector(
    (state) => state.layout.rightbar.isOpen
  );

  if (!categoria) {
    return (
      <ZoomOnScroll>
        <SpeedDial
          FabProps={{
            color: "secondary",
          }}
          onClose={() => setSpeedDialOpen(false)}
          onOpen={() => setSpeedDialOpen(true)}
          open={speedDialOpen}
          ariaLabel="Add publicação"
          sx={({ spacing }) => ({
            position: "fixed",
            bottom: spacing(2),
            right: !rightbarIsOpen
              ? spacing(2)
              : spacing(Environment.DRAWER_WIDTH + 2),
          })}
          icon={<SpeedDialIcon />}
        >
          {categorias.map((categoria) => (
            <SpeedDialAction
              key={`area-${categoria.id}`}
              icon={<Icon categoria={categoria} />}
              tooltipTitle={categoria.nome}
              onClick={() => {
                setSpeedDialOpen(false);
                handleAddMensagem("normal", categoria.id ?? 0);
              }}
            />
          ))}
        </SpeedDial>
      </ZoomOnScroll>
    );
  }
  return (
    <Float
      onClick={() => handleAddMensagem("normal", categoria.id ?? 0)}
      title={categoria.nome}
    />
  );
};
