import React, { FC, useState, useEffect, useRef } from "react";
import Axios from "axios";
import { IMensagemCategoria } from "@deltasge/marauders-map";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  TextField,
  Typography,
  InputAdornment,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  Radio,
  ListItemText,
} from "@mui/material";
import {
  ChevronDown as ExpandMoreIcon,
  Send as SendIcon,
} from "mdi-material-ui";
import { Environment } from "configs";
import { IFilterMensagem } from "interfaces";
import { useAppDispatch } from "store";
import { request as getCategorias } from "store/modules/categorias";
import { isTouchDevice } from "utils";

export const Filter: FC<{
  Component: () => JSX.Element;
  filter: IFilterMensagem;
  navigate: (params: IFilterMensagem) => void;
  mensagemCategoria: IMensagemCategoria[];
  tab?: string;
}> = ({ Component, filter, mensagemCategoria, navigate, tab }) => {
  const CancelToken = Axios.CancelToken;
  const source = CancelToken.source();
  const dispatch = useAppDispatch();

  const [panel, setPanel] = useState({ texto: false, rascunho: false });
  const [texto, setTexto] = useState(filter.texto ?? "");
  const field = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (mensagemCategoria.length == 0) {
      dispatch(getCategorias());
    }
    return () => source.cancel();
  }, []);

  return (
    <Box
      sx={{
        width: ({ spacing }) => spacing(Environment.DRAWER_WIDTH),
        display: "block",
      }}
    >
      <Component />
      <Accordion
        elevation={0}
        expanded={panel.texto}
        onChange={() => {
          setPanel((prev) => ({ ...prev, texto: !panel.texto }));
          if (field.current && !panel.texto) {
            window.setTimeout(() => field.current?.focus(), 500);
          }
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Grid container>
            <Grid item xs={12} component={Typography} variant="subtitle2">
              Texto da Publicação
            </Grid>
            <Grid item xs={12} component={Typography} variant="caption">
              {!filter.texto ? "QUALQUER TEXTO" : `CONTENHA: ${filter.texto}`}
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <TextField
            fullWidth
            placeholder="Texto da publicação contenha"
            inputRef={field}
            value={texto}
            InputProps={{
              endAdornment: !isTouchDevice() ? undefined : (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() =>
                      navigate({
                        ...filter,
                        texto: texto.length > 0 ? texto : undefined,
                      })
                    }
                  >
                    <SendIcon />
                  </IconButton>
                </InputAdornment>
              ),
              disableUnderline: true,
              sx: (theme) => ({
                backgroundColor: "transparent",
                "&.Mui-focused": {
                  backgroundColor: "transparent",
                },
                "& .MuiInputBase-input": {
                  paddingTop: theme.spacing(1.5),
                  paddingBottom: theme.spacing(1.5),
                },
              }),
            }}
            onChange={({ target: { value: texto } }) => setTexto(texto)}
            onKeyUp={(e) => {
              if (e.key == "Enter")
                navigate({
                  ...filter,
                  texto: texto.length > 0 ? texto : undefined,
                });
            }}
          />
        </AccordionDetails>
      </Accordion>
      {tab != "2" && (
        <Accordion
          elevation={0}
          expanded={panel.rascunho}
          onChange={() =>
            setPanel((prev) => ({
              ...prev,
              rascunho: !panel.rascunho,
            }))
          }
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Grid container>
              <Grid item xs={12} component={Typography} variant="subtitle2">
                Apresentar rascunho
              </Grid>
              <Grid item xs={12} component={Typography} variant="caption">
                {!filter.rascunho || filter.rascunho == 0
                  ? "Todos"
                  : filter.rascunho == 1
                  ? "À Publicar"
                  : "Publicados"}
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <List dense>
              <ListItemButton
                onClick={() => {
                  navigate({
                    ...filter,
                    rascunho: undefined,
                  });
                  setPanel((prev) => ({ ...prev, rascunho: false }));
                }}
              >
                <ListItemIcon>
                  <Radio
                    checked={
                      undefined == filter.rascunho || 0 == filter.rascunho
                    }
                  />
                </ListItemIcon>
                <ListItemText primary="Todos" />
              </ListItemButton>
              <ListItemButton
                onClick={() => {
                  navigate({
                    ...filter,
                    rascunho: 1,
                  });
                  setPanel((prev) => ({ ...prev, rascunho: false }));
                }}
              >
                <ListItemIcon>
                  <Radio checked={1 == filter.rascunho} />
                </ListItemIcon>
                <ListItemText primary="À Publicar" />
              </ListItemButton>
              <ListItemButton
                onClick={() => {
                  navigate({
                    ...filter,
                    rascunho: 2,
                  });
                  setPanel((prev) => ({ ...prev, rascunho: false }));
                }}
              >
                <ListItemIcon>
                  <Radio checked={2 == filter.rascunho} />
                </ListItemIcon>
                <ListItemText primary="Publicados" />
              </ListItemButton>
            </List>
          </AccordionDetails>
        </Accordion>
      )}
    </Box>
  );
};
