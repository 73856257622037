import React, { FC, useState } from "react";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Radio,
  TextField,
  Typography,
} from "@mui/material";
import { ChevronDown as ExpandMoreIcon } from "mdi-material-ui";

import { Environment } from "configs";
import { IChatFilter, Setor } from "../Chats";
import { IUser } from "@deltasge/marauders-map";
import { Avatar } from "components/Avatar";

export const ChatFilter: FC<{
  filter: IChatFilter;
  setFilter: (filter: IChatFilter) => void;
  usuarios: IUser[];
}> = ({ filter, setFilter, usuarios }) => {
  const [panel, setPanel] = useState({
    arquivado: false,
    setor: false,
    usuario: false,
  });

  let strSetor = "TODOS OS SETORES";
  if (filter.idSetor == "coordenacao") strSetor = "Coordenação";
  else if (filter.idSetor == "diretoria") strSetor = "Diretoria";
  else if (filter.idSetor == "professor") strSetor = "Professor";
  else if (filter.idSetor == "secretaria") strSetor = "Secretaria";
  else if (filter.idSetor == "tesouraria") strSetor = "Tesouraria";

  const setores = [
    { key: "coordenacao", name: "Coordenação" },
    { key: "diretoria", name: "Diretoria" },
    { key: "professor", name: "Professor" },
    { key: "secretaria", name: "Secretaria" },
    { key: "tesouraria", name: "Tesouraria" },
  ];

  return (
    <Box
      sx={{
        width: ({ spacing }) => spacing(Environment.DRAWER_WIDTH),
        display: "block",
      }}
    >
      <Accordion
        elevation={0}
        expanded={panel.arquivado}
        onChange={() =>
          setPanel((prev) => ({ ...prev, arquivado: !panel.arquivado }))
        }
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Grid container>
            <Grid item xs={12} component={Typography} variant="subtitle2">
              Conversas
            </Grid>
            <Grid item xs={12} component={Typography} variant="caption">
              {filter.arquivadas ? "Arquivadas" : "Não arquivadas"}
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <List dense>
            <ListItemButton
              onClick={() => {
                setFilter({ ...filter, arquivadas: true });
                setPanel((prev) => ({ ...prev, arquivado: false }));
              }}
            >
              <ListItemIcon>
                <Radio checked={filter.arquivadas} />
              </ListItemIcon>
              <ListItemText primary="Arquivadas" />
            </ListItemButton>
            <ListItemButton
              onClick={() => {
                setFilter({ ...filter, arquivadas: false });
                setPanel((prev) => ({ ...prev, arquivado: false }));
              }}
            >
              <ListItemIcon>
                <Radio checked={!filter.arquivadas} />
              </ListItemIcon>
              <ListItemText primary="Não arquivadas" />
            </ListItemButton>
          </List>
        </AccordionDetails>
      </Accordion>
      <Accordion
        elevation={0}
        expanded={panel.setor}
        onChange={() => setPanel((prev) => ({ ...prev, setor: !panel.setor }))}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Grid container>
            <Grid item xs={12} component={Typography} variant="subtitle2">
              Setores
            </Grid>
            <Grid item xs={12} component={Typography} variant="caption">
              {strSetor}
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <List dense>
            <ListItemButton
              onClick={() => {
                setFilter({ ...filter, idSetor: undefined });
                setPanel((prev) => ({ ...prev, setor: false }));
              }}
            >
              <ListItemIcon>
                <Radio checked={!filter.idSetor} />
              </ListItemIcon>
              <ListItemText primary="TODOS OS SETORES" />
            </ListItemButton>
            {setores.map(({ key, name }) => (
              <ListItemButton
                key={key}
                onClick={() => {
                  setFilter({ ...filter, idSetor: key as Setor });
                  setPanel((prev) => ({ ...prev, setor: false }));
                }}
              >
                <ListItemIcon>
                  <Radio checked={key == filter.idSetor} />
                </ListItemIcon>
                <ListItemText primary={name} />
              </ListItemButton>
            ))}
          </List>
        </AccordionDetails>
      </Accordion>
      <Accordion
        elevation={0}
        expanded={panel.usuario}
        onChange={() =>
          setPanel((prev) => ({ ...prev, usuario: !panel.usuario }))
        }
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Grid container>
            <Grid item xs={12} component={Typography} variant="subtitle2">
              Usuarios
            </Grid>
            <Grid item xs={12} component={Typography} variant="caption">
              {filter.usuario
                ? filter.usuario.displayName ?? filter.usuario.nome
                : "TODOS OS USUÁRIOS"}
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Autocomplete
            options={usuarios}
            autoHighlight
            getOptionLabel={(o) => o.displayName ?? o.nome}
            onChange={(e, usuario) => {
              setFilter({ ...filter, usuario: usuario as IUser });
              setPanel((prev) => ({ ...prev, usuario: false }));
            }}
            renderOption={(props, o) => (
              <ListItem {...props}>
                <ListItemAvatar>
                  <Avatar
                    displayName={o.displayName || o.nome}
                    src={o.photoUrl}
                    photoS3={o.photoS3}
                  />
                </ListItemAvatar>
                <ListItemText primary={o.displayName ?? o.nome} />
              </ListItem>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password",
                }}
                label="Escolha um usuário"
              />
            )}
          />
        </AccordionDetails>
      </Accordion>
      <Divider />
    </Box>
  );
};
