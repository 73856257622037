import React, { FC } from "react";
import { IDNColumn } from "@deltasge/marauders-map";
import { Tooltip, Typography } from "@mui/material";

export const TableCellDescription: FC<{
  column: IDNColumn;
  showDetalhes: boolean;
}> = ({ column, showDetalhes }) => {
  if (column.descricao) {
    const avaliacao = column.avaliacao;
    return (
      <Tooltip
        title={
          avaliacao ? (
            <p style={{ textAlign: "center", padding: 0, margin: 0 }}>
              {column.descricao}
              {showDetalhes && (
                <>
                  <br />
                  <b>Toque para mais opções</b>
                </>
              )}
            </p>
          ) : (
            column.descricao
          )
        }
        key={`column-${column.id}`}
      >
        <Typography
          variant="inherit"
          noWrap
          sx={{ flexGrow: 1 }}
          textAlign={column.align}
        >
          {column.nome}
        </Typography>
      </Tooltip>
    );
  }

  return (
    <Typography
      variant="inherit"
      noWrap
      sx={{ flexGrow: 1 }}
      textAlign={column.align}
    >
      {column.nome}
    </Typography>
  );
};
