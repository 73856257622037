import React, { FC } from "react";

import { Drawer, Box } from "@mui/material";

import { useAppSelector, useAppDispatch } from "store";
import { toggleRightbar } from "store/modules/layout";
import { useMobile } from "hooks/useMobile";
import PerfectScrollbar from "react-perfect-scrollbar";

import * as styles from "assets/styles/drawer";
import { ToolbarWrapper } from "assets/styles/drawer";

export const Rightbar: FC = ({ children }) => {
  const isMobile = useMobile("md");
  const open = useAppSelector((state) => state.layout.rightbar.isOpen);
  const dispatch = useAppDispatch();

  const drawerVariant = isMobile ? "temporary" : "persistent";

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={() => dispatch(toggleRightbar())}
      sx={styles.drawer}
      variant={drawerVariant}
      ModalProps={{
        keepMounted: true,
      }}
    >
      {!isMobile && <ToolbarWrapper />}
      <PerfectScrollbar>
        <Box sx={styles.container} role="presentation">
          {children}
        </Box>
      </PerfectScrollbar>
    </Drawer>
  );
};
