import React, { FC } from "react";
import { ptBR } from "date-fns/locale";
import { format, formatDistance, parseISO } from "date-fns";
import {
  EMensagemDestinatario,
  IMensagem,
  IDestinatarioMensagem,
} from "@deltasge/marauders-map";
import PerfectScrollbar from "react-perfect-scrollbar";

import {
  Stack,
  Chip,
  IconButton,
  List,
  ListItem as ListItemMUI,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  AccountCheck as EntregueIcon,
  AccountMinus as DevolvidoIcon,
  AccountArrowRight as AtribuidoIcon,
  Message as MessageIcon,
} from "mdi-material-ui";
import { history } from "configs";
import { Avatar } from "components/Avatar";

const ListItem = ({
  destinatario,
  mensagem,
}: {
  destinatario: IDestinatarioMensagem;
  mensagem: IMensagem;
}) => {
  let tab = 1;
  if (destinatario.tipo == EMensagemDestinatario.Alunos) tab = 2;
  else if (destinatario.tipo == EMensagemDestinatario.Responsáveis) tab = 3;
  else if (destinatario.tipo == EMensagemDestinatario["Logins extra do Aluno"])
    tab = 4;
  else if (destinatario.tipo == EMensagemDestinatario.Professores) tab = 5;

  let title = "Atribuido";
  let icon = <AtribuidoIcon />;
  if (destinatario.mensagemResposta) {
    if (destinatario.mensagemResposta.devolvido) {
      title = "Devolvido ";
      icon = <DevolvidoIcon color="error" />;
    } else {
      title = "Entregue ";
      icon = <EntregueIcon color="success" />;
    }
    title += formatDistance(
      parseISO((destinatario.mensagemResposta.updatedAt ?? "") as string),
      new Date(),
      { addSuffix: true, locale: ptBR }
    );
  } else if (mensagem.permiteResposta && !mensagem.respostaComAnexo) {
    title = "Conversar";
    icon = <MessageIcon />;
  }
  return (
    <ListItemMUI component={Paper} variant="outlined" sx={{ marginBottom: 1 }}>
      <ListItemAvatar>
        <Avatar displayName={destinatario.nome} src={destinatario.photoUrl} />
      </ListItemAvatar>
      <ListItemText
        primary={
          destinatario.displayName ? (
            <Tooltip title={destinatario.nome}>
              <Typography variant="caption" noWrap>
                {destinatario.codigo
                  .concat(
                    destinatario.nrChamada
                      ? " - ".concat(destinatario.nrChamada).concat(" - ")
                      : " - "
                  )
                  .concat(destinatario.displayName)}
              </Typography>
            </Tooltip>
          ) : (
            destinatario.codigo
              .concat(
                destinatario.nrChamada
                  ? " - ".concat(destinatario.nrChamada).concat(" - ")
                  : " - "
              )
              .concat(destinatario.nome)
          )
        }
        secondary={
          <Stack
            direction="row"
            justifyContent="space-between"
            spacing={{ xs: 12 }}
            component="span"
          >
            <Typography variant="caption" noWrap>
              {destinatario.detalhe?.codigo
                .concat(
                  destinatario.detalhe?.nrChamada
                    ? " - "
                        .concat(destinatario.detalhe?.nrChamada)
                        .concat(" - ")
                    : " - "
                )
                .concat(destinatario.detalhe?.nome)}
            </Typography>
            {destinatario.mensagemVisualizacao && (
              <Tooltip
                title={"visto ".concat(
                  format(
                    parseISO(
                      (destinatario.mensagemVisualizacao.updatedAt ??
                        "") as string
                    ),
                    "dd 'de' MMM. 'de' yyyy 'às' H:mm"
                  )
                )}
              >
                <Typography variant="caption" color="primary" noWrap>
                  {formatDistance(
                    parseISO(
                      (destinatario.mensagemVisualizacao.updatedAt ??
                        "") as string
                    ),
                    new Date(),
                    { addSuffix: true, locale: ptBR }
                  )}
                </Typography>
              </Tooltip>
            )}
          </Stack>
        }
        primaryTypographyProps={{ variant: "body2" }}
      />
      {destinatario.mensagemRespostaFormatada && (
        <ListItemSecondaryAction>
          <Tooltip
            title={"respondido ".concat(
              formatDistance(
                parseISO(
                  (destinatario.mensagemRespostaFormatada.updatedAt ??
                    "") as string
                ),
                new Date(),
                { addSuffix: true, locale: ptBR }
              )
            )}
          >
            <Chip
              label={
                destinatario.mensagemRespostaFormatada?.perguntaFormatada?.nome
              }
            />
          </Tooltip>
        </ListItemSecondaryAction>
      )}
      {mensagem.permiteResposta && mensagem.perguntasFormatadas?.length == 0 && (
        <ListItemSecondaryAction>
          <Tooltip title={title}>
            <IconButton
              onClick={() =>
                history.push(
                  `/publicacoes/detalhes/${mensagem.id}/tab/${tab}/destinatario/${destinatario.id}`
                )
              }
              edge="end"
              aria-label="chat"
            >
              {icon}
            </IconButton>
          </Tooltip>
        </ListItemSecondaryAction>
      )}
    </ListItemMUI>
  );
};

export const ListItemDestinatario: FC<{
  grupos: [string, IDestinatarioMensagem[]][];
  mensagem: IMensagem;
}> = ({ grupos, mensagem }) => {
  return (
    <PerfectScrollbar>
      {grupos.length > 1 ? (
        <>
          {grupos.map((grupo) => (
            <List
              sx={{ padding: 1 }}
              key={grupo[0]}
              component="nav"
              aria-labelledby="nested-list-subheader"
              subheader={
                <ListSubheader component="div">
                  <Typography variant="h6">{grupo[0]}</Typography>
                </ListSubheader>
              }
            >
              {grupo[1].map((destinatario, index) => {
                return (
                  <ListItem
                    key={`destinatario-${index}`}
                    destinatario={destinatario}
                    mensagem={mensagem}
                  />
                );
              })}
            </List>
          ))}
        </>
      ) : (
        <List sx={{ padding: 1 }}>
          {grupos[0][1].map((destinatario, index) => (
            <ListItem
              key={`aluno-${destinatario.id}-${index}`}
              destinatario={destinatario}
              mensagem={mensagem}
            />
          ))}
        </List>
      )}
    </PerfectScrollbar>
  );
};
