import {
  IConfiguracoesEducador,
  IPeriodoEtapa,
  ITipoAvaliacaoOnline,
} from "@deltasge/marauders-map";

export enum ActionTypes {
  SET = "@configuracoes/SET",
}

export type ConfiguracoesEducador = Pick<
  IConfiguracoesEducador,
  "configOnline"
> & {
  tiposAvaliacoes: ITipoAvaliacaoOnline[];
  etapas: IPeriodoEtapa[];
};
