import React, { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Axios, { CancelTokenSource } from "axios";
import {
  EMensagemDestinatario,
  IDestinatarioMensagem,
  IMensagem,
  IMensagemAtribuicao,
} from "@deltasge/marauders-map";

import { Alert as AlertMUI, Grid, LinearProgress } from "@mui/material";

import { snack } from "components/GlobalSnackbar";
import { Hidden } from "components/Hidden";
import { Alert } from "components/Alert";
import { api } from "configs";
import { useMobile } from "hooks/useMobile";
import { useWidth } from "hooks/useWidth";
import { getError, groupBy } from "utils";

import { DetalhesDestinatario } from "./DetalhesDestinatario";
import { ListItemDestinatario } from "./ListItemDestinatario";
import { IFilter } from "../Detalhes";

import imgMensagem from "assets/images/task-check.png";

export const TabResponsaveis: FC<{
  mensagemAtribuicoes: IMensagemAtribuicao[];
  source: CancelTokenSource;
  responsaveis: IDestinatarioMensagem[];
  setResponsaveis: (responsaveis: IDestinatarioMensagem[]) => void;
  mensagem: IMensagem;
  filter: IFilter;
}> = ({
  mensagemAtribuicoes,
  source,
  mensagem,
  responsaveis: todos,
  setResponsaveis,
  filter,
}) => {
  const [loading, setLoading] = useState(false);
  const { destinatario } = useParams<{
    destinatario?: string;
  }>();
  const isLg = useMobile("lg");
  const width = useWidth();

  useEffect(() => {
    const getDestinatario = async () => {
      try {
        setLoading(true);
        const destinatarios: IDestinatarioMensagem[] = [];
        for await (const mensagemAtribuicao of mensagemAtribuicoes) {
          const { data } = await api.get<IDestinatarioMensagem[]>(
            `educador/mensagem/${mensagem.id}/atribuicao/${mensagemAtribuicao.id}/destinatario/${EMensagemDestinatario.Responsáveis}`,
            {
              cancelToken: source.token,
            }
          );
          if (data && data.length > 0) {
            destinatarios.push(...data);
          }
        }
        if (destinatarios.length > 0) {
          setResponsaveis(destinatarios);
        }
      } catch (err) {
        if (!Axios.isCancel(err)) {
          snack.warning(getError(err));
        }
      } finally {
        setLoading(false);
      }
    };
    if (todos.length == 0) getDestinatario();
    return () => source.cancel();
  }, [mensagemAtribuicoes]);

  let responsaveis = todos;
  if (filter.pergunta > 0) {
    responsaveis = todos.filter(
      (f) =>
        f.mensagemRespostaFormatada != undefined &&
        f.mensagemRespostaFormatada.idMensagemPerguntaFormatada ==
          filter.pergunta
    );
  }

  if (filter.devolvido != undefined) {
    responsaveis = todos.filter(
      (f) =>
        f.mensagemResposta != undefined &&
        f.mensagemResposta.devolvido == filter.devolvido
    );
  }

  const turmas =
    responsaveis.length > 0
      ? groupBy(responsaveis, (resp) => resp.grupo?.nome ?? "")
      : [];

  const responsavel = responsaveis.find(
    (r) => r.id == parseInt(destinatario ?? "", 10)
  );

  return (
    <Grid container spacing={2} height="100%">
      {loading && (
        <Grid item xs={12} p={2}>
          <LinearProgress sx={{ m: 2 }} />
        </Grid>
      )}
      {responsaveis.length == 0 && !loading && (
        <Grid item xs={12}>
          <AlertMUI severity="info" sx={{ m: 2 }}>
            Nenhum responsável encontrado!
          </AlertMUI>
        </Grid>
      )}
      {responsaveis.length > 0 && (
        <>
          <Hidden visible={!responsavel || !isLg}>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={4}
              height={isLg ? "auto" : "100%"}
            >
              <ListItemDestinatario
                grupos={Object.entries(turmas)}
                mensagem={mensagem}
              />
            </Grid>
          </Hidden>
          <Hidden visible={!responsavel && ["lg", "xl"].includes(width)}>
            <Grid item lg>
              <Alert imageSrc={imgMensagem} />
            </Grid>
          </Hidden>
          <Hidden visible={!!responsavel}>
            <Grid item xs={12} sm={12} md={12} lg={8} height="100%">
              {responsavel && (
                <DetalhesDestinatario
                  mensagem={mensagem}
                  destinatario={responsavel}
                  source={source}
                  showClose={isLg}
                />
              )}
            </Grid>
          </Hidden>
        </>
      )}
    </Grid>
  );
};
