import { useState, ChangeEvent, FormEvent } from "react";
import * as Yup from "yup";

type ErrorRecord<T> = Partial<Record<keyof T, string>>;

export const useForm = <T extends Record<string, any> = {}>(options?: {
  initialValues?: Partial<T>;
  onSubmit?: (data: T, submitter?: HTMLButtonElement) => void;
  schema?: Yup.AnySchema;
  trackSubmitter?: boolean;
}) => {
  const [data, setData] = useState<T>((options?.initialValues || {}) as T);
  const [errors, setErrors] = useState<ErrorRecord<T>>({});

  let submitter: HTMLButtonElement | undefined = undefined;

  const setValue = (key: keyof T, value: unknown) =>
    setData({
      ...data,
      [key]: value,
    });

  const handleChange =
    <S>(key: keyof T, sanitizeFn?: (value: string) => S) =>
    (e: ChangeEvent<HTMLInputElement & HTMLSelectElement>) => {
      const value = sanitizeFn ? sanitizeFn(e.target.value) : e.target.value;
      setData({
        ...data,
        [key]: value,
      });
    };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (
      options?.trackSubmitter &&
      (e.nativeEvent as SubmitEvent) != undefined
    ) {
      const submitButton = (e.nativeEvent as SubmitEvent).submitter;
      if (
        submitButton &&
        (submitButton as HTMLButtonElement).name == "submit"
      ) {
        submitter = submitButton as HTMLButtonElement;
      }
    }
    const newErrors: ErrorRecord<T> = {};
    let hasError = false;
    if (options?.schema) {
      try {
        options?.schema.validateSync(data);
      } catch (error) {
        if (error as Yup.ValidationError) {
          newErrors[(error as Yup.ValidationError).path as keyof T] = (
            error as Yup.ValidationError
          ).message;
          hasError = true;
        } else {
          throw new Error((error as any).toString());
        }
      }
    }
    setErrors(newErrors);

    if (hasError) return;

    if (options?.onSubmit) {
      options.onSubmit(data, submitter);
    }
  };

  return {
    data,
    handleChange,
    handleSubmit,
    errors,
    setValue,
  };
};
