import React, { FC } from "react";
import { EMensagemCategoriaTipo, IMensagem } from "@deltasge/marauders-map";

import {
  Divider,
  Grid,
  ImageList,
  ImageListItem,
  Typography,
  useTheme,
} from "@mui/material";
import { format, parseISO } from "date-fns";
import { DetalhesAnexos } from "./DetalhesAnexos";
import { Imagem } from "components/Imagem";
import { useMobile } from "hooks/useMobile";

export const TabDetalhes: FC<{
  mensagem: IMensagem;
}> = ({ mensagem }) => {
  const theme = useTheme();
  const isSm = useMobile("sm");
  const isMd = useMobile("md");

  return (
    <Grid container>
      <Grid item xs={12}>
        {mensagem.titulo && (
          <Typography variant="h5" noWrap>
            {mensagem.titulo}
          </Typography>
        )}
        <Typography variant="subtitle1" noWrap>
          {mensagem.mensagemCategoria?.nome}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography variant="caption">
          {mensagem.usuario?.nome ?? mensagem.professor?.nome}
          &nbsp;&bull;&nbsp;
          {mensagem.createdAt
            ? format(
                parseISO(mensagem.createdAt.toString()),
                "dd 'de' MMM. 'de' yyyy"
              )
            : ""}
          {mensagem.updatedAt && mensagem.createdAt != mensagem.updatedAt
            ? format(
                parseISO(mensagem.updatedAt.toString()),
                "' Editado às' dd 'de' MMM. 'de' yyyy"
              )
            : ""}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        {mensagem.mensagemAtribuicoes && (
          <Typography
            variant="caption"
            textAlign="right"
            component="p"
            fontWeight="bold"
          >
            Matéria:{" "}
            {mensagem.mensagemAtribuicoes[0].materia?.nome ??
              "TODAS AS MATÉRIAS"}
          </Typography>
        )}
      </Grid>
      {(mensagem.respostaAte || mensagem.enviarEm) && (
        <Grid item xs={12}>
          {mensagem.enviarEm && (
            <Typography variant="caption" sx={{ float: "left" }}>
              Será enviado em:
              {format(
                parseISO(mensagem.enviarEm.toString()),
                " dd 'de' MMM. 'de' yyyy 'às' H:mm"
              )}
            </Typography>
          )}
          {mensagem.respostaAte && (
            <Typography variant="caption" sx={{ float: "right" }}>
              Data da entrega:
              {format(
                parseISO(mensagem.respostaAte.toString()),
                " dd 'de' MMM. 'de' yyyy 'às' H:mm"
              )}
            </Typography>
          )}
        </Grid>
      )}
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <div dangerouslySetInnerHTML={{ __html: mensagem.texto ?? "" }} />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      {((mensagem.mensagemArquivos ?? []).length > 0 ||
        (mensagem.mensagemLinks ?? []).length > 0) && (
        <Grid item xs={12}>
          {mensagem.mensagemCategoria?.tipo == EMensagemCategoriaTipo.Fotos && (
            <ImageList
              cols={isSm ? 1 : isMd ? 2 : 3}
              rowHeight={parseInt(theme.spacing(20), 10)}
            >
              {(mensagem.mensagemArquivos ?? []).map((foto, index) => (
                <ImageListItem key={`image-${index}`}>
                  <Imagem
                    src={foto.arquivo?.caminhoArquivo}
                    alt={foto.arquivo?.caminhoArquivo ?? ""}
                    style={{ maxHeight: theme.spacing(20), objectFit: "cover" }}
                  />
                </ImageListItem>
              ))}
            </ImageList>
          )}
          {mensagem.mensagemCategoria?.tipo != EMensagemCategoriaTipo.Fotos && (
            <DetalhesAnexos
              mensagemArquivos={mensagem.mensagemArquivos ?? []}
              mensagemLinks={mensagem.mensagemLinks ?? []}
            />
          )}
        </Grid>
      )}
    </Grid>
  );
};
