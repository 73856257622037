import React, { FC, useState } from "react";
import * as Yup from "yup";
import { Button, DialogActions, DialogContent, TextField } from "@mui/material";
import {
  IMensagemLink,
  schemaMensagemLink,
  yupMensagemLink,
} from "@deltasge/marauders-map";

export const DialogLink: FC<{
  link?: IMensagemLink;
  idMensagem: number | null;
  set: (link: IMensagemLink) => void;
}> = ({ link, set, idMensagem }) => {
  const [text, setText] = useState(link?.url ?? "");
  const [error, setError] = useState<string>();

  const validate = (url: string): boolean => {
    try {
      schemaMensagemLink.validateSync({
        ...yupMensagemLink.getDefault(),
        idMensagem,
        url: url.indexOf("http") >= 0 ? url : "http://".concat(url),
      });
      setError(undefined);
      return true;
    } catch (e) {
      if (e as Yup.ValidationError)
        setError((e as Yup.ValidationError).message);
      else setError((e as Error).message);
      return false;
    }
  };

  return (
    <>
      <DialogContent dividers>
        <TextField
          label="URL"
          onKeyUp={({ key }) => {
            if (text.trim() == "") return;
            key == "Enter" && validate(text)
              ? set({
                  url: text.indexOf("http") ? text : "http://".concat(text),
                  idMensagem: link?.idMensagem ?? null,
                  ordem: link?.ordem ?? 0,
                  id: link?.id,
                })
              : null;
          }}
          onChange={({ target: { value } }) => setText(value)}
          value={text}
          error={!!error}
          helperText={error}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            if (text.trim() == "") return;
            if (!validate(text)) return;
            set({
              idMensagem: link?.idMensagem ?? null,
              url: text,
              ordem: link?.ordem ?? 0,
              id: link?.id,
            });
          }}
        >
          OK
        </Button>
      </DialogActions>
    </>
  );
};
