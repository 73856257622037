import React, { FC, useState } from "react";
import {
  EUserType,
  IMensagemPerguntaFormatada,
  IMensagemRespostaFormatada,
} from "@deltasge/marauders-map";
import {
  Chip,
  CircularProgress,
  Grid,
  Paper,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { CheckboxMarkedCircle as CheckedIcon } from "mdi-material-ui";
import { formatDistance, parseISO } from "date-fns";
import { ptBR } from "date-fns/locale";
import { getError } from "utils";
import { snack } from "components/GlobalSnackbar";
import { api } from "configs";
import { useAppSelector } from "store";

export const MensagemPerguntaFormatada: FC<{
  perguntasFormatadas: IMensagemPerguntaFormatada[];
  respostasFormatadas?: IMensagemRespostaFormatada[];
  idMensagem: number;
  permitirResposta: boolean;
}> = ({
  perguntasFormatadas,
  respostasFormatadas,
  idMensagem,
  permitirResposta,
}) => {
  const {
    usuario: { usuario },
  } = useAppSelector((state) => state);

  const [loading, setLoading] = useState<number>();
  const [resposta, setResposta] = useState<
    IMensagemRespostaFormatada | undefined
  >(respostasFormatadas ? respostasFormatadas[0] : undefined);
  const theme = useTheme();
  const handleResponder = async (idMensagemPerguntaFormatada: number) => {
    try {
      setLoading(idMensagemPerguntaFormatada);
      let keyUsuario = "idAluno";
      if (usuario?.tipoUsuario == EUserType.Professor)
        keyUsuario = "idProfessor";
      else if (usuario?.tipoUsuario == EUserType.Responsavel)
        keyUsuario = "idResponsavel";
      else if (usuario?.tipoUsuario == EUserType.LoginExtra)
        keyUsuario = "idLoginExtra";

      const params = {
        idMensagem,
        idMensagemPerguntaFormatada,
        [keyUsuario]: usuario?.id,
      };

      if (respostasFormatadas && respostasFormatadas.length > 0) {
        const { data } = await api.put<IMensagemRespostaFormatada>(
          `educador/mensagem-resposta-formatada/${respostasFormatadas[0].id}`,
          params
        );
        if (data) setResposta(data);
      } else {
        const { data } = await api.post<IMensagemRespostaFormatada>(
          "educador/mensagem-resposta-formatada",
          params
        );
        if (data) setResposta(data);
      }
      setLoading(undefined);
    } catch (err) {
      setLoading(undefined);
      snack.warning(getError(err));
    }
  };

  return (
    <Grid
      item
      xs={12}
      component={Paper}
      elevation={4}
      sx={{ flex: 1, padding: ({ spacing }) => spacing(2) }}
    >
      <Typography variant="h6" textAlign="center">
        Escolha uma resposta
      </Typography>
      <Stack direction="column" spacing={1} mt={1}>
        {perguntasFormatadas.map((m) => {
          const checked = resposta?.idMensagemPerguntaFormatada == m.id;
          return (
            <Chip
              disabled={!permitirResposta}
              color={checked ? "primary" : undefined}
              key={`pergunta-${m.id}`}
              label={m.nome}
              icon={
                loading == m.id ? (
                  <CircularProgress size={theme.spacing(3)} />
                ) : checked ? (
                  <CheckedIcon />
                ) : undefined
              }
              onClick={() => handleResponder(m.id ?? 0)}
            />
          );
        })}
      </Stack>
      {resposta?.updatedAt && (
        <Typography
          textAlign="center"
          paddingTop={1}
          variant="caption"
          component="p"
        >
          {"Respondido ".concat(
            formatDistance(
              parseISO(resposta.updatedAt.toString()),
              Date.now(),
              {
                addSuffix: true,
                locale: ptBR,
              }
            )
          )}
        </Typography>
      )}
    </Grid>
  );
};
