import React, { FC, useEffect, useState } from "react";
import { IconButton, SxProps, Theme, Tooltip } from "@mui/material";
import { Bell as BellIcon } from "mdi-material-ui";
import { useAppDispatch } from "store";
import { toggleFeedback } from "store/modules/layout";

export const ButtonFeedback: FC<{ animate?: boolean }> = ({
  animate = false,
}) => {
  const [animation, setAnimation] = useState<SxProps<Theme>>();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (animate) {
      setAnimation({
        animation: "rubberBand",
        animationDuration: "2s",
        animationIterationCount: "infinite",
      });
      setTimeout(() => setAnimation(undefined), 10000);
    }
  }, [animate]);

  return (
    <Tooltip title="Notificações">
      <IconButton onClick={() => dispatch(toggleFeedback())} color="inherit">
        <BellIcon sx={animation} />
      </IconButton>
    </Tooltip>
  );
};
