import React, { FC, useState } from "react";
import { formatDistance, parseISO } from "date-fns";
import { ptBR } from "date-fns/locale";
import { IChatGroup } from "@deltasge/marauders-map";

import {
  AvatarGroup,
  Grid,
  IconButton,
  ListItem as MUIListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import {
  DotsVertical as MenuIcon,
  Delete as DeleteIcon,
  ArchiveArrowDown as ArquivaIcon,
  ArchiveArrowUp as DesarquivarIcon,
} from "mdi-material-ui";

import { history } from "configs";
import { Avatar } from "components/Avatar";
import { useAppSelector } from "store";

export const ListItem: FC<{
  item: IChatGroup;
  divider?: boolean;
  handleChat: ({
    chat,
    action,
    add,
  }: {
    chat: IChatGroup;
    action: "archived" | "deleted";
    add?: boolean | undefined;
  }) => Promise<void>;
}> = ({ item, divider = false, handleChat }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const {
    usuario: { usuario },
  } = useAppSelector((state) => state);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => setAnchorEl(null);

  return (
    <MUIListItem
      disablePadding
      secondaryAction={
        <>
          <IconButton onClick={handleClick}>
            <MenuIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem
              onClick={() => {
                handleClose();
                handleChat({ chat: item, action: "deleted", add: true });
              }}
            >
              <ListItemIcon>
                <DeleteIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Excluir</ListItemText>
            </MenuItem>
            {!!item.archived.find((f) => f.deltaId == usuario?.deltaId) && (
              <MenuItem
                onClick={() => {
                  handleClose();
                  handleChat({ chat: item, action: "archived", add: false });
                }}
              >
                <ListItemIcon>
                  <DesarquivarIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Desarquivar</ListItemText>
              </MenuItem>
            )}
            {(!item.archived ||
              !item.archived.find((f) => f.deltaId == usuario?.deltaId)) && (
              <MenuItem
                onClick={() => {
                  handleClose();
                  handleChat({ chat: item, action: "archived", add: true });
                }}
              >
                <ListItemIcon>
                  <ArquivaIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Arquivar</ListItemText>
              </MenuItem>
            )}
          </Menu>
        </>
      }
    >
      <ListItemButton
        onClick={() => history.push(`/chat/channel/${item._id}`)}
        divider={divider}
      >
        <ListItemAvatar>
          <AvatarGroup max={2}>
            {item.chatUsers &&
              item.chatUsers.map((user) => (
                <Avatar
                  key={user.deltaId}
                  displayName={user.displayName ?? user.nome}
                  src={user.photoUrl}
                />
              ))}
          </AvatarGroup>
        </ListItemAvatar>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="body1">
              {item.chatUsers
                ? item.chatUsers
                    .map((user) =>
                      user.deltaId == usuario?.deltaId
                        ? "Você"
                        : user.displayName ?? user.nome
                    )
                    .join(", ")
                : ""}
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="caption">
              {item.chats ? item.chats[0].context : ""}
            </Typography>
          </Grid>
          <Grid item xs={4} textAlign="right">
            <Typography variant="caption">
              {item.chats && item.chats[0].createdAt
                ? formatDistance(
                    parseISO(item.chats[0].createdAt.toString()),
                    new Date(),
                    {
                      addSuffix: true,
                      locale: ptBR,
                    }
                  )
                : ""}
            </Typography>
          </Grid>
        </Grid>
      </ListItemButton>
    </MUIListItem>
  );
};
