import { createReducer, PayloadAction } from "@reduxjs/toolkit";
import { IUser } from "@deltasge/marauders-map";

import { ActionTypes, IUsuariosAuth } from "./types";

const INITIAL_STATE: IUsuariosAuth = {
  usuario: null,
  usuarios: [],
};

export const reducers = createReducer(INITIAL_STATE, {
  [ActionTypes.SET]: (state, action: PayloadAction<IUser>) => {
    const { payload: usuario } = action;
    const index = state.usuarios.findIndex(
      (f) => f.escolaId === usuario.escolaId && f.id === usuario.id
    );
    const newUsuarios: IUser[] = [...state.usuarios];
    if (index < 0) newUsuarios.push(usuario);
    else newUsuarios[index] = usuario;
    return {
      usuario,
      usuarios: newUsuarios,
    };
  },
});
