import React, { FC } from "react";
import { Tab } from "@mui/material";
import { TabList } from "@mui/lab";

import { history } from "configs";

export const Tabs: FC<{
  tab: string;
  idMensagemCategoria?: string;
}> = ({ tab, idMensagemCategoria }) => {
  return (
    <TabList
      value={tab}
      onChange={(_, tab) =>
        history.push(
          `/publicacoes/${
            idMensagemCategoria
              ? idMensagemCategoria.concat("/categoria")
              : "todas/categoria"
          }/tab/${tab}`
        )
      }
      aria-label="Tabs"
      textColor="inherit"
      indicatorColor="secondary"
    >
      <Tab label="Enviadas" value="1" />
      <Tab label="Recebidas" value="2" />
    </TabList>
  );
};
