import React, {
  createContext,
  useEffect,
  useContext,
  useCallback,
  useState,
} from "react";
import { Children, IBeforeInstallPromptEvent, PromptCtx } from "./types";

const PromptToInstall = createContext<PromptCtx>({
  deferredEvt: null,
  isInstalled: false,
});

export function PromptToInstallProvider(props: Children) {
  const [isInstalled, setIsInstalled] = useState(false);
  const [deferredEvt, setDeferredEvt] =
    useState<IBeforeInstallPromptEvent | null>(null);

  const hidePrompt = useCallback(() => {
    setDeferredEvt(null);
  }, []);

  useEffect(() => {
    const ready = (e: IBeforeInstallPromptEvent) => {
      e.preventDefault();
      setDeferredEvt(e);
    };

    const onInstall = () => {
      setIsInstalled(true);
    };

    window.addEventListener("beforeinstallprompt", ready as any);
    window.addEventListener("appinstalled", onInstall as any);

    return () => {
      window.removeEventListener("beforeinstallprompt", ready as any);
      window.removeEventListener("appinstalled", onInstall as any);
    };
  }, []);

  return (
    <PromptToInstall.Provider value={{ deferredEvt, hidePrompt, isInstalled }}>
      {props.children}
    </PromptToInstall.Provider>
  );
}

export function usePromptToInstall() {
  const ctx = useContext(PromptToInstall);
  if (!ctx) {
    throw new Error(
      "Cannot use usePromptToInstall() outside <PromptToInstallProvider />"
    );
  }
  return ctx;
}
