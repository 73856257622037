import React, { FC } from "react";
import { Button, Grid, Typography } from "@mui/material";

export const ChatUndo: FC<{
  action: "archived" | "deleted";
  handleClick: () => void;
}> = ({ action, handleClick }) => {
  return (
    <Grid container alignItems="center">
      <Grid item flexGrow={1}>
        <Typography variant="subtitle2" component="p">
          Conversa {action == "archived" ? "arquivada" : "removida"}
        </Typography>
      </Grid>
      <Grid item sx={{ pl: 1 }}>
        <Button onClick={handleClick}>Desfazer</Button>
      </Grid>
    </Grid>
  );
};
