import React, { FC, useState } from "react";
import { IMensagem } from "@deltasge/marauders-map";
import {
  Grid,
  IconButton,
  ListItem as MUIListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
  Chip,
} from "@mui/material";
import {
  DotsVertical as MoreIcon,
  Pencil as EditIcon,
  Delete as DeleteIcon,
  TextBoxSearch as DetailIcon,
  Check as PublicarIcon,
} from "mdi-material-ui";
import {
  differenceInMinutes,
  format,
  formatDistance,
  parseISO,
} from "date-fns";
import { ptBR } from "date-fns/locale";
import { useMobile } from "hooks/useMobile";
export const ListItem: FC<{
  item: IMensagem;
  divider?: boolean;
  handleEdit: (idMensagem?: number) => void;
  handleDetalhes: (idMensagem?: number) => void;
  handleDelete: (idMensagem: number) => void;
  handlePublicar: (idMensagem: number, publicar: boolean) => void;
}> = ({
  item,
  divider = false,
  handleEdit,
  handleDetalhes,
  handleDelete,
  handlePublicar,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const isSm = useMobile("sm");

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => setAnchorEl(null);

  const atribuicao =
    item.mensagemAtribuicoes != null ? item.mensagemAtribuicoes[0] : null;
  const enviadoPara: string[] = [];
  let materia = "Independente da matéria";
  let turma = "Todos os cursos";
  if (atribuicao?.materia) {
    materia = atribuicao.materia?.nome;
  }

  const mensagemAtribuicoes = item.mensagemAtribuicoes ?? [];
  let turmas = mensagemAtribuicoes.map((m) => m.turma?.tituloTurma);

  if (mensagemAtribuicoes.filter((f) => !!f.turma?.tituloTurma).length > 0) {
    turma = mensagemAtribuicoes.map((m) => m.turma?.tituloTurma).join(", ");
  }
  if (turmas.length > 3) {
    turma = turmas.slice(0, 3).join(", ");
    turmas = turmas.slice(3);
  }

  if (atribuicao?.aluno) {
    enviadoPara.push("Alunos");
  }
  if (atribuicao?.responsavel) {
    enviadoPara.push("Responsáveis");
  }
  if (atribuicao?.loginExtra) {
    enviadoPara.push("Logins dos Alunos");
  }
  if (atribuicao?.professor) {
    enviadoPara.push("Professores");
  }

  return (
    <MUIListItem divider={divider}>
      <Grid container justifyContent="space-between" spacing={1}>
        <Grid item xs={6} sm={4} lg={2}>
          <Typography variant="caption">Categoria</Typography>
          <Typography variant="caption" fontWeight="bold" component="p">
            {item.mensagemCategoria?.nome}
          </Typography>
        </Grid>
        <Grid item xs={6} sm={4} lg={2}>
          <Typography variant="caption">Enviado</Typography>
          <Tooltip
            title={format(
              parseISO((item.createdAt ?? "") as string),
              "dd/MM/yyyy H:mm:ss"
            )}
          >
            <Typography variant="caption" fontWeight="bold" component="p">
              {formatDistance(
                parseISO((item.createdAt ?? "") as string),
                new Date(),
                { addSuffix: true, locale: ptBR }
              )}
            </Typography>
          </Tooltip>
        </Grid>
        <Grid item xs={6} sm={4} lg={2}>
          <Typography variant="caption">Turma</Typography>
          <Typography variant="caption" fontWeight="bold" component="p">
            {turma}
          </Typography>
          {turmas.length > 3 && (
            <Tooltip title={turmas.join(", ")}>
              <Chip label={`+ ${turmas.length}`} size="small" color="primary" />
            </Tooltip>
          )}
        </Grid>
        <Grid item xs={6} sm={4} lg={2}>
          <Typography variant="caption">Matéria</Typography>
          <Typography variant="caption" fontWeight="bold" component="p">
            {materia.rUpper()}
          </Typography>
        </Grid>
        <Grid item xs={6} sm={4} lg={2}>
          <Typography variant="caption">Enviado para</Typography>
          <Typography variant="caption" fontWeight="bold" component="p">
            {enviadoPara.join(", ")}
          </Typography>
        </Grid>
        <Grid item xs={6} sm={4} lg={2}>
          {item.enviarEm &&
            differenceInMinutes(
              parseISO(item.enviarEm.toString()),
              new Date()
            ) > 0 && (
              <>
                <Typography variant="caption">Será entregue</Typography>
                <Tooltip
                  title={format(
                    parseISO((item.enviarEm.toString() ?? "") as string),
                    "dd/MM/yyyy"
                  )}
                >
                  <Typography
                    variant="caption"
                    fontWeight="bold"
                    component="p"
                    sx={{
                      color: (theme) => theme.palette.primary.main,
                    }}
                  >
                    {formatDistance(
                      parseISO(item.enviarEm.toString()),
                      new Date(),
                      {
                        addSuffix: true,
                        locale: ptBR,
                      }
                    )}
                  </Typography>
                </Tooltip>
              </>
            )}
        </Grid>
        <Grid item xs={12}>
          {item.titulo && (
            <Typography variant="body1" noWrap>
              {item.titulo}
            </Typography>
          )}
          {item.texto && !item.titulo && (
            <div dangerouslySetInnerHTML={{ __html: item.texto ?? "" }} />
          )}
        </Grid>
      </Grid>
      <ListItemSecondaryAction>
        {!isSm && (
          <>
            <Tooltip title={!item.rascunho ? "Publicado" : "Publicar"}>
              <IconButton
                color={!item.rascunho ? "primary" : "secondary"}
                onClick={() => handlePublicar(item.id ?? 0, !item.rascunho)}
              >
                <PublicarIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Detalhes">
              <IconButton onClick={() => handleDetalhes(item.id)}>
                <DetailIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Editar">
              <IconButton onClick={() => handleEdit(item.id)}>
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Remover">
              <IconButton onClick={() => handleDelete(item.id ?? 0)}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </>
        )}
        {isSm && (
          <>
            <IconButton onClick={handleClick}>
              <MoreIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem
                onClick={() => {
                  handleClose();
                  handlePublicar(item.id ?? 0, !item.rascunho);
                }}
              >
                <ListItemIcon>
                  <PublicarIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>
                  {!item.rascunho ? "Publicado" : "Publicar"}
                </ListItemText>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleClose();
                  handleEdit(item.id);
                }}
              >
                <ListItemIcon>
                  <EditIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Editar</ListItemText>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleClose();
                  handleDetalhes(item.id);
                }}
              >
                <ListItemIcon>
                  <DetailIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Detalhes</ListItemText>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleClose();
                  handleEdit(item.id);
                }}
              >
                <ListItemIcon>
                  <EditIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Editar</ListItemText>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleClose();
                  handleDelete(item.id ?? 0);
                }}
              >
                <ListItemIcon>
                  <DeleteIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Excluir</ListItemText>
              </MenuItem>
            </Menu>
          </>
        )}
      </ListItemSecondaryAction>
    </MUIListItem>
  );
};
