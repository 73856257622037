import React, { FC, ImgHTMLAttributes, useEffect, useState } from "react";
import { utils } from "@deltasge/marauders-map";
import { loadS3File } from "utils";
import imageError from "assets/images/image-error.png";
import { CircularProgress } from "@mui/material";

export const Imagem: FC<ImgHTMLAttributes<HTMLImageElement>> = ({
  src: initSrc,
  ...rest
}) => {
  const [src, setSrc] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (
      initSrc?.indexOf("https") == -1 &&
      initSrc?.indexOf("blob:") == -1 &&
      !utils.isBase64(initSrc, { allowMime: true })
    ) {
      (async () => {
        try {
          const { error, processed } = await loadS3File(initSrc, true);
          if (!error && processed) setSrc(processed);
        } catch (error) {
          setSrc(imageError);
        }
      })();
    } else {
      const image = new Image();
      const handleLoad = () => setSrc(initSrc);

      image.addEventListener("load", handleLoad);
      image.src = initSrc ?? "";
      return () => {
        image.removeEventListener("load", handleLoad);
      };
    }
  }, []);

  if (src) return <img {...rest} src={src} />;

  return <CircularProgress />;
};
