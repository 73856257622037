import React, { FC, useEffect, useState } from "react";
import {
  Avatar,
  CircularProgress,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Paper,
  SxProps,
  Theme,
} from "@mui/material";
import {
  Link as LinkIcon,
  ImageRemove as ImageErrorIcon,
  FileDocument as DocIcon,
  Delete as DeleteIcon,
} from "mdi-material-ui";
import { isImage, loadS3File } from "utils";

export const Anexo: FC<{
  url: string | File;
  title: string;
  isS3File?: boolean;
  isLink?: boolean;
  loading?: boolean;
  handleRemove?: () => void;
  sx?: {
    root?: SxProps<Theme>;
  };
}> = ({
  url: initUrl,
  title,
  isLink = false,
  isS3File = false,
  loading: initLoading = false,
  handleRemove,
  sx = { root: { marginTop: 2 } },
}) => {
  const [url, setUrl] = useState(initUrl);
  const [hasError, setHasError] = useState(false);
  const [loading, setLoading] = useState(initLoading);
  useEffect(() => {
    if (isS3File && typeof url == "string") {
      (async function () {
        setLoading(true);
        setHasError(false);
        const { error, processed } = await loadS3File(url);
        if (processed) setUrl(processed);
        setLoading(false);
        if (error) setHasError(true);
      })();
    }
  }, []);

  return (
    <Paper variant="outlined" sx={sx.root}>
      <ListItem
        disablePadding
        secondaryAction={
          handleRemove ? (
            <IconButton edge="end" aria-label="delete" onClick={handleRemove}>
              <DeleteIcon />
            </IconButton>
          ) : undefined
        }
      >
        <ListItemButton
          onClick={
            typeof url == "string" ? () => window.open(url, "blank") : undefined
          }
          dense
          disableGutters
        >
          <ListItemAvatar sx={{ paddingRight: 1, paddingLeft: 1 }}>
            {loading && (
              <CircularProgress
                sx={({ spacing }) => ({
                  margin: spacing(0.5),
                })}
              />
            )}
            {hasError && (
              <Avatar
                sx={({ spacing }) => ({
                  minWidth: spacing(7),
                  height: spacing(7),
                })}
                variant="rounded"
              >
                <ImageErrorIcon />
              </Avatar>
            )}
            {!hasError && url && isImage(initUrl) && !isLink && (
              <Avatar
                sx={({ spacing }) => ({
                  minWidth: spacing(7),
                  height: spacing(7),
                })}
                variant="rounded"
                src={typeof url == "string" ? url : URL.createObjectURL(url)}
              />
            )}
            {!hasError && url && !isImage(initUrl) && !isLink && (
              <Avatar
                sx={({ spacing }) => ({
                  minWidth: spacing(7),
                  height: spacing(7),
                })}
                variant="rounded"
              >
                <DocIcon />
              </Avatar>
            )}
            {!hasError && isLink && (
              <Avatar
                sx={({ spacing }) => ({
                  height: spacing(7),
                  width: spacing(7),
                })}
              >
                <LinkIcon />
              </Avatar>
            )}
          </ListItemAvatar>
          <ListItemText
            primary={title}
            primaryTypographyProps={{ noWrap: true }}
          />
        </ListItemButton>
      </ListItem>
    </Paper>
  );
};
