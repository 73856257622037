import React, { FC } from "react";

import { IMensagem } from "@deltasge/marauders-map";
import { history } from "configs";

import { List, Paper } from "@mui/material";
import { Pagination } from "components/Pagination";
import { ListItemRecebida } from "./ListItemRecebida";
import { EmptyState } from "components/EmptyStates";

export const TabMensagemRecebida: FC<{
  loading: boolean;
  mensagens: [IMensagem[], number];
  rowsPerPageOptions: number[];
  rowsPerPage: number;
  page: number;
  onChangePage: (event: unknown, newPage: number) => void;
  onChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void;
}> = ({
  loading,
  mensagens,
  rowsPerPageOptions,
  rowsPerPage,
  page,
  onChangeRowsPerPage,
  onChangePage,
}) => {
  const handleDetalhes = (idMensagem?: number) =>
    history.push(`/publicacoes/recebida/${idMensagem}`);

  return (
    <>
      {mensagens[0].length > 0 && (
        <List component={Paper}>
          {mensagens[0].map((item, i) => (
            <ListItemRecebida
              key={`mensagem-${i}`}
              item={item}
              divider={true}
              handleDetalhes={handleDetalhes}
            />
          ))}
          <Pagination
            rowsPerPageOptions={rowsPerPageOptions}
            count={mensagens[1]}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={onChangePage}
            onChangeRowsPerPage={onChangeRowsPerPage}
          />
        </List>
      )}
      {!loading && mensagens[0].length === 0 && (
        <EmptyState
          type="404"
          title="Nenhuma publicação recebida!"
          subtitle="O Filtro selecionado não encontrou nenhuma publicação"
        />
      )}
    </>
  );
};
