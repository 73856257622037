import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { useKey } from "react-use";
import {
  Paper,
  Table as TableMUI,
  TableBody,
  TableContainer,
  TableHead,
  Zoom,
  Tooltip,
  SxProps,
  Fab,
  Theme,
  useScrollTrigger,
  Box,
} from "@mui/material";
import { Plus as AddIcon } from "mdi-material-ui";
import {
  ESistAvalUsaVirgula,
  IAvaNota,
  IAvaliacao,
  IDNColumn,
  IDNRowInfo,
  IDNRown,
  IDNTable,
  IDNTableInfo,
  ISistemaAvaliacao,
  utils,
} from "@deltasge/marauders-map";
import { TableRowHead } from "./TableRowHead";
import { TableRowBody } from "./TableRowBody";
import { getError, isTouchDevice } from "utils";
import { snack } from "components/GlobalSnackbar";
import { api } from "configs";
import { useStateRef } from "hooks/useStateRef";
import { TableBarBottom } from "./TableBarBottom";

export const allowedKeys = [
  "Backspace",
  "End",
  "Home",
  "Insert",
  "Delete",
  "PageUp",
  "PageDown",
  "Escape",
  "Control",
  "Alt",
  "CapsLock",
  "Tab",
  "Enter",
  "ArrowRight",
  "ArrowLeft",
  "ArrowUp",
  "ArrowDown",
];

interface ITableProps {
  table: IDNTable;
  sistemaAvaliacao: ISistemaAvaliacao;
  etapa: number;
  updateTable: (value: SetStateAction<IDNTable | undefined>) => void;
  handleAdd?: {
    onAdd: () => Promise<void>;
    title: string;
    disabled: boolean;
    sx?: SxProps<Theme>;
  };
  handleColar: (params: {
    colar: IAvaliacao;
    copiar: IAvaliacao;
  }) => Promise<void>;
  handleImportar: (params: {
    file: File;
    idAvaliacao: number;
  }) => Promise<void>;
  setRow: Dispatch<SetStateAction<IDNRown | undefined>>;
}

export const Table: FC<ITableProps> = ({
  table,
  sistemaAvaliacao,
  etapa,
  updateTable,
  handleAdd,
  handleColar,
  handleImportar,
  setRow,
}) => {
  const [selected, setSelected, selectedRef] = useStateRef([0, 0]);
  const [edit, setEdit, editRef] = useStateRef(false);
  const [autoFocus, setAutoFocus, autoFocusRef] = useStateRef(false);
  const tableContainerRef = useRef(null);
  const [isHovered, setIsHovered] = useState(false);
  const [info, setInfo] = useState<IDNTableInfo<IDNRown>>({});

  const sizeX = table.columns.filter((f) => f.visivel).length;
  const sizeY = table.rowns.length;

  useKey("Enter", (event) => {
    event.stopPropagation();
    if (!editRef.current) {
      setEdit(!isTouchDevice());
      setAutoFocus(false);
    }
  });

  useKey("F2", (event) => {
    event.stopPropagation();
    if (!editRef.current) {
      setEdit(true);
      setAutoFocus(false);
    }
  });

  useKey("Escape", (event) => {
    event.stopPropagation();
    setAutoFocus(false);
    if (editRef.current) setEdit(false);
  });

  useKey("Tab", (event) => {
    event.preventDefault();
    setAutoFocus(false);
    if (editRef.current) {
      setEdit(false);
    }
    setSelected(([x, y]) => {
      if (event.shiftKey) {
        if (x == 0 && y == 0) return [sizeX - 1, sizeY - 1];
        if (x == 0) return [sizeX - 1, --y];
        return [--x, y];
      } else {
        if (x + 1 == sizeX && y + 1 == sizeY) return [0, 0];
        if (x + 1 == sizeX) return [0, ++y];
        return [++x, y];
      }
    });
  });

  useKey("ArrowRight", (event) => {
    event.stopPropagation();
    if (!editRef.current) {
      setSelected(([x, y]) => {
        if (x + 1 == sizeX && y + 1 == sizeY) return [0, 0];
        if (x + 1 == sizeX) return [0, ++y];
        return [++x, y];
      });
    }
  });

  useKey("ArrowLeft", (event) => {
    event.stopPropagation();
    if (!editRef.current) {
      setSelected(([x, y]) => {
        if (x == 0 && y == 0) return [sizeX - 1, sizeY - 1];
        if (x == 0) return [sizeX - 1, --y];
        return [--x, y];
      });
    }
  });

  useKey("ArrowUp", (event) => {
    event.stopPropagation();
    if (!editRef.current) {
      setSelected(([x, y]) => {
        if (x == 0 && y == 0) return [sizeX - 1, sizeY - 1];
        if (y == 0) return [--x, sizeY - 1];
        return [x, --y];
      });
    }
  });

  useKey("ArrowDown", (event) => {
    event.stopPropagation();
    if (!editRef.current) {
      setSelected(([x, y]) => {
        if (x + 1 == sizeX && y + 1 == sizeY) return [0, 0];
        if (y + 1 == sizeY) return [++x, 0];
        return [x, ++y];
      });
    }
  });

  useKey("Delete", (event) => {
    event.stopPropagation();
    if (!editRef.current) {
      handleDelete();
    }
  });

  useKey(
    (event) => {
      return (
        ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"].includes(
          event.key
        ) &&
        !editRef.current &&
        !isTouchDevice()
      );
    },
    () => {
      setAutoFocus(true);
      setEdit(true);
    }
  );

  const trigger = useScrollTrigger({
    target: tableContainerRef.current ?? undefined,
  });

  useEffect(() => {
    const index = selectedRef.current[1];
    setRow(table.rowns.at(index));
  }, [selectedRef.current]);

  const handleCellClick = (select: [number, number]): void => {
    setEdit(false);
    setSelected(select);
    if (autoFocusRef.current) setAutoFocus(false);
  };

  const handleDoubleClick = (
    e: React.MouseEvent<HTMLTableCellElement, MouseEvent>,
    select: [number, number]
  ): void => {
    e.preventDefault();
    setEdit(true);
    if (autoFocusRef.current) setAutoFocus(false);
    setSelected(select);
  };

  const arrowDown = () => {
    setEdit(false);
    if (autoFocusRef.current) setAutoFocus(false);
    setSelected(([x, y]) => {
      if (x + 1 == sizeX && y + 1 == sizeY) return [0, 0];
      if (y + 1 == sizeY) return [++x, 0];
      return [x, ++y];
    });
  };

  const arrowUp = () => {
    setEdit(false);
    if (autoFocusRef.current) setAutoFocus(false);
    setSelected(([x, y]) => {
      if (x == 0 && y == 0) return [sizeX - 1, sizeY - 1];
      if (y == 0) return [--x, sizeY - 1];
      return [x, --y];
    });
  };

  const arrowLeft = () => {
    setEdit(false);
    if (autoFocusRef.current) setAutoFocus(false);
    setSelected(([x, y]) => {
      if (x == 0 && y == 0) return [sizeX - 1, sizeY - 1];
      if (x == 0) return [sizeX - 1, --y];
      return [--x, y];
    });
  };

  const arrowRight = () => {
    setEdit(false);
    if (autoFocusRef.current) setAutoFocus(false);
    setSelected(([x, y]) => {
      if (x + 1 == sizeX && y + 1 == sizeY) return [0, 0];
      if (x + 1 == sizeX) return [0, ++y];
      return [++x, y];
    });
  };

  const handleCancel = ({
    indexRow,
    id,
  }: {
    id: keyof IDNRown;
    indexRow: number;
  }) => {
    delete info[indexRow][id];
    setInfo(info);
    setEdit(false);
  };

  const handleDelete = async () => {
    const row = table.rowns.at(selectedRef.current[1]);
    const column = table.columns.at(selectedRef.current[0]);
    if (column && row && column.liberado) {
      await handleSubmit({ value: "", row, column });
    }
  };

  const handleInfo = (
    indexRow: number,
    id: keyof IDNRown,
    info?: IDNRowInfo
  ) => {
    setInfo((prev) => {
      if (!prev[indexRow]) prev[indexRow] = {};
      prev[indexRow][id] = info;
      if (!info && prev[indexRow]) {
        delete prev[indexRow][id];
        if (Object.keys(prev[indexRow]).length == 0) delete prev[indexRow];
        return { ...prev };
      }
      return { ...prev };
    });
  };

  const handleSubmit = async ({
    value,
    row,
    column,
    keyPress,
  }: {
    value: string;
    row: IDNRown;
    column: IDNColumn;
    keyPress?: string;
  }): Promise<boolean> => {
    try {
      const indexRow = selectedRef.current[1];
      const field = row[column.id];
      const previousValue = utils.digitacao.getText(field);
      if (previousValue == value) {
        arrowDown();
        return true;
      }
      const rowInfo: IDNRowInfo = {
        msg: "init",
        status: "pending",
        previousValue,
        currentValue: value,
      };
      handleInfo(indexRow, column.id, rowInfo);

      const isDecimal =
        sistemaAvaliacao.usavirgula ==
        ESistAvalUsaVirgula["Digitar com vírgula"];
      const isNumeric = utils.digitacao.isNumericValue(column.id, field);
      const maxValue = Number(column.avaliacao ? column.avaliacao.valor : 100);
      const minValue = 0;
      const numValue = isDecimal
        ? parseFloat(value.replace(",", "."))
        : parseInt(value);

      if (
        value != "" &&
        isNumeric &&
        (numValue > maxValue || numValue < minValue)
      ) {
        handleInfo(indexRow, column.id, {
          ...rowInfo,
          msg: `O Valor dever ser entre ${minValue} e ${maxValue}`,
          status: "rejected",
        });
        throw new Error(`O Valor dever ser entre ${minValue} e ${maxValue}`);
      }

      if (typeof field == "object" && "nota" in field) {
        const avaNota = field as IAvaNota;
        if (!avaNota.uid) {
          handleInfo(indexRow, column.id, {
            ...rowInfo,
            msg: "Não é possível gravar a nota sem o ID",
            status: "rejected",
          });
          throw new Error("Não é possível gravar a nota sem o ID");
        }

        api
          .post("nota-avaliacao/update-nota", {
            id: avaNota.uid,
            nota: value,
          })
          .then(() => {
            handleInfo(indexRow, column.id, { msg: "ok", status: "resolved" });
            window.setTimeout(() => handleInfo(indexRow, column.id), 1000);
          })
          .catch((error) => {
            handleInfo(indexRow, column.id, {
              ...rowInfo,
              msg: getError(error),
              status: "rejected",
            });
          });
        avaNota.nota = value;
      } else if (column.id == "recBim" || column.id == "recSem") {
        api
          .post("notasboletim/update-recuperacao", {
            idNota: row.idNota,
            idMedia: row.idMedia,
            nota: value,
            bimestre: etapa,
          })
          .then(() => {
            handleInfo(indexRow, column.id, { msg: "ok", status: "resolved" });
            window.setTimeout(() => handleInfo(indexRow, column.id), 1000);
          })
          .catch((error) => {
            handleInfo(indexRow, column.id, {
              ...rowInfo,
              msg: getError(error),
              status: "rejected",
            });
          });
      } else if (column.id == "exame") {
        api
          .post("notasboletim/update-recuperacao-exame", {
            idNota: row.idNota,
            idNotaRec: row.idNotaRec,
            nota: value,
          })
          .then(() => {
            handleInfo(indexRow, column.id, { msg: "ok", status: "resolved" });
            window.setTimeout(() => handleInfo(indexRow, column.id), 1000);
          })
          .catch((error) => {
            handleInfo(indexRow, column.id, {
              ...rowInfo,
              msg: getError(error),
              status: "rejected",
            });
          });
      } else if (column.id == "faltas") {
        api
          .post("notasboletim/update-faltas", {
            idNota: row.idNota,
            idMedia: row.idMedia,
            faltas: value,
            bimestre: etapa,
          })
          .then(() => {
            handleInfo(indexRow, column.id, { msg: "ok", status: "resolved" });
            window.setTimeout(() => handleInfo(indexRow, column.id), 1000);
          })
          .catch((error) => {
            handleInfo(indexRow, column.id, {
              ...rowInfo,
              msg: getError(error),
              status: "rejected",
            });
          });
      } else if (column.id == "recFinal") {
        api
          .post("notasboletim/update-recuperacao-final", {
            idNota: row.idNota,
            idNotaRec: row.idNotaRec,
            nota: value,
          })
          .then(() => {
            handleInfo(indexRow, column.id, { msg: "ok", status: "resolved" });
            window.setTimeout(() => handleInfo(indexRow, column.id), 1000);
          })
          .catch((error) => {
            handleInfo(indexRow, column.id, {
              ...rowInfo,
              msg: getError(error),
              status: "rejected",
            });
          });
      } else if (column.id == "rs1" || column.id == "rs2") {
        api
          .post("notasboletim/update-recuperacao-semestral", {
            idNota: row.idNota,
            idNotaRec: row.idNotaRec,
            nota: value,
            campo: column.id,
          })
          .then(() => {
            handleInfo(indexRow, column.id, { msg: "ok", status: "resolved" });
            window.setTimeout(() => handleInfo(indexRow, column.id), 1000);
          })
          .catch((error) => {
            handleInfo(indexRow, column.id, {
              ...rowInfo,
              msg: getError(error),
              status: "rejected",
            });
          });
      }

      const numericColumns: Array<keyof IDNRown> = [
        "recBim",
        "recSem",
        "recFinal",
        "exame",
        "faltas",
        "rs1",
        "rs2",
      ];
      if (numericColumns.includes(column.id)) {
        if (Number.isNaN(numValue)) delete row[column.id];
        else (row[column.id] as number) = numValue;
      }

      if (keyPress == "Enter" || keyPress == "ArrowDown") arrowDown();
      else if (keyPress == "ArrowUp") arrowUp();
      else if (keyPress == "ArrowLeft") arrowLeft();
      else if (keyPress == "ArrowRight") arrowRight();

      return true;
    } catch (error) {
      snack.error(getError(error));
      return false;
    }
  };

  const handlePublicar = async (column: IDNColumn) => {
    try {
      if (!column.avaliacao || !column.avaliacao.uid) {
        throw new Error("Coluna da avaliação não encontrada");
      }
      column.avaliacao.liberado = !column.avaliacao.liberado;
      updateTable((table) => {
        if (table) {
          table.columns = table.columns.map((m) => {
            if (column.id == m.id) return column;
            return m;
          });
          return { ...table };
        }
        return table;
      });
      await api.post("/avaliacao/publicar", {
        id: column.avaliacao.uid,
        liberado: column.avaliacao.liberado,
      });
    } catch (error) {
      snack.error(getError(error));
    }
  };

  return (
    <Box
      sx={(theme) => ({
        width: "100%",
        height: isTouchDevice()
          ? `calc(100% - ${theme.mixins.toolbar.minHeight}px)`
          : "100%",
        overflow: "hidden",
      })}
    >
      <TableContainer
        component={Paper}
        variant="outlined"
        sx={{
          maxHeight: "100%",
          maxWidth: "100%",
        }}
        ref={tableContainerRef}
      >
        <TableMUI stickyHeader size="small">
          <TableHead>
            <TableRowHead
              columns={table.columns}
              handlePublicar={handlePublicar}
              handleColar={handleColar}
              handleImportar={handleImportar}
              avaCtr={table.avaCtr}
            />
          </TableHead>
          <TableBody>
            {table.rowns.map((row, index) => (
              <TableRowBody
                key={`row-${row.nrChamada}-${row.idAluno}`}
                row={row}
                columns={table.columns}
                rowSelected={index == selected[1]}
                indexRowSelected={index}
                indexCellSelected={selected[0]}
                handleCellClick={handleCellClick}
                handleDoubleClick={handleDoubleClick}
                handleSubmit={handleSubmit}
                handleCancel={handleCancel}
                showInput={edit}
                autoFocus={autoFocus}
                sistemaAvaliacao={sistemaAvaliacao}
                info={info}
              />
            ))}
          </TableBody>
        </TableMUI>
      </TableContainer>
      {isTouchDevice() && (
        <TableBarBottom
          handleAdd={handleAdd}
          row={table.rowns[selected[1]]}
          column={table.columns[selected[0]]}
          handleSubmit={handleSubmit}
          isDecimal={
            sistemaAvaliacao.usavirgula ==
            ESistAvalUsaVirgula["Digitar com vírgula"]
          }
        />
      )}
      {handleAdd && !handleAdd.disabled && table.avaCtr && !isTouchDevice() && (
        <Zoom in={!trigger} timeout={200} unmountOnExit>
          <Tooltip
            title="Adicionar avaliação"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            style={{ opacity: isHovered ? 1 : 0.6 }}
          >
            <Fab sx={handleAdd.sx} onClick={handleAdd.onAdd} color="secondary">
              <AddIcon />
            </Fab>
          </Tooltip>
        </Zoom>
      )}
    </Box>
  );
};
