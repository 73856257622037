import React, { FC } from "react";
import { IUser } from "@deltasge/marauders-map";

import { ListItem, ListItemAvatar, ListItemText } from "@mui/material";
import { Avatar } from "components/Avatar";

export const UserListItem: FC<{
  user: IUser;
  props: React.HTMLAttributes<HTMLLIElement>;
}> = ({ user, props }) => (
  <ListItem {...props} key={user.deltaId}>
    <ListItemAvatar>
      <Avatar
        displayName={user?.displayName ?? user?.nome}
        src={user?.photoUrl}
      />
    </ListItemAvatar>
    <ListItemText
      primary={user?.displayName ?? user?.nome}
      secondary={`Codigo: ${user.codigo} - Email: ${
        user.email ?? user.firebaseEmail
      }`}
    />
  </ListItem>
);
