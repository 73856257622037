import React, { FC, ReactNode } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { ReactSVG } from "react-svg";

export const EmptyState: FC<{
  type?: "404" | "search";
  title: string | ReactNode;
  subtitle?: string;
  button?: ReactNode;
}> = ({ title, subtitle, button, type = "search" }) => {
  let src = "/assets/animations/search.svg";
  if (type == "404") {
    src = "/assets/animations/404.svg";
  }
  return (
    <Grid
      height="100%"
      container
      direction="column"
      justifyContent="space-evenly"
      alignItems="stretch"
      flexWrap="nowrap"
    >
      <Grid item xs={12}>
        <Box sx={{ width: ({ spacing }) => spacing(50), margin: "auto" }}>
          <ReactSVG
            onError={(e) => {
              // eslint-disable-next-line no-console
              console.log(e);
            }}
            src={src}
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        {typeof title == "string" && (
          <Typography variant="h6" textAlign="center">
            {title}
          </Typography>
        )}
        {typeof title != "string" && title}
        <Typography sx={{ mt: 1 }} textAlign="center">
          {subtitle}
        </Typography>
        {button && (
          <Box mt={1} textAlign="center">
            {button}
          </Box>
        )}
      </Grid>
    </Grid>
  );
};
