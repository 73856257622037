import React, { createContext, FC, useRef, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";

export interface Position {
  left: number;
  top: number;
}

export interface ScrollPosition {
  prev: Position;
  curr: Position;
}

export const initialPosition: ScrollPosition = {
  prev: { left: 0, top: 0 },
  curr: { left: 0, top: 0 },
};

export const ScrollContext = createContext<{
  container: React.MutableRefObject<HTMLElement | undefined>;
  position: ScrollPosition;
}>({
  container: { current: undefined },
  position: initialPosition,
});

export const ProvideScroll: FC = ({ children }) => {
  const container = useRef<HTMLElement | undefined>(undefined);
  const [position, setPosition] = useState<ScrollPosition>(initialPosition);

  return (
    <ScrollContext.Provider value={{ container, position }}>
      <PerfectScrollbar
        onScroll={(e) => {
          setPosition({
            prev: { ...position.curr },
            curr: {
              top: e.currentTarget.scrollTop,
              left: e.currentTarget.scrollLeft,
            },
          });
        }}
      >
        {children}
      </PerfectScrollbar>
    </ScrollContext.Provider>
  );
};
