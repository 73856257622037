import React, { FC } from "react";
import { /* Redirect, */ Route } from "react-router-dom";

// import { useHasPermission } from "hooks/useHasPermission";
// import { snack } from "components/GlobalSnackbar";

export const CustomRoute: FC<{
  isPrivate?: boolean;
  group?: number;
  role?: number;
  exact: boolean;
  path: string;
  component?: React.FC<{}>;
}> = ({ /*isPrivate = false, group, role,*/ ...props }) => {
  // const hasPermission = useHasPermission(
  //   group ?? 0,
  //   role ?? 0,
  //   !group || !role
  // );

  // if (!hasPermission) {
  //   snack.warning("Você não tem permissão para acessar essa rota.");
  //   return <Redirect to="/" />;
  // }

  return <Route {...props} />;
};
