import {
  Alert,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Typography,
} from "@mui/material";
import { PushNotificationContext } from "context/PushNotificationContext";
import { Main } from "pages/_layouts/Main";
import React, { FC, useContext } from "react";
import { useAppSelector } from "store";

export const DevMode: FC = () => {
  const { devMode } = useAppSelector((state) => state.layout);
  if (!devMode) {
    return <Alert severity="error">O Modo Desenvolvedor esta desligado</Alert>;
  }

  const {
    isSupported,
    permission,
    token,
    requestPermission,
    requestPushToken,
    lastNotification,
  } = useContext(PushNotificationContext);

  return (
    <Main title="Modo Desenvolvedor">
      <Card>
        <CardHeader title="Testes Push Notification" />
        {!isSupported && (
          <CardContent>
            <Typography>
              Se você não esta vendo nada aqui é porque seu dispositivo não tem
              suporte a Push Notification
            </Typography>
          </CardContent>
        )}
        {isSupported && (
          <CardContent>
            <Typography variant="button">Push Token:</Typography>
            {token && <Typography variant="caption">{token}</Typography>}
            <br />
            <Typography variant="button">Permissão:</Typography>
            {permission && (
              <Typography variant="caption">{permission}</Typography>
            )}
            <br />
            <Typography variant="button">Push Notification:</Typography>
            {lastNotification && (
              <Typography variant="caption">
                {JSON.stringify(lastNotification)}
              </Typography>
            )}
          </CardContent>
        )}
        {isSupported && (
          <CardActions>
            <Button size="small" onClick={requestPermission} variant="outlined">
              Permitir Notificações
            </Button>
            {permission == "granted" && (
              <Button
                size="small"
                onClick={requestPushToken}
                variant="outlined"
              >
                Get PushToken
              </Button>
            )}
          </CardActions>
        )}
      </Card>
    </Main>
  );
};
