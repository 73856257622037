import React, { FC } from "react";
import { IMensagem } from "@deltasge/marauders-map";

import { IconButton, Tooltip } from "@mui/material";
import { Print as PrintIcon } from "@mui/icons-material";

import { store } from "store";

export const BtnImprimir: FC<{
  mensagem: IMensagem;
}> = ({ mensagem }) => {
  const {
    usuario: { usuario },
    escola,
  } = store.getState();

  return (
    <Tooltip key="btn-print" title="Imprimir">
      <IconButton
        color="inherit"
        aria-label="print"
        onClick={() => {
          window.open(
            `${escola.onlineExt}/relatorios/mensagens/resposta-formatada/?id=${mensagem.id}&token=${usuario?.token}`
          );
        }}
      >
        <PrintIcon />
      </IconButton>
    </Tooltip>
  );
};
