import React, { FC } from "react";
import { FeedbackJob } from "@deltasge/marauders-map";
import { Avatar, Grid, IconButton, Typography, useTheme } from "@mui/material";
import {
  OpenInNew as OpenIcon,
  AlertCircle as WarningIcon,
  CheckCircle as SuccessIcon,
  CloseCircle as ErrorIcon,
} from "mdi-material-ui";
import { useSnackbar } from "notistack";
import { useMobile } from "hooks/useMobile";

export const NotificationFeedback: FC<{
  feedback: FeedbackJob;
}> = ({ feedback }) => {
  const { closeSnackbar } = useSnackbar();
  const isSm = useMobile("sm");
  const theme = useTheme();
  const size = parseInt(theme.spacing(1), 10);
  let maxWidth = 0;
  if (isSm) maxWidth = window.screen.width - size * 14;
  else maxWidth = (window.screen.width * 60) / 100;
  return (
    <Grid container alignItems="center" wrap="nowrap" maxWidth={maxWidth}>
      <Grid item sx={{ pr: 1 }}>
        <Avatar
          sx={(theme) => {
            let color = theme.palette.success.light;
            if (feedback.tipo == "error") color = theme.palette.error.light;
            else if (feedback.tipo == "warning")
              color = theme.palette.warning.light;
            return {
              background: color,
            };
          }}
        >
          {feedback.tipo == "error" && <ErrorIcon />}
          {feedback.tipo == "warning" && <WarningIcon />}
          {feedback.tipo == "success" && <SuccessIcon />}
        </Avatar>
      </Grid>
      <Grid item flexGrow={1} zeroMinWidth>
        <Typography variant="subtitle2" component="p" noWrap>
          {feedback.titulo}
        </Typography>
        <Typography variant="caption" component="p" noWrap>
          {feedback.mensagem}
        </Typography>
      </Grid>
      {(feedback.bufferResult || feedback.strResult) && (
        <Grid item sx={{ pl: 1 }}>
          {feedback.typeResult == "url" && feedback.strResult && (
            <IconButton
              color="primary"
              onClick={() => {
                closeSnackbar(feedback.criacao);
                window.open(feedback.strResult, "_blank");
              }}
            >
              <OpenIcon />
            </IconButton>
          )}
        </Grid>
      )}
    </Grid>
  );
};
