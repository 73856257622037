import React, { FC } from "react";
import {
  Grid,
  IconButton,
  LabelDisplayedRowsArgs,
  TablePagination,
} from "@mui/material";

import { Printer as PrintIcon } from "mdi-material-ui";

export const Pagination: FC<{
  onChangeImpimir?: () => void;
  rowsPerPageOptions: number[];
  count: number;
  rowsPerPage: number;
  page: number;
  onChangePage: (event: unknown, newPage: number) => void;
  onChangeRowsPerPage: ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => void;
}> = ({
  onChangeImpimir,
  rowsPerPageOptions,
  count,
  rowsPerPage,
  page,
  onChangePage,
  onChangeRowsPerPage,
}) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={4} sm={2}>
        {onChangeImpimir && (
          <IconButton aria-label="imprimir" onClick={onChangeImpimir}>
            <PrintIcon />
          </IconButton>
        )}
      </Grid>
      <Grid item xs={8} sm={10}>
        <TablePagination
          labelRowsPerPage="Linhas por página"
          labelDisplayedRows={(p: LabelDisplayedRowsArgs) => (
            <span>
              {p.from} - {p.to} de {p.count}
            </span>
          )}
          SelectProps={{
            fullWidth: false,
          }}
          rowsPerPageOptions={rowsPerPageOptions}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={onChangePage}
          onRowsPerPageChange={onChangeRowsPerPage}
        />
      </Grid>
    </Grid>
  );
};
