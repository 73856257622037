import { io, Socket } from "socket.io-client";
import { IUser } from "@deltasge/marauders-map";
import { Environment, refreshUser } from "configs";

export class IO {
  private static instance: IO;
  client: Socket | undefined;
  public static getInstance(usuario: IUser): IO {
    if (!IO.instance) {
      IO.instance = new IO();
      IO.instance.client = io(Environment.PUSH_DELTA_API_URL, {
        auth: {
          authorization: `Bearer ${usuario.token}`,
        },
      });
      IO.instance.client.on("error", (data) => {
        if (typeof data === "object" && data.message == "jwt expired") {
          refreshUser(usuario)
            .then((novoUsuario) => {
              (
                IO.instance.client?.auth as { [key: string]: any }
              ).authorization = `Bearer ${novoUsuario.token}`;
              IO.instance.client?.disconnect().connect();
            })
            .catch(() => {
              IO.instance.client?.disconnect();
            });
        }
      });
    }
    return IO.instance;
  }
}
