import React, { FC } from "react";

import { List, ListItem, ListItemText, Divider } from "@mui/material";

import { useAppDispatch, useAppSelector } from "store";
import { toggleSidebar } from "store/modules/layout";
import { useAuthentication } from "context/AuthenticationProvider";
import { useMobile } from "hooks/useMobile";
import { history } from "configs";

export const Profile: FC = () => {
  const { escola } = useAppSelector((state) => state);

  const isMobile = useMobile();
  const dispatch = useAppDispatch();

  const { AuthenticatedUserComponent } = useAuthentication();

  return (
    <List>
      <ListItem>
        <ListItemText primary={escola.nome} />
      </ListItem>
      <Divider />
      <AuthenticatedUserComponent
        onClick={() => {
          if (isMobile) {
            dispatch(toggleSidebar());
          }
          history.push("/profile/");
        }}
      />
      <Divider />
    </List>
  );
};
