import { all } from "redux-saga/effects";

import { sagas as calculoAvaliacao } from "./calculo-avaliacoes";
import { sagas as categorias } from "./categorias";
import { sagas as diarios } from "./relatorios/diarios/modelos";
import { sagas as logoff } from "./auth/logoff";
import { sagas as papeis } from "./relatorios/configuracoes/papeis";
import { sagas as validate } from "./auth/validate";
import { sagas as sistemaAvaliacao } from "./sistema-avaliacao";

export function* rootSaga(): any {
  return yield all([
    calculoAvaliacao,
    categorias,
    diarios,
    logoff,
    papeis,
    validate,
    sistemaAvaliacao,
  ]);
}
