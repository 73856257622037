import { format, parseISO } from "date-fns";

import { apiRelatorio } from "configs";
import { store } from "store";
import { snack } from "components/GlobalSnackbar";
import { getError } from "utils";

export interface IParamsDiario {
  idCurso: number;
  idSerie: number;
  idTurma: number;
  idMateria: number;
}

export const impressaoRapidaDiario = async (
  aula: IParamsDiario
): Promise<void> => {
  const {
    escola: { anoSite, bimestre },
    configuracoes: { etapas },
    configDiarioPadrao,
    configPapelPadrao,
    modelosDiarios: { data: modelosDiarios },
  } = store.getState();

  const etapasFiltradas = etapas.filter((f) =>
    f.idsCursos?.includes(aula.idCurso)
  );

  const etapa = etapasFiltradas.find((f) => f.etapa == bimestre);

  let dataIni: Date | null = null;
  if (etapa && etapa.dataEtapaIni) {
    dataIni = parseISO(etapa.dataEtapaIni);
  }
  if (!dataIni) {
    throw new Error("Erro ao obter a data inicial do bimestre");
  }

  let dataFim: Date | null = null;
  if (etapa && etapa.dataEtapaFim) {
    dataFim = parseISO(etapa.dataEtapaFim);
  }
  if (!dataFim) {
    throw new Error("Erro ao obter a data final do bimestre");
  }

  const modelo = modelosDiarios.find(
    (f) => f.id == configDiarioPadrao.idModelo
  );

  if (!modelo) {
    throw new Error("Erro ao obter o modelo padrão");
  }

  const params = {
    tipo: "porMateria",
    anoLetivo: anoSite,
    curso: aula.idCurso,
    serie: aula.idSerie,
    turma: aula.idTurma,
    bimestre,
    materia: aula.idMateria,
    dataini: format(dataIni, "yyyy-MM-dd"),
    datafim: format(dataFim, "yyyy-MM-dd"),
    recuperacao: configDiarioPadrao.recuperacao,
    preencherDias: configDiarioPadrao.preencherDias,
    mostrarObservacoes: configDiarioPadrao.mostrarObservacoes,
    width: `${configPapelPadrao.width}mm`,
    height: `${configPapelPadrao.height}mm`,
    marginTop: `${configPapelPadrao.marginTop}mm`,
    marginBottom: `${configPapelPadrao.marginBottom}mm`,
    marginLeft: `${configPapelPadrao.marginLeft}mm`,
    marginRight: `${configPapelPadrao.marginRight}mm`,
  };

  const url = `/diario/${configDiarioPadrao.idModelo}/${
    configDiarioPadrao.frente ? "frente" : "verso"
  }/`;
  try {
    await apiRelatorio.get(url, { params });
    snack.info("Aguarde, o Diário de Classe será apresentado em breve");
  } catch (error) {
    snack.error(getError(error));
  }
};
