import Axios from "axios";
import { snack } from "components/GlobalSnackbar";
import { Environment } from "configs";
import { getError } from "./error";

export const getBNCC = async (
  value: string,
  withCode?: boolean
): Promise<string | undefined> => {
  try {
    if (value.match("^\\s?\\(?(EI|EF|EM)[0-9]{2}[A-Z]{2}\\w{2,4}\\)?\\s?$")) {
      value = value.replace(/\s/g, "");
      value = value.replace(/[\\(\\)]/g, "");
      const { data } = await Axios.get(
        `${Environment.CONVERSOR_DELTA_API_URL}/bncc/${value}`
      );
      if (data && data.dados) {
        if (!withCode) return data.dados.habilidades || value;

        let valorNovo = data.dados.habilidades || value;
        if (!valorNovo.match(value)) {
          valorNovo = `(${value}) ${valorNovo}`;
        }

        return valorNovo;
      }
    }
  } catch (error) {
    snack.error(getError(error));
  }
};
