import { call, put, select, takeLatest } from "redux-saga/effects";
import { ActionTypes } from "./types";
import { failure, success } from "./actions";
import { snack } from "components/GlobalSnackbar";
import { getError } from "utils";
import { RootState } from "../root_reducer";
import { api, cacheDados } from "configs";
import { IAvaCalc } from "@deltasge/marauders-map";

function* request() {
  try {
    const {
      usuario: { usuario },
      configuracoes: {
        configOnline: { configuracoes },
      },
    }: RootState = yield select();

    if (!usuario) {
      throw new Error("Usuário não encontrado");
    }

    const cache24h = 60 * 24;
    const key = `calculo-avaliacao-${usuario?.escolaId}`;
    const cache = cacheDados.get<IAvaCalc[]>(key);
    if (cache && typeof cache != "string") {
      yield put(success(cache));
    } else {
      const { data } = yield call(api.get, "calculo-avaliacao", {
        params: {
          select: ["descricao", "id", "peso", "pesoCalc", "valor", "calculo"],
          where: {
            anoLetivo: configuracoes.ano_site,
          },
        },
      });
      if (!data || !Array.isArray(data) || !Array.isArray(data.at(0))) {
        throw new Error("Nenhum calculo de avaliação encontrado");
      }
      yield put(success(data[0] as IAvaCalc[]));
      cacheDados.set(key, data[0] as IAvaCalc[], cache24h);
    }
  } catch (error) {
    yield put(failure());
    snack.error(getError(error));
  }
}
export const sagas = takeLatest([ActionTypes.REQUEST], request);
