import React, { FC, ReactNode, useEffect, useState } from "react";

import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Autocomplete,
  Button,
  Checkbox,
  Chip,
  DialogActions,
  DialogContent,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  ListItem,
  ListItemText,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { DateTimePicker } from "@mui/x-date-pickers";
import { ArrowRightBold as ArrowRightBoldIcon } from "mdi-material-ui";

import { Form } from "assets/styleds";
import {
  IOcorrenciaPayload,
  ICategoriaOcorrencia,
  ITipoOcorrencia,
  utils,
  yupOcorrencia,
  EUserType,
} from "@deltasge/marauders-map";
import { useAppSelector } from "store";
import { snack } from "components/GlobalSnackbar";
import { api, history } from "configs";
import { getError } from "utils";
import { useSessionStorage } from "hooks/useSessionStorage";
import { format } from "date-fns";

export interface IOcorrenciaErrors {
  data?: string;
  idTurma?: string;
  idMateria?: string;
  alunos?: string;
  idCategoriaOcorrencia?: string;
  idTipoOcorrencia?: string;
  idProfessor?: string;
  bimestre?: string;
  restrita?: string;
  historico?: string;
}

const yupOcorrenciaParamsMax = yupOcorrencia.fields.historico.tests.find(
  (f) => f.OPTIONS.name == "max"
)?.OPTIONS.params;
const maxLength =
  yupOcorrenciaParamsMax && !Number.isNaN(yupOcorrenciaParamsMax.max)
    ? Number(yupOcorrenciaParamsMax.max)
    : 8000;

export const CadastroOcorrencia: FC<{
  handleClose: (reload?: boolean) => void;
  item: IOcorrenciaPayload;
  goToList?: boolean;
}> = ({ handleClose, item, goToList = false }) => {
  const {
    configuracoes: {
      etapas,
      configOnline: { configuracoes },
    },
    treeView: { cursos },
    usuario: { usuario },
  } = useAppSelector((state) => state);

  const [categorias, setCategorias] = useSessionStorage<ICategoriaOcorrencia[]>(
    "CategoriasOcorrencia",
    [],
    2
  );
  const [tipos, setTipos] = useSessionStorage<ITipoOcorrencia[]>(
    "TiposOcorrencia",
    [],
    2
  );
  const [payload, setPayload] = useState<IOcorrenciaPayload>({
    ...item,
    gerarNotificacao:
      typeof item.gerarNotificacao === "boolean"
        ? item.gerarNotificacao
        : false,
    idMateria: (() => {
      const turma = utils
        .getTurmasTreeView({ cursos })
        .find((f) => f.id === item.idTurma);

      let materia =
        (turma?.materias ?? []).find((f) => f.id === item.idMateria) ?? null;

      if (!materia && usuario?.tipoUsuario === EUserType.Professor) {
        materia = turma?.materias?.at(0) ?? null;
      }
      return materia?.id ?? null;
    })(),
  });

  const [errors, setErrors] = useState<IOcorrenciaErrors>({});
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    if (categorias.length == 0 || tipos.length == 0) {
      getCategorias();
      getTipos();
    }
  }, []);

  const getCategorias = async () => {
    try {
      const { data } = await api.get<[ICategoriaOcorrencia[]]>(
        "categoriaocorrencia",
        {
          params: {
            select: ["id", "descricao"],
            order: { descricao: "ASC" },
          },
        }
      );
      if (Array.isArray(data) && Array.isArray(data[0])) {
        setCategorias(data[0]);
      }
    } catch (error) {
      snack.error(getError(error));
    }
  };

  const getTipos = async () => {
    try {
      const { data } = await api.get<[ITipoOcorrencia[]]>("tipoocorrencia", {
        params: {
          select: ["id", "nome", "idCategoriaOcorrencia", "textoPadrao"],
          order: { nome: "ASC" },
        },
      });
      if (Array.isArray(data) && Array.isArray(data[0])) {
        setTipos(data[0]);
      }
    } catch (error) {
      snack.error(getError(error));
    }
  };

  const handleDate = (data: Date | null) => {
    if (!data) {
      setPayload((p) => ({ ...p, data: new Date() }));
      setErrors((p) => ({
        ...p,
        data: "Você deve selecionar uma data",
      }));
    } else {
      setErrors((p) => ({ ...p, data: undefined }));
      const etapa = utils.getEtapaPorData({
        data,
        cursos,
        etapas,
        idTurma: payload.idTurma,
      });
      if (!etapa) {
        setErrors((p) => ({
          ...p,
          data: "Não foi possível encontrar a etapa do curso desta turma para a data atual",
        }));
        snack.warning(
          "Não foi possível encontrar a etapa do curso desta turma para a data atual"
        );
      } else {
        setPayload((p) => ({
          ...p,
          data: data,
          bimestre: etapa.etapa,
        }));
        setErrors((p) => ({ ...p, data: undefined }));
      }
    }
  };

  const handleSubmit = async () => {
    try {
      if (!payload.data) {
        setErrors({
          data: "Você deve selecionar uma data",
        });
        return;
      }
      if (payload.idTiposOcorrencia.length == 0) {
        setErrors({
          idTipoOcorrencia: "Você deve ao menos selecionar um tipo",
        });
        return;
      }
      setSaving(true);

      if (payload.id) {
        const {
          id,
          // idCategoriaOcorrencia,
          idTiposOcorrencia,
          alunos,
          data,
          ...rest
        } = payload;
        await api.put(`ocorrencia/${id}`, {
          ...rest,
          idTipoOcorrencia: idTiposOcorrencia[0],
          idAluno: alunos[0].id,
          data: format(data, "yyyy-MM-dd"),
          hora: format(data, "HH:mm"),
        });
      } else {
        await api.post("ocorrencia/payload", payload);
      }
      setSaving(false);
      const s = payload.alunos.length > 1 ? "s" : "";

      let msg: string | ReactNode = `Ocorrência${s} criada${s} com sucesso!`;
      if (goToList) {
        let url = `/ocorrencias/${payload.idTurma}`;
        if (payload.alunos.length == 1) {
          url += `/?idAluno=${payload.alunos[0].id}`;
        }
        msg = (
          <Grid container alignItems="center">
            <Grid item>{msg}</Grid>
            <Grid item>
              <IconButton
                size="small"
                color="inherit"
                onClick={() => {
                  history.push(url);
                }}
              >
                <ArrowRightBoldIcon fontSize="small" />
              </IconButton>
            </Grid>
          </Grid>
        );
      }
      snack.success(msg);
      handleClose(true);
    } catch (error) {
      setSaving(false);
      snack.error(getError(error));
    }
  };
  const turma = utils
    .getTurmasTreeView({ cursos })
    .find((f) => f.id == payload.idTurma);

  let materia =
    (turma?.materias ?? []).find((f) => f.id == payload.idMateria) ?? null;
  if (!materia && usuario?.tipoUsuario == EUserType.Professor) {
    materia = turma?.materias?.at(0) ?? null;
  }

  const categoria = categorias.find(
    (f) => f.id == payload.idCategoriaOcorrencia
  );
  const tiposSelecionados = tipos.filter((f) =>
    payload.idTiposOcorrencia.includes(f.id ?? 0)
  );
  const tiposFiltrados = tipos.filter(
    (f) =>
      f.idCategoriaOcorrencia == payload.idCategoriaOcorrencia ||
      payload.idCategoriaOcorrencia == undefined
  );

  return (
    <>
      <PerfectScrollbar>
        <DialogContent dividers style={{ paddingBottom: 0 }}>
          <Form noValidate id="formCadastroOcorrencia">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="caption" component="p" pl={1.5}>
                  {"Aluno".concat(item.alunos.length > 1 ? "s" : "")}
                </Typography>
                {item.alunos.map((m) => (
                  <Chip
                    key={`ocorrencia_aluno_${m.id}`}
                    label={m.nome ?? ""}
                    sx={{ mr: 1, mb: 1 }}
                  />
                ))}
              </Grid>
              <Grid item xs={12} md={6}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <DateTimePicker
                      label="Data e hora"
                      onChange={handleDate}
                      value={payload.data}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={!!errors.data}
                          helperText={errors.data}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      select
                      label="Matéria"
                      SelectProps={{ displayEmpty: true }}
                      InputLabelProps={{ shrink: true }}
                      value={payload.idMateria ?? ""}
                      onChange={({ target: { value } }) =>
                        setPayload((p) => ({
                          ...p,
                          idMateria: value != "" ? parseInt(value, 10) : null,
                        }))
                      }
                    >
                      {usuario?.tipoUsuario != EUserType.Professor && (
                        <MenuItem value="">
                          <em>INDEPENDENTE DA MATÉRIA</em>
                        </MenuItem>
                      )}
                      {(turma?.materias ?? []).map((option) => (
                        <MenuItem
                          key={`materia-${option.id}`}
                          value={option.id}
                        >
                          {option.nome?.rUpper()}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      select
                      label="Categoria"
                      SelectProps={{ displayEmpty: true }}
                      InputLabelProps={{ shrink: true }}
                      value={categoria?.id ?? ""}
                      onChange={({ target: { value } }) => {
                        setPayload((p) => ({
                          ...p,
                          idCategoriaOcorrencia:
                            value != "" ? parseInt(value, 10) : null,
                          idTiposOcorrencia: [],
                        }));
                      }}
                    >
                      <MenuItem value="">
                        <em>TODAS AS CATEGORIAS</em>
                      </MenuItem>
                      {(categorias ?? []).map((option) => (
                        <MenuItem
                          key={`categoria-${option.id}`}
                          value={option.id}
                        >
                          {option.descricao?.rUpper()}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12}>
                    <Autocomplete
                      noOptionsText="Nenhum tipo encontrado"
                      sx={{ mb: 2 }}
                      multiple
                      value={tiposSelecionados}
                      options={tiposFiltrados}
                      getOptionLabel={(o) => o.nome ?? ""}
                      onChange={(e, value) => {
                        setPayload((p) => ({
                          ...p,
                          idTiposOcorrencia: value.map((m) => m.id ?? 0),
                          historico: value.at(0)?.textoPadrao ?? "",
                        }));
                        setErrors((p) => ({
                          ...p,
                          idTipoOcorrencia: undefined,
                        }));
                      }}
                      renderInput={(p) => (
                        <TextField
                          {...p}
                          label="Tipo"
                          error={!!errors.idTipoOcorrencia}
                          helperText={errors.idTipoOcorrencia}
                        />
                      )}
                      renderOption={(p, o) => (
                        <ListItem {...p}>
                          <ListItemText primary={o.nome} />
                        </ListItem>
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormGroup sx={{ mt: 0.5, mb: 0.5 }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={({ target: { checked } }) =>
                              setPayload((p) => ({ ...p, restrita: checked }))
                            }
                            checked={payload.restrita}
                          />
                        }
                        label="Não Imprimir no Boletim / Aluno Online"
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      value={payload.historico}
                      onChange={({ target: { value } }) =>
                        setPayload((p) => ({ ...p, historico: value }))
                      }
                      disabled={configuracoes.bloq_padrao_ocorre}
                      InputLabelProps={{ shrink: true }}
                      inputProps={{ maxLength }}
                      multiline
                      rows={6}
                      label="Histórico"
                      helperText={`${
                        maxLength - payload.historico.length
                      } caracteres restantes.`}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        </DialogContent>
      </PerfectScrollbar>
      <DialogActions>
        <Button onClick={() => handleClose()} variant="text" color="inherit">
          Cancelar
        </Button>
        <LoadingButton loading={saving} onClick={handleSubmit}>
          Salvar
        </LoadingButton>
      </DialogActions>
    </>
  );
};
