import React, { FC, useEffect } from "react";
import { Redirect } from "react-router-dom";

import { Box } from "@mui/material";

import { useAppSelector, useAppDispatch } from "store";
import { request } from "store/modules/auth/validate";
import { LoadingWithMessage } from "components/LoadingWithMessage";
import { useFirebaseRoutesAnalytics } from "hooks/useFirebaseAnalytics";

export const Interno: FC = ({ children }) => {
  const dispatch = useAppDispatch();
  const state = useAppSelector((state) => state.validate);

  useEffect(() => {
    dispatch(request());
  }, [dispatch]);

  useFirebaseRoutesAnalytics();

  if (state.loading) {
    return (
      <Box paddingTop="50vh" height="calc(100% - 50vh)">
        <LoadingWithMessage message="Aguarde, verificando usuário..." />
      </Box>
    );
  }

  if (state.error) {
    return <Redirect to="/login" />;
  }

  return (
    <Box
      sx={{
        display: "flex",
        height: "100%",
      }}
    >
      <Box
        sx={{
          width: "100%",
        }}
      >
        {children}
      </Box>
    </Box>
  );
};
