import React, { FC } from "react";

import { Box, Grid } from "@mui/material";
import * as colors from "@mui/material/colors";
import { ColorPartial } from "@mui/material/styles/createPalette";

import { ColorCircle } from "components/ColorCircle";

export const DialogColorPicker: FC<{
  onClick: (color: ColorPartial) => void;
  hideDialog: () => void;
  selected?: ColorPartial;
}> = ({ onClick, hideDialog, selected }) => {
  return (
    <Box
      sx={{
        pl: 2,
        pr: 2,
        pb: 2,
      }}
    >
      <Grid container spacing={1}>
        {Object.entries(colors)
          .filter((f) => f[0] != "common")
          .map((m) => (
            <Grid item key={m[0]}>
              <ColorCircle
                color={m[1] as ColorPartial}
                selected={(m[1] as ColorPartial) == selected}
                onClick={(color) => {
                  hideDialog();
                  onClick(color);
                }}
              />
            </Grid>
          ))}
      </Grid>
    </Box>
  );
};
