import { Avatar } from "@mui/material";
import React, { FC } from "react";

export const ImgIcon: FC<{
  src: string;
}> = ({ src }) => {
  return (
    <Avatar
      src={src}
      sx={({ palette: { mode } }) => ({
        img: {
          filter: mode == "dark" ? "invert(100%)" : "inherit",
        },
      })}
    />
  );
};
