import { IUser } from "@deltasge/marauders-map";

export enum ActionTypes {
  SET = "@auth/usuario/SET",
}

export interface IPayloadRequest {
  usuario: string;
  senha: string;
}

export interface IUsuariosAuth {
  usuario: IUser | null;
  usuarios: IUser[];
}
