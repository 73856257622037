import React, { FC, useRef, useState } from "react";
import { default as AvatarEditorLib, Position } from "react-avatar-editor";

import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Slider,
  Stack,
} from "@mui/material";
import {
  MagnifyPlus as ZoomPlusIcon,
  MagnifyMinus as ZoomMinusIcon,
  RotateRight as RotateRightIcon,
  RotateLeft as RotateLeftIcon,
} from "mdi-material-ui";

interface IPropsEditor {
  image: File | string;
  allowZoomOut: boolean;
  position: { x: number; y: number };
  scale: number;
  rotate: number;
  borderRadius: number;
  // preview: undefined;
  width: number;
  height: number;
  disableCanvasRotation: boolean;
  isTransparent: boolean;
  // backgroundColor: undefined;
  showGrid: boolean;
}

const INIT_PROPS_EDITOR: IPropsEditor = {
  image: "",
  allowZoomOut: false,
  position: { x: 0.5, y: 0.5 },
  scale: 1,
  rotate: 0,
  borderRadius: 50,
  // preview: undefined,
  width: 400,
  height: 400,
  disableCanvasRotation: false,
  isTransparent: false,
  // backgroundColor: undefined,
  showGrid: false,
};

interface IEditor {
  image: File | string;
  onSave: (image: string) => void;
}

export const AvatarEditor: FC<IEditor> = ({ image, onSave }) => {
  const editor = useRef<AvatarEditorLib | null>(null);
  const [props, setProps] = useState<IPropsEditor>({
    ...INIT_PROPS_EDITOR,
    image,
  });

  const handlePositionChange = (position: Position) =>
    setProps({ ...props, position });

  const handleSave = () => {
    const image = editor.current?.getImageScaledToCanvas().toDataURL();
    if (!image) throw new Error("Erro ao gravar a imagem do editor");

    onSave(image);
  };

  return (
    <Box m={2}>
      <Grid container justifyContent="space-between" alignItems="stretch">
        <Grid item xs={12} textAlign="center">
          <AvatarEditorLib
            ref={editor}
            image={props.image}
            width={props.width}
            height={props.height}
            scale={props.scale}
            rotate={props.rotate}
            position={props.position}
            borderRadius={props.width / (100 / props.borderRadius)}
            onPositionChange={handlePositionChange}
          />
        </Grid>
        <Grid item xs={12}>
          <FormGroup>
            <FormControlLabel
              sx={{ margin: "auto" }}
              control={
                <Checkbox
                  checked={props.allowZoomOut}
                  onChange={(e, allowZoomOut) => {
                    setProps({
                      ...props,
                      allowZoomOut,
                    });
                  }}
                />
              }
              label="Permitir escala < 1"
            />
          </FormGroup>
        </Grid>
        <Grid item xs={6}>
          <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
            <IconButton
              disabled={props.scale == 1}
              onClick={() => {
                setProps({
                  ...props,
                  scale: props.scale - 0.1,
                });
              }}
            >
              <ZoomMinusIcon />
            </IconButton>
            <Slider
              value={props.scale}
              onChange={(event: Event, scale: number | number[]) => {
                if (typeof scale != "number") return;
                setProps({
                  ...props,
                  scale,
                });
              }}
              min={props.allowZoomOut ? 0.1 : 1}
              max={2}
              step={0.01}
            />
            <IconButton
              disabled={props.scale == 2}
              onClick={() => {
                setProps({
                  ...props,
                  scale: props.scale + 0.1,
                });
              }}
            >
              <ZoomPlusIcon />
            </IconButton>
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
            <IconButton
              disabled={props.rotate == 0}
              onClick={() => {
                setProps({ ...props, rotate: (props.rotate - 90) % 360 });
              }}
            >
              <RotateLeftIcon />
            </IconButton>
            <Slider
              value={props.rotate}
              onChange={(event: Event, rotate: number | number[]) => {
                if (typeof rotate != "number") return;
                setProps({
                  ...props,
                  rotate,
                });
              }}
              min={0}
              max={180}
              step={1}
            />
            <IconButton
              disabled={props.rotate == 180}
              onClick={() => {
                setProps({ ...props, rotate: (props.rotate + 90) % 360 });
              }}
            >
              <RotateRightIcon />
            </IconButton>
          </Stack>
        </Grid>
        <Grid item xs={12} textAlign="right">
          <Button onClick={handleSave}>Ok</Button>
        </Grid>
      </Grid>
    </Box>
  );
};
