import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "store";

import { toggleSidebar } from "store/modules/layout";
import { useMobile } from "../useMobile";

export const useSidebar = () => {
  const dispatch = useDispatch();
  const isMobile = useMobile();
  const { sidebar } = useAppSelector((state) => state.layout);
  const toggle = useCallback(() => {
    if (isMobile) {
      dispatch(toggleSidebar());
    }
  }, [dispatch, isMobile]);

  return { toggle, sidebar };
};
