import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import {
  IDiaAula,
  IMensagem,
  IMensagemArquivo,
  IMensagemLink,
} from "@deltasge/marauders-map";
import { format, parseISO } from "date-fns";

import {
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  Chip,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Stack,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { MobileDateTimePicker } from "@mui/x-date-pickers";
import {
  Link as LinkIcon,
  TrayArrowUp as UploadIcon,
  ArrowTopRightThinCircleOutline as TarefaIcon,
  ClockOutline as RelogioIcon,
  Paperclip as FileIcon,
  ContentCopy as CopyIcon,
  ContentPaste as PasteIcon,
  Close as CleanIcon,
  Delete as DeleteIcon,
} from "mdi-material-ui";

import { Input } from "assets/styleds";
import { api, history, Environment } from "configs";
import { snack } from "components/GlobalSnackbar";
import { getBNCC, getError, loadS3File, sendS3File } from "utils";
import { useDialog } from "hooks/useDialog";
import { DialogLink } from "pages/Mensagens/cadastro/components";
import { useConfirmDialog } from "hooks/useDialogConfirm";

export const DadosAula: FC<{
  aula: IDiaAula;
  gravarAula: (aula: IDiaAula) => Promise<void>;
  debouncedGravarAula: (aula: IDiaAula) => Promise<void>;
  gravarTarefa: (mensagem: IMensagem) => Promise<void>;
  debouncedGravarTarefa: (mensagem: IMensagem) => Promise<void>;
  setAula: (value: React.SetStateAction<IDiaAula | undefined>) => void;
  mensagem?: IMensagem;
  apagarTodasFaltas: () => Promise<void>;
  removerTarefa: () => Promise<void>;
  conteudoCopiado?: string;
  setConteudoCopiado: (value: string) => void;
  setMsgErrorAttr: Dispatch<SetStateAction<string | undefined>>;
  msgErrorAttr?: string;
}> = ({
  aula,
  mensagem,
  gravarAula,
  debouncedGravarAula,
  gravarTarefa,
  debouncedGravarTarefa,
  setAula,
  apagarTodasFaltas,
  conteudoCopiado,
  setConteudoCopiado,
  removerTarefa,
  setMsgErrorAttr,
  msgErrorAttr,
}) => {
  const [link, setLink] = useState<IMensagemLink>();
  const [descricao, setDescricao] = useState(aula.descricao);
  const [texto, setTexto] = useState(mensagem?.texto);
  const [respostaAte, setRespostaAte] = useState<Date | null>(
    !mensagem
      ? null
      : typeof mensagem.respostaAte == "string"
      ? parseISO(mensagem.respostaAte)
      : mensagem.respostaAte
  );
  const [uploading, setUploading] = useState(false);

  const theme = useTheme();

  const uploadMensagemArquivo = async (files: FileList | null) => {
    try {
      if (!mensagem) {
        throw new Error("A mensagem precisa ser criada");
      }
      setUploading(true);
      if (files != null && mensagem.id) {
        if (!mensagem.mensagemArquivos) {
          mensagem.mensagemArquivos = [];
        }
        let ordem = 1;
        for await (const file of files) {
          const arquivo = await sendS3File({
            file,
          });

          await api.post<IMensagemArquivo>("educador/mensagem-arquivo", {
            idArquivo: arquivo.id,
            idMensagem: mensagem.id,
            ordem,
          });

          mensagem.mensagemArquivos.push({
            idArquivo: arquivo.id ?? "",
            idMensagem: mensagem.id ?? null,
            ordem,
            arquivo,
          });
          ordem++;
        }
      }
      setAula({ ...aula, mensagem });
    } catch (error) {
      snack.warning(getError(error));
    } finally {
      setUploading(false);
    }
  };

  const updateLink = async (newLink: IMensagemLink) => {
    try {
      if (!mensagem) {
        throw new Error("A mensagem precisa ser criada");
      }

      if (!mensagem.mensagemLinks) mensagem.mensagemLinks = [];

      let i = -1;
      if (newLink.id) {
        await api.put(`educador/mensagem-link/${newLink.id}`, {
          url: newLink.url,
        });
        i = mensagem.mensagemLinks.findIndex((f) => f.id == newLink.id);
      } else {
        const { data } = await api.post<IMensagemLink>(
          "educador/mensagem-link",
          { ...newLink, idMensagem: mensagem.id }
        );
        newLink.id = data.id;
        newLink.idMensagem = mensagem.id ?? null;
        i = mensagem.mensagemLinks.findIndex(
          (f) => f.url == (link ?? newLink).url
        );
      }
      if (i >= 0) {
        mensagem.mensagemLinks[i] = newLink;
      } else {
        mensagem.mensagemLinks.push(newLink);
      }
      setAula({ ...aula, mensagem });
      setLink(undefined);
      hideLink();
    } catch (error) {
      snack.warning(getError(error));
    }
  };

  const deleteLink = async (link: IMensagemLink) => {
    try {
      if (!mensagem) {
        throw new Error("A mensagem precisa ser criada");
      }

      mensagem.mensagemLinks = (mensagem.mensagemLinks ?? []).filter(
        (f) => f.url != link.url
      );
      setAula({ ...aula, mensagem });

      await api.delete(`educador/mensagem-link/${link.id}`);
    } catch (error) {
      snack.warning(getError(error));
    }
  };

  const deleteArquivo = async (ma: IMensagemArquivo) => {
    try {
      if (!mensagem) {
        throw new Error("A mensagem precisa ser criada");
      }

      mensagem.mensagemArquivos = (mensagem.mensagemArquivos ?? []).filter(
        (f) => f.idArquivo != ma.idArquivo
      );
      setAula({ ...aula, mensagem });

      await api.delete(`educador/mensagem-arquivo/ids/`, {
        params: {
          ids: {
            idMensagem: ma.idMensagem,
            idArquivo: ma.idArquivo,
          },
        },
      });
    } catch (error) {
      snack.warning(getError(error));
    }
  };

  const handleOpenArquivo = async (ma: IMensagemArquivo) => {
    try {
      if (!ma.arquivo) return;
      const { processed, error } = await loadS3File(ma.arquivo.caminhoArquivo);
      if (error) throw new Error(error);

      window.open(processed, "_blank");
    } catch (error) {
      snack.warning(getError(error));
    }
  };

  const handleChangeAtr = ({
    aluno,
    loginExtra,
    responsavel,
  }: {
    aluno?: boolean;
    loginExtra?: boolean;
    responsavel?: boolean;
  }) => {
    try {
      if (!mensagem) {
        throw new Error("Tarefa ainda não criada");
      }

      if (!mensagem.mensagemAtribuicoes) {
        throw new Error("Nenhuma atribuição definida");
      }
      mensagem.mensagemAtribuicoes[0].aluno =
        aluno == undefined ? mensagem.mensagemAtribuicoes[0].aluno : aluno;
      mensagem.mensagemAtribuicoes[0].loginExtra =
        loginExtra == undefined
          ? mensagem.mensagemAtribuicoes[0].loginExtra
          : loginExtra;
      mensagem.mensagemAtribuicoes[0].responsavel =
        responsavel == undefined
          ? mensagem.mensagemAtribuicoes[0].responsavel
          : responsavel;

      if (
        !mensagem.mensagemAtribuicoes[0].aluno &&
        !mensagem.mensagemAtribuicoes[0].loginExtra &&
        !mensagem.mensagemAtribuicoes[0].responsavel
      ) {
        setMsgErrorAttr("Você deve escolher quem vai receber a tarefa");
      } else {
        setMsgErrorAttr(undefined);
      }
      gravarTarefa(mensagem);
    } catch (error) {
      snack.error(getError(error));
    }
  };

  const { show: showRemoverAula, RenderDialog: DialogRemoverAula } =
    useConfirmDialog<IDiaAula>({
      defaults: {
        title: "Remover Aula",
        content: "Tem certeza que deseja remover este dia de aula?",
      },
      onConfirmed: async (payload, hide) => {
        try {
          await apagarTodasFaltas();
          await api.delete(`/diaaula/${payload.id}`);
          hide();
          history.push("/");
        } catch (error) {
          snack.error(getError(error));
        }
      },
    });

  const {
    show: showLink,
    RenderDialog: RenderLink,
    hide: hideLink,
  } = useDialog({
    title: "Adicione um link",
    options: {
      modal: true,
      maxWidth: "xs",
      scroll: "paper",
    },
  });

  useEffect(() => {
    if (mensagem && mensagem.texto != texto) setTexto(mensagem.texto);
  }, [mensagem?.texto]);

  return (
    <Box component="form" noValidate>
      <DialogRemoverAula />
      {mensagem && (
        <RenderLink>
          <DialogLink
            idMensagem={mensagem.id ?? null}
            link={link}
            set={updateLink}
          />
        </RenderLink>
      )}
      <Grid
        container
        pt={1}
        pl={1}
        spacing={1}
        width={({ spacing }) => spacing(Environment.DRAWER_WIDTH)}
        alignItems="center"
      >
        <Grid item xs={12}>
          <Typography variant="subtitle2">GERENCIAR AULA</Typography>
        </Grid>
        <Grid item xs={12}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={aula.diaSemAula}
                  onChange={async ({ target: { checked } }) => {
                    aula.diaSemAula = checked;
                    if (checked) {
                      setTexto("");
                    }
                    gravarAula(aula);
                  }}
                />
              }
              label="Dia sem aula"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={aula.inutilizaDia}
                  onChange={async ({ target: { checked } }) => {
                    aula.inutilizaDia = checked;
                    if (checked) aula.descricao = "";
                    gravarAula(aula);
                  }}
                />
              }
              disabled={!aula.diaSemAula}
              label="Inutilizar dia"
            />
          </FormGroup>
        </Grid>
        <Grid item xs={10}>
          <Typography variant="subtitle2">
            {aula.diaSemAula ? "DIA SEM AULA" : "CONTEÚDO MINISTRADO"}
          </Typography>
        </Grid>
        <Grid item xs={2} textAlign="right">
          <Tooltip title="Consultar diretamente a BNCC">
            <div>
              <IconButton
                aria-label="consulta-bncc"
                color="primary"
                onClick={() =>
                  window.open(
                    `http://basenacionalcomum.mec.gov.br/abase/#${
                      aula.curso?.grau === "1"
                        ? "fundamental"
                        : aula.curso?.grau === "2"
                        ? "medio"
                        : "infantil"
                    }`,
                    "_blank"
                  )
                }
              >
                <TarefaIcon />
              </IconButton>
            </div>
          </Tooltip>
        </Grid>
        <Grid item xs={12}>
          <Divider />
          <Box sx={{ p: 2 }}>
            <ButtonGroup fullWidth>
              <Button
                startIcon={<CopyIcon />}
                disabled={!descricao || descricao.length == 0}
                onClick={() => setConteudoCopiado(descricao ?? "")}
              >
                Copiar
              </Button>
              <Button
                startIcon={<PasteIcon />}
                color="success"
                disabled={
                  aula.inutilizaDia ||
                  !conteudoCopiado ||
                  conteudoCopiado.length == 0
                }
                onClick={() => {
                  setDescricao(conteudoCopiado ?? "");
                  aula.descricao = conteudoCopiado || "";
                  gravarAula(aula);
                }}
              >
                Colar
              </Button>
              <Button
                startIcon={<CleanIcon />}
                color="warning"
                disabled={!conteudoCopiado || conteudoCopiado.length == 0}
                onClick={() => setConteudoCopiado("")}
              >
                Limpar
              </Button>
            </ButtonGroup>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <TextField
            value={descricao}
            multiline
            rows={8}
            disabled={aula.inutilizaDia}
            label={
              aula.diaSemAula
                ? "Descrição de dia sem aula"
                : "Descrição do Conteúdo Ministrado"
            }
            onChange={async ({ target: { value } }) => {
              aula.descricao = value;
              setDescricao(value);
              await debouncedGravarAula(aula);
            }}
            helperText={"Interpreta código BNCC"}
            inputProps={{
              onKeyUp: async (e: any) => {
                const valorNovo = await getBNCC(e.target.value as string, true);
                if (valorNovo) {
                  e.target.value = valorNovo;
                  setDescricao(valorNovo);
                  await debouncedGravarAula({ ...aula, descricao: valorNovo });
                }
              },
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        {mensagem && (
          <>
            <Grid item xs>
              <Typography variant="subtitle2">TAREFA DE CASA</Typography>
            </Grid>
            <Grid item textAlign="right">
              <Tooltip title="Remover da tarefa de casa">
                <div>
                  <IconButton
                    aria-label="remover-tarefa"
                    color="secondary"
                    disabled={!mensagem.id}
                    onClick={removerTarefa}
                  >
                    <DeleteIcon />
                  </IconButton>
                </div>
              </Tooltip>
            </Grid>
            <Grid item textAlign="right">
              <Tooltip title="Detalhes da tarefa de casa">
                <div>
                  <IconButton
                    aria-label="detalhes-tarefa"
                    color="primary"
                    disabled={!mensagem.id}
                    onClick={() =>
                      history.push(`/publicacoes/detalhes/${mensagem.id}/tab/1`)
                    }
                  >
                    <TarefaIcon />
                  </IconButton>
                </div>
              </Tooltip>
            </Grid>
            <Grid item xs={12}>
              <TextField
                multiline
                rows={6}
                disabled={
                  aula.diaSemAula ||
                  !aula.descricao ||
                  aula.descricao.length == 0 ||
                  mensagem.idMensagemCategoria == 0
                }
                value={texto}
                label="Descrição da tarefa"
                onChange={({ target: { value } }) => {
                  setTexto(value);
                  mensagem.texto = value;
                  debouncedGravarTarefa(mensagem);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <ListItem
                secondaryAction={
                  <MobileDateTimePicker
                    minDateTime={new Date()}
                    maxDate={new Date(parseInt(aula.anoLetivo, 10), 11, 31)}
                    label="Responder até"
                    value={respostaAte}
                    onAccept={(respostaAte) => setRespostaAte(respostaAte)}
                    onChange={(respostaAte) => {
                      setRespostaAte(respostaAte);
                      debouncedGravarTarefa({
                        ...mensagem,
                        respostaAte,
                      });
                    }}
                    renderInput={(props) => {
                      if (props.InputProps?.readOnly) {
                        return (
                          <Tooltip title="Definir data de entrega">
                            <div>
                              <IconButton
                                edge="end"
                                aria-label="relogio"
                                disabled={
                                  !mensagem.permiteResposta ||
                                  !mensagem.respostaComAnexo
                                }
                                onClick={(e) => {
                                  if (props.inputProps?.onClick)
                                    props.inputProps?.onClick(
                                      e as React.MouseEvent<
                                        HTMLInputElement | HTMLTextAreaElement,
                                        MouseEvent
                                      >
                                    );
                                }}
                              >
                                <RelogioIcon />
                              </IconButton>
                            </div>
                          </Tooltip>
                        );
                      } else {
                        return <TextField {...props} />;
                      }
                    }}
                  />
                }
                disablePadding
              >
                <ListItemButton
                  disabled={aula.diaSemAula || !mensagem.id}
                  role={undefined}
                  dense
                  onClick={() => {
                    if (mensagem.permiteResposta) setRespostaAte(null);
                    gravarTarefa({
                      ...mensagem,
                      permiteResposta: !mensagem.permiteResposta,
                      respostaComAnexo: !mensagem.respostaComAnexo,
                      respostaAte: !mensagem.permiteResposta
                        ? null
                        : mensagem.respostaAte,
                    });
                  }}
                >
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={
                        mensagem.permiteResposta && mensagem.respostaComAnexo
                      }
                      tabIndex={-1}
                      disableRipple
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary="Permitir entrega"
                    secondary={
                      respostaAte
                        ? `Até: ${format(respostaAte, "dd/MM/yyyy HH:mm")}`
                        : undefined
                    }
                    secondaryTypographyProps={{
                      variant: "caption",
                    }}
                  />
                </ListItemButton>
              </ListItem>
            </Grid>
            <Grid item xs={12}>
              <Paper variant="outlined" sx={{ p: 1 }}>
                <Tooltip title="Criar link">
                  <span>
                    <IconButton
                      aria-label="criar-link"
                      disabled={aula.diaSemAula || !mensagem.id}
                      onClick={() => {
                        showLink();
                      }}
                    >
                      <LinkIcon />
                    </IconButton>
                  </span>
                </Tooltip>
                <Tooltip title="Fazer upload de arquivos">
                  <label htmlFor="icon-button-file">
                    <Input
                      accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf, image/*"
                      id="icon-button-file"
                      type="file"
                      multiple
                      disabled={aula.diaSemAula || !mensagem.id || uploading}
                      onChange={async ({ target: { files } }) => {
                        await uploadMensagemArquivo(files);
                      }}
                    />
                    <IconButton
                      aria-label="upload-arquivo"
                      component="span"
                      disabled={aula.diaSemAula || !mensagem.id || uploading}
                    >
                      {uploading ? (
                        <CircularProgress size={theme.spacing(3)} />
                      ) : (
                        <UploadIcon />
                      )}
                    </IconButton>
                  </label>
                </Tooltip>
                <Stack direction="column" spacing={2}>
                  {(mensagem.mensagemLinks ?? [])
                    .filter((m) => !m.deletedAt)
                    .map((link) => (
                      <Chip
                        disabled={aula.diaSemAula || !mensagem.id}
                        icon={<LinkIcon />}
                        key={link.url}
                        label={link.url}
                        variant="outlined"
                        onClick={() => window.open(link.url, "_blank")}
                        onDelete={() => deleteLink(link)}
                      />
                    ))}
                  {(mensagem.mensagemArquivos ?? [])
                    .filter((m) => !m.deletedAt)
                    .map((ma) => (
                      <Chip
                        disabled={aula.diaSemAula || !mensagem.id}
                        icon={<FileIcon />}
                        key={ma.idArquivo}
                        label={ma.arquivo?.nomeArquivo}
                        variant="outlined"
                        onDelete={() => deleteArquivo(ma)}
                        onClick={() => handleOpenArquivo(ma)}
                      />
                    ))}
                </Stack>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper variant="outlined">
                <FormControl error={!!msgErrorAttr}>
                  <Typography variant="caption" sx={{ p: 0.5 }}>
                    Enviar para
                  </Typography>

                  <Divider />
                  <FormGroup sx={{ p: 0.5 }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={
                            !mensagem.texto || mensagem.texto.trim().length == 0
                          }
                          checked={
                            mensagem.mensagemAtribuicoes?.at(0)?.aluno ?? false
                          }
                          onChange={({ target: { checked } }) => {
                            handleChangeAtr({ aluno: checked });
                          }}
                        />
                      }
                      label="Alunos"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={
                            !mensagem.texto || mensagem.texto.trim().length == 0
                          }
                          checked={
                            mensagem.mensagemAtribuicoes?.at(0)?.responsavel ??
                            false
                          }
                          onChange={({ target: { checked } }) => {
                            handleChangeAtr({ responsavel: checked });
                          }}
                        />
                      }
                      label="Responsáveis"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={
                            !mensagem.texto || mensagem.texto.trim().length == 0
                          }
                          checked={
                            mensagem.mensagemAtribuicoes?.at(0)?.loginExtra ??
                            false
                          }
                          onChange={({ target: { checked } }) => {
                            handleChangeAtr({ loginExtra: checked });
                          }}
                        />
                      }
                      label="Logins extra do aluno"
                    />
                  </FormGroup>
                  {msgErrorAttr && (
                    <FormHelperText>{msgErrorAttr}</FormHelperText>
                  )}
                </FormControl>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <Button
            color="secondary"
            fullWidth
            variant="text"
            onClick={() => showRemoverAula(aula)}
          >
            Excluir dia de Aula
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};
