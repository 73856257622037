import { FC } from "react";

import {
  ClockOutline as AulasIcon,
  GoogleClassroom as TurmasIcon,
  Forum as ForumIcon,
  FormatListBulletedSquare as ConteudoIcon,
  FolderStar as OcorrenciaIcon,
  ClipboardClock as AvaliacaoIcon,
  TableCheck as NotasIcon,
  MessageBulleted as MensagensIcon,
} from "mdi-material-ui";

import { Home } from "pages/Home";
import { Turmas } from "pages/Turmas";
import { Mensagens } from "pages/Mensagens";
import { DetalhesMensagem } from "pages/Mensagens/detalhes/Detalhes";
import { Configuracoes } from "pages/Configuracoes";
import { MensagemRecebida } from "pages/Mensagens/recebida";
import { Chats } from "pages/Chats";
import { Chamada } from "pages/Chamada";
import { GerenciarAula } from "pages/GerenciarAula";
import { Profile } from "pages/Profile";
import { Chat } from "pages/Chats/Chat";
import { ConteudoMinistradoAulas } from "pages/ConteudoMinistrado";
import { Turma } from "pages/Turma";
import { Ocorrencias } from "pages/Ocorrencias";
import { Avaliacao } from "pages/Avaliacao";
import { Notas } from "pages/Notas";
import { DevMode } from "pages/DevMode";

export interface IRoute {
  showOnSidebar?: boolean;
  icon?: FC;
  label?: string;
  group?: number;
  role?: number;
  path: string;
  component?: FC;
  routes?: IRoute[];
}

export const routes: IRoute[] = [
  {
    showOnSidebar: true,
    icon: AulasIcon,
    label: "Aulas",
    path: "/",
    component: Home,
  },
  {
    path: "/gerenciar-aula/:idDiaAula",
    component: GerenciarAula,
  },
  {
    showOnSidebar: true,
    icon: TurmasIcon,
    label: "Turmas",
    path: "/turmas",
    component: Turmas,
  },
  {
    showOnSidebar: false,
    path: "/chamada-dia/:data/turma/:idTurma",
    component: Chamada,
  },
  {
    showOnSidebar: true,
    label: "Conteúdo ministrado",
    icon: ConteudoIcon,
    path: "/conteudo-ministrado",
    component: ConteudoMinistradoAulas,
  },
  {
    showOnSidebar: true,
    path: "/avaliacao",
    component: Avaliacao,
    icon: AvaliacaoIcon,
    label: "Avaliações",
  },
  {
    showOnSidebar: true,
    icon: ForumIcon,
    label: "Conversas",
    path: "/chat",
    component: Chats,
  },
  {
    path: "/chat/setor/:idSetor/:deltaId",
    component: Chat,
  },
  {
    path: "/chat/channel/:idChatGroup",
    component: Chat,
  },
  {
    showOnSidebar: true,
    label: "Publicações",
    icon: MensagensIcon,
    path: "/publicacoes",
    component: Mensagens,
    routes: [],
  },
  {
    path: "/publicacoes/:idMensagemCategoria/categoria",
    component: Mensagens,
  },
  {
    path: "/publicacoes/:idMensagemCategoria/categoria/tab/:tab",
    component: Mensagens,
  },
  {
    path: "/publicacoes/detalhes/:idMensagem",
    component: DetalhesMensagem,
  },
  {
    path: "/publicacoes/detalhes/:idMensagem/tab/:tab",
    component: DetalhesMensagem,
  },
  {
    path: "/publicacoes/detalhes/:idMensagem/tab/:tab/destinatario/:destinatario",
    component: DetalhesMensagem,
  },
  {
    path: "/publicacoes/recebida/:idMensagem",
    component: MensagemRecebida,
  },
  {
    path: "/notas",
    component: Notas,
    icon: NotasIcon,
    label: "Digitação de notas",
    showOnSidebar: true,
  },
  {
    path: "/configuracoes",
    component: Configuracoes,
  },
  {
    path: "/profile",
    component: Profile,
  },
  {
    path: "/turma/:id",
    component: Turma,
  },
  {
    path: "/ocorrencias/",
    component: Ocorrencias,
    icon: OcorrenciaIcon,
    label: "Ocorrências",
    showOnSidebar: true,
  },
  {
    path: "/dev",
    component: DevMode,
  },
];
