import React, { FC } from "react";
import { IMensagem } from "@deltasge/marauders-map";
import { ptBR } from "date-fns/locale";
import {
  differenceInMinutes,
  format,
  formatDistance,
  parseISO,
} from "date-fns";

import { Divider, Grid, Typography } from "@mui/material";

import { DetalhesAnexos } from "./DetalhesAnexos";

export const DetalhesMensagem: FC<{ mensagem: IMensagem }> = ({ mensagem }) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        {mensagem.titulo && (
          <Typography variant="h5" noWrap>
            {mensagem.titulo}
          </Typography>
        )}
        <Typography variant="subtitle1" noWrap>
          {mensagem.mensagemCategoria?.nome}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography variant="caption">
          {mensagem.usuario?.nome ?? mensagem.professor?.nome}
          &nbsp;&bull;&nbsp;
          {mensagem.createdAt
            ? format(
                parseISO(mensagem.createdAt.toString()),
                "dd 'de' MMM. 'de' yyyy"
              )
            : ""}
          {mensagem.updatedAt && mensagem.createdAt != mensagem.updatedAt
            ? format(
                parseISO(mensagem.updatedAt.toString()),
                "' Editado às' dd 'de' MMM. 'de' yyyy"
              )
            : ""}
        </Typography>
      </Grid>
      {mensagem.respostaAte && (
        <Grid item xs={12}>
          {differenceInMinutes(
            parseISO(mensagem.respostaAte.toString()),
            Date.now()
          ) >= 0 ? (
            <Typography variant="caption">
              {"Data da entrega: ".concat(
                formatDistance(
                  parseISO(mensagem.respostaAte.toString()),
                  Date.now(),
                  {
                    addSuffix: true,
                    locale: ptBR,
                  }
                )
              )}
            </Typography>
          ) : (
            <Typography variant="caption" color="secondary">
              {"Prazo de entrega: ".concat(
                format(
                  parseISO(mensagem.respostaAte.toString()),
                  " dd 'de' MMM. 'de' yyyy 'às' H:mm"
                )
              )}
            </Typography>
          )}
        </Grid>
      )}
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <div dangerouslySetInnerHTML={{ __html: mensagem.texto ?? "" }} />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      {((mensagem.mensagemArquivos ?? []).length > 0 ||
        (mensagem.mensagemLinks ?? []).length > 0) && (
        <Grid item xs={12}>
          <DetalhesAnexos
            mensagemArquivos={mensagem.mensagemArquivos ?? []}
            mensagemLinks={mensagem.mensagemLinks ?? []}
          />
        </Grid>
      )}
    </Grid>
  );
};
