import React, { FC, useState } from "react";
import { Button, DialogActions, DialogContent, TextField } from "@mui/material";
import { IMensagemPerguntaFormatada } from "@deltasge/marauders-map";

export const DialogPergunta: FC<{
  pergunta?: IMensagemPerguntaFormatada;
  set: (pergunta: IMensagemPerguntaFormatada) => void;
  idMensagem: number | null;
}> = ({ pergunta, set }) => {
  const [text, setText] = useState(pergunta?.nome ?? "");
  return (
    <>
      <DialogContent dividers>
        <TextField
          label="Texto da resposta"
          onKeyUp={({ key }) => {
            if (text.trim() == "") return;
            key == "Enter"
              ? set({
                  nome: text,
                  idMensagem: pergunta?.idMensagem ?? null,
                })
              : null;
          }}
          onChange={({ target: { value } }) => setText(value)}
          value={text}
          helperText="Informe o texto que o usuário poderá escolher ao dar a resposta"
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            if (text.trim() == "") return;
            set({
              idMensagem: pergunta?.idMensagem ?? null,
              nome: text,
            });
          }}
        >
          OK
        </Button>
      </DialogActions>
    </>
  );
};
