import React, { Dispatch, FC, SetStateAction, useState } from "react";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  CircularProgress,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";

import {
  ChevronDown as ExpandMoreIcon,
  Calculator as RecalculoIcon,
  AccountSync as RemanejadoIcon,
  ClipboardClock as AvaliacaoIcon,
  FileDocumentMultipleOutline as RelNotaGelIcon,
  FileDocumentMultiple as RelMediaGelIcon,
  FileDocumentOutline as RelNotaIcon,
  ChartBox as GraficoTurmaIcon,
  ChartBoxOutline as GraficoAlunoIcon,
  TableSearch as ProvasIcon,
  CheckboxBlankOutline as ConcluirIcon,
  CheckboxMarked as ConcluidoIcon,
  Sync as RecarregarIcon,
} from "mdi-material-ui";
import { RuleFolder as BoletimIcon } from "@mui/icons-material";

import { IFilterDigitacao } from "interfaces";
import { Environment } from "configs";
import { IAvaCtr, IDNColumn } from "@deltasge/marauders-map";
import { snack } from "components/GlobalSnackbar";

export const Filter: FC<{
  Component: () => JSX.Element;
  filter: IFilterDigitacao;
  columns: IDNColumn[];
  setColumns: Dispatch<SetStateAction<IDNColumn[]>>;
  avaCtr?: IAvaCtr;
  handleReaload: () => void;
  handleConcluir: (avaCtr: IAvaCtr) => Promise<void>;
  handleBoletim: () => void;
  handleProvas: () => void;
  handleRelNotas: (tipo: "porMateria" | "gerais" | "gerais_bimestre") => void;
  handleAvaliacoes: () => void;
  handleCorrigirRemanejados: () => Promise<void>;
  handleRecalcular: () => Promise<void>;
  handleGrafico: (isAluno?: boolean) => Promise<void>;
}> = ({
  columns,
  setColumns,
  Component,
  avaCtr,
  handleReaload,
  handleConcluir,
  handleBoletim,
  handleProvas,
  handleRelNotas,
  handleAvaliacoes,
  handleCorrigirRemanejados,
  handleGrafico,
  handleRecalcular,
}) => {
  const [panel, setPanel] = useState({
    columns: false,
  });
  const [concluindo, setConcluindo] = useState(false);
  const [remanejando, setRemanejando] = useState(false);
  const [recalculando, setRecalculando] = useState(false);
  const [loadingGrafTurma, setLoadingGrafTurma] = useState(false);
  const [loadingGrafAluno, setLoadingGrafAluno] = useState(false);

  const onConcluir = async () => {
    if (!avaCtr) return;
    setConcluindo(true);
    await handleConcluir(avaCtr);
    setConcluindo(false);
  };

  const handleRemanejados = async () => {
    setRemanejando(true);
    await handleCorrigirRemanejados();
    setRemanejando(false);
  };

  const handleRecalculo = async () => {
    setRecalculando(true);
    await handleRecalcular();
    setRecalculando(false);
  };

  const handleOpenGraficoTurma = async () => {
    setLoadingGrafTurma(true);
    await handleGrafico();
    setLoadingGrafTurma(false);
  };

  const handleOpenGraficoAluno = async () => {
    setLoadingGrafAluno(true);
    await handleGrafico(true);
    setLoadingGrafAluno(false);
  };

  return (
    <Box
      sx={{
        width: ({ spacing }) => spacing(Environment.DRAWER_WIDTH),
        display: "block",
      }}
    >
      <Component />
      <Accordion
        elevation={0}
        expanded={panel.columns}
        onChange={() =>
          setPanel((prev) => ({ ...prev, columns: !panel.columns }))
        }
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Grid container>
            <Grid item xs={12} component={Typography} variant="subtitle2">
              Mostrar as colunas
            </Grid>
            <Grid item xs={12} component={Typography} variant="caption">
              {`${columns
                .filter((f) => f.visivel)
                .map((m) => m.nome)
                .join(", ")}`}
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <List dense>
            {columns.map((column) => (
              <ListItem disablePadding key={`coluna_id_${column.id}`}>
                <ListItemButton
                  onClick={() => {
                    if (
                      columns.filter((e) => e.visivel).length <= 2 &&
                      column.visivel
                    ) {
                      snack.warning(
                        "Você deve deixar ao menos 2 (duas) colunas visíveis"
                      );
                      return;
                    }
                    setColumns(
                      columns.map((current) => {
                        if (current.id == column.id) {
                          current.visivel = !current.visivel;
                        }
                        return current;
                      })
                    );
                  }}
                >
                  <ListItemIcon>
                    <Checkbox checked={column.visivel} disableRipple />
                  </ListItemIcon>
                  <ListItemText
                    primary={column.nome}
                    primaryTypographyProps={{
                      noWrap: true,
                    }}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </AccordionDetails>
      </Accordion>
      <Divider />
      <Box pt={1} pl={1} pr={1}>
        <ButtonGroup
          orientation="vertical"
          fullWidth
          sx={{ mb: 1 }}
          color="secondary"
        >
          <Button
            startIcon={
              concluindo ? (
                <CircularProgress
                  color="secondary"
                  sx={(theme) => ({
                    width: `${theme.spacing(2)} !important`,
                    height: `${theme.spacing(2)} !important`,
                  })}
                />
              ) : avaCtr && avaCtr.concluido ? (
                <ConcluidoIcon />
              ) : (
                <ConcluirIcon />
              )
            }
            disabled={avaCtr ? avaCtr.concluido : true}
            onClick={onConcluir}
          >
            {avaCtr && avaCtr.concluido ? "Concluído" : "Concluir"}
          </Button>
          <Button
            disabled={avaCtr ? avaCtr.concluido : true}
            startIcon={
              recalculando ? (
                <CircularProgress
                  color="secondary"
                  sx={(theme) => ({
                    width: `${theme.spacing(2)} !important`,
                    height: `${theme.spacing(2)} !important`,
                  })}
                />
              ) : (
                <RecalculoIcon />
              )
            }
            onClick={handleRecalculo}
          >
            Recalcular
          </Button>
          <Button
            disabled={avaCtr ? avaCtr.concluido : true}
            startIcon={
              remanejando ? (
                <CircularProgress
                  color="secondary"
                  sx={(theme) => ({
                    width: `${theme.spacing(2)} !important`,
                    height: `${theme.spacing(2)} !important`,
                  })}
                />
              ) : (
                <RemanejadoIcon />
              )
            }
            onClick={handleRemanejados}
          >
            Remanejados
          </Button>
          <Button startIcon={<RecarregarIcon />} onClick={handleReaload}>
            Recarregar
          </Button>
        </ButtonGroup>
        <ButtonGroup orientation="vertical" fullWidth sx={{ mb: 1 }}>
          <Button startIcon={<AvaliacaoIcon />} onClick={handleAvaliacoes}>
            Lista de avaliações
          </Button>
          <Button
            startIcon={<RelNotaIcon />}
            onClick={() => handleRelNotas("porMateria")}
          >
            Relatório de notas
          </Button>
          <Button
            startIcon={<RelNotaGelIcon />}
            onClick={() => handleRelNotas("gerais")}
          >
            Relatório de notas gerais
          </Button>
          <Button
            startIcon={<RelMediaGelIcon />}
            onClick={() => handleRelNotas("gerais_bimestre")}
          >
            Relatório de média gerais
          </Button>
        </ButtonGroup>
        <ButtonGroup orientation="vertical" fullWidth sx={{ mb: 1 }}>
          <Button
            startIcon={
              loadingGrafTurma ? (
                <CircularProgress
                  color="secondary"
                  sx={(theme) => ({
                    width: `${theme.spacing(2)} !important`,
                    height: `${theme.spacing(2)} !important`,
                  })}
                />
              ) : (
                <GraficoTurmaIcon />
              )
            }
            onClick={handleOpenGraficoTurma}
          >
            Gráfico da turma
          </Button>
          <Button
            startIcon={
              loadingGrafAluno ? (
                <CircularProgress
                  color="secondary"
                  sx={(theme) => ({
                    width: `${theme.spacing(2)} !important`,
                    height: `${theme.spacing(2)} !important`,
                  })}
                />
              ) : (
                <GraficoAlunoIcon />
              )
            }
            onClick={handleOpenGraficoAluno}
          >
            Gráfico do aluno
          </Button>
          <Button startIcon={<ProvasIcon />} onClick={handleProvas}>
            Provas do aluno
          </Button>
          <Button startIcon={<BoletimIcon />} onClick={handleBoletim}>
            Boletim do aluno
          </Button>
        </ButtonGroup>
      </Box>
    </Box>
  );
};
