import React, { FC, useState } from "react";
import {
  EUserType,
  IMensagemResposta,
  IMensagemRespostaAnexo,
  IMensagemRespostaLink,
  yupMensagemResposta,
} from "@deltasge/marauders-map";
import { api } from "configs";
import { useAppSelector } from "store";
import { getError, sendS3File } from "utils";

import {
  Grid,
  IconButton,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { Link as LinkIcon, TrayArrowUp as UploadIcon } from "mdi-material-ui";
import { LoadingButton } from "@mui/lab";

import { Input } from "assets/styleds";
import { Anexo } from "components/Anexo";
import { snack } from "components/GlobalSnackbar";
import { useDialog } from "hooks/useDialog";
import { DialogRespostaLink } from "./DialogRespostaLink";
import { useConfirmDialog } from "hooks/useDialogConfirm";

export const MensagemResposta: FC<{
  resposta?: IMensagemResposta;
  idMensagem: number;
  permitirResposta: boolean;
}> = ({ resposta: init, idMensagem, permitirResposta }) => {
  const {
    usuario: { usuario },
  } = useAppSelector((state) => state);

  const [resposta, setResposta] = useState<IMensagemResposta>({
    ...yupMensagemResposta.getDefault(),
    anexos: [],
    links: [],
    devolvido: undefined,
    ...init,
    idMensagem,
    idAluno:
      usuario?.tipoUsuario == EUserType.Aluno ? usuario?.id ?? null : null,
    idResponsavel:
      usuario?.tipoUsuario == EUserType.Responsavel
        ? usuario?.id ?? null
        : null,
    idLoginExtra:
      usuario?.tipoUsuario == EUserType.LoginExtra ? usuario?.id ?? null : null,
    idProfessor:
      usuario?.tipoUsuario == EUserType.Professor ? usuario?.id ?? null : null,
  });
  const [files, setFiles] = useState<File[]>([]);
  const [entregando, setEntregando] = useState(false);
  const [removendo, setRemovendo] = useState<
    { index: number; tipo: "anexo" | "link" | "file" } | undefined
  >();

  const {
    show: showLink,
    RenderDialog: RenderLink,
    hide: hideLink,
  } = useDialog({
    title: "Adicione um link",
    options: {
      modal: true,
      maxWidth: "xs",
      scroll: "paper",
    },
  });

  const { show: showDeleteAnexo, RenderDialog: DeleteAnexo } =
    useConfirmDialog<{
      obj: IMensagemRespostaAnexo | IMensagemRespostaLink | File;
      index: number;
    }>({
      defaults: {
        title: "Excluir este Anexo?",
      },
      onConfirmed: async ({ obj, index }, hide) => {
        try {
          if ((obj as IMensagemRespostaLink).url) {
            setRemovendo({ index, tipo: "link" });
            if ((obj as IMensagemRespostaLink).id) {
              await api.delete(
                `educador/mensagem-resposta-link/${
                  (obj as IMensagemRespostaLink).id
                }`
              );
            }
            resposta.links?.splice(index, 1);
            setResposta(resposta);
          } else if ((obj as IMensagemRespostaAnexo).arquivo) {
            setRemovendo({ index, tipo: "anexo" });
            if ((obj as IMensagemRespostaAnexo).id) {
              await api.delete(
                `educador/mensagem-resposta-anexo/${
                  (obj as IMensagemRespostaAnexo).id
                }`
              );
            }
            resposta.anexos?.splice(index, 1);
            setResposta(resposta);
          } else {
            setRemovendo({ index, tipo: "file" });
            files.splice(index, 1);
            setFiles(files);
          }
          setRemovendo(undefined);
          hide();
        } catch (err) {
          snack.warning(getError(err));
          hide();
        }
      },
    });

  const handleAddLink = async (link: IMensagemRespostaLink) => {
    if (!resposta.links) {
      resposta.links = [];
    }
    resposta.links?.push(link);
    hideLink();
  };

  const handleAddAnexo = async (list: FileList) => {
    const files: File[] = [];
    for (const file of list) {
      files.push(file);
    }
    setFiles(files);
  };

  const handleEntregar = async ({ devolvido }: { devolvido: boolean }) => {
    try {
      setEntregando(true);
      resposta.devolvido = devolvido;

      if (!devolvido && files.length > 0) {
        for await (const file of files) {
          const arquivo = await sendS3File({ file });
          resposta.anexos?.push({
            idArquivo: arquivo.id ?? "",
            idMensagemResposta: resposta.id ?? null,
          });
        }
        setFiles([]);
      }

      let id = resposta.id;
      if (id) {
        await api.put<IMensagemResposta>(
          `educador/mensagem-resposta/${resposta.id}`,
          resposta
        );
      } else {
        const { data } = await api.post<IMensagemResposta>(
          "educador/mensagem-resposta",
          resposta
        );
        if (data.id) id = data.id;
      }

      const { data } = await api.get<IMensagemResposta | null>(
        `educador/mensagem-resposta/${id}`,
        {
          params: {
            select: [
              "id",
              "idMensagem",
              "createdAt",
              "updatedAt",
              "devolvido",
              "anexos.arquivo.caminhoArquivo",
              "anexos.arquivo.hash",
              "anexos.arquivo.nomeArquivo",
              "anexos.idArquivo",
              "anexos.idMensagem",
              "anexos.ordem",
              "anexos.id",
              "links.id",
              "links.idMensagem",
              "links.ordem",
              "links.url",
            ],
            id,
          },
        }
      );
      if (data) setResposta(data);
      setEntregando(false);
    } catch (err) {
      setEntregando(false);
      snack.warning(getError(err));
    }
  };

  return (
    <Grid
      item
      xs={12}
      component={Paper}
      elevation={4}
      sx={{ flex: 1, padding: ({ spacing }) => spacing(2) }}
    >
      <Typography
        sx={{ float: "right" }}
        variant="subtitle2"
        color={
          resposta.devolvido === true
            ? "secondary"
            : resposta.devolvido === false
            ? "primary"
            : undefined
        }
      >
        {resposta.devolvido === true
          ? "Devolvido"
          : resposta.devolvido === false
          ? "Entregue"
          : "Atribuido"}
      </Typography>

      <Typography variant="h6" textAlign="center">
        Seus anexos
      </Typography>
      {(resposta.anexos || resposta.links) && (
        <Stack>
          {resposta.links?.map((obj, index) => (
            <Anexo
              loading={removendo?.index == index && removendo?.tipo == "link"}
              key={`link-${index}`}
              isLink={true}
              url={obj.url}
              title={obj.url}
              handleRemove={
                resposta.devolvido == true
                  ? () => showDeleteAnexo({ obj, index })
                  : undefined
              }
            />
          ))}
          {resposta.anexos?.map((obj, index) => (
            <Anexo
              isS3File
              key={`anexo-${index}`}
              url={obj.arquivo?.caminhoArquivo ?? ""}
              loading={removendo?.index == index && removendo?.tipo == "anexo"}
              title={obj.arquivo?.nomeArquivo ?? ""}
              handleRemove={
                resposta.devolvido == true
                  ? () => showDeleteAnexo({ obj, index })
                  : undefined
              }
            />
          ))}
          {files.map((obj, index) => (
            <Anexo
              key={`file-${index}`}
              url={obj}
              title={obj.name}
              loading={removendo?.index == index && removendo?.tipo == "file"}
              handleRemove={
                resposta.devolvido == true
                  ? () => showDeleteAnexo({ obj, index })
                  : undefined
              }
            />
          ))}
        </Stack>
      )}
      <Stack direction="column" spacing={1} mt={2}>
        {resposta.devolvido != false && (
          <Stack direction="row" spacing={1} justifyContent="center">
            <Tooltip title="Enviar link">
              <IconButton aria-label="enviar-link" onClick={showLink}>
                <LinkIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Fazer upload de arquivos">
              <label htmlFor="icon-button-file">
                <Input
                  accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf, image/*"
                  id="icon-button-file"
                  type="file"
                  multiple
                  onChange={async ({ target: { files } }) => {
                    if (files != null) handleAddAnexo(files);
                  }}
                />
                <IconButton aria-label="upload-arquivo" component="span">
                  <UploadIcon />
                </IconButton>
              </label>
            </Tooltip>
          </Stack>
        )}
        {resposta.devolvido != false && (
          <LoadingButton
            loading={entregando}
            variant="outlined"
            fullWidth
            disabled={!permitirResposta}
            onClick={() => handleEntregar({ devolvido: false })}
          >
            Entregar
          </LoadingButton>
        )}
        {resposta.devolvido === false && (
          <LoadingButton
            loading={entregando}
            variant="outlined"
            fullWidth
            color="secondary"
            onClick={() => handleEntregar({ devolvido: true })}
            disabled={!permitirResposta}
          >
            Cancelar entrega
          </LoadingButton>
        )}
      </Stack>
      <RenderLink>
        <DialogRespostaLink
          handleAddLink={handleAddLink}
          idMensagemResposta={resposta.id ?? null}
        />
      </RenderLink>
      <DeleteAnexo />
    </Grid>
  );
};
