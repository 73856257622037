import { createReducer } from "@reduxjs/toolkit";
import { ActionTypes } from "./types";

interface State {
  loading: boolean;
}

const INITIAL_STATE: State = {
  loading: false,
};

export const reducers = createReducer(INITIAL_STATE, {
  [ActionTypes.REQUEST]: (state) => ({
    ...state,
    loading: true,
  }),
  [ActionTypes.SUCCESS]: (state) => ({
    ...state,
    loading: false,
  }),
  [ActionTypes.FAILURE]: (state) => ({
    ...state,
    loading: false,
  }),
});
