import { call, put, select, takeLatest } from "redux-saga/effects";
import { ActionTypes } from "./types";
import { failure, success } from "./actions";
import { snack } from "components/GlobalSnackbar";
import { getError } from "utils";
import { RootState } from "../root_reducer";
import { api, cacheDados } from "configs";
import { ISistemaAvaliacao } from "@deltasge/marauders-map";

interface IRequest {
  type: typeof ActionTypes.REQUEST;
  payload: number;
}

function* request({ payload }: IRequest) {
  try {
    const {
      usuario: { usuario },
    }: RootState = yield select();

    if (!usuario) {
      throw new Error("Usuário não encontrado");
    }

    const cache24h = 60 * 24;
    const key = `sistemas-avaliacao-${usuario?.escolaId}`;
    const cache = cacheDados.get<ISistemaAvaliacao[]>(key);
    let busca = false;
    if (cache && typeof cache != "string") {
      const sist = cache.find((f) => f.id == payload);
      if (sist) yield put(success(sist));
      else busca = true;
    } else busca = true;

    if (busca) {
      const { data } = yield call(api.get, `sistemaavaliacao/${payload}`);
      if (!data) {
        throw new Error("Nenhum sistema de avaliação encontrado");
      }
      yield put(success(data));
      if (Array.isArray(cache)) {
        cacheDados.set(key, cache.concat(data), cache24h);
      } else {
        cacheDados.set(key, [data], cache24h);
      }
    }
  } catch (error) {
    yield put(failure());
    snack.error(getError(error));
  }
}
export const sagas = takeLatest([ActionTypes.REQUEST], request);
