import React, { FC, useState } from "react";
import { IAvaliacao } from "@deltasge/marauders-map";
import {
  Alert,
  AlertTitle,
  Button,
  ButtonGroup,
  DialogContent,
  Divider,
  Typography,
} from "@mui/material";
import {
  ContentCopy as CopyIcon,
  ContentPaste as PasteIcon,
  TableArrowLeft as ImportIcon,
} from "mdi-material-ui";
import { useSessionStorage } from "hooks/useSessionStorage";
import { LoadingButton } from "@mui/lab";
import { Input } from "assets/styleds";

export const OpcoesNotas: FC<{
  avaliacao: IAvaliacao;
  handleClose: () => void;
  disabled: boolean;
  handleColar: (params: {
    colar: IAvaliacao;
    copiar: IAvaliacao;
  }) => Promise<void>;
  handleImportar: (params: {
    file: File;
    idAvaliacao: number;
  }) => Promise<void>;
}> = ({
  handleClose,
  avaliacao,
  disabled,
  handleColar: onColar,
  handleImportar: onImportar,
}) => {
  const [copiar, setCopiar] = useSessionStorage<IAvaliacao | undefined>(
    `nota-avaliacao-copiadas-${avaliacao.idTurma}`,
    undefined
  );
  const [colando, setColando] = useState(false);
  const [importando, setImportando] = useState(false);

  const handleCopiar = () => {
    setCopiar(avaliacao);
    handleClose();
  };

  const handleColar = async () => {
    if (copiar) {
      setColando(true);
      await onColar({ colar: avaliacao, copiar });
      handleClose();
      setColando(false);
    }
  };

  const handleImportar = async ({
    target: { files },
  }: React.ChangeEvent<HTMLInputElement>) => {
    if (files && files.length > 0) {
      const file = files.item(0);
      if (file != null && avaliacao.uid) {
        setImportando(true);
        await onImportar({ file, idAvaliacao: avaliacao.uid });
        setImportando(false);
        handleClose();
      }
    }
  };

  return (
    <DialogContent dividers>
      <Alert severity="info">
        <AlertTitle>Copiar e Colar</AlertTitle>
        Você poderá colar as notas da avaliação somente na mesma turma, com o
        mesmo valor e peso
      </Alert>
      <ButtonGroup sx={{ mt: 1 }} fullWidth>
        <Button onClick={handleCopiar} startIcon={<CopyIcon />}>
          Copiar
        </Button>
        <LoadingButton
          loading={colando}
          variant="outlined"
          disabled={
            disabled ||
            copiar == undefined ||
            (copiar.valor != avaliacao.valor &&
              copiar.peso != avaliacao.peso) ||
            copiar.uid == avaliacao.uid
          }
          onClick={handleColar}
          startIcon={<PasteIcon />}
          color="success"
        >
          Colar
        </LoadingButton>
      </ButtonGroup>
      {copiar && (
        <Typography
          variant="caption"
          textAlign="center"
          component="p"
          fontStyle="italic"
          pt={0.5}
        >{`Você copiou a avaliação ${copiar.descricao} com o valor ${copiar.valor} e peso ${copiar.peso}`}</Typography>
      )}
      <Divider sx={{ mb: 2, mt: 2 }} />
      <Alert severity="info">
        <AlertTitle>Importação de notas</AlertTitle>O arquivo deve conter uma
        coluna com o código do aluno e outra coluna com a nota, separados por
        &quot;vírgula&quot; ou &quot;ponto e vírgula&quot;
      </Alert>
      <label htmlFor="import-button-file">
        <Input
          accept=".csv"
          id="import-button-file"
          type="file"
          onChange={!importando ? handleImportar : undefined}
        />
        <LoadingButton
          sx={{ mt: 1 }}
          fullWidth
          variant="outlined"
          aria-label="upload-arquivo"
          component="span"
          startIcon={<ImportIcon />}
          loading={importando}
        >
          Importar
        </LoadingButton>
      </label>
    </DialogContent>
  );
};
