import React, { FC, useEffect, useState } from "react";
import logo from "assets/images/logo.png";
import { Image } from "assets/styles/drawer";
import { useAppDispatch, useAppSelector } from "store";
import { toggleDevMode } from "store/modules/layout";
import { snack } from "components/GlobalSnackbar";

export const ImageDev: FC<{
  hideDialog: () => void;
}> = ({ hideDialog }) => {
  const { devMode } = useAppSelector((state) => state.layout);

  const [development, setDevelopment] = useState(0);

  const canEnable = development >= 10;

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (development >= 10) {
      hideDialog();
      snack.info(`Modo desenvolvedor ${!devMode ? "ligado" : "desligado"}`);
      dispatch(toggleDevMode(!devMode));
    }
  }, [canEnable]);

  return (
    <Image
      src={logo}
      alt="Logo"
      onClick={() => setDevelopment(development + 1)}
    />
  );
};
