import { ITreeViewTurma } from "@deltasge/marauders-map";

import { createReducer, PayloadAction } from "@reduxjs/toolkit";

import { ActionTypes } from "./types";

export const reducers = createReducer({} as ITreeViewTurma, {
  [ActionTypes.SET]: (state, action: PayloadAction<ITreeViewTurma>) =>
    action.payload,
});
