import React, { FC, useState } from "react";
import Qs from "qs";
import { useHistory } from "react-router-dom";
import { IAulasChamada } from "@deltasge/marauders-map";

import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Grid,
  GridSize,
  IconButton,
  LinearProgress,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  DotsVertical as DotsVerticalIcon,
  MessageBulleted as MensagensIcon,
  AccountMultipleCheck as ChamadaIcon,
  CalendarCheck as ChamadaDiaIcon,
  Notebook as DiarioIcon,
  NotebookCheck as DiarioRapidoIcon,
  TableCheck as NotasIcon,
  FormatListBulletedSquare as ConteudoIcon,
  ClipboardClock as AvaliacaoIcon,
  FolderStar as OcorrenciaIcon,
} from "mdi-material-ui";

import { useSessionStorage } from "hooks/useSessionStorage";

export const Item: FC<{
  isProfessor: boolean;
  etapa: number;
  mdSize: GridSize;
  lgSize: GridSize;
  xlSize: GridSize;
  image: string;
  aula: IAulasChamada;
  divider?: boolean;
  aulaTerminou: boolean;
  percentage?: number;
  handleAula: (aula: IAulasChamada) => void;
  handleChamadaDia: (aula: IAulasChamada) => Promise<void>;
  handleDiario: (
    aula: IAulasChamada,
    showImpressaoRapida?: boolean
  ) => Promise<void>;
  showImpressaoRapida: boolean;
}> = ({
  isProfessor,
  etapa,
  mdSize,
  image,
  aula,
  percentage,
  handleAula,
  handleChamadaDia,
  handleDiario,
  showImpressaoRapida,
}) => {
  const history = useHistory();

  const [diarioDownPrint] = useSessionStorage<number>("DiarioDownPrint", 0);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const query = Qs.stringify({
    idCurso: aula.idCurso,
    idSerie: aula.idSerie,
    idTurma: aula.idTurma,
    idMateria: aula.idMateria,
    etapa,
  });

  return (
    <Grid item xs={12} sm={6} md={mdSize} lg={4} xl={4}>
      <Card>
        <CardHeader
          sx={{
            "& .MuiCardHeader-content": {
              width: "80%",
            },
          }}
          action={
            <>
              <IconButton
                aria-label="more"
                id="button"
                aria-controls={anchorEl ? "menu" : undefined}
                aria-expanded={anchorEl ? "true" : undefined}
                aria-haspopup="true"
                onClick={({ currentTarget }) => setAnchorEl(currentTarget)}
              >
                <DotsVerticalIcon />
              </IconButton>
              <Menu
                id="menu"
                MenuListProps={{
                  "aria-labelledby": "button",
                }}
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
              >
                <MenuItem onClick={() => history.push(`/avaliacao/?${query}`)}>
                  <ListItemIcon>
                    <AvaliacaoIcon fontSize="small" />
                  </ListItemIcon>
                  Avaliações
                </MenuItem>
                <MenuItem
                  onClick={() => history.push(`/conteudo-ministrado/?${query}`)}
                >
                  <ListItemIcon>
                    <ConteudoIcon fontSize="small" />
                  </ListItemIcon>
                  Conteúdo ministrado
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleDiario(aula);
                    setAnchorEl(null);
                  }}
                >
                  <ListItemIcon>
                    <DiarioIcon fontSize="small" />
                  </ListItemIcon>
                  Diário de classe
                </MenuItem>
                {showImpressaoRapida && (
                  <MenuItem
                    onClick={() => {
                      handleDiario(aula, true);
                      setAnchorEl(null);
                    }}
                  >
                    <ListItemIcon>
                      <DiarioRapidoIcon fontSize="small" />
                    </ListItemIcon>
                    {diarioDownPrint == 1 ? "Download do" : "Visualizar"} diário
                    de classe
                  </MenuItem>
                )}
                <MenuItem
                  onClick={() => {
                    const query = Qs.stringify({
                      idCurso: aula.idCurso,
                      idSerie: aula.idSerie,
                      idTurma: aula.idTurma,
                      idMateria: aula.idMateria,
                      etapa,
                    });
                    history.push(`/notas/?${query}`);
                  }}
                >
                  <ListItemIcon>
                    <NotasIcon fontSize="small" />
                  </ListItemIcon>
                  Digitação de notas
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    const query = Qs.stringify({
                      idCurso: aula.idCurso,
                      idSerie: aula.idSerie,
                      idTurma: aula.idTurma,
                    });
                    history.push(`/publicacoes/tab/1/?${query}`);
                  }}
                >
                  <ListItemIcon>
                    <MensagensIcon fontSize="small" />
                  </ListItemIcon>
                  Enviar Mensagens
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    const query = Qs.stringify({
                      idCurso: aula.idCurso,
                      idSerie: aula.idSerie,
                      idTurma: aula.idTurma,
                      idMateria: aula.idMateria,
                    });
                    history.push(`/ocorrencias/?${query}`);
                  }}
                >
                  <ListItemIcon>
                    <OcorrenciaIcon fontSize="small" />
                  </ListItemIcon>
                  Ocorrências
                </MenuItem>
              </Menu>
            </>
          }
          title={aula.turmaDescricao}
          titleTypographyProps={{ noWrap: true }}
          subheader={aula.aulaDescricao
            .concat(" - ")
            .concat(aula.materiaNome ? aula.materiaNome.rUpper() : "")}
          subheaderTypographyProps={{ noWrap: true }}
        />
        <CardMedia
          sx={{ height: ({ spacing }) => spacing(15) }}
          image={image}
        />
        <CardContent sx={{ p: 0 }}>
          <LinearProgress
            variant="determinate"
            value={percentage}
            color="primary"
          />
          <Box sx={{ pr: 2, pl: 2, pt: 2 }}>
            <Typography
              variant="caption"
              color="text.secondary"
              component="p"
              noWrap
            >
              {`${aula.horaIni} à ${aula.horaFim} - duração: ${aula.duracao} minutos`}
            </Typography>
            {!isProfessor && (
              <Typography
                variant="caption"
                color="text.secondary"
                component="p"
                noWrap
              >
                {"Professor: " + aula.nomeProfessor?.rUpper()}
              </Typography>
            )}
            <Typography
              variant="caption"
              color="text.secondary"
              component="p"
              noWrap
            >
              &nbsp;
              {aula.nomeUsuario
                ? "  Registrado por: " + aula.nomeUsuario.rUpper()
                : ""}
            </Typography>
          </Box>
        </CardContent>
        <CardActions disableSpacing>
          {!isProfessor && (
            <Tooltip title="Fazer chamada do dia">
              <IconButton onClick={() => handleChamadaDia(aula)}>
                <ChamadaDiaIcon />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip
            title={
              <div style={{ whiteSpace: "pre-line", textAlign: "center" }}>
                {aula.idDiaAulas == null
                  ? "Criar aula"
                  : "Alterar aula" +
                    (aula.gradeAtual ? "" : " \n (fora da grade atual)")}
              </div>
            }
          >
            <IconButton onClick={() => handleAula(aula)}>
              <ChamadaIcon
                color={
                  aula.idDiaAulas == null
                    ? "error"
                    : aula.gradeAtual
                    ? "success"
                    : "warning"
                }
              />
            </IconButton>
          </Tooltip>
        </CardActions>
      </Card>
    </Grid>
  );
};
