import { call, put, select, takeLatest } from "redux-saga/effects";
import { ActionTypes } from "./types";
import { failure, success } from "./actions";
import { snack } from "components/GlobalSnackbar";
import { getError } from "utils";
import { RootState } from "../../../root_reducer";
import { apiRelatorio, cacheDados } from "configs";
import { IPaper } from "@deltasge/marauders-map";

function* request() {
  try {
    const {
      usuario: { usuario },
    }: RootState = yield select();

    if (!usuario) {
      throw new Error("Usuário não encontrado");
    }

    const cache24h = 60 * 24;
    const key = `papel-${usuario?.escolaId}`;
    const cache = cacheDados.get<IPaper[]>(key);
    if (cache && typeof cache != "string") {
      yield put(success(cache));
    } else {
      const { data } = yield call(apiRelatorio.get, "tamanho-papel");
      if (!data || !Array.isArray(data)) {
        throw new Error("Nenhum tamanho de papel encontrado");
      }
      yield put(success(data as IPaper[]));
      cacheDados.set(key, data as IPaper[], cache24h);
    }
  } catch (error) {
    yield put(failure());
    snack.error(getError(error));
  }
}
export const sagas = takeLatest([ActionTypes.REQUEST], request);
