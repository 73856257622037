import { createLogger } from "redux-logger";
import { configureStore } from "@reduxjs/toolkit";
import { Environment } from "configs";
import {
  useDispatch,
  useSelector as useReduxSelector,
  TypedUseSelectorHook,
} from "react-redux";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import createSagaMiddleware from "redux-saga";

import { rootReducer } from "./modules/root_reducer";
import { rootSaga } from "./modules/root_saga";

const persistedReducer = persistReducer(
  {
    key: "root",
    storage,
    whitelist: ["layout", "usuario", "escola"],
    version: 1,
  },
  rootReducer
);

const loggerMiddleware = createLogger({
  predicate: () => Environment.IS_DEV_MODE,
});

const sagaMiddleware = createSagaMiddleware();
export const store = configureStore({
  reducer: persistedReducer,
  devTools: Environment.IS_DEV_MODE,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(loggerMiddleware, sagaMiddleware),
});
export const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);

export const useAppSelector: TypedUseSelectorHook<RootState> = useReduxSelector;
export const useAppDispatch = () => useDispatch<AppDispatch>();

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
