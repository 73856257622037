import React, { FC, useEffect } from "react";
import { Provider } from "react-redux";
import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";
import { getAnalytics } from "firebase/analytics";
import { PersistGate } from "redux-persist/es/integration/react";
import ptBr from "date-fns/locale/pt-BR";

import { Theme } from "components/Theme";
import { store, persistor } from "store";
import {
  GlobalSnackbar,
  GlobalSnackbarProvider,
} from "components/GlobalSnackbar";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { Routes } from "pages/_layouts/Routes";
import { CssBaseline, GlobalStyles } from "@mui/material";
import { Environment } from "configs";
import { ProviderPushNotification } from "context/PushNotificationContext";
import { PromptToInstallProvider } from "context/PromptToInstallProvider";
import ProviderSocket from "context/SocketContext/SocketContext";
import { ProviderAuthentication } from "context/AuthenticationProvider";
interface NavigatorVirtualKeyboard extends Navigator {
  virtualKeyboard: {
    overlaysContent: boolean;
  };
}

export const firebaseApp = initializeApp(Environment.FIREBASE_KEY);
export const fcm = getMessaging(firebaseApp);
export const analytics = getAnalytics(firebaseApp);

const App: FC = () => {
  useEffect(() => {
    if ("virtualKeyboard" in window.navigator) {
      (
        window.navigator as NavigatorVirtualKeyboard
      ).virtualKeyboard.overlaysContent = true;
    }
  }, []);

  return (
    <PromptToInstallProvider>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Theme>
            <GlobalSnackbarProvider>
              <GlobalSnackbar />
              <CssBaseline enableColorScheme />
              <GlobalStyles
                styles={{
                  ".notistack-SnackbarContainer": {
                    bottom:
                      "calc(env(keyboard-inset-height, 0px) + 14px) !important",
                  },
                }}
              />
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={ptBr}
              >
                <ProviderSocket>
                  <ProviderPushNotification fcm={fcm}>
                    <ProviderAuthentication>
                      <Routes />
                    </ProviderAuthentication>
                  </ProviderPushNotification>
                </ProviderSocket>
              </LocalizationProvider>
            </GlobalSnackbarProvider>
          </Theme>
        </PersistGate>
      </Provider>
    </PromptToInstallProvider>
  );
};

export default App;
