import { cacheDados } from "configs";
import { format, isValid, parseISO } from "date-fns";
import { Record } from "mdi-material-ui";
import { useMemo } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useAppSelector } from "store";

export type Primitives = string | number | boolean | Date | null | undefined;

export const useQueryStorage = <
  Query extends { [K in keyof Query]?: Primitives } = {},
  Params extends { [K in keyof Params]?: string } = {}
>({
  key,
  initialValue,
  keysOfCache,
}: {
  key: string;
  initialValue: Query;
  keysOfCache: (keyof Query)[];
}): readonly [Query, (pathname: string, params: Query) => void, Params] => {
  const { escola } = useAppSelector((state) => state);
  const { search } = useLocation();
  const params = useParams<Params>();

  const history = useHistory();
  const sesstionKey = `${key}-${escola.codigo}`;
  const query = useMemo(() => {
    const query = new URLSearchParams(search);
    const url: Record<string, Primitives> = {};
    const cache = cacheDados.get<Record<string, Primitives>>(sesstionKey);
    let result: Record<string, Primitives> = { ...initialValue };
    query.forEach((value, name) => (url[name] = applyType(value)));

    if (Object.keys(url).length > 0) {
      result = { ...result, ...url };
    } else if (
      cache != null &&
      typeof cache != "string" &&
      Object.keys(cache).length > 0
    ) {
      const cacheResult: Record<string, Primitives> = {};
      Object.keys(cache).map(
        (key) =>
          (cacheResult[key] =
            typeof cache[key] == "string"
              ? applyType(cache[key] as string)
              : cache[key])
      );
      result = { ...result, ...cacheResult };
    }
    return result;
  }, [search]) as Query;

  const navigate = (pathname: string, params: Query) => {
    const cache: Record<string, Primitives> = {};
    keysOfCache.map((key) => (cache[key as string] = params[key]));
    cacheDados.set(sesstionKey, cache);
    history.push({
      pathname,
      search: createSearchParams(params),
    });
  };

  return [query, navigate, params] as const;
};

export const createSearchParams = <T extends Record<string, any> = {}>(
  params: T
): string => {
  const record: Record<string, string> = {};
  Object.keys(params).forEach((key) => {
    if (params[key] != null && params[key] != undefined) {
      if (params[key] instanceof Date)
        record[key] = format(params[key], "yyyy-MM-dd HH:mm:ss");
      else record[key] = params[key].toString();
    }
  });
  const url = new URLSearchParams(record);
  return "?".concat(url.toString());
};

const applyType = (param: string | null | undefined): Primitives => {
  if (param == null || param == "null") return null;
  else if (param == undefined || param == "undefined") return undefined;
  else if (
    typeof param == "string" &&
    (RegExp(/\d{4}-\d{2}-\d{2}\s\d{2}:\d{2}:\d{2}/).test(param) ||
      RegExp(
        /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})\.(\d{3})Z$/
      ).test(param)) &&
    isValid(parseISO(param))
  )
    return parseISO(param);
  else if (typeof param == "string" && param == "true") return true;
  else if (typeof param == "string" && param == "false") return false;
  else if (typeof param == "string" && !isNaN(parseFloat(param)))
    return parseFloat(param);
  else if (typeof param == "string" && !isNaN(parseInt(param, 10)))
    return parseInt(param, 10);
  return param;
};
