import React, { FC, useState } from "react";
import { IDadosDispositivo } from "@deltasge/marauders-map";

import {
  Avatar,
  Button,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import {
  ExitToApp as ExitToAppIcon,
  Brightness3 as MoonIcon,
  Brightness5 as SunIcon,
  BrightnessAuto as AutoDarkIcon,
  Bell as NotOnIcon,
  BellOff as NotOffIcon,
  BellSleep as NotZZIcon,
  MonitorArrowDown as InstallAppIcon,
  DatabaseSync as CacheClearIcon,
} from "mdi-material-ui";

import { api, cacheDados, cachePhotoUrl } from "configs";
import { useConfirmDialog } from "hooks/useDialogConfirm";
import { Main } from "pages/_layouts/Main";
import { useAppDispatch, useAppSelector } from "store";
import { ITheme, toggleTheme } from "store/modules/layout";

import { CardConfig } from "./components/CardConfig";
import { EUserType } from "@deltasge/marauders-map";
import { useDialog } from "hooks/useDialog";
import { DialogColorPicker } from "./components/DialogColorPicker";
import { DialogThemeMode } from "./components/DialogThemeMode";
import { DialogThemeInput } from "./components/DialogThemeInput";
import { ColorCircle } from "components/ColorCircle";
import { ImgIcon } from "./components/ImgIcon";

import FilledIcon from "assets/icons/filled.svg";
import OutlinedIcon from "assets/icons/outlined.svg";
import StandardIcon from "assets/icons/standard.svg";
import { usePushNotification } from "context/PushNotificationContext";
import { getError } from "utils";
import { usePromptToInstall } from "context/PromptToInstallProvider";
import { snack } from "components/GlobalSnackbar";
import { useAuthentication } from "context/AuthenticationProvider";

export const Configuracoes: FC = () => {
  const [isPrimary, setIsPrimary] = useState(true);

  const { signOut, AuthenticatedUserComponent, AppleButton, GoogleButton } =
    useAuthentication();
  const { isSupported, permission, token, requestPermission, dispositivo } =
    usePushNotification();

  const { deferredEvt, isInstalled } = usePromptToInstall();

  const dispatch = useAppDispatch();

  const {
    usuario: { usuario },
    layout: { theme },
  } = useAppSelector((state) => state);

  const handleCacheClear = async () => {
    try {
      cachePhotoUrl.flush();
      cacheDados.flush();
      location.reload();
    } catch (error) {
      snack.error(getError(error));
    }
  };

  const {
    RenderDialog: DialogColor,
    show: showColor,
    hide: hideColor,
  } = useDialog({
    title: `Cor ${isPrimary ? "primária" : "secundária"}`,
    options: {
      maxWidth: "sm",
    },
  });

  const {
    RenderDialog: DialogModo,
    show: showModo,
    hide: hideModo,
  } = useDialog({
    title: "Modo do tema",
    options: {
      maxWidth: "xs",
    },
  });

  const {
    RenderDialog: DialogInput,
    show: showInput,
    hide: hideInput,
  } = useDialog({
    title: "Estilo de campos",
    options: {
      maxWidth: "xs",
    },
  });

  const { show: showSair, RenderDialog: DialogSair } = useConfirmDialog({
    defaults: {
      title: "Sair da aplicativo",
      content: "Tem certeza que deseja sair do aplicativo?",
    },
    onConfirmed: () => signOut(),
  });

  const { show: showCacheClear, RenderDialog: DialogCacheClear } =
    useConfirmDialog({
      defaults: {
        title: "Renovar cache do aplicativo",
        content:
          "Ao renovar o cache o aplicativo será recarregado. Deseja continuar?",
      },
      onConfirmed: () => handleCacheClear(),
    });

  const idUsuario =
    usuario?.tipoUsuario == EUserType.UsuarioSistema ? usuario.id : null;
  const idProfessor =
    usuario?.tipoUsuario == EUserType.Professor ? usuario.id : null;
  const payload = { idProfessor, idUsuario };

  if (!idUsuario) delete payload.idUsuario;
  if (!idProfessor) delete payload.idProfessor;

  const handleDispositivo = async (notTodas = true) => {
    if (!token || permission != "granted") return;

    await api.put<IDadosDispositivo>(
      `dados-dispositivo/tipo-usuario/${usuario?.tipoUsuario}/token/${token}`,
      {
        ...payload,
        notTodas,
      }
    );
  };

  return (
    <Main title="Configurações">
      <DialogSair />
      <DialogCacheClear />
      <DialogColor>
        <DialogColorPicker
          onClick={(color) => {
            let key = "primary" as keyof ITheme;
            if (!isPrimary) {
              key = "secondary";
            }
            dispatch(
              toggleTheme({
                ...theme,
                [key]: color,
              })
            );
          }}
          hideDialog={hideColor}
          selected={isPrimary ? theme.primary : theme.secondary}
        />
      </DialogColor>
      <DialogModo>
        <DialogThemeMode
          hideDialog={hideModo}
          selected={theme.mode}
          onClick={(mode) =>
            dispatch(
              toggleTheme({
                ...theme,
                mode,
              })
            )
          }
        />
      </DialogModo>
      <DialogInput>
        <DialogThemeInput
          hideDialog={hideInput}
          selected={theme.inputType ?? "standard"}
          onClick={(inputType) =>
            dispatch(
              toggleTheme({
                ...theme,
                inputType,
              })
            )
          }
        />
      </DialogInput>
      <Grid container alignItems="stretch">
        {deferredEvt && (
          <Grid item xs={12} sm={6} padding={1}>
            <CardConfig title="Instalar Delta Educador" sx={{ height: "100%" }}>
              <Typography variant="caption" textAlign="center" component="p">
                Toque no botão abaixo para instalar o aplicativo em seu
                dispositivo
              </Typography>
              <br />
              <Button
                disabled={isInstalled}
                fullWidth
                variant="text"
                startIcon={<InstallAppIcon />}
                onClick={() => {
                  deferredEvt.prompt();
                }}
              >
                {isInstalled ? "Instalado" : "Instalar"}
              </Button>
            </CardConfig>
          </Grid>
        )}
        <Grid item xs={12} sm={deferredEvt ? 6 : 12} padding={1}>
          <CardConfig title="Rede Social">
            {usuario?.firebaseId && (
              <AuthenticatedUserComponent showRemoveLinkAccount />
            )}
            {!usuario?.firebaseId && (
              <>
                <GoogleButton fullWidth />
                <AppleButton fullWidth />
              </>
            )}
          </CardConfig>
        </Grid>
        <Grid item xs={12} padding={1}>
          <CardConfig title="Tema" sx={{ textAlign: "center" }}>
            <List>
              <ListItemButton
                onClick={() => {
                  setIsPrimary(true);
                  showColor();
                }}
              >
                <ListItemAvatar>
                  <ColorCircle color={theme.primary} />
                </ListItemAvatar>
                <ListItemText primary="Cor primária" />
              </ListItemButton>
              <ListItemButton
                onClick={() => {
                  setIsPrimary(false);
                  showColor();
                }}
              >
                <ListItemAvatar>
                  <ColorCircle color={theme.secondary} />
                </ListItemAvatar>
                <ListItemText primary="Cor secundária" />
              </ListItemButton>
              <ListItemButton onClick={() => showModo()}>
                <ListItemAvatar>
                  <Avatar
                    sx={{
                      bgcolor: theme.primary ? theme.primary[500] : "inherit",
                    }}
                  >
                    {theme.mode == "auto" && <AutoDarkIcon />}
                    {theme.mode == "dark" && <MoonIcon />}
                    {theme.mode == "light" && <SunIcon />}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={"Modo ".concat(
                    theme.mode == "auto"
                      ? "automático"
                      : theme.mode == "dark"
                      ? "escuro"
                      : "claro"
                  )}
                />
              </ListItemButton>
              <ListItemButton onClick={() => showInput()}>
                <ListItemAvatar>
                  <ImgIcon
                    src={
                      theme.inputType == "filled"
                        ? FilledIcon
                        : theme.inputType == "outlined"
                        ? OutlinedIcon
                        : StandardIcon
                    }
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={"Campos no estilo ".concat(
                    theme.inputType == "filled"
                      ? "preenchido"
                      : theme.inputType == "outlined"
                      ? "contorno"
                      : "padrão"
                  )}
                />
              </ListItemButton>
            </List>
          </CardConfig>
        </Grid>
        <Grid item xs={12} padding={1}>
          <CardConfig title="Notificações" sx={{ textAlign: "center" }}>
            <ListItem>
              <ListItemButton
                disabled={!isSupported}
                onClick={async () => {
                  if (permission == "granted") {
                    handleDispositivo(
                      dispositivo == undefined ? true : !dispositivo.notTodas
                    );
                  } else {
                    requestPermission();
                  }
                }}
              >
                <ListItemIcon>
                  {permission != "granted" && <NotOffIcon color="warning" />}
                  {permission == "granted" && dispositivo?.notTodas && (
                    <NotOnIcon color="primary" />
                  )}
                  {permission == "granted" && !dispositivo?.notTodas && (
                    <NotZZIcon color="error" />
                  )}
                </ListItemIcon>
                <ListItemText
                  primary={
                    permission == "granted" && dispositivo?.notTodas
                      ? "Notificações ativas"
                      : permission == "granted" && !dispositivo?.notTodas
                      ? "Não perturbe"
                      : "Notificações desativadas"
                  }
                  secondary={
                    permission != "granted"
                      ? "Clique para liberar nas notificações. Caso você tenha negado você deverá defini-las diretamente em seu dispositivo"
                      : permission == "granted" && !dispositivo?.notTodas
                      ? "Você não receberá notificações"
                      : undefined
                  }
                />
              </ListItemButton>
            </ListItem>
          </CardConfig>
        </Grid>
        <Grid item xs={12} md={6} padding={1}>
          <CardConfig title="Renovar cache local" sx={{ textAlign: "center" }}>
            <Button
              fullWidth
              startIcon={<CacheClearIcon />}
              color="inherit"
              onClick={showCacheClear}
            >
              {`Renovar cache (${(
                (cacheDados.size() + cachePhotoUrl.size()) /
                1024
              ).toFixed(2)}kb)`}
            </Button>
          </CardConfig>
        </Grid>
        <Grid item xs={12} md={6} padding={1}>
          <CardConfig
            title="Sair e limpar dados locais"
            sx={{ textAlign: "center" }}
          >
            <Button
              fullWidth
              startIcon={<ExitToAppIcon />}
              color="secondary"
              onClick={showSair}
            >
              Sair
            </Button>
          </CardConfig>
        </Grid>
      </Grid>
    </Main>
  );
};
