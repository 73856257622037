import React, { FC, useState, forwardRef } from "react";
import { IMensagem } from "@deltasge/marauders-map";
import {
  DragVertical as DragIcon,
  CloseCircleOutline as DeleteIcon,
} from "mdi-material-ui";

import {
  DndContext,
  closestCenter,
  MouseSensor,
  TouchSensor,
  DragOverlay,
  useSensor,
  useSensors,
  DragStartEvent,
  DragEndEvent,
  UniqueIdentifier,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  rectSortingStrategy,
  useSortable,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

import { IPreMensagemArquivo } from "../../utilMensagem";
import {
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { useMobile } from "hooks/useMobile";
import { Imagem } from "components/Imagem";

export interface Props {
  fotos: IFoto[];
  set: (key: keyof IMensagem, value: unknown) => void;
}
interface IFoto extends IPreMensagemArquivo {
  id: UniqueIdentifier;
}

export const AlbumFotos: FC<Props> = ({ fotos, set }) => {
  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);
  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));

  const theme = useTheme();
  const isSm = useMobile("sm");
  const isMd = useMobile("md");

  const handleDragStart = (event: DragStartEvent) =>
    setActiveId(event.active.id);

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (over && active.id !== over.id) {
      const oldIndex = fotos.findIndex((f) => f.id == active.id.toString());
      const newIndex = fotos.findIndex((f) => f.id == over.id.toString());
      const novaOrdem = arrayMove(fotos, oldIndex, newIndex);
      set(
        "mensagemArquivos",
        novaOrdem.map((foto, ordem) => ({ ...foto, ordem }))
      );
    }
    setActiveId(null);
  };

  const handleDragCancel = () => setActiveId(null);

  const handleRemove = (foto: IFoto) => {
    if (foto.file) {
      set(
        "mensagemArquivos",
        fotos.filter((f) => f.file?.name != (foto.file?.name ?? ""))
      );
    } else {
      set(
        "mensagemArquivos",
        fotos.map((p) => ({
          ...p,
          deletedAt:
            p.arquivo?.caminhoArquivo == foto.arquivo?.caminhoArquivo
              ? new Date()
              : foto.deletedAt,
        }))
      );
    }
  };

  const foto = fotos.find((f) => f.id == activeId);

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      onDragCancel={handleDragCancel}
    >
      <SortableContext items={fotos} strategy={rectSortingStrategy}>
        <ImageList
          cols={isSm ? 1 : isMd ? 2 : 3}
          rowHeight={parseInt(theme.spacing(20), 10)}
        >
          {fotos.map((item) => (
            <SortablePhoto key={item.id} foto={item} onRemove={handleRemove} />
          ))}
        </ImageList>
      </SortableContext>
      <DragOverlay adjustScale={true}>
        {foto ? <Photo foto={foto} onRemove={handleRemove} /> : null}
      </DragOverlay>
    </DndContext>
  );
};

type FotoProps = {
  onRemove: (foto: IFoto) => void;
  foto: IFoto;
  faded?: boolean;
  overlay?: boolean;
  active?: boolean;
  insertPosition?: "before" | "after";
  attributes?: Partial<React.HTMLAttributes<HTMLDivElement>>;
  listeners?: Partial<React.HTMLAttributes<HTMLDivElement>>;
  style?: {
    transform: string | undefined;
    transition: string | undefined;
  };
};

const Photo = forwardRef<HTMLLIElement, FotoProps>(
  ({ foto, onRemove, faded, style, ...props }, ref) => {
    const theme = useTheme();
    const title = foto.arquivo?.caminhoArquivo ?? foto.file?.name ?? "";
    return (
      <ImageListItem
        ref={ref}
        sx={{
          ...style,
          opacity: faded ? "0.2" : "1",
          transformOrigin: "0 0",
          height: theme.spacing(20),
          overflow: "hidden",
        }}
      >
        <Imagem
          src={foto.id.toString()}
          alt={title}
          loading="lazy"
          style={{ height: theme.spacing(20), objectFit: "cover" }}
        />
        <ImageListItemBar
          title={
            <Tooltip title={title}>
              <Typography variant="caption">{title}</Typography>
            </Tooltip>
          }
          actionIcon={
            <IconButton
              title="Remover"
              sx={{ color: "rgba(255, 255, 255, 0.54)" }}
              onClick={() => onRemove(foto)}
            >
              <DeleteIcon />
            </IconButton>
          }
        />
        <ImageListItemBar
          position="top"
          sx={{
            background:
              "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, " +
              "rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
          }}
          actionIcon={
            <IconButton
              {...props}
              title="Mover"
              sx={{ color: "rgba(255, 255, 255, 0.54)", cursor: "move" }}
            >
              <DragIcon />
            </IconButton>
          }
        />
      </ImageListItem>
    );
  }
);

const SortablePhoto: FC<{ foto: IFoto; onRemove: (foto: IFoto) => void }> = ({
  foto,
  onRemove,
}) => {
  const sortable = useSortable({ id: foto.id });
  const { attributes, listeners, setNodeRef, transform, transition } = sortable;

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <Photo
      onRemove={onRemove}
      foto={foto}
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
    />
  );
};
