import React, { FC } from "react";

import { ThemeProvider } from "@mui/material/styles";

import { useAppSelector } from "store";
import { defaultTheme } from "assets/themes";

export const Theme: FC = ({ children }) => {
  const theme = defaultTheme(useAppSelector((state) => state.layout.theme));
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};
