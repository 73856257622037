import React, { FC, useEffect, useState } from "react";
import { EUserType, IAulasChamada, IDiaAula } from "@deltasge/marauders-map";

import {
  Button,
  DialogActions,
  DialogContent,
  Grid,
  TextField,
} from "@mui/material";
import { getBNCC } from "utils";
import { ArrowTopRightThinCircleOutline } from "mdi-material-ui";
import { api } from "configs";
import { useAppSelector } from "store";
import { format } from "date-fns";

export const EditAula: FC<{
  grau?: string;
  aula: IAulasChamada;
  close: () => void;
  submit: (diaAula: Partial<IDiaAula>) => void;
}> = ({ aula, close, submit, grau }) => {
  const {
    usuario: { usuario },
  } = useAppSelector((state) => state);

  const [idDiasAula, setIdDiasAula] = useState(aula.idDiaAulas);
  const [descricao, setDescricao] = useState(aula.descricaoChamada);

  useEffect(() => {
    (async () => {
      if (!aula.idDiaAulas && aula.data) {
        const { data } = await api.post<IDiaAula | undefined | null>(
          "diaaula",
          {
            anoLetivo: format(new Date(aula.data), "yyyy"),
            data: aula.data,
            aula: aula.aula,
            idCurso: aula.idCurso,
            idSerie: aula.idSerie,
            idTurma: aula.idTurma,
            idMateria: aula.idMateria,
            diaSemAula: false,
            inutilizaDia: false,
            idProfessor:
              usuario?.tipoUsuario === EUserType.Professor ? usuario?.id : null,
            idUsuario:
              usuario?.tipoUsuario === EUserType.UsuarioSistema
                ? usuario?.id
                : null,
            descricao: "",
            idMensagem: null,
            tarefa: "",
            id: null,
          }
        );
        if (data) {
          setIdDiasAula(data.id);
          setDescricao(data.descricao || "");
        }
      }
    })();
  }, []);

  return (
    <>
      <DialogContent dividers>
        <TextField
          disabled={!idDiasAula}
          value={descricao}
          multiline
          rows={8}
          label={
            aula.diasemaula
              ? "Descrição de dia sem aula"
              : "Descrição do Conteúdo Ministrado"
          }
          onChange={async ({ target: { value } }) => {
            aula.descricao = value;
            setDescricao(value);
          }}
          helperText={"Interpreta código BNCC"}
          inputProps={{
            onKeyUp: async (e: any) => {
              const valorNovo = await getBNCC(e.target.value as string, true);
              if (valorNovo) {
                e.target.value = valorNovo;
                setDescricao(valorNovo);
              }
            },
          }}
        />
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent="space-between" spacing={1}>
          <Grid item>
            <Button
              startIcon={<ArrowTopRightThinCircleOutline />}
              onClick={() =>
                window.open(
                  `http://basenacionalcomum.mec.gov.br/abase/#${
                    grau === "1"
                      ? "fundamental"
                      : grau === "2"
                      ? "medio"
                      : "infantil"
                  }`,
                  "_blank"
                )
              }
              color="primary"
            >
              Consultar BNCC
            </Button>
          </Grid>
          <Grid item textAlign="right">
            <Button onClick={close} color="secondary" sx={{ mr: 1 }}>
              Cancelar
            </Button>
            <Button
              onClick={() =>
                submit({ id: idDiasAula, descricao: descricao || "" })
              }
              color="primary"
            >
              Gravar
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </>
  );
};
