import React, { FC, useEffect, useRef, useState } from "react";
import { IDNColumn, IDNRown, utils } from "@deltasge/marauders-map";
import { AppBar, Fab, SxProps, TextField, Theme, Toolbar } from "@mui/material";
import { Plus as AddIcon } from "mdi-material-ui";
import { allowedKeys } from "./Table";
import { NumericFormat } from "react-number-format";

export const TableBarBottom: FC<{
  row: IDNRown;
  column: IDNColumn;
  isDecimal: boolean;
  handleSubmit: (props: {
    value: string;
    row: IDNRown;
    column: IDNColumn;
  }) => Promise<boolean>;
  handleAdd?: {
    onAdd: () => Promise<void>;
    title: string;
    disabled: boolean;
    sx?: SxProps<Theme>;
  };
}> = ({ handleAdd, column, row, isDecimal, handleSubmit }) => {
  const disabled = !column.liberado;
  const cell = row[column.id];
  const pureText = utils.digitacao.getText(cell);
  const isNumeric = utils.digitacao.isNumericValue(column.id, cell);

  let testNum: RegExp | undefined = undefined;
  if (isDecimal) testNum = /^[0-9]|,$/i;
  else if (isNumeric) testNum = /^[0-9]$/i;

  const [value, setValue] = useState(pureText);
  const field = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setValue(pureText);
    if (field.current) {
      field.current.focus();
    }
  }, [pureText]);

  return (
    <AppBar
      position="absolute"
      sx={{ top: "auto", bottom: 0, borderLeft: "none", borderRight: "none" }}
      variant="outlined"
      elevation={0}
      color="inherit"
    >
      <Toolbar sx={{ pl: 1, pr: 1 }}>
        <NumericFormat
          decimalSeparator={isDecimal ? "," : undefined}
          decimalScale={isDecimal ? 2 : undefined}
          fixedDecimalScale={isDecimal}
          inputMode={isDecimal ? "decimal" : "numeric"}
          customInput={TextField}
          name={column.id.toString()}
          inputRef={field}
          disabled={disabled}
          value={value}
          onValueChange={({ formattedValue }) => setValue(formattedValue)}
          onKeyDown={(event) => {
            if (
              testNum &&
              !testNum.test(event.key) &&
              !allowedKeys.includes(event.key)
            ) {
              event.preventDefault();
            }
            if (event.key == "Enter") {
              event.preventDefault();
              handleSubmit({ value, column, row });
            }
          }}
          label={`Digite o valor aqui`}
        />
        {handleAdd && (
          <Fab
            sx={(theme) => ({ ml: 1, minWidth: theme.spacing(7) })}
            disabled={handleAdd.disabled}
            onClick={handleAdd.onAdd}
            color="secondary"
          >
            <AddIcon />
          </Fab>
        )}
      </Toolbar>
    </AppBar>
  );
};
