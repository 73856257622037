import React, { FC, useState, useEffect } from "react";
import {
  EChatType,
  IChat,
  IChatGroup,
  IChatEmit,
} from "@deltasge/marauders-map";
import { Grid, IconButton, InputAdornment, TextField } from "@mui/material";
import { Send as SendIcon } from "mdi-material-ui";
import PerfectScrollbar from "react-perfect-scrollbar";

import { useSocket } from "context/SocketContext";
import { useAppSelector } from "store";
import { ChatItem } from "components/ChatItem";
import { snack } from "components/GlobalSnackbar";
import { getError } from "utils";
import { Environment } from "configs";
import { isBefore, parseISO } from "date-fns";

export const ChatComponent: FC<{
  chatGroup: IChatGroup;
  setChatGroup: React.Dispatch<React.SetStateAction<IChatGroup | undefined>>;
}> = ({ chatGroup, setChatGroup }) => {
  const [scrollEl, setScrollEl] = useState<HTMLElement>();
  const [message, setMessage] = useState("");

  const {
    usuario: { usuario },
  } = useAppSelector((state) => state);

  const socket = useSocket();

  const handleSendMsg = () => {
    try {
      const chat: IChat = {
        chatType: EChatType.Text,
        context: message,
        fromId: usuario?.deltaId ?? "",
        viewed: [],
        delivered: [],
      };
      const chatEmit: IChatEmit = {
        id: chatGroup?._id,
        chat,
      };
      socket?.emit("chat:msg:v3", chatEmit);

      setMessage("");
      if (scrollEl) {
        setTimeout(() => {
          scrollEl.scrollTop = scrollEl.scrollHeight;
        }, 200);
      }
    } catch (err) {
      snack.warning(getError(err));
    }
  };

  const setDelivered = ({
    id,
    chatGroup,
  }: {
    id?: string;
    chatGroup: IChatGroup;
  }) => {
    socket?.emit("chat:setDelivered", {
      chatGroupId: chatGroup._id,
      deltaId: usuario?.deltaId,
      chatId: id,
    });
  };

  const alertUser = (event: BeforeUnloadEvent) => {
    event.preventDefault();
    return (event.returnValue = "Deseja fechar esta conversa?");
  };

  const chatWith = async (chatWith?: string) => {
    if (usuario && usuario.deltaId && usuario.escolaId && usuario.token) {
      const data: Record<string, string> = {
        deltaId: usuario?.deltaId,
        escolaId: usuario?.escolaId,
        token: usuario?.token,
      };
      if (chatWith) data.chatWith = chatWith;

      const params = new URLSearchParams(data).toString();

      navigator.sendBeacon(
        `${Environment.CONFIG_DELTA_API_URL}/usuarios/usuario/chat-with?${params}`
      );
    }
  };

  const clearChatWith = () => {
    chatWith();
  };

  const handleChat = (data: IChat, chatGroup: IChatGroup) => {
    if (
      data.fromId != usuario?.deltaId &&
      !data.delivered.find((f) => f.deltaId == usuario?.deltaId)
    ) {
      setDelivered({ id: data._id, chatGroup: chatGroup });
    }

    setChatGroup((prev) => {
      const chats: IChat[] = [];
      if (prev?.chats) chats.push(...prev.chats);

      const index = chats.findIndex((f) => f._id == data._id);
      if (index >= 0) chats[index] = data;
      else chats.unshift(data);

      return {
        ...chatGroup,
        chats,
      };
    });
    if (scrollEl) {
      setTimeout(() => {
        scrollEl.scrollTop = scrollEl.scrollHeight;
      }, 200);
    }
  };

  useEffect(() => {
    if (scrollEl) {
      scrollEl.scrollTop = scrollEl.scrollHeight;
    }
  }, [scrollEl, message]);

  useEffect(() => {
    if (scrollEl) {
      scrollEl.scrollTop = scrollEl.scrollHeight;
    }
    chatWith(chatGroup._id);

    if (socket && chatGroup?._id) {
      socket?.on(chatGroup?._id, (chat: IChat) => handleChat(chat, chatGroup));
    }

    if (
      chatGroup.chats &&
      chatGroup.chats.find(
        (f) =>
          !f.delivered.map((m) => m.deltaId).includes(usuario?.deltaId ?? "")
      )
    ) {
      setDelivered({ chatGroup });
    }

    window.addEventListener("beforeunload", alertUser);
    window.addEventListener("unload", clearChatWith);
    return () => {
      window.removeEventListener("beforeunload", alertUser);
      window.removeEventListener("unload", clearChatWith);
      clearChatWith();
      socket?.off(chatGroup?._id);
    };
  }, []);

  const deleted = chatGroup.deleted.find((f) => f.deltaId == usuario?.deltaId);

  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-end"
      alignItems="stretch"
      sx={{ height: "100%" }}
    >
      <Grid
        item
        sx={{
          height: (theme) => `calc(100% - ${theme.spacing(7)})`,
          pb: 1,
        }}
      >
        <PerfectScrollbar containerRef={setScrollEl}>
          <Grid
            container
            direction="column-reverse"
            flexWrap="nowrap"
            justifyContent="flex-end"
            sx={{ height: "100%", p: 1 }}
          >
            {(chatGroup.chats ?? [])
              .filter((chat) => {
                if (!deleted) return true;
                const createdAt = parseISO((chat.createdAt || "").toString());
                const deletedAt = parseISO(deleted.at.toString());
                return isBefore(deletedAt, createdAt);
              })
              .map((m, i) => (
                <ChatItem
                  key={`${i}_${m._id}`}
                  chat={m}
                  user={chatGroup?.chatUsers?.find(
                    (f) => f.deltaId == m.fromId
                  )}
                  isMe={usuario?.deltaId == m.fromId}
                />
              ))}
          </Grid>
        </PerfectScrollbar>
      </Grid>
      <Grid item>
        <TextField
          value={message}
          onChange={({ target: { value } }) => setMessage(value)}
          onKeyPress={(e) => {
            if (e.key == "Enter") {
              e.preventDefault();
              handleSendMsg();
            }
          }}
          disabled={!socket?.connected}
          label="Mensagem"
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton aria-label="send-message" onClick={handleSendMsg}>
                  <SendIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
    </Grid>
  );
};
