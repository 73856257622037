import React, { FC } from "react";

import {
  Box,
  Toolbar,
  AppBar,
  Grid,
  IconButton,
  Typography,
  Avatar,
  Tooltip,
  Divider,
  useMediaQuery,
} from "@mui/material";
import { Menu as MenuIcon } from "@mui/icons-material";

import { useAppSelector, useAppDispatch } from "store";
import { toggleSidebar, toggleRightbar } from "store/modules/layout";
import { useMobile } from "hooks/useMobile";
import icon from "assets/images/icon.png";
import iconDark from "assets/images/icon-dark.png";

import * as styles from "./styles";

export const Header: FC<{
  buttons: React.ReactNode[];
  title: string;
  subtitle?: string;
  searchElement?: React.ReactNode;
  hasRightbar?: boolean;
  rightbarIcon?: React.ReactNode;
}> = ({
  buttons,
  title,
  searchElement,
  hasRightbar = false,
  subtitle,
  rightbarIcon,
}) => {
  const isMd = useMobile("md");
  const dispatch = useAppDispatch();
  const { mode } = useAppSelector((state) => state.layout.theme);
  const isDark = useMediaQuery("(prefers-color-scheme: dark)");

  return (
    <Box sx={styles.root}>
      <AppBar
        position="fixed"
        sx={styles.appBar({ more: isMd ? 0 : 1 })}
        variant="outlined"
        elevation={0}
        color="inherit"
      >
        <Toolbar>
          <Grid container wrap="nowrap" alignItems="center">
            <Grid item>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="Menu"
                onClick={() => dispatch(toggleSidebar())}
              >
                <MenuIcon />
              </IconButton>
            </Grid>
            <Grid item>
              <Avatar
                alt="Delta Software"
                src={
                  mode == "auto"
                    ? isDark
                      ? iconDark
                      : icon
                    : mode == "light"
                    ? icon
                    : iconDark
                }
              />
            </Grid>
            {subtitle && (
              <Grid item xs zeroMinWidth>
                <Typography variant="h6" noWrap>
                  {title}
                </Typography>

                <Typography variant="caption" noWrap component="p">
                  {subtitle}
                </Typography>
              </Grid>
            )}
            {!subtitle && (
              <Grid item xs zeroMinWidth>
                <Typography variant="h6" noWrap>
                  {title}
                </Typography>
              </Grid>
            )}
            {searchElement && (
              <Grid item xs={!isMd} sx={{ flexFlow: !isMd ? "inherit" : 2 }}>
                {searchElement}
              </Grid>
            )}
            {searchElement && (hasRightbar || buttons.length > 0) && (
              <Grid item>
                <Divider sx={styles.divider} orientation="vertical" />
              </Grid>
            )}
            {buttons.map((btn, i) => (
              <Grid key={`headerbtn${i}`} item>
                {btn}
              </Grid>
            ))}
            {hasRightbar && (
              <Grid item>
                <Tooltip title="Mostrar ou esconder o menu">
                  <IconButton
                    color="inherit"
                    onClick={() => dispatch(toggleRightbar())}
                  >
                    {rightbarIcon && rightbarIcon}
                  </IconButton>
                </Tooltip>
              </Grid>
            )}
          </Grid>
        </Toolbar>
      </AppBar>
    </Box>
  );
};
