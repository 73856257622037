import "@deltasge/marauders-map/lib/utils/date.extension";
import * as yup from "yup";

import React, { FC, useEffect, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  EMensagemCategoriaTipo,
  ESistAvalUsaVirgula,
  ETipoAvaliacao,
  EUserType,
  IAvaCalc,
  IAvaSubs,
  IAvaliacao,
  IMensagem,
  IMensagemArquivo,
  IPeriodoEtapa,
  ISistemaAvaliacao,
  IValorRestanteCalculo,
  getKeyFromValue,
  schemaAvaliac,
  yupAvaliacao,
  yupMensagem,
  yupMensagemAtribuicao,
} from "@deltasge/marauders-map";
import { Form } from "assets/styleds";
import {
  Box,
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  Grid,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { DatePicker } from "@mui/x-date-pickers";

import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useApplyProps } from "hooks/useApplyProps";
import { format, parseISO } from "date-fns";
import { useAppSelector } from "store";
import { NumericFormat } from "react-number-format";
import { api } from "configs";
import * as classesForm from "assets/styles/form";
import { snack } from "components/GlobalSnackbar";
import { getError, sendS3File } from "utils";

import { Mensagem, IMsgError } from "./components/Mensagem";
import { IPreMensagemArquivo } from "pages/Mensagens/utilMensagem";

const yupAvaliacaoParamsMax = yupAvaliacao.fields.descricao.tests.find(
  (f) => f.OPTIONS.name == "max"
)?.OPTIONS.params;
const maxLength =
  yupAvaliacaoParamsMax && !Number.isNaN(yupAvaliacaoParamsMax.max)
    ? Number(yupAvaliacaoParamsMax.max)
    : 70;

type IAvaliacaoForm = Omit<
  IAvaliacao,
  | "professor"
  | "curso"
  | "serie"
  | "turma"
  | "materia"
  | "substituir"
  | "substitutiva"
  | "notas"
  | "mensagem"
>;

const getSchema = (etapa: IPeriodoEtapa) => {
  const min = etapa.dataEtapaIni ? parseISO(etapa.dataEtapaIni) : new Date();
  const max = etapa.dataEtapaFim ? parseISO(etapa.dataEtapaFim) : new Date();
  return yupAvaliacao
    .omit(["data"])
    .concat(
      yup.object({
        data: yup
          .date()
          .required()
          .default(new Date())
          .min(
            min,
            () =>
              `A data deve ser entre ${format(min, "dd/MM/yyyy")} e ${format(
                max,
                "dd/MM/yyyy"
              )}`
          )
          .max(max)
          .label("Data da avaliação"),
      })
    )
    .shape({});
};

export const CadastroAvaliacao: FC<{
  handleClose: (reload?: boolean) => void;
  item?: Partial<IAvaliacao>;
  sistemaAvaliacao: ISistemaAvaliacao;
  etapa: IPeriodoEtapa;
}> = ({ handleClose, item, sistemaAvaliacao, etapa }) => {
  const {
    calculoAvaliacoes: { data: calculos },
    mensagemCategoria: { data: mensagemCategorias },
    usuario: { usuario },
  } = useAppSelector((state) => state);

  const [habilita, setHabilita] = useState({
    peso: false,
    valor: false,
  });
  const [avaliacoes, setAvaliacoes] = useState<IAvaliacao[]>([]);
  const [saving, setSaving] = useState(false);
  const [reload, setReload] = useState(false);
  const [mensagem, setMensagem] = useState<IMensagem | undefined>(
    item?.mensagem
  );

  const [msgError, setMsgError] = useState<IMsgError>({});

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    watch,
    setError,
    clearErrors,
    reset,
  } = useForm<IAvaliacaoForm>({
    defaultValues: {
      ...yupAvaliacao.getDefault(),
      ...item,
      bimestre: etapa.etapa,
      calculo: item?.calculo ?? calculos.at(0)?.calculo,
      idProfessor:
        usuario?.tipoUsuario == EUserType.Professor ? usuario.id : null,
      idUsuario:
        usuario?.tipoUsuario == EUserType.UsuarioSistema ? usuario.id : null,
    },
    resolver: yupResolver(getSchema(etapa)),
  });

  const codigoCalculo = watch("calculo");
  const calculoSelecionado = calculos.find((f) => f.calculo == codigoCalculo);
  const codigo = watch("codigo");

  const applyProps = useApplyProps<IAvaliacaoForm>({
    register,
    errors,
    schema: getSchema(etapa),
  });

  const onSubmit = async (payload: IAvaliacao) => {
    try {
      setSaving(true);

      if (!calculoSelecionado) {
        throw new Error("Erro ao buscar o calculo selecionado");
      }

      if (!payload.codigo) {
        throw new Error("Erro buscar o código da avaliação");
      }

      let idMensagemDelete: number | undefined = undefined;
      if (mensagem && mensagem.texto && mensagem.texto.trim().length > 0) {
        const atr = mensagem.mensagemAtribuicoes?.at(0);

        if (!atr) {
          setMsgError({
            mensagemAtribuicoes: "Nenhum envio foi definido na mensagem",
          });
          throw new Error("Nenhum envio foi definido na mensagem");
        }

        if (!atr.aluno && !atr.responsavel && !atr.loginExtra) {
          setMsgError({
            mensagemAtribuicoes: "O Envio deve ser definido",
          });
          throw new Error("O Envio deve ser definido");
        }

        mensagem.idUsuario = null;
        mensagem.idProfessor = null;
        if (usuario?.tipoUsuario == EUserType.Professor) {
          mensagem.idProfessor = usuario?.id ?? null;
        } else {
          mensagem.idUsuario = usuario?.id ?? null;
        }

        mensagem.mensagemArquivos = await Promise.all(
          (mensagem.mensagemArquivos ?? []).map(
            async (item): Promise<IMensagemArquivo> => {
              const { file } = item as IPreMensagemArquivo;
              if (file) {
                const arquivo = await sendS3File({
                  file,
                });
                item.idArquivo = arquivo.id ?? "";
                item.idMensagem = mensagem.id ?? null;
              }
              return item;
            }
          )
        );

        const { id, ...payloadMensagem } = mensagem;
        if (id) {
          await api.put<IMensagem>(`educador/mensagem/${id}`, payloadMensagem, {
            params: {
              sendNotification: true,
            },
          });
        } else {
          const { data } = await api.post<IMensagem>(
            "educador/mensagem",
            payloadMensagem,
            {
              params: {
                sendNotification: true,
              },
            }
          );
          if (!data.id) {
            throw new Error("Erro ao resgatar o ID da mensagem");
          }
          mensagem.id = data.id;
          payload.idMensagem = data.id;
        }
      } else if (
        payload.idMensagem &&
        (!mensagem || !mensagem.texto || mensagem.texto.trim().length == 0)
      ) {
        idMensagemDelete = payload.idMensagem;
        payload.idMensagem = null;
      }

      if (payload.mensagem) delete payload.mensagem;

      const calculoAntigo = calculos.find((f) => f.calculo == item?.calculo);
      if (
        item?.calculo != payload.calculo &&
        calculoAntigo?.permitiSub &&
        item?.uid
      ) {
        const { data } = await api.get<IAvaSubs[]>("substitutiva", {
          params: {
            select: ["uid", "idSubstitui"],
            where: {
              idSubstitutiva: item?.uid,
            },
          },
        });
        if (data.length > 0) {
          await api.delete(`substitutiva/${data.map((m) => m.uid).join(",")}`);
        }
      }
      if (calculoSelecionado.permitiSub) {
        const substituir = avaliacoes
          .filter((f) => f.substitutiva != undefined)
          .map((m) => m.substitutiva) as IAvaSubs[];

        if (substituir.length == 0) {
          throw new Error(
            "Você deve ao menos selecionar uma avaliação para ser substituida"
          );
        }

        payload.substituir = substituir;
      }

      if (item?.uid) {
        await api.put(`avaliacao/${item.uid}`, payload);
        handleClose(true);
      } else {
        await api.post("avaliacao", payload);
        const { data } = await api.get<{ codigo?: string }>(
          `avaliacao/proximo-codigo/${payload.idTurma}/bimestre/${payload.bimestre}/materia/${payload.idMateria}`,
          {
            cache: { ignoreCache: true },
          }
        );
        if (!data || !data.codigo) {
          throw new Error("Erro ao obter o código da avaliação");
        }
        reset({
          ...yupAvaliacao.getDefault(),
          ...item,
          bimestre: etapa.etapa,
          calculo: calculos.at(0)?.calculo,
          codigo: data.codigo,
          idProfessor:
            usuario?.tipoUsuario === EUserType.Professor ? usuario?.id : null,
        });
        setAvaliacoes([]);
      }
      if (idMensagemDelete) {
        await api.delete(`avaliacao/${item?.uid}/mensagem/${idMensagemDelete}`);
      }
    } catch (error) {
      snack.error(getError(error));
    } finally {
      setSaving(false);
      setReload(true);
      setMensagem(undefined);
    }
  };

  const getSubs = async () => {
    if (avaliacoes.length == 0) {
      const params = {
        idTurma: item?.idTurma,
        idMateria: item?.idMateria,
        bimestre: etapa.etapa,
        idAvaliacao: item?.uid,
      };
      if (!item?.uid) {
        delete params.idAvaliacao;
      }
      const { data } = await api.get<IAvaliacao[]>(
        "avaliacao/substs/get-list",
        {
          cache: { ignoreCache: true },
          params,
        }
      );
      if (Array.isArray(data)) {
        setAvaliacoes(data);
      }
    }
  };

  const getValorRestante = async (calculo: IAvaCalc) => {
    try {
      if (
        !item?.anoLetivo ||
        !etapa.etapa ||
        !item?.idMateria ||
        !item?.idTurma
      ) {
        throw new Error("Falta parametros para buscar valor restante");
      }
      const params: IValorRestanteCalculo = {
        calculo: calculo.calculo,
        anoLetivo: item.anoLetivo,
        bimestre: etapa.etapa,
        idAvaliacao: item.uid,
        idMateria: item.idMateria,
        idTurma: item.idTurma,
      };
      const { data } = await api.post<number | undefined>(
        "avaliacao/get-valor-restante-calculo",
        params
      );
      if (data && data > 0) {
        setValue("valor", data);
      } else if (data && data < 0) {
        snack.warning(
          `A soma das avaliações desta etapa, matéria e cálculo ultrapassam o valor máximo (${calculo.valor})`
        );
      }
    } catch (error) {
      snack.error(getError(error));
    }
  };

  const handleSubstitui = async (avaliacao: IAvaliacao) => {
    try {
      setAvaliacoes((prev) =>
        prev.map((m) => {
          if (avaliacao.uid == m.uid) {
            if (!m.substitutiva) {
              if (!avaliacao.codigo || !codigo || !avaliacao.uid) {
                throw new Error("Erro ao gerar a substitutiva");
              }
              m.substitutiva = {
                anoLetivo: avaliacao.anoLetivo,
                bimestre: avaliacao.bimestre,
                codSubstitui: avaliacao.codigo,
                codSubstitutiva: codigo,
                idCurso: avaliacao.idCurso,
                idMateria: avaliacao.idMateria,
                idSerie: avaliacao.idSerie,
                idSubstitui: avaliacao.uid,
                idSubstitutiva: null,
                idTurma: avaliacao.idTurma,
              };
            } else {
              delete m.substitutiva;
            }
          }
          return m;
        })
      );
    } catch (error) {
      snack.error(getError(error));
    }
  };

  useEffect(() => {
    if (!calculoSelecionado) return;

    if (
      calculoSelecionado.calculo == 15 &&
      calculoSelecionado.valor > 0 &&
      !item?.uid
    ) {
      getValorRestante(calculoSelecionado);
    }
    if (calculoSelecionado.permitiSub) {
      getSubs();
    }
    if (!item?.uid) {
      setValue(
        "valor",
        calculoSelecionado.valor > 0 ? calculoSelecionado.valor : 100
      );
      setValue(
        "peso",
        calculoSelecionado.peso > 0 ? calculoSelecionado.peso : 1.0
      );
    }
    setHabilita({
      peso: calculoSelecionado.pesoLiberado ?? true,
      valor: calculoSelecionado.valor == 0 || calculoSelecionado.calculo == 15,
    });
  }, [codigoCalculo]);

  let minValor = 1;
  let isDecimal = false;
  if (
    sistemaAvaliacao.usavirgula == ESistAvalUsaVirgula["Digitar com vírgula"]
  ) {
    minValor = 0.1;
    isDecimal = true;
  }

  const mensagemCategoria = mensagemCategorias.find(
    (f) => f.tipo == EMensagemCategoriaTipo["Conteúdo para avaliações"]
  );

  return (
    <>
      <PerfectScrollbar>
        <DialogContent dividers style={{ paddingBottom: 0 }}>
          <Form onSubmit={handleSubmit(onSubmit)} noValidate id="cadavaliacao">
            <Grid container spacing={2}>
              <Grid item xs={6} sm={3}>
                <TextField {...applyProps("codigo")} disabled label="Código" />
              </Grid>
              <Grid item xs={6} sm={3}>
                <Controller
                  control={control}
                  name="data"
                  render={({ field }) => (
                    <DatePicker
                      {...field}
                      DialogProps={{ fullWidth: false }}
                      label={schemaAvaliac.fields.data.spec.label}
                      value={parseISO(field.value)}
                      onChange={(data) => {
                        if (data && Date.prototype.isValid(data)) {
                          setValue("data", format(data, "yyyy-MM-dd"));
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          helperText={errors.data?.message}
                          error={!!errors.data}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  control={control}
                  name="tipo"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      select
                      label={schemaAvaliac.fields.tipo.spec.label}
                      helperText={errors.tipo?.message}
                      error={!!errors.tipo}
                    >
                      {Object.entries(ETipoAvaliacao).map((option, index) => (
                        <MenuItem key={index} value={option[1]}>
                          {option[0]}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  control={control}
                  name="calculo"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      select
                      label={schemaAvaliac.fields.calculo.spec.label}
                      helperText={errors.calculo?.message}
                      error={!!errors.calculo}
                    >
                      {calculos.map((m) => (
                        <MenuItem key={`calculo-${m.id}`} value={m.calculo}>
                          {m.calculo} - {m.descricao}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </Grid>
              <Grid item xs={6} sm={3}>
                <Controller
                  name="valor"
                  control={control}
                  render={({ field }) => (
                    <NumericFormat
                      customInput={TextField}
                      label={schemaAvaliac.fields.valor.spec.label}
                      helperText={errors.valor?.message}
                      error={!!errors.valor}
                      value={field.value}
                      disabled={!habilita.valor}
                      decimalSeparator={isDecimal ? "," : undefined}
                      decimalScale={isDecimal ? 2 : undefined}
                      fixedDecimalScale={isDecimal}
                      inputMode={isDecimal ? "decimal" : "numeric"}
                      isAllowed={({ floatValue }) => {
                        if (!floatValue) return true;
                        return floatValue <= 100 && floatValue >= minValor;
                      }}
                      onValueChange={({ floatValue, formattedValue }) => {
                        if (!floatValue || floatValue <= 0) {
                          setError("valor", {
                            message: "O valor deve ser maior que 0",
                          });
                          return;
                        } else if (formattedValue.includes(".") && !isDecimal) {
                          setError("valor", {
                            message: "O valor informado é inválido",
                          });
                          return;
                        } else {
                          clearErrors("valor");
                        }
                        setValue("valor", floatValue);

                        const calculo = calculos.find(
                          (f) => f.calculo == codigoCalculo
                        );
                        if (!calculo) return;
                        if (!calculo.pesoLiberado && calculo.peso <= 0) {
                          setValue("peso", floatValue / (isDecimal ? 10 : 100));
                        }
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6} sm={3}>
                <Controller
                  name="peso"
                  control={control}
                  render={({ field }) => (
                    <NumericFormat
                      customInput={TextField}
                      label={schemaAvaliac.fields.peso.spec.label}
                      helperText={errors.peso?.message}
                      error={!!errors.peso}
                      value={field.value}
                      disabled={!habilita.peso}
                      decimalSeparator={","}
                      decimalScale={2}
                      fixedDecimalScale
                      inputMode="decimal"
                      isAllowed={({ floatValue }) => {
                        if (!floatValue) return true;
                        return floatValue <= 100 && floatValue >= minValor;
                      }}
                      onValueChange={({ floatValue, formattedValue }) => {
                        if (!floatValue || floatValue <= 0) {
                          setError("peso", {
                            message: "O peso deve ser maior que 0",
                          });
                          return;
                        } else if (formattedValue.includes(".")) {
                          setError("peso", {
                            message: "O peso informado é inválido",
                          });
                          return;
                        } else {
                          clearErrors("peso");
                        }
                        setValue("peso", floatValue);
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  {...applyProps("descricao")}
                  helperText={
                    errors.descricao?.message ??
                    `${
                      maxLength - watch("descricao").length
                    } caracteres restante`
                  }
                />
              </Grid>
              {calculoSelecionado?.permitiSub && (
                <Grid item xs={12}>
                  <Box
                    component="fieldset"
                    sx={classesForm.fieldset}
                    mb={2}
                    p={0}
                  >
                    <Typography variant="subtitle2" component="legend">
                      Substitutiva
                    </Typography>
                    <TableContainer>
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell>Substituir</TableCell>
                            <TableCell>Avaliação</TableCell>
                            <TableCell align="right">Data</TableCell>
                            <TableCell align="right">Valor</TableCell>
                            <TableCell>Tipo</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {avaliacoes.map((m) => (
                            <TableRow
                              key={`avaliacao-sub-${m.uid}`}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                                cursor: "pointer",
                              }}
                              hover
                              role="checkbox"
                              onClick={() => handleSubstitui(m)}
                            >
                              <TableCell padding="checkbox">
                                <Checkbox
                                  checked={m.substitutiva != undefined}
                                  color="primary"
                                  inputProps={{
                                    "aria-label": `select ${m.uid}`,
                                  }}
                                />
                              </TableCell>
                              <TableCell>{m.descricao}</TableCell>
                              <TableCell align="right">
                                {format(parseISO(m.data), "dd/MM/yyyy")}
                              </TableCell>
                              <TableCell align="right">{m.valor}</TableCell>
                              <TableCell>
                                {getKeyFromValue(ETipoAvaliacao, m.tipo)}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                </Grid>
              )}
              <Grid item xs={12}>
                {item?.anoLetivo &&
                  item?.idCurso &&
                  item?.idSerie &&
                  item?.idTurma &&
                  item?.idMateria &&
                  mensagemCategoria?.id && (
                    <Mensagem
                      partAtribuicao={{
                        idCurso: item.idCurso,
                        idSerie: item.idSerie,
                        idTurma: item.idTurma,
                        idMateria: item.idMateria,
                        anoLetivo: item.anoLetivo,
                      }}
                      mensagem={
                        mensagem ?? {
                          ...yupMensagem.getDefault(),
                          rascunho: false,
                          idMensagemCategoria: mensagemCategoria.id,
                          mensagemAtribuicoes: [
                            {
                              ...yupMensagemAtribuicao.getDefault(),
                              idCurso: item.idCurso,
                              idSerie: item.idSerie,
                              idTurma: item.idTurma,
                              idMateria: item.idMateria,
                              anoLetivo: item.anoLetivo,
                              aluno: true,
                              excluido: false,
                              exportado: false,
                            },
                          ],
                        }
                      }
                      setMensagem={setMensagem}
                      msgError={msgError}
                      setMsgError={setMsgError}
                      idAvaliacao={item?.uid}
                    />
                  )}
              </Grid>
            </Grid>
          </Form>
        </DialogContent>
      </PerfectScrollbar>
      <DialogActions>
        <Button
          onClick={() => handleClose(reload)}
          variant="text"
          color="inherit"
        >
          Fechar
        </Button>
        <LoadingButton type="submit" loading={saving} form="cadavaliacao">
          Salvar
        </LoadingButton>
      </DialogActions>
    </>
  );
};
