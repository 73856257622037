import React, { FC, useState } from "react";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { DatePicker, MobileDatePicker } from "@mui/x-date-pickers";
import {
  Calendar as CalendarIcon,
  CalendarRemove as ClearIcon,
} from "mdi-material-ui";
import { differenceInDays, format } from "date-fns";
import { snack } from "components/GlobalSnackbar";

interface Props {
  selecionado?: Date;
  onClick: (data?: Date) => void;
  render: "accordion" | "autocomplete";
  minDate?: Date;
  maxDate?: Date;
  allowsToClean?: boolean;
  label?: string;
  dataIni?: Date;
  dataFim?: Date;
}

export const FilterData: FC<Props> = ({
  label = "Data",
  selecionado,
  onClick,
  render,
  minDate,
  maxDate,
  allowsToClean = true,
  dataIni,
  dataFim,
}) => {
  const [panel, setPanel] = useState(false);

  const handleClick = (data?: Date) => {
    if (
      (data && dataIni && differenceInDays(dataIni, data) > 0) ||
      (data && dataFim && differenceInDays(data, dataFim) > 0)
    ) {
      snack.warning("A data fimal deve ser maior que a inicial");
      return;
    }
    onClick(data);
    if (render == "accordion") setPanel(false);
  };

  if (render == "accordion") {
    return (
      <Accordion
        elevation={0}
        expanded={panel}
        onClick={(e) => e.preventDefault()}
      >
        <MobileDatePicker
          minDate={minDate}
          maxDate={maxDate}
          showToolbar
          closeOnSelect
          value={selecionado ?? null}
          onChange={(data: Date | null) => handleClick(data ?? undefined)}
          renderInput={(props) => {
            if (props.InputProps?.readOnly) {
              return (
                <AccordionSummary
                  expandIcon={<CalendarIcon />}
                  onClick={(e) => {
                    if (props.inputProps?.onClick)
                      props.inputProps?.onClick(
                        e as React.MouseEvent<
                          HTMLInputElement | HTMLTextAreaElement,
                          MouseEvent
                        >
                      );
                  }}
                >
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid item xs={allowsToClean && selecionado ? 10 : 12}>
                      <Typography variant="subtitle2">{label}</Typography>
                      <Typography variant="caption">
                        {!selecionado
                          ? "A QUALQUER MOMENTO"
                          : format(selecionado, "dd/MM/yyyy")}
                      </Typography>
                    </Grid>
                    {allowsToClean && selecionado && (
                      <Grid item xs>
                        <Tooltip title="Limpar">
                          <IconButton onClick={() => onClick()}>
                            <ClearIcon />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    )}
                  </Grid>
                </AccordionSummary>
              );
            } else {
              return <TextField {...props} />;
            }
          }}
        />
        <AccordionDetails></AccordionDetails>
      </Accordion>
    );
  }

  return (
    <Box m={1}>
      <DatePicker
        componentsProps={
          allowsToClean
            ? {
                actionBar: {
                  actions: ["clear"],
                },
              }
            : undefined
        }
        label={label}
        value={selecionado ?? null}
        onChange={(data) => onClick(data ?? undefined)}
        renderInput={(params) => <TextField {...params} />}
      />
    </Box>
  );
};
