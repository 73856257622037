import React, { FC } from "react";
import { List } from "@mui/material";
import { DevTo as DevIcon } from "mdi-material-ui";
import { IRoute } from "configs";
import { useSidebar } from "hooks/useSidebar";

import * as styles from "assets/styles/drawer";
import { SidebarItem } from "./SidebarItem";
import { SidebarListCollapsable } from "./SidebarListCollapsable";
import { useAppSelector } from "store";

export const SidebarList: FC<{
  routes?: IRoute[];
  leftSpace: number;
}> = ({ routes, leftSpace = 0 }) => {
  const { devMode } = useAppSelector((state) => state.layout);
  const { toggle } = useSidebar();

  if (!routes) return null;

  return (
    <List sx={styles.list}>
      {routes
        .filter((r) => r.showOnSidebar)
        .sort((a, b) => {
          if (b.label === "Dashboard") return 1;
          if ((a.label ?? "") < (b.label ?? "")) return -1;
          if ((a.label ?? "") > (b.label ?? "")) return 1;
          return 0;
        })
        .map((route) => {
          if (route.routes) {
            return (
              <SidebarListCollapsable
                route={route}
                key={route.label}
                leftSpace={leftSpace + 1}
              >
                <SidebarList routes={route.routes} leftSpace={leftSpace + 1} />
              </SidebarListCollapsable>
            );
          }
          return (
            <SidebarItem
              key={route.path}
              {...route}
              toggle={() => toggle()}
              leftSpace={leftSpace + 1}
            />
          );
        })}
      {devMode == true && (
        <SidebarItem
          toggle={() => toggle()}
          path="/dev"
          label="Modo Desenvolvedor"
          leftSpace={leftSpace + 1}
          icon={DevIcon}
        />
      )}
    </List>
  );
};
