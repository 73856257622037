import React, { FC, useEffect, useRef, useState } from "react";
import {
  CircularProgress,
  InputAdornment,
  TextField,
  Theme,
  Typography,
} from "@mui/material";
import { NumericFormat } from "react-number-format";

import { ESistAvalUsaVirgula, IAvaNota, utils } from "@deltasge/marauders-map";
import { allowedKeys } from "./Table";

export const arrowKeys = ["ArrowRight", "ArrowLeft", "ArrowUp", "ArrowDown"];

export const TextEdit: FC<{
  usavirgula: ESistAvalUsaVirgula;
  text: string | number | boolean | IAvaNota | undefined;
  enabled: boolean;
  submit: (value: string, key?: string) => Promise<boolean>;
  cancel: () => void;
  isNumeric: boolean;
  isNumericFormat: boolean;
  autoFocus: boolean;
}> = ({
  text,
  enabled,
  submit,
  isNumeric,
  cancel,
  autoFocus,
  usavirgula,
  isNumericFormat,
}) => {
  const pureText = utils.digitacao.getText(text);

  if (!enabled) {
    return (
      <Typography variant="inherit" component="span" noWrap>
        {isNumericFormat && typeof text == "number"
          ? utils.numberToString(usavirgula, text)
          : pureText}
      </Typography>
    );
  }

  const initialValue = !autoFocus ? pureText : "";

  const [value, setValue] = useState(initialValue);
  const [saving, setSaving] = useState(false);
  const [isReady, setIsReady] = useState(false);

  let testNum: RegExp | undefined = undefined;
  const isDecimal = usavirgula == ESistAvalUsaVirgula["Digitar com vírgula"];
  if (isDecimal) testNum = /^[0-9]|,$/i;
  else if (isNumeric) testNum = /^[0-9]$/i;

  const field = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (field.current && !autoFocus) {
      field.current.focus();
    }
  }, [field.current]);

  return (
    <NumericFormat
      decimalSeparator={isDecimal ? "," : undefined}
      decimalScale={isDecimal ? 2 : undefined}
      fixedDecimalScale={isDecimal}
      inputMode={isDecimal ? "decimal" : "numeric"}
      customInput={TextField}
      autoFocus={autoFocus}
      inputRef={field}
      InputProps={{
        startAdornment: !saving ? undefined : (
          <InputAdornment position="start" sx={{ marginTop: "0 !important" }}>
            <CircularProgress
              color="secondary"
              sx={(theme) => ({
                width: `${theme.spacing(2)} !important`,
                height: `${theme.spacing(2)} !important`,
              })}
            />
          </InputAdornment>
        ),
        disableUnderline: true,
        sx: (theme: Theme) => ({
          backgroundColor: "transparent",
          "&.Mui-focused": {
            backgroundColor: "transparent",
          },
          "& .MuiInputBase-input": {
            textAlign: "right",
            paddingTop: "3px",
            paddingRight: theme.spacing(2),
            userSelect: "text",
            fontSize: theme.typography.fontSize,
          },
        }),
      }}
      value={value}
      onKeyDown={async (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (!isReady) setIsReady(true);
        if (
          testNum &&
          !testNum.test(event.key) &&
          !allowedKeys.includes(event.key)
        ) {
          event.preventDefault();
        }
        if (event.key == "Escape") {
          setValue(pureText);
          cancel();
        }
      }}
      onKeyUp={async (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (
          (event.key == "Enter" && isReady) ||
          (autoFocus && arrowKeys.includes(event.key) && value != initialValue)
        ) {
          setSaving(true);
          if (!(await submit(value, event.key))) {
            event.preventDefault();
          }
          setSaving(false);
        }
      }}
      onBlur={async (event) => {
        if (initialValue != value) {
          setSaving(true);
          if (!(await submit(value))) {
            event.preventDefault();
          }
          setSaving(false);
        }
      }}
      onValueChange={({ formattedValue }) => setValue(formattedValue)}
    />
  );
};
