// import { Main } from "../Main";
import { Alert, AlertTitle } from "@mui/material";
import React, { FC } from "react";
import { useLocation } from "react-router-dom";

export const NotFound: FC = () => {
  const location = useLocation();

  return (
    // <Main title="Ops!">
    <Alert severity="warning">
      <AlertTitle>Ops!</AlertTitle>
      <span>
        A página <b>{location.pathname}</b> não foi encontrada
      </span>
    </Alert>
    // </Main>
  );
};
