import { createActionWithPayload } from "store/helpers";
import { ActionTypes } from "./types";
import { createAction } from "@reduxjs/toolkit";
import { IMensagemCategoria } from "@deltasge/marauders-map";

export const request = createAction<void>(ActionTypes.REQUEST);
export const success = createActionWithPayload<IMensagemCategoria[]>(
  ActionTypes.SUCCESS
);
export const failure = createAction<void>(ActionTypes.FAILURE);
export const set = createActionWithPayload<IMensagemCategoria[]>(
  ActionTypes.SET
);
