import React, { FC } from "react";

import { Box, Divider, Paper, SxProps, Theme, Typography } from "@mui/material";

export const CardConfig: FC<{
  title: string;
  children: React.ReactNode;
  sx?: SxProps<Theme>;
}> = ({ children, title, sx }) => {
  return (
    <Paper sx={{ height: "100%" }}>
      <Typography variant="subtitle2" sx={{ padding: 1 }}>
        {title}
      </Typography>
      <Divider />
      <Box sx={{ padding: 1, ...sx }}>{children}</Box>
    </Paper>
  );
};
