import React, { useState, FC } from "react";

import {
  TextField,
  InputAdornment,
  IconButton,
  TextFieldProps,
} from "@mui/material";

import {
  VisibilityOutlined as EyeIcon,
  VisibilityOffOutlined as EyeOffIcon,
} from "@mui/icons-material";

export const Password: FC<TextFieldProps> = (props) => {
  const [show, setShow] = useState(false);

  return (
    <TextField
      type={show ? "text" : "password"}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={() => setShow(!show)}>
              {show ? <EyeOffIcon /> : <EyeIcon />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...props}
    />
  );
};
