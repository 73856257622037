import React, { FC, useEffect, useState } from "react";
import {
  EChatType,
  IMensagem,
  IUser,
  IChatGroup,
  IChat,
  IChatEmit,
} from "@deltasge/marauders-map";

import { Grid, IconButton, InputAdornment, TextField } from "@mui/material";

import { Send as SendIcon } from "mdi-material-ui";

import { useSocket } from "context/SocketContext";
import { useAppSelector } from "store";
import Axios from "axios";
import { apiConfig, apiPush } from "configs";
import { getError, loadS3File } from "utils";
import { snack } from "components/GlobalSnackbar";
import { ChatItem } from "components/ChatItem";

export const ChatMensagem: FC<{
  mensagem: IMensagem;
}> = ({ mensagem }) => {
  const [chatGroup, setChatGroup] = useState<IChatGroup>();
  const [message, setMessage] = useState("");

  const {
    usuario: { usuario },
  } = useAppSelector((state) => state);

  const socket = useSocket();

  const getChannel = async () => {
    try {
      const { data } = await apiPush.put<IChatGroup>(`/api/v3/chat-group`, {
        idEscola: usuario?.escolaId,
        idMensagem: mensagem.id,
        searchForUser: usuario?.deltaId,
        users: [usuario?.deltaId],
      });
      if (data) {
        const { data: chatUsers } = await apiConfig.get<IUser[]>(
          "/v2/usuarios",
          {
            params: {
              where: {
                deltaId: { $in: data.users },
              },
            },
          }
        );
        if (chatUsers && chatUsers.length > 0) {
          data.chatUsers = await Promise.all(
            await chatUsers.map(async (u) => {
              if (u.photoS3 && u.photoUrl) {
                const { processed } = await loadS3File(u.photoUrl);
                if (processed) u.photoUrl = processed;
              }
              return u;
            })
          );
        }
        setChatGroup({
          ...data,
          chats:
            data.chats && data.chats.length > 0 ? data.chats?.reverse() : [],
        });
        if (socket && data._id) {
          setDelivered({ chatGroup: data });
          socket.on(data._id, (chat: IChat) => handleChat(chat, data));
        }
      }
    } catch (error) {
      if (!Axios.isCancel(error)) {
        snack.warning(getError(error));
      }
    }
  };

  const handleChat = (data: IChat, chatGroup: IChatGroup) => {
    if (
      data.fromId != usuario?.deltaId &&
      !data.delivered.find((f) => f.deltaId == usuario?.deltaId)
    ) {
      setDelivered({ id: data._id, chatGroup: chatGroup });
    }

    setChatGroup((prev) => {
      const chats: IChat[] = [];
      if (prev?.chats) chats.push(...prev.chats);

      const index = chats.findIndex((f) => f._id == data._id);
      if (index >= 0) chats[index] = data;
      else chats.unshift(data);

      return {
        ...chatGroup,
        chats,
      };
    });
  };

  const handleSendMsg = () => {
    try {
      const chat: IChat = {
        chatType: EChatType.Text,
        context: message,
        fromId: usuario?.deltaId ?? "",
        viewed: [],
        delivered: [],
      };
      const chatEmit: IChatEmit = {
        id: chatGroup?._id,
        chat,
      };
      socket?.emit("chat:msg:v3", chatEmit);

      setMessage("");
    } catch (err) {
      snack.warning(getError(err));
    }
  };

  const setDelivered = ({
    id,
    chatGroup,
  }: {
    id?: string;
    chatGroup: IChatGroup;
  }) => {
    socket?.emit("chat:setDelivered", {
      chatGroupId: chatGroup._id,
      deltaId: usuario?.deltaId,
      chatId: id,
    });
  };

  useEffect(() => {
    getChannel();
  }, [usuario?.deltaId]);

  return (
    <Grid item xs={12}>
      <Grid
        container
        direction="column"
        justifyContent="flex-end"
        alignItems="stretch"
        height="100%"
      >
        <Grid item xs>
          <Grid
            container
            direction="column-reverse"
            flexWrap="nowrap"
            justifyContent="flex-end"
            sx={{ height: "100%", p: 1 }}
          >
            {(chatGroup?.chats ?? []).map((m, i) => (
              <ChatItem
                key={`${i}_${m._id}`}
                chat={m}
                user={chatGroup?.chatUsers?.find((f) => f.deltaId == m.fromId)}
                isMe={usuario?.deltaId == m.fromId}
              />
            ))}
          </Grid>
        </Grid>
        <Grid item>
          <TextField
            value={message}
            onChange={({ target: { value } }) => setMessage(value)}
            onKeyPress={(e) => {
              if (e.key == "Enter") {
                e.preventDefault();
                handleSendMsg();
              }
            }}
            disabled={!socket?.connected}
            label="Mensagem"
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton aria-label="send-message" onClick={handleSendMsg}>
                    <SendIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
