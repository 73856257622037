import { combineReducers, Reducer, AnyAction } from "@reduxjs/toolkit";

import { reducers as layout } from "./layout";
import { AppActionTypes } from "./app";
import { reducers as usuario } from "./auth/usuario";
import { reducers as validate } from "./auth/validate";
import { reducers as logoff } from "./auth/logoff";
import { reducers as escola } from "./escola";
import { reducers as configuracoes } from "./configuracoes";
import { reducers as versao } from "./versao";
import { reducers as treeView } from "./tree-view";
import { reducers as mensagemCategoria } from "./categorias";
import { reducers as sistemaAvaliacao } from "./sistema-avaliacao";
import { reducers as calculoAvaliacoes } from "./calculo-avaliacoes";
import { reducers as tamanhoPapeis } from "./relatorios/configuracoes/papeis";
import { reducers as configPapelPadrao } from "./relatorios/configuracoes/papel-padrao";
import { reducers as modelosDiarios } from "./relatorios/diarios/modelos";
import { reducers as configDiarioPadrao } from "./relatorios/diarios/modelo-padrao";

export const combinedReducers = combineReducers({
  configuracoes,
  escola,
  layout,
  logoff,
  treeView,
  validate,
  versao,
  usuario,
  mensagemCategoria,
  modelosDiarios,
  sistemaAvaliacao,
  tamanhoPapeis,
  configPapelPadrao,
  configDiarioPadrao,
  calculoAvaliacoes,
});

export type RootState = ReturnType<typeof combinedReducers>;

export const rootReducer: Reducer<RootState> = (state, action: AnyAction) => {
  if (action.type === AppActionTypes.RESET_STORE) {
    return combinedReducers(undefined, action);
  }

  return combinedReducers(state, action);
};
