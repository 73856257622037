import React, { FC } from "react";

import {
  List,
  ListItemIcon,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import {
  Brightness3 as MoonIcon,
  Brightness5 as SunIcon,
  BrightnessAuto as AutoDarkIcon,
} from "mdi-material-ui";

export const DialogThemeMode: FC<{
  onClick: (modo: "dark" | "light" | "auto") => void;
  hideDialog: () => void;
  selected: "dark" | "light" | "auto";
}> = ({ onClick, hideDialog, selected }) => {
  return (
    <List>
      <ListItemButton
        selected={selected == "dark"}
        onClick={() => {
          hideDialog();
          onClick("dark");
        }}
      >
        <ListItemIcon>
          <MoonIcon />
        </ListItemIcon>
        <ListItemText primary="Escuro" />
      </ListItemButton>
      <ListItemButton
        selected={selected == "light"}
        onClick={() => {
          hideDialog();
          onClick("light");
        }}
      >
        <ListItemIcon>
          <SunIcon />
        </ListItemIcon>
        <ListItemText primary="Claro" />
      </ListItemButton>
      <ListItemButton
        selected={selected == "auto"}
        onClick={() => {
          hideDialog();
          onClick("auto");
        }}
      >
        <ListItemIcon>
          <AutoDarkIcon />
        </ListItemIcon>
        <ListItemText primary="Definido pelo dispositivo" />
      </ListItemButton>
    </List>
  );
};
