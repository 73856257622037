import React, { FC, useState, useEffect } from "react";
import Axios from "axios";
import { useParams } from "react-router-dom";
import {
  EMensagemDestinatario,
  EUserType,
  IMensagem,
  MensagemParams,
} from "@deltasge/marauders-map";
import { Box } from "@mui/material";

import { Filter as FilterIcon } from "mdi-material-ui";

import { api, apiPush } from "configs";
import { useDialog } from "hooks/useDialog";
import { useMobile } from "hooks/useMobile";
import { Main } from "pages/_layouts/Main";

import { Cadastro } from "./cadastro";
import { snack } from "components/GlobalSnackbar";
import { getError } from "utils";
import {
  Filter,
  Add,
  TabMensagemRecebida,
  TabMensagemEnviada,
} from "./components";
import { useAppSelector } from "store";
import { utilMensagem as util } from "./utilMensagem";

import { useConfirmDialog } from "hooks/useDialogConfirm";
import { Tabs, ToolbarCadastro } from "./components";
import { TabContext, TabPanel } from "@mui/lab";
import { IOptions, useFilter } from "hooks/useFilter";
import { IFilterMensagem, IParamsMensagem } from "interfaces";

export const Mensagens: FC = () => {
  const CancelToken = Axios.CancelToken;
  const source = CancelToken.source();
  const formId = "form-mensagem";
  const rowsPerPageOptions = [25, 50, 100];

  const {
    usuario: { usuario },
    escola,
    mensagemCategoria: { data: mensagemCategoria },
  } = useAppSelector((state) => state);

  // eslint-disable-next-line prefer-const
  let { tab, idMensagemCategoria } = useParams<IParamsMensagem>();
  const categoria = mensagemCategoria.find(
    (f) => f.id == parseInt(idMensagemCategoria ?? "-1", 10)
  );
  if (idMensagemCategoria == "todas") idMensagemCategoria = undefined;

  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(rowsPerPageOptions[0]);
  const [loading, setLoading] = useState(false);

  const handleFilterCurso = (idCurso?: number) =>
    navigate({
      ...filter,
      idCurso,
      idSerie: undefined,
      idTurma: undefined,
      idMateria: undefined,
    });

  const handleFilterSerie = (idSerie?: number) =>
    navigate({
      ...filter,
      idSerie,
      idTurma: undefined,
      idMateria: undefined,
    });

  const handleFilterTurma = (idTurma?: number) =>
    navigate({
      ...filter,
      idTurma,
    });

  const handleFilterMateria = (idMateria?: number) =>
    navigate({
      ...filter,
      idMateria,
    });

  const handleFilterDataIni = (dataIni?: Date) =>
    navigate({
      ...filter,
      dataIni,
    });

  const handleFilterDataFim = (dataFim?: Date) =>
    navigate({
      ...filter,
      dataFim,
    });

  let optionMateria: IOptions | undefined = undefined;
  if (tab != "2") {
    optionMateria = {
      onClick: handleFilterMateria,
      mostrarTodos: true,
    };
  }

  const pathname = tab
    ? `/publicacoes/${idMensagemCategoria}/categoria/tab/${tab}`
    : "/publicacoes/".concat(
        idMensagemCategoria ? `${idMensagemCategoria}/categoria` : ""
      );

  const {
    Component,
    navigate,
    filter,
    selecionados: { curso, serie, turma, materia },
  } = useFilter<IFilterMensagem, IParamsMensagem>({
    pathname,
    queryKeys: [
      "dataIni",
      "dataFim",
      "idCurso",
      "idSerie",
      "idTurma",
      "idMateria",
      "texto",
      "rascunho",
    ],
    options: {
      curso: {
        onClick: handleFilterCurso,
        mostrarTodos: true,
      },
      serie: {
        onClick: handleFilterSerie,
        mostrarTodos: true,
      },
      turma: {
        onClick: handleFilterTurma,
        mostrarTodos: true,
      },
      materia: optionMateria,
      dataIni: {
        onClick: handleFilterDataIni,
      },
      dataFim: {
        onClick: handleFilterDataFim,
      },
    },
  });

  const [mensagens, setMensagens] = useState<[IMensagem[], number]>([[], 0]);
  const [idMensagem, setIdMensagem] = useState<number | undefined>();
  const [typeMensagem, setTypeMensagem] = useState<{
    type: "normal" | "pergunta" | "programada";
    categoria: number;
  }>({ type: "normal", categoria: 0 });

  const isSm = useMobile("sm");

  const {
    show: showCadastro,
    RenderDialog: DialogCadastro,
    hide: hideCadastro,
  } = useDialog({
    title: `${idMensagem ? "Editar" : "Criar"} publicação`,
    customClose: (hide) => {
      handleFilter();
      hide();
    },
    RenderToolbar: ({ hide, title }) => (
      <ToolbarCadastro hide={hide} title={title} idForm={formId} />
    ),
    options: {
      fullScreen: true,
      modal: true,
      maxWidth: "lg",
      scroll: "paper",
    },
  });

  const { show: showDeleteDialog, RenderDialog: DeleteDialog } =
    useConfirmDialog<number>({
      defaults: {
        title: "Excluir a mensagem?",
        content: "Os anexos e as respostas também serão excluidos",
      },
      onConfirmed: async (payload, hide) => {
        try {
          await api.delete(`educador/mensagem/${payload}`);
          await apiPush.delete(`/api/v3/chat-group/mensagem/${payload}`, {
            headers: {
              Authorization: `Bearer ${usuario?.token}`,
            },
          });
          handleFilter();
        } catch (error) {
          snack.warning((error as Error).toString());
        } finally {
          hide();
        }
      },
    });

  const onChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const onChangeRowsPerPage = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setPage(0);
    setRowsPerPage(parseInt(value, 10));
  };

  const handleFilter = async () => {
    try {
      setLoading(true);

      if (tab == "1" || !tab) {
        const where: MensagemParams = {
          anoLetivo: escola.anoSite,
          dataFim: filter.dataFim,
          dataIni: filter.dataIni,
          idProfessor:
            usuario?.tipoUsuario == EUserType.Professor
              ? usuario.id
              : undefined,
          idCurso: filter.idCurso,
          idMateria: filter.idMateria,
          idSerie: filter.idSerie,
          idTurma: filter.idTurma,
          idMensagemCategoria: idMensagemCategoria
            ? parseInt(idMensagemCategoria, 10)
            : undefined,
          professor:
            usuario?.tipoUsuario == EUserType.Professor ? false : undefined,
          rascunho:
            filter.rascunho == 1
              ? true
              : filter.rascunho == 2
              ? false
              : undefined,
          texto: filter.texto,
        };

        const params = {
          where,
          take: rowsPerPage,
          skip: rowsPerPage * page,
        };
        const { data } = await api.get<[IMensagem[], number]>(
          "educador/mensagem-enviadas",
          {
            cancelToken: source.token,
            params,
            cache: { ignoreCache: true },
          }
        );
        if (Array.isArray(data) && Array.isArray(data[0])) {
          setMensagens(data);
        }
      } else {
        const where = util.getWhereMensagensRecebidas({
          filter,
          idCategoria: idMensagemCategoria,
        });
        const { data } = await api.get<[IMensagem[], number]>(
          `educador/mensagem/destinatario/${EMensagemDestinatario.Professores}/usuario/${usuario?.id}`,
          {
            cancelToken: source.token,
            params: {
              ...where,
              take: rowsPerPage,
              skip: rowsPerPage * page,
            },
            cache: { ignoreCache: true },
          }
        );
        if (Array.isArray(data) && Array.isArray(data[0])) {
          setMensagens(data);
        }
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      if (!Axios.isCancel(err)) {
        snack.warning(getError(err));
      }
    }
  };

  const handleEdit = (idMensagem?: number) => {
    setIdMensagem(idMensagem);
    showCadastro();
  };

  const handlePublicar = async (idMensagem: number, publicar: boolean) => {
    try {
      const index = mensagens[0].findIndex((f) => f.id == idMensagem);
      if (index < 0) {
        throw new Error("Publicação não encontrada na lista");
      }
      mensagens[0][index].rascunho = publicar;

      await api.put(
        `educador/mensagem/${idMensagem}`,
        {
          rascunho: publicar,
        },
        {
          params: publicar
            ? {
                sendNotification: 1,
              }
            : undefined,
        }
      );

      setMensagens([...mensagens]);
    } catch (error) {
      snack.error(getError(error));
    }
  };

  useEffect(() => {
    handleFilter();
  }, [tab, page, rowsPerPage, filter, idMensagemCategoria]);

  return (
    <TabContext value={tab ?? "1"}>
      <Main
        maxWidth={false}
        title={`Publicações${
          categoria?.nome ? " de ".concat(categoria.nome) : ""
        }`}
        subtitle={util.getSubtitle({ curso, serie, materia, turma })}
        loading={loading}
        rightbarChildren={
          <Filter
            filter={filter}
            mensagemCategoria={mensagemCategoria}
            Component={Component}
            navigate={navigate}
            tab={tab}
          />
        }
        rightbarIcon={<FilterIcon />}
        searchElement={
          isSm
            ? undefined
            : usuario?.tipoUsuario == EUserType.Professor && (
                <Tabs
                  tab={tab ?? "1"}
                  idMensagemCategoria={idMensagemCategoria}
                />
              )
        }
      >
        {isSm && usuario?.tipoUsuario == EUserType.Professor && (
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs tab={tab ?? "1"} idMensagemCategoria={idMensagemCategoria} />
          </Box>
        )}
        <TabPanel value="1" sx={{ padding: 0 }}>
          <TabMensagemEnviada
            loading={loading}
            mensagens={mensagens}
            handleDelete={showDeleteDialog}
            handleEdit={handleEdit}
            handlePublicar={handlePublicar}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={rowsPerPageOptions}
            onChangePage={onChangePage}
            onChangeRowsPerPage={onChangeRowsPerPage}
          />
        </TabPanel>
        <TabPanel value="2" sx={{ padding: 0 }}>
          <TabMensagemRecebida
            loading={loading}
            mensagens={mensagens}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={rowsPerPageOptions}
            onChangePage={onChangePage}
            onChangeRowsPerPage={onChangeRowsPerPage}
          />
        </TabPanel>
        <DeleteDialog />
        <DialogCadastro>
          <Cadastro
            idMensagem={idMensagem}
            type={typeMensagem.type}
            formId={formId}
            handleClose={hideCadastro}
            idMensagemCategoria={typeMensagem.categoria}
          />
        </DialogCadastro>
        {tab != "2" && (
          <Add
            categoria={categoria}
            categorias={mensagemCategoria}
            handleAddMensagem={(
              type: "normal" | "pergunta" | "programada",
              idCategoria: number
            ) => {
              setTypeMensagem({ type, categoria: idCategoria });
              setIdMensagem(undefined);
              showCadastro();
            }}
          />
        )}
      </Main>
    </TabContext>
  );
};
