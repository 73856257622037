import React, { FC, useEffect, useState } from "react";
import { Avatar as AvatarMUI, SxProps, Theme } from "@mui/material";
import { loadS3File } from "utils";
import { utils } from "@deltasge/marauders-map";

export const Avatar: FC<{
  photoS3?: boolean | null;
  displayName?: string;
  src?: string | null;
  sx?: SxProps<Theme>;
}> = ({ displayName, src: initSrc, sx, photoS3 }) => {
  const [src, setSrc] = useState(initSrc);

  useEffect(() => {
    if (
      src &&
      src.indexOf("https") == -1 &&
      !utils.isBase64(src, { allowMime: true })
    ) {
      loadS3File(src, true)
        .then(({ processed }) => {
          if (processed) setSrc(processed);
        })
        .catch(() => {
          setSrc(null);
        });
    }
  }, [photoS3, src]);

  if (src)
    return (
      <AvatarMUI
        sx={{
          ...sx,
          bgcolor: displayName ? displayName.toColor() : undefined,
        }}
        src={src}
        alt={displayName ?? "Usuário"}
      />
    );
  else if (displayName)
    return (
      <AvatarMUI
        sx={{
          ...sx,
          //bgcolor: displayName ? displayName.toColor() : undefined,
        }}
      >
        {displayName.at(0)}
      </AvatarMUI>
    );
  return <AvatarMUI sx={sx} />;
};
