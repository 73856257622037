export class LsCache {
  hasSupport: boolean | undefined;
  hasSupportJSON: boolean | undefined;
  type: "local" | "session";
  private storage: Storage;
  CACHE_PREFIX = "lscache-";
  CACHE_SUFFIX = "-cacheexpiration";
  cacheBucket = "";
  EXPIRY_RADIX = 10;
  expiryMilliseconds = 60 * 1000;
  maxDate = this.calculateMaxDate(this.expiryMilliseconds);
  warnings = false;

  constructor({
    type,
    cacheBucket,
    expiryMilliseconds,
    warnings,
  }: {
    type: "local" | "session";
    cacheBucket?: string;
    expiryMilliseconds?: number;
    warnings?: boolean;
  }) {
    this.type = type;
    if (type == "local") {
      this.storage = localStorage;
    } else {
      this.storage = sessionStorage;
    }
    if (cacheBucket) this.cacheBucket = cacheBucket;
    if (expiryMilliseconds) this.expiryMilliseconds = expiryMilliseconds;
    if (warnings != undefined) this.warnings = warnings;
  }

  private warn(message: string, err?: Error) {
    if (!this.warnings) return;
    if (!("console" in window) || typeof window.console.warn !== "function")
      return;
    window.console.warn("lscache - " + message);
    if (err) window.console.warn("lscache - The error was: " + err.message);
  }

  private isOutOfSpace(e: Error | undefined) {
    return (
      e &&
      (e.name === "QUOTA_EXCEEDED_ERR" ||
        e.name === "NS_ERROR_DOM_QUOTA_REACHED" ||
        e.name === "QuotaExceededError")
    );
  }

  private setItem(key: string, value: string) {
    // Fix for iPad issue - sometimes throws QUOTA_EXCEEDED_ERR on setItem.
    this.storage.removeItem(this.CACHE_PREFIX + this.cacheBucket + key);
    this.storage.setItem(this.CACHE_PREFIX + this.cacheBucket + key, value);
  }

  private getItem(key: string) {
    return this.storage.getItem(this.CACHE_PREFIX + this.cacheBucket + key);
  }

  private removeItem(key: string) {
    this.storage.removeItem(this.CACHE_PREFIX + this.cacheBucket + key);
  }

  private flushItem(key: string) {
    const exprKey = this.expirationKey(key);
    this.removeItem(key);
    this.removeItem(exprKey);
  }

  private flushExpiredItem(key: string) {
    const exprKey = this.expirationKey(key);
    const expr = this.getItem(exprKey);

    if (expr) {
      const expirationTime = parseInt(expr, this.EXPIRY_RADIX);

      // Check if we should actually kick item out of storage
      if (this.currentTime() >= expirationTime) {
        this.removeItem(key);
        this.removeItem(exprKey);
        return true;
      }
    }
  }

  private supportsStorage() {
    const key = "__lscachetest__";
    const value = key;

    if (this.hasSupport !== undefined) {
      return this.hasSupport;
    }

    try {
      this.setItem(key, value);
      this.removeItem(key);
      this.hasSupport = true;
    } catch (e) {
      // If we hit the limit, and we don't have an empty localStorage then it means we have support
      if (this.isOutOfSpace(e as Error) && this.storage.length) {
        this.hasSupport = true; // just maxed it out and even the set test failed.
      } else {
        this.hasSupport = false;
      }
    }
    return this.hasSupport;
  }

  private escapeRegExpSpecialCharacters(text: string) {
    return text.replace(/[[\]{}()*+?.\\^$|]/g, "\\$&");
  }

  private expirationKey(key: string) {
    return key + this.CACHE_SUFFIX;
  }

  private calculateMaxDate(expiryMilliseconds: number) {
    return Math.floor(8.64e15 / expiryMilliseconds);
  }

  private currentTime() {
    return Math.floor(new Date().getTime() / this.expiryMilliseconds);
  }

  // Determines if native JSON (de-)serialization is supported in the browser.
  private supportsJSON() {
    /*jshint eqnull:true */
    if (this.hasSupportJSON === undefined) {
      this.hasSupportJSON = JSON != null && JSON != undefined;
    }
    return this.hasSupportJSON;
  }

  private eachKey(fn: (key: string, exprKey: string) => void) {
    const prefixRegExp = new RegExp(
      "^" +
        this.CACHE_PREFIX +
        this.escapeRegExpSpecialCharacters(this.cacheBucket) +
        "(.*)"
    );
    // We first identify which keys to process
    const keysToProcess = [];
    let key, i;
    for (i = 0; i < this.storage.length; i++) {
      key = this.storage.key(i);
      key = key && key.match(prefixRegExp);
      key = key && key[1];
      if (key && key.indexOf(this.CACHE_SUFFIX) < 0) {
        keysToProcess.push(key);
      }
    }
    // Then we apply the processing function to each key
    for (i = 0; i < keysToProcess.length; i++) {
      fn(keysToProcess[i], this.expirationKey(keysToProcess[i]));
    }
  }

  public set<T>(key: string, value: T, time?: number): boolean {
    if (!this.supportsStorage()) return false;

    // If we don't get a string value, try to stringify
    // In future, localStorage may properly support storing non-strings
    // and this can be removed.
    if (!this.supportsJSON()) return false;

    let valueForStorage: string;
    try {
      valueForStorage = JSON.stringify(value);
    } catch (e) {
      // Sometimes we can't stringify due to circular refs
      // in complex objects, so we won't bother storing then.
      return false;
    }

    try {
      this.setItem(key, valueForStorage);
    } catch (e) {
      if (this.isOutOfSpace(e as Error)) {
        // If we exceeded the quota, then we will sort
        // by the expire time, and then remove the N oldest
        const storedKeys: {
          key: string;
          size: number;
          expiration: number;
        }[] = [];
        let storedKey;
        this.eachKey((key: string, exprKey: string) => {
          const expStr = this.getItem(exprKey);
          let expiration: number;
          if (expStr) {
            expiration = parseInt(expStr, this.EXPIRY_RADIX);
          } else {
            // TODO: Store date added for non-expiring items for smarter removal
            expiration = this.maxDate;
          }
          storedKeys.push({
            key: key,
            size: (this.getItem(key) || "").length,
            expiration: expiration,
          });
        });
        // Sorts the keys with oldest expiration time last
        storedKeys.sort(function (a, b) {
          return b.expiration - a.expiration;
        });

        let targetSize = (valueForStorage || "").length;
        while (storedKeys.length && targetSize > 0) {
          storedKey = storedKeys.pop();
          if (storedKey) {
            this.warn(
              "Cache is full, removing item with key '" + storedKey.key + "'"
            );
            this.flushItem(storedKey.key);
            targetSize -= storedKey.size;
          }
        }
        try {
          this.setItem(key, valueForStorage);
        } catch (e) {
          // value may be larger than total quota
          // eslint-disable-next-line no-console
          this.warn(
            "Could not add item with key '" + key + "', perhaps it's too big?",
            e as Error
          );
          return false;
        }
      } else {
        // If it was some other error, just give up.
        // eslint-disable-next-line no-console
        this.warn("Could not add item with key '" + key + "'", e as Error);
        return false;
      }
    }

    if (time) {
      this.setItem(
        this.expirationKey(key),
        (this.currentTime() + time).toString(this.EXPIRY_RADIX)
      );
    } else {
      // In case they previously set a time, remove that info from localStorage.
      this.removeItem(this.expirationKey(key));
    }
    return true;
  }

  public get<T>(key: string): T | string | null {
    if (!this.supportsStorage()) return null;

    // Return the de-serialized item if not expired
    if (this.flushExpiredItem(key)) {
      return null;
    }

    // Tries to de-serialize stored value if its an object, and returns the normal value otherwise.
    const value = this.getItem(key);
    if (!value || !this.supportsJSON()) {
      return value;
    }

    try {
      // We can't tell if its JSON or a string, so we try to parse
      return JSON.parse(value) as T;
    } catch (e) {
      // If we can't parse, it's probably because it isn't an object
      return value;
    }
  }

  public remove(key: string) {
    if (!this.supportsStorage()) return;
    this.flushItem(key);
  }

  public supported() {
    return this.supportsStorage();
  }

  public flush() {
    if (!this.supportsStorage()) return;
    this.eachKey((key) => {
      this.flushItem(key);
    });
  }

  public size() {
    if (!this.supportsStorage()) 0;
    let size = 0;
    this.eachKey((key) => {
      const d = this.get(key);
      size += new Blob([JSON.stringify(d)]).size;
    });
    return size;
  }

  public flushExpired() {
    if (!this.supportsStorage()) return;
    this.eachKey((key) => {
      this.flushExpiredItem(key);
    });
  }

  public setBucket(bucket: string) {
    this.cacheBucket = bucket;
  }

  public resetBucket() {
    this.cacheBucket = "";
  }

  public getExpiryMilliseconds() {
    return this.expiryMilliseconds;
  }

  public setExpiryMilliseconds(milliseconds: number) {
    this.expiryMilliseconds = milliseconds;
    this.maxDate = this.calculateMaxDate(this.expiryMilliseconds);
  }

  public enableWarnings(enabled: boolean) {
    this.warnings = enabled;
  }
}

export const cachePhotoUrl = new LsCache({
  type: "session",
  cacheBucket: "v1-",
});

export const cacheDados = new LsCache({
  type: "session",
  cacheBucket: "v1-",
});
