import React, { FC } from "react";
import { IAulasChamada } from "@deltasge/marauders-map";
import {
  IconButton,
  TableCell,
  TableRow as TableRowMUI,
  Tooltip,
} from "@mui/material";
import {
  PlaylistCheck as TarefaIcon,
  Pencil as EditIcon,
  CalendarAlert as DiaSemAulaIcon,
  CalendarCheck as DiaAulaIcon,
  AccountMultipleCheck as ChamadaIcon,
  Plus as AddIcon,
} from "mdi-material-ui";
import { format } from "date-fns";
import { history } from "configs";

export const TableRowAula: FC<{
  aula: IAulasChamada;
  materia: boolean;
  handleEdit: (aula: IAulasChamada) => void;
}> = ({ aula, handleEdit, materia }) => {
  return (
    <TableRowMUI>
      <TableCell sx={{ width: ({ spacing }) => spacing(1) }}>
        <Tooltip
          title={
            !aula.idDiaAulas
              ? "Aula ainda não registrada"
              : "Alterar dia de aula"
          }
        >
          {aula.idDiaAulas ? (
            <IconButton
              color={aula.gradeAtual ? "success" : "warning"}
              onClick={() => history.push(`/gerenciar-aula/${aula.idDiaAulas}`)}
            >
              <ChamadaIcon />
            </IconButton>
          ) : (
            <IconButton color={"error"}>
              <ChamadaIcon />
            </IconButton>
          )}
        </Tooltip>
      </TableCell>
      <TableCell align="right" sx={{ width: ({ spacing }) => spacing(8) }}>
        {aula.data ? format(new Date(aula.data), "dd/MM/yyyy") : ""}
      </TableCell>
      <TableCell align="center" sx={{ width: ({ spacing }) => spacing(10) }}>
        {aula.aulaDescricao}
      </TableCell>
      <TableCell align="right" sx={{ width: ({ spacing }) => spacing(4) }}>
        {aula.horaIni}
      </TableCell>
      {!materia && (
        <TableCell align="center" sx={{ width: ({ spacing }) => spacing(16) }}>
          {aula.materiaNome}
        </TableCell>
      )}
      <TableCell>
        <Tooltip
          title={`${
            aula.idDiaAulas ? "Editar a descrição do " : "Criar registro do "
          }${aula.diasemaula ? "dia sem aula" : "dia de aula"}`}
        >
          <IconButton size="small" onClick={() => handleEdit(aula)}>
            {aula.idDiaAulas && <EditIcon fontSize="small" />}
            {!aula.idDiaAulas && <AddIcon fontSize="small" color="error" />}
          </IconButton>
        </Tooltip>

        {aula.descricaoChamada}
      </TableCell>
      <TableCell sx={{ width: ({ spacing }) => spacing(1) }}>
        {aula.diasemaula && (
          <Tooltip title="Dia sem Aula">
            <DiaSemAulaIcon sx={{ verticalAlign: "middle" }} color="warning" />
          </Tooltip>
        )}
        {!aula.diasemaula && (
          <Tooltip title="Dia de aula normal">
            <DiaAulaIcon sx={{ verticalAlign: "middle" }} color="action" />
          </Tooltip>
        )}
      </TableCell>
      <TableCell align="right">
        <Tooltip
          title={!aula.tarefaChamada ? "Sem tarefa de casa" : "Tarefa de casa"}
        >
          <span>
            <IconButton
              disabled={!aula.tarefaChamada}
              color={!aula.tarefaChamada ? undefined : "primary"}
              size="small"
              onClick={() =>
                history.push(
                  `/publicacoes/detalhes/${aula.tarefaChamada}/tab/1`
                )
              }
            >
              <TarefaIcon />
            </IconButton>
          </span>
        </Tooltip>
      </TableCell>
    </TableRowMUI>
  );
};
