import React, { FC } from "react";

import { IconButton, Tooltip } from "@mui/material";
import { Delete as DeleteIcon } from "@mui/icons-material";

export const BtnDelete: FC<{
  handleClick: () => void;
}> = ({ handleClick }) => {
  return (
    <Tooltip key="btn-delete" title="Excluir mensagem">
      <IconButton color="inherit" aria-label="delete" onClick={handleClick}>
        <DeleteIcon />
      </IconButton>
    </Tooltip>
  );
};
