import { ISistemaAvaliacao } from "@deltasge/marauders-map";

import { createReducer, PayloadAction } from "@reduxjs/toolkit";

import { ActionTypes } from "./types";

interface IState {
  loading: boolean;
  error: boolean;
  data?: ISistemaAvaliacao;
}

const INITIAL_STATE: IState = {
  loading: true,
  error: false,
};

export const reducers = createReducer(INITIAL_STATE, {
  [ActionTypes.REQUEST]: () => ({
    loading: true,
    error: false,
  }),
  [ActionTypes.SUCCESS]: (state, action: PayloadAction<ISistemaAvaliacao>) => ({
    loading: false,
    error: false,
    data: action.payload,
  }),
  [ActionTypes.FAILURE]: () => ({ loading: false, error: true }),
});
