import React, { FC } from "react";
import { parseISO, formatDistanceToNow } from "date-fns";
import { ptBR } from "date-fns/locale";
import { useAppSelector } from "store";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { Cog as SettingIcon, Information as InfoIcon } from "mdi-material-ui";
import { history } from "configs";
import { useDialog } from "hooks/useDialog";

import { useSidebar } from "hooks/useSidebar";
import { ImageDev } from "./ImageDev";

export const Footer: FC = () => {
  const { escola } = useAppSelector((state) => state);
  const { versao } = useAppSelector((state) => state);
  const { toggle } = useSidebar();

  const { show, RenderDialog, hide } = useDialog({
    title: "Delta Educador",
    options: {
      modal: true,
      maxWidth: "sm",
      scroll: "paper",
    },
  });

  return (
    <>
      <RenderDialog>
        <DialogContent dividers>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item xs={6}>
              <ImageDev hideDialog={hide} />
            </Grid>
            <Grid item xs={6}>
              <Typography variant="caption" component="p">
                Versão do aplicativo: <b>{versao.app}</b>
              </Typography>
              <Typography variant="caption" component="p">
                Versão da API: <b>{versao.api}</b>
              </Typography>
              <Typography variant="caption" component="p">
                Versão do Service: <b>{versao.service}</b>
              </Typography>
              <Typography variant="caption" component="p">
                Versão da Model: <b>{versao.model}</b>
              </Typography>
              <Typography variant="caption" component="p">
                Versão do SGE 2 - Desktop: <b>{escola.verSge}</b>
              </Typography>
              <Typography variant="caption" component="p">
                Versão do Exportador do Delta SGE 2: <b>{escola.verExp}</b>
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="inherit" onClick={hide}>
            Fechar
          </Button>
        </DialogActions>
      </RenderDialog>
      <Divider />
      <Box padding={2}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Typography variant="caption" component="p">
              Última atualização:&nbsp;
              <b>
                {formatDistanceToNow(
                  parseISO(`${escola.dataPub}T${escola.horaFPub}`),
                  {
                    locale: ptBR,
                    addSuffix: true,
                  }
                )}
              </b>
            </Typography>
            <Typography variant="caption" component="p">
              Versão do aplicativo: <b>{versao.app}</b>
            </Typography>
            <Typography variant="caption" component="p">
              Versão da API: <b>{versao.api}</b>
            </Typography>
          </Grid>
          <Grid item>
            <IconButton size="medium" onClick={show}>
              <InfoIcon />
            </IconButton>
            <IconButton
              size="medium"
              onClick={() => {
                toggle();
                history.push("/configuracoes");
              }}
            >
              <SettingIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
