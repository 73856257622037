import React, { FC, useState } from "react";
import {
  Alert,
  Button,
  DialogActions,
  DialogContent,
  Grid,
  MenuItem,
  TextField,
} from "@mui/material";
import { Form } from "assets/styleds";
import { api } from "configs";
import { format } from "date-fns";
import { useApi } from "hooks/useApi";
import {
  EUserType,
  IDiaAula,
  IGrade,
  IHorarioAula,
  IHorarioSerie,
  IMateria,
} from "@deltasge/marauders-map";
import { useAppSelector } from "store";
import { snack } from "components/GlobalSnackbar";
import { IFilterAula } from "interfaces";

interface IMateriaProfessor extends IMateria {
  nomeProfessor: string;
}

export const DiaAulaCadastro: FC<{
  filtro: IFilterAula;
  close: () => void;
  carregarAulas: () => void;
}> = ({ filtro, close, carregarAulas }) => {
  const {
    escola: { anoSite },
    usuario: { usuario },
  } = useAppSelector((state) => state);

  const [aula, setAula] = useState<IHorarioAula>();
  const [materia, setMateria] = useState<IMateria>();

  const { data: aulasTurmaDia, loading: loadingAulasTurmaDia } = useApi<
    [IHorarioSerie[], number],
    IHorarioAula[]
  >({
    url: `horarioserie?idTurma=${filtro.idTurma}&diaSemana=${
      filtro.data.getDay() + 1
    }`,
    params: {
      select: ["oHorario", "oHorario.horarioAulas"],
      order: {
        diaSemana: "ASC",
      },
    },
    trataDados: (dados) => {
      if (
        dados &&
        dados[0].length > 0 &&
        dados[0][0] &&
        dados[0][0].oHorario &&
        dados[0][0].oHorario.horarioAulas.length > 0
      ) {
        return (dados[0][0].oHorario?.horarioAulas as IHorarioAula[]).sort(
          (a, b) =>
            Number(a.horaIni.replace(":", "")) -
            Number(b.horaIni.replace(":", ""))
        );
      }
      return [];
    },

    refresh: filtro.idTurma + filtro.data.toString(),
  });

  const { data: materias, loading: loadingMaterias } = useApi<
    [IGrade[], number],
    IMateriaProfessor[]
  >({
    url: `grade?idCurso=${filtro.idCurso}&idSerie=${filtro.idSerie}&materia.agrupada=false`,
    params: {
      select: ["materia", "gradeProfessor", "gradeProfessor.professor"],
      order: {
        anoLetivo: "DESC",
      },
    },
    trataDados: (grade) => {
      const materiaProfessor: IMateriaProfessor[] = [];

      if (grade[1] > 0) {
        const ordemAlfabetica = grade[0].sort(
          (a: IGrade, b: IGrade) =>
            a.materia?.nome.localeCompare(b.materia?.nome || "") || 0
        );

        ordemAlfabetica.map((g) => {
          const professores = g.gradeProfessor?.filter(
            (gp) =>
              gp.idTurma === filtro.idTurma && g.idMateria === gp.idMateria
          );

          const doProfessor =
            professores &&
            professores.filter((p) => p.professor?.id === filtro.idProfessor)
              .length > 0;

          const nomeProfessor =
            professores && professores.length > 0
              ? professores[0].professor?.nome || ""
              : "...";

          if (filtro.idProfessor && !doProfessor) return;

          materiaProfessor.push({
            ...g.materia,
            nomeProfessor: nomeProfessor.rUpper(),
          } as IMateriaProfessor);
        });
      }
      return materiaProfessor;
    },

    refresh: filtro.idTurma?.toString(),
  });

  const submitAula = async () => {
    if (
      filtro.idTurma &&
      filtro.idSerie &&
      filtro.idCurso &&
      aula &&
      materia &&
      (usuario?.tipoUsuario === EUserType.Professor ||
        usuario?.tipoUsuario === EUserType.UsuarioSistema)
    ) {
      const { data } = await api.post<IDiaAula | undefined | null>("diaaula", {
        anoLetivo: anoSite,
        data: format(filtro.data, "yyy-MM-dd HH:mm:ss"),
        aula: aula.aula,
        idCurso: filtro.idCurso,
        idSerie: filtro.idSerie,
        idTurma: filtro.idTurma,
        idMateria: materia.id,
        diaSemAula: false,
        inutilizaDia: false,
        idProfessor:
          usuario?.tipoUsuario === EUserType.Professor ? usuario?.id : null,
        idUsuario:
          usuario?.tipoUsuario === EUserType.UsuarioSistema
            ? usuario?.id
            : null,
        descricao: "",
        idMensagem: null,
        tarefa: "",
        id: null,
      });

      if (!data) {
        throw new Error("Erro ao gravar a aula");
      }
      carregarAulas();
      close();
    } else {
      if (!aula) {
        snack.error("Selecione uma aula");
      } else if (!materia) {
        snack.error("Selecione uma materia");
      } else if (usuario?.tipoUsuario === EUserType.UsuarioInterno) {
        snack.error("Você está usando um usuário inválido.");
      }
    }
  };

  return (
    <>
      <Form noValidate onSubmit={submitAula}>
        <DialogContent dividers>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
              {!loadingAulasTurmaDia &&
                aulasTurmaDia &&
                aulasTurmaDia.length > 0 && (
                  <TextField
                    label={"Aula"}
                    select
                    value={aula?.id || ""}
                    onChange={(p) => {
                      const aula = aulasTurmaDia.find(
                        (a) => a.id == Number(p.target.value)
                      );
                      if (aula) setAula(aula);
                    }}
                  >
                    {aulasTurmaDia.map((aula) => (
                      <MenuItem key={`aulas_${aula.id}`} value={aula.id}>
                        {`${aula.descricao} - ${aula.horaIni} -> ${aula.horaFim}`}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              {!loadingAulasTurmaDia &&
                (!aulasTurmaDia || aulasTurmaDia.length === 0) && (
                  <Alert severity="warning">
                    Turma sem horário na grade de aulas.
                  </Alert>
                )}
            </Grid>
            <Grid item xs={12} md={6}>
              {!loadingMaterias && materias && materias.length > 0 && (
                <TextField
                  label={"Materia"}
                  select
                  value={materia?.id || ""}
                  onChange={(p) => {
                    const selecionada = materias.find(
                      (m) => m.id == Number(p.target.value)
                    );
                    if (selecionada) setMateria(selecionada);
                  }}
                >
                  {materias.map((m) => (
                    <MenuItem key={`materias_${m.id}`} value={m.id}>
                      {`${m.nome} - ${m.nomeProfessor}`}
                    </MenuItem>
                  ))}
                </TextField>
              )}
              {!loadingMaterias && (!materias || materias.length === 0) && (
                <Alert severity="warning">
                  {filtro.idProfessor
                    ? "Professor não faz parte da grade de matérias da turma."
                    : "Indique matérias na grade da turma"}
                </Alert>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={close} color={"secondary"}>
            Cancelar
          </Button>
          <Button
            onClick={submitAula}
            disabled={!filtro.idTurma || !aula || !materia}
          >
            Salvar
          </Button>
        </DialogActions>
      </Form>
    </>
  );
};
