import React, { FC } from "react";

import {
  Drawer,
  Box,
  List,
  Card,
  CardHeader,
  IconButton,
  Avatar,
  CardContent,
  Typography,
  CardActions,
  Divider,
} from "@mui/material";
import {
  Close as DeleteIcon,
  OpenInNew as OpenIcon,
  AlertCircle as WarningIcon,
  CheckCircle as SuccessIcon,
  CloseCircle as ErrorIcon,
} from "mdi-material-ui";
import ptBr from "date-fns/locale/pt-BR";

import { useAppSelector, useAppDispatch } from "store";
import { toggleFeedback } from "store/modules/layout";
import { useMobile } from "hooks/useMobile";
import PerfectScrollbar from "react-perfect-scrollbar";

import * as styles from "assets/styles/drawer";
import { ToolbarWrapper } from "assets/styles/drawer";
import { FeedbackJob } from "@deltasge/marauders-map";
import { formatDistance, parseISO } from "date-fns";

export const RightbarFeedback: FC<{
  feedbacks: FeedbackJob[];
  setFeedbacks: (
    value: FeedbackJob[] | ((val: FeedbackJob[]) => FeedbackJob[])
  ) => void;
}> = ({ feedbacks, setFeedbacks }) => {
  const isMd = useMobile("md");
  const feedback = useAppSelector((state) => state.layout.feedback);
  const dispatch = useAppDispatch();

  const drawerVariant = isMd ? "temporary" : "persistent";

  return (
    <Drawer
      anchor="right"
      open={feedback && feedback.isOpen != undefined ? feedback.isOpen : false}
      onClose={() => dispatch(toggleFeedback())}
      sx={styles.drawer}
      variant={drawerVariant}
      ModalProps={{
        keepMounted: true,
      }}
    >
      {!isMd && <ToolbarWrapper />}
      <PerfectScrollbar>
        <Box sx={styles.container} role="presentation">
          <List dense sx={{ width: "100%" }}>
            {feedbacks.map((item, i) => (
              <React.Fragment key={`feed-${i}`}>
                <Card elevation={0} square={true}>
                  <CardHeader
                    sx={{ pt: 1, pl: 1, pr: 1, pb: 0 }}
                    subheaderTypographyProps={{
                      variant: "caption",
                    }}
                    action={
                      <IconButton
                        size="small"
                        onClick={() => {
                          setFeedbacks((prev) => [
                            ...prev.filter((f) => f.criacao != item.criacao),
                          ]);
                          if (feedbacks.length == 1) {
                            dispatch(toggleFeedback());
                          }
                        }}
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    }
                    avatar={
                      <Avatar
                        sx={(theme) => {
                          let color = theme.palette.success.light;
                          if (item.tipo == "error")
                            color = theme.palette.error.light;
                          else if (item.tipo == "warning")
                            color = theme.palette.warning.light;
                          return {
                            background: color,
                          };
                        }}
                      >
                        {item.tipo == "error" && <ErrorIcon />}
                        {item.tipo == "warning" && <WarningIcon />}
                        {item.tipo == "success" && <SuccessIcon />}
                      </Avatar>
                    }
                    title={item.titulo}
                    subheader={formatDistance(
                      parseISO(item.criacao),
                      new Date(),
                      { addSuffix: true, locale: ptBr }
                    )}
                  />
                  <CardContent sx={{ p: 1 }}>
                    <Typography variant="body2">{item.mensagem}</Typography>
                    {item.expiracao && (
                      <Typography variant="caption">
                        Disponível até{" "}
                        {formatDistance(parseISO(item.expiracao), new Date(), {
                          addSuffix: true,
                          locale: ptBr,
                        })}
                      </Typography>
                    )}
                    {item.error && (
                      <Typography variant="caption" color="error">
                        {item.error}
                      </Typography>
                    )}
                  </CardContent>
                  {(item.bufferResult || item.strResult) && (
                    <CardActions>
                      {item.typeResult == "url" && item.strResult && (
                        <IconButton
                          onClick={() => window.open(item.strResult, "_blank")}
                        >
                          <OpenIcon />
                        </IconButton>
                      )}
                    </CardActions>
                  )}
                </Card>
                <Divider />
              </React.Fragment>
            ))}
          </List>
        </Box>
      </PerfectScrollbar>
    </Drawer>
  );
};
