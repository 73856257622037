import React, { FC, useState } from "react";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Radio,
  TextField,
  Typography,
} from "@mui/material";
import { ChevronDown as ExpandMoreIcon } from "mdi-material-ui";
import { IFiltroSerie } from "@deltasge/marauders-map";

interface Props {
  selecionado?: IFiltroSerie;
  series?: IFiltroSerie[];
  onClick: (id?: number) => void;
  render: "accordion" | "autocomplete";
  mostrarTodos: boolean;
}

export const FilterSerie: FC<Props> = ({
  selecionado,
  onClick,
  series,
  render,
  mostrarTodos,
}) => {
  const [panel, setPanel] = useState(false);

  const handleClick = (id?: number) => {
    onClick(id);
    if (render == "accordion") setPanel(false);
  };

  if (render == "accordion") {
    return (
      <Accordion
        elevation={0}
        expanded={panel}
        onChange={() => setPanel(!panel)}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Grid container>
            <Grid item xs={12} component={Typography} variant="subtitle2">
              Série
            </Grid>
            <Grid item xs={12} component={Typography} variant="caption">
              {!selecionado ? "TODAS AS SÉRIES" : selecionado?.descricao}
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <List dense>
            {mostrarTodos && (
              <ListItemButton onClick={() => handleClick()}>
                <ListItemIcon>
                  <Radio checked={undefined == selecionado} />
                </ListItemIcon>
                <ListItemText primary="TODAS AS SÉRIES" />
              </ListItemButton>
            )}
            {series?.map((serie) => (
              <ListItemButton
                key={`filter-serie-${serie.id}`}
                onClick={() => handleClick(serie.id)}
              >
                <ListItemIcon>
                  <Radio checked={serie.id == selecionado?.id} />
                </ListItemIcon>
                <ListItemText
                  primary={(serie.descricao as string).rUpper()}
                  primaryTypographyProps={{
                    noWrap: true,
                  }}
                />
              </ListItemButton>
            ))}
          </List>
        </AccordionDetails>
      </Accordion>
    );
  }

  return (
    <Autocomplete
      sx={{ m: 1 }}
      disablePortal
      id="filter-serie"
      options={series ?? []}
      value={selecionado}
      renderInput={(params) => <TextField {...params} label="Série" />}
      getOptionLabel={(option) => option.descricao?.rUpper() ?? ""}
      onChange={(event, value) => handleClick(value?.id)}
      noOptionsText="Selecione um curso"
      clearText="Todos as séries"
      openText="Selecione uma série"
      renderOption={(props, option) => (
        <ListItem {...props}>
          <ListItemText
            key={option.id}
            primary={option.descricao?.rUpper()}
            primaryTypographyProps={{
              noWrap: true,
            }}
          />
        </ListItem>
      )}
    />
  );
};
