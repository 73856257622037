import React, { FC, useEffect, useState } from "react";
import { format, parseISO } from "date-fns";
import { IAluno, IPaper, IRelOptions } from "@deltasge/marauders-map";

import {
  Box,
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";

import { NumericFormat } from "react-number-format";

import { Environment, api, apiRelatorio } from "configs";
import { set as setConfigPapelPadrao } from "store/modules/relatorios/configuracoes/papel-padrao";
import { set as setModeloDiarioPadrao } from "store/modules/relatorios/diarios/modelo-padrao";
import { useAppDispatch, useAppSelector } from "store";
import * as styles from "assets/styles/form";
import { snack } from "components/GlobalSnackbar";
import { getError } from "utils";
import { LoadingButton } from "@mui/lab";

interface IFilterDiario {
  idCurso: number;
  idSerie: number;
  idTurma: number;
  idMateria: number;
  idAluno?: number;
  etapa: number;
  dataIni?: Date;
  dataFim?: Date;
  modelo?: number;
  papel?: IPaper;
  paisagem: boolean;
  opcoes: IRelOptions;
  frente: boolean;
  mostrarObservacoes: boolean;
  preencherDias: boolean;
  recuperacao: boolean;
}

export interface IParamsDiario {
  turmaMateria: {
    idCurso: number;
    idSerie: number;
    idTurma: number;
    idMateria: number;
    idAluno?: number;
  };
  close: () => void;
}

export const Diario: FC<IParamsDiario> = ({ turmaMateria, close }) => {
  const {
    tamanhoPapeis: { data: tamanhoPapeis },
    modelosDiarios: { data: modelosDiarios },
    configDiarioPadrao,
    configPapelPadrao,
    escola,
    configuracoes: { etapas },
    usuario: { usuario },
  } = useAppSelector((state) => state);

  const [diario, setDiario] = useState<IFilterDiario>({
    papel:
      tamanhoPapeis.find(
        (f) =>
          f.height == configPapelPadrao.height &&
          f.width == configPapelPadrao.width
      ) ?? tamanhoPapeis.find((f) => f.height == 210 && f.width == 297),
    modelo:
      modelosDiarios.find((f) => f.id == configDiarioPadrao?.idModelo)?.id ??
      modelosDiarios[0].id,
    idCurso: turmaMateria.idCurso,
    idSerie: turmaMateria.idSerie,
    idTurma: turmaMateria.idTurma,
    idMateria: turmaMateria.idMateria,
    idAluno: turmaMateria.idAluno,
    etapa: escola.bimestre,
    opcoes: {
      width: 297,
      height: 210,
      margin: {
        top: 10,
        bottom: 10,
        left: 10,
        right: 10,
      },
    },
    paisagem: true,
    frente: true,
    mostrarObservacoes: false,
    preencherDias: true,
    recuperacao: false,
  });
  const [errors, setErrors] = useState<{
    modelo?: string;
    dataIni?: string;
    dataFim?: string;
  }>();

  const [modeloPadrao, setModeloPadrao] = useState(false);
  const [papelPadrao, setPapelPadrao] = useState(false);
  const [gerando, setGerando] = useState(false);
  const [alunos, setAlunos] = useState<IAluno[]>([]);

  const dispatch = useAppDispatch();

  const etapasFiltradas = etapas.filter((f) =>
    f.idsCursos?.includes(turmaMateria.idCurso)
  );

  const etapa = etapasFiltradas.find((f) => f.etapa == diario.etapa);
  let dataIni: Date | undefined = diario.dataIni;
  if (!dataIni && etapa && etapa.dataEtapaIni) {
    dataIni = parseISO(etapa.dataEtapaIni);
  }
  let dataFim: Date | undefined = diario.dataFim;
  if (!dataFim && etapa && etapa.dataEtapaFim) {
    dataFim = parseISO(etapa.dataEtapaFim);
  }

  const modelo = modelosDiarios.find((f) => f.id == diario.modelo);

  const onSubmit = async () => {
    setGerando(true);
    if (!diario.modelo) {
      setErrors({ modelo: "Selecione um modelo" });
      return;
    }
    if (!dataIni) {
      setErrors({ dataIni: "Selecione a data inicial" });
      return;
    }
    if (!dataFim) {
      setErrors({ dataFim: "Selecione a data final" });
      return;
    }

    const params = {
      tipo: "porMateria",
      anoLetivo: escola.anoSite,
      idCurso: diario.idCurso,
      idSerie: diario.idSerie,
      idTurma: diario.idTurma,
      bimestre: diario.etapa,
      idMateria: diario.idMateria > 0 ? diario.idMateria : undefined,
      idAluno: diario.idAluno,
      dataIni: format(dataIni, "yyyy-MM-dd"),
      dataFim: format(dataFim, "yyyy-MM-dd"),
      recuperacao: diario.recuperacao ? 1 : 0,
      preencherDias: diario.preencherDias ? 1 : 0,
      mostrarObservacoes: diario.mostrarObservacoes ? 1 : 0,
      width: `${diario.opcoes.width}mm`,
      height: `${diario.opcoes.height}mm`,
      marginTop: `${diario.opcoes.margin.top}mm`,
      marginBottom: `${diario.opcoes.margin.bottom}mm`,
      marginLeft: `${diario.opcoes.margin.left}mm`,
      marginRight: `${diario.opcoes.margin.right}mm`,
    };

    if (modeloPadrao) {
      dispatch(
        setModeloDiarioPadrao({
          mostrarObservacoes: diario.mostrarObservacoes ? 1 : 0,
          preencherDias: diario.preencherDias ? 1 : 0,
          recuperacao: diario.recuperacao ? 1 : 0,
          idModelo: diario.modelo,
          frente: diario.frente,
        })
      );
    }

    if (papelPadrao) {
      dispatch(
        setConfigPapelPadrao({
          width: Number(diario.opcoes.width),
          height: Number(diario.opcoes.height),
          marginTop: Number(diario.opcoes.margin.top),
          marginBottom: Number(diario.opcoes.margin.bottom),
          marginLeft: Number(diario.opcoes.margin.left),
          marginRight: Number(diario.opcoes.margin.right),
        })
      );
    }

    const url = `/diario/${diario.modelo}/${
      diario.frente ? "frente" : "verso"
    }/`;

    try {
      await apiRelatorio.get(url, {
        params,
        baseURL: `${Environment.RELATORIO_API_URL}/api/v2/${usuario?.escolaId}`,
      });
      snack.info("Aguarde, o Diário de Classe será apresentado em breve");
    } catch (error) {
      snack.error(getError(error));
    }
    setErrors({});
    setGerando(false);
  };

  useEffect(() => {
    (async () => {
      if (turmaMateria.idTurma) {
        const { data } = await api.get<[IAluno[], number]>(
          "aluno/matriculados",
          {
            params: {
              ["matriculas.idTurma"]: turmaMateria.idTurma,
              select: ["nome", "uid", "nrChamada"],
              order: {
                nrChamada: "ASC",
              },
            },
          }
        );
        if (data && data[0] && Array.isArray(data[0])) setAlunos(data[0]);
      }
    })();
  }, [turmaMateria.idTurma]);

  return (
    <>
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                ...styles.fieldset,
                p: 1,
                mb: 1,
              }}
            >
              <Typography variant="subtitle1">Etapas</Typography>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    select
                    label={escola.nomeEtapa}
                    value={diario.etapa}
                    onChange={({ target: { value } }) => {
                      setDiario((prev) => ({
                        ...prev,
                        etapa: parseInt(value, 10),
                        dataFim: undefined,
                        dataIni: undefined,
                      }));
                    }}
                  >
                    {etapasFiltradas.map((etapa) => (
                      <MenuItem key={etapa.etapa} value={etapa.etapa}>
                        {etapa.etapa}º {escola.nomeEtapa}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={6}>
                  <DatePicker
                    label="Data Inicial"
                    value={dataIni}
                    onChange={(dataIni) =>
                      setDiario((prev) => ({
                        ...prev,
                        dataIni: dataIni ?? undefined,
                      }))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={!!errors?.dataIni}
                        helperText={errors?.dataIni}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <DatePicker
                    label="Data Final"
                    value={dataFim}
                    onChange={(dataFim) =>
                      setDiario((prev) => ({
                        ...prev,
                        dataFim: dataFim ?? undefined,
                      }))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={!!errors?.dataFim}
                        helperText={errors?.dataFim}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box
              sx={{
                ...styles.fieldset,
                p: 1,
              }}
            >
              <Typography variant="subtitle1">Modelos</Typography>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    select
                    label="Diários de classe"
                    value={diario.modelo ?? ""}
                    onChange={({ target: { value } }) => {
                      const modelo = modelosDiarios.find(
                        (f) => f.id === parseInt(value, 10)
                      );
                      if (modelo) {
                        setDiario((prev) => ({
                          ...prev,
                          modelo: modelo.id,
                          frente: !modelo.temVerso ? true : prev.frente,
                          mostrarObservacoes: !modelo.temVerso
                            ? false
                            : prev.mostrarObservacoes,
                        }));
                      }
                    }}
                    error={!!errors?.modelo}
                    helperText={errors?.modelo}
                  >
                    <MenuItem>Nenhum</MenuItem>
                    {modelosDiarios.map((option, index) => (
                      <MenuItem key={index} value={option.id}>
                        {option.nome}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12}>
                  <FormControl disabled={!modelo?.temVerso}>
                    <RadioGroup
                      row
                      name="frente-verso"
                      value={diario.frente}
                      onChange={({ target: { value } }) => {
                        const frente = value === "true";
                        setDiario((prev) => ({
                          ...prev,
                          frente,
                          mostrarObservacoes: frente
                            ? false
                            : prev.mostrarObservacoes,
                          preencherDias: !frente ? false : prev.preencherDias,
                          recuperacao: !frente ? false : prev.recuperacao,
                        }));
                      }}
                    >
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label="Frente"
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label="Verso"
                      />
                    </RadioGroup>
                  </FormControl>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={diario.frente}
                          checked={diario.mostrarObservacoes}
                          onChange={({ target: { checked } }) =>
                            setDiario((prev) => ({
                              ...prev,
                              mostrarObservacoes: checked,
                            }))
                          }
                          name="mostrarObservacoes"
                        />
                      }
                      label="Imprimir observações no verso"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={!diario.frente}
                          checked={diario.preencherDias}
                          onChange={({ target: { checked } }) =>
                            setDiario((prev) => ({
                              ...prev,
                              preencherDias: checked,
                            }))
                          }
                          name="preencherDias"
                        />
                      }
                      label="Preencher o diário com os dias de aula"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={!diario.frente}
                          checked={diario.recuperacao}
                          onChange={({ target: { checked } }) =>
                            setDiario((prev) => ({
                              ...prev,
                              recuperacao: checked,
                            }))
                          }
                          name="recuperacao"
                        />
                      }
                      label="Somente em recuperação"
                    />
                  </FormGroup>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                ...styles.fieldset,
                p: 1,
                mb: 1,
              }}
            >
              <Typography variant="subtitle1">Aluno</Typography>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    select
                    value={diario.idAluno ?? ""}
                    onChange={({ target: { value } }) => {
                      setDiario((prev) => ({
                        ...prev,
                        idAluno: value != "" ? parseInt(value, 10) : undefined,
                      }));
                    }}
                    SelectProps={{
                      displayEmpty: true,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  >
                    <MenuItem value="">Todos os alunos</MenuItem>
                    {alunos.map((aluno, index) => (
                      <MenuItem
                        key={`aluno-${index}`}
                        value={aluno.uid?.toString()}
                      >
                        {aluno.nome}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
            </Box>
            <Box
              sx={{
                ...styles.fieldset,
                p: 1,
              }}
            >
              <Typography variant="subtitle1">Opções</Typography>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    label="Papel"
                    select
                    value={diario.papel?.name ?? ""}
                    onChange={({ target: { value } }) => {
                      const papel = tamanhoPapeis.find((f) => f.name == value);
                      if (papel) {
                        const { opcoes } = diario;
                        opcoes.height = papel.height;
                        opcoes.width = papel.width;
                        setDiario((prev) => ({
                          ...prev,
                          opcoes,
                          papel,
                        }));
                      }
                    }}
                  >
                    <MenuItem>Nenhum</MenuItem>
                    {tamanhoPapeis.map((option, index) => (
                      <MenuItem key={index} value={option.name}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12}>
                  <FormControl disabled={diario.papel?.name == "Customizado"}>
                    <RadioGroup
                      row
                      name="orientacao"
                      value={diario.paisagem}
                      onChange={({ target: { value } }) => {
                        const { opcoes } = diario;
                        if (diario.papel) {
                          opcoes.width =
                            value === "true"
                              ? diario.papel.width
                              : diario.papel.height;
                          opcoes.height =
                            value === "true"
                              ? diario.papel.height
                              : diario.papel.width;
                        }
                        setDiario((prev) => ({
                          ...prev,
                          paisagem: value === "true",
                        }));
                      }}
                    >
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label="Paisagem"
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label="Retrato"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <NumericFormat
                    suffix=" mm"
                    label="Largura"
                    customInput={TextField}
                    value={diario.opcoes.width}
                    onValueChange={({ floatValue }) => {
                      if (floatValue) {
                        const { opcoes } = diario;
                        opcoes.width = floatValue;
                        setDiario((prev) => ({
                          ...prev,
                          opcoes,
                        }));
                      }
                    }}
                    allowNegative={false}
                    thousandSeparator={false}
                    disabled={diario.papel?.name != "Customizado"}
                  />
                </Grid>
                <Grid item xs={6}>
                  <NumericFormat
                    suffix=" mm"
                    label="Altura"
                    customInput={TextField}
                    value={diario.opcoes.height}
                    onValueChange={({ floatValue }) => {
                      if (floatValue) {
                        const { opcoes } = diario;
                        opcoes.height = floatValue;
                        setDiario((prev) => ({
                          ...prev,
                          opcoes,
                        }));
                      }
                    }}
                    allowNegative={false}
                    thousandSeparator={false}
                    disabled={diario.papel?.name != "Customizado"}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box
                    sx={{
                      ...styles.fieldset,
                      p: 1,
                      mb: 1,
                    }}
                  >
                    <Typography variant="caption">Margens</Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <NumericFormat
                          suffix=" mm"
                          label="Topo"
                          customInput={TextField}
                          value={diario.opcoes.margin.top}
                          onValueChange={({ floatValue }) => {
                            if (floatValue) {
                              const { opcoes } = diario;
                              opcoes.margin.top = floatValue;
                              setDiario((prev) => ({
                                ...prev,
                                opcoes,
                              }));
                            }
                          }}
                          allowNegative={false}
                          thousandSeparator={false}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <NumericFormat
                          suffix=" mm"
                          label="Base"
                          customInput={TextField}
                          value={diario.opcoes.margin.bottom}
                          onValueChange={({ floatValue }) => {
                            if (floatValue) {
                              const { opcoes } = diario;
                              opcoes.margin.bottom = floatValue;
                              setDiario((prev) => ({
                                ...prev,
                                opcoes,
                              }));
                            }
                          }}
                          allowNegative={false}
                          thousandSeparator={false}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <NumericFormat
                          suffix=" mm"
                          label="Esquerda"
                          customInput={TextField}
                          value={diario.opcoes.margin.left}
                          onValueChange={({ floatValue }) => {
                            if (floatValue) {
                              const { opcoes } = diario;
                              opcoes.margin.left = floatValue;
                              setDiario((prev) => ({
                                ...prev,
                                opcoes,
                              }));
                            }
                          }}
                          allowNegative={false}
                          thousandSeparator={false}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <NumericFormat
                          suffix=" mm"
                          label="Direita"
                          customInput={TextField}
                          value={diario.opcoes.margin.right}
                          onValueChange={({ floatValue }) => {
                            if (floatValue) {
                              const { opcoes } = diario;
                              opcoes.margin.right = floatValue;
                              setDiario((prev) => ({
                                ...prev,
                                opcoes,
                              }));
                            }
                          }}
                          allowNegative={false}
                          thousandSeparator={false}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <FormGroup row>
          <FormControlLabel
            control={
              <Checkbox
                value={modeloPadrao}
                onChange={({ target: { checked } }) => setModeloPadrao(checked)}
              />
            }
            label="Modelo padrão"
          />
          <FormControlLabel
            control={
              <Checkbox
                value={papelPadrao}
                onChange={({ target: { checked } }) => setPapelPadrao(checked)}
              />
            }
            label="Configuração de papel padrão"
          />
        </FormGroup>
        <Button onClick={close} color={"secondary"}>
          Cancelar
        </Button>
        <LoadingButton variant="outlined" loading={gerando} onClick={onSubmit}>
          Visualizar
        </LoadingButton>
      </DialogActions>
    </>
  );
};
