import React, { FC, useState } from "react";

import {
  Checkbox,
  IconButton,
  ListItem as ListItemMUI,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import {
  DotsVertical as MenuIcon,
  FolderStar as OcorrenciaIcon,
  Forum as ConversaIcon,
  Notebook as DiarioIcon,
} from "mdi-material-ui";
import { history } from "configs";

import { IChamada } from "../GerenciarAula";
import { useAppSelector } from "store";
import {
  EUserType,
  IDiaAula,
  IOcorrenciaPayload,
  utils,
} from "@deltasge/marauders-map";
import { snack } from "components/GlobalSnackbar";
import { useDialog } from "hooks/useDialog";
import { isAfter, parseISO } from "date-fns";
import { Avatar } from "components/Avatar";
import { CadastroOcorrencia } from "pages/Ocorrencias/Cadastro/CadastroOcorrencia";
import { Diario } from "pages/Home/components/Diario";
import { getError } from "utils";

interface IDiario {
  idCurso: number;
  idSerie: number;
  idTurma: number;
  idMateria: number;
  idAluno?: number;
}

export const ListItem: FC<{
  aluno: IChamada;
  gerarFalta: (aluno: IChamada) => Promise<void>;
  apagarFalta: (aluno: IChamada) => Promise<void>;
  divider: boolean;
  diaAula: IDiaAula;
}> = ({ aluno, divider, diaAula, gerarFalta, apagarFalta }) => {
  const {
    usuario: { usuario },
    configuracoes: {
      etapas,
      configOnline: { configuracoes },
    },
    treeView: { cursos },
  } = useAppSelector((state) => state);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [ocorrencia, setOcorrencia] = useState<IOcorrenciaPayload>();
  const [diario, setDiario] = useState<IDiario | undefined>();

  const {
    RenderDialog: OcorrenciaDialog,
    hide: hideOcorrenciaDialog,
    show: showOcorrenciaDialog,
  } = useDialog({
    title: "Criar ocorrência",
    options: {
      scroll: "paper",
    },
  });

  const {
    RenderDialog: SetorDialog,
    hide: hideSetorDialog,
    show: showSetorDialog,
  } = useDialog({
    title: "Selecione o setor",
    options: {
      maxWidth: "xs",
    },
  });

  const {
    RenderDialog: DialogDiario,
    show: showDiario,
    hide: hideDiario,
  } = useDialog({
    title: `Diário de classe - ${diaAula.turma?.tituloTurma
      .concat(" - ")
      .concat(diaAula.materia?.nome ?? "")}`,
    options: {
      modal: true,
    },
  });

  const handleDiario = (aluno: IChamada) => {
    setAnchorEl(null);
    try {
      if (!diaAula.idCurso) {
        throw new Error("ID do curso não encontrado");
      }
      if (!diaAula.idSerie) {
        throw new Error("ID do série não encontrado");
      }
      if (!diaAula.idMateria) {
        throw new Error("ID da matéria não encontrado");
      }
      if (!diaAula.idTurma) {
        throw new Error("ID da turma não encontrado");
      }
      setDiario({
        idCurso: diaAula.idCurso,
        idSerie: diaAula.idSerie,
        idMateria: diaAula.idMateria,
        idTurma: diaAula.idTurma,
        idAluno: aluno.alunoUid,
      });
      showDiario();
    } catch (error) {
      snack.error(getError(error));
    }
  };

  const handleOcorrencia = (aluno: IChamada) => {
    setAnchorEl(null);
    if (!diaAula.idTurma) {
      snack.warning("Não foi possível encontrar o ID da turma");
      return;
    }

    const etapa = utils.getEtapaPorData({
      etapas,
      data: new Date(),
      idTurma: diaAula.idTurma,
      cursos,
    });
    if (!etapa) {
      snack.warning(
        "Não foi possível encontrar a etapa do curso desta turma para a data atual"
      );
      return;
    }

    setOcorrencia({
      idCategoriaOcorrencia: null,
      anoLetivo: configuracoes.ano_site.toString(),
      restrita: false,
      historico: "",
      bimestre: etapa?.etapa,
      data: new Date(),
      alunos: [{ id: aluno.alunoUid, nome: aluno.alunoNome }],
      idTiposOcorrencia: [],
      idMateria: diaAula.idMateria ?? null,
      idTurma: diaAula.idTurma,
      idProfessor:
        usuario?.tipoUsuario == EUserType.Professor && usuario.id
          ? usuario.id
          : null,
      idUsuario:
        usuario?.tipoUsuario == EUserType.UsuarioSistema
          ? usuario?.id ?? null
          : null,
    });
    showOcorrenciaDialog();
  };

  const idSetores: { key: string; name: string }[] = [];
  if (usuario?.secretaria) {
    idSetores.push({ key: "secretaria", name: "Secretaria" });
  }
  if (usuario?.coordenacao) {
    idSetores.push({ key: "coordenacao", name: "Coordenação" });
  }
  if (usuario?.tesouraria) {
    idSetores.push({ key: "tesouraria", name: "Tesouraria" });
  }
  if (usuario?.diretoria) {
    idSetores.push({ key: "diretoria", name: "Diretoria" });
  }

  const deltaId = aluno?.deltaId ? btoa(aluno?.deltaId) : undefined;

  return (
    <>
      <DialogDiario>
        {diario && <Diario turmaMateria={diario} close={hideDiario} />}
      </DialogDiario>
      <OcorrenciaDialog>
        {ocorrencia && (
          <CadastroOcorrencia
            handleClose={hideOcorrenciaDialog}
            item={ocorrencia}
            goToList
          />
        )}
      </OcorrenciaDialog>
      <SetorDialog>
        {idSetores.map((m) => (
          <ListItemMUI key={m.key} disablePadding>
            <ListItemButton
              onClick={() => {
                hideSetorDialog();
                history.push(`/chat/setor/${m.key}/${deltaId}`);
              }}
            >
              <ListItemText primary={m.name} />
            </ListItemButton>
          </ListItemMUI>
        ))}
      </SetorDialog>
      <ListItemMUI
        key={"aluno_" + aluno.nChamada}
        divider={divider}
        disablePadding
        secondaryAction={
          <>
            <Tooltip title="Menu">
              <IconButton
                edge="end"
                aria-label="more-vert"
                onClick={({ currentTarget }) => setAnchorEl(currentTarget)}
              >
                <MenuIcon />
              </IconButton>
            </Tooltip>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={() => setAnchorEl(null)}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem onClick={() => handleOcorrencia(aluno)}>
                <ListItemIcon>
                  <OcorrenciaIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Criar ocorrência</ListItemText>
              </MenuItem>
              <MenuItem onClick={() => handleDiario(aluno)}>
                <ListItemIcon>
                  <DiarioIcon fontSize="small" />
                </ListItemIcon>
                Diário de classe
              </MenuItem>
              {aluno.podeConversar && (
                <MenuItem
                  onClick={() => {
                    setAnchorEl(null);
                    if (deltaId) {
                      if (usuario?.tipoUsuario == EUserType.Professor) {
                        history.push(`/chat/setor/professor/${deltaId}`);
                      } else if (idSetores.length > 1) {
                        showSetorDialog();
                      } else if (idSetores.length == 1) {
                        history.push(
                          `/chat/setor/${idSetores[0].key}/${deltaId}`
                        );
                      } else {
                        snack.warning(
                          "Seu usuário não tem nenhum perfil configurado"
                        );
                      }
                    }
                  }}
                >
                  <ListItemIcon>
                    <ConversaIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Iniciar uma conversa</ListItemText>
                </MenuItem>
              )}
            </Menu>
          </>
        }
      >
        <ListItemButton
          disabled={
            diaAula.diaSemAula || isAfter(parseISO(diaAula.data), Date.now())
          }
          role={undefined}
          onClick={async () => {
            if (aluno.idFalta) {
              await apagarFalta(aluno);
            } else {
              await gerarFalta(aluno);
            }
          }}
          dense
        >
          <ListItemIcon>
            <Checkbox
              edge="start"
              checked={aluno.selected}
              tabIndex={-1}
              disableRipple
              inputProps={{ "aria-labelledby": "aluno_" + aluno.nChamada }}
            />
          </ListItemIcon>
          <ListItemAvatar>
            <Avatar
              key={"avatar_" + aluno.alunoUid}
              displayName={aluno.alunoNome}
              src={aluno.photoUrl}
            />
          </ListItemAvatar>
          <ListItemText
            id={"aluno_" + aluno.nChamada}
            primary={`${aluno.nChamada} - ${aluno.alunoNome.rUpper()}`}
          />
        </ListItemButton>
      </ListItemMUI>
    </>
  );
};
