import React, { FC } from "react";

import { Button, IconButton, Toolbar, Typography } from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";

export const ToolbarCadastro: FC<{
  hide: () => void;
  title?: string;
  idForm: string;
}> = ({ hide, title, idForm }) => (
  <Toolbar>
    <IconButton edge="start" color="inherit" onClick={hide} aria-label="close">
      <CloseIcon />
    </IconButton>
    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
      {title}
    </Typography>
    <Button
      form={idForm}
      sx={{ mr: 1 }}
      name="submit"
      type="submit"
      value="rascunho"
    >
      Salvar rascunho
    </Button>
    <Button
      autoFocus
      color="primary"
      type="submit"
      form={idForm}
      variant="contained"
      disableElevation
      name="submit"
      value="submit"
    >
      Publicar
    </Button>
  </Toolbar>
);
