import React, { FC, useEffect, useState } from "react";
import {
  EUserType,
  IAvaCtr,
  IAvaliacao,
  IFiltroMateria,
  IFiltroTurma,
  IIniOnlineSGE2,
  IParamsAvaliacaoPaste,
  yupAvaliacao,
} from "@deltasge/marauders-map";

import {
  Checkbox,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Filter as FilterIcon } from "mdi-material-ui";

import { Float } from "components/Buttons/Float";
import { snack } from "components/GlobalSnackbar";
import { Pagination } from "components/Pagination";
import { api } from "configs";
import { useMobile } from "hooks/useMobile";
import { useDialog } from "hooks/useDialog";
import { Main } from "pages/_layouts/Main";
import { useAppDispatch, useAppSelector } from "store";
import { request as getCalculos } from "store/modules/calculo-avaliacoes";
import { request as getSistema } from "store/modules/sistema-avaliacao";
import { request as getCategorias } from "store/modules/categorias";
import { getError } from "utils";
import { TableRowComponent } from "./components/TableRowComponent";
import { Filter } from "./components/Filter";
import { CadastroAvaliacao } from "./Cadastro/CadastroAvaliacao";
import { parseISO } from "date-fns";
import { useConfirmDialog } from "hooks/useDialogConfirm";
import { useSessionStorage } from "react-use";
import { IFilterAvaliacao } from "interfaces";
import { useFilter } from "hooks/useFilter";
import { EmptyState } from "components/EmptyStates";

const getSubtitle = ({
  turma,
  materia,
}: {
  turma?: IFiltroTurma;
  materia?: IFiltroMateria;
}): string => {
  if (!turma) return "Nenhuma turma selecionada";
  return ` Turma ${turma.tituloTurma}`.concat(
    ` - ${materia?.nome ?? "Todas as matérias"}`
  );
};

const setDisabled = ({
  configuracoes,
  avaCtr,
  avaliacoes,
}: {
  configuracoes: IIniOnlineSGE2;
  avaCtr?: IAvaCtr;
  avaliacoes: IAvaliacao[];
}): boolean => {
  if (!avaCtr && avaliacoes.length == 0) return false;
  if (avaCtr && avaCtr.concluido) return true;

  const dataIni = parseISO(configuracoes.digi_ini.toString());
  const dataFim = parseISO(
    configuracoes.digi_fim.toString().concat(" 23:59:59")
  );
  const agora = new Date();
  return (
    agora.getTime() < dataIni.getTime() || agora.getTime() > dataFim.getTime()
  );
};

export const Avaliacao: FC = () => {
  const rowsPerPageOptions = [25, 50, 100];
  const {
    calculoAvaliacoes: { data: calculos },
    configuracoes: {
      configOnline: { configuracoes },
    },
    mensagemCategoria: { data: mensagemCategorias },
    sistemaAvaliacao: { data: sistemaAvaliacao, loading: loadingSistema },
    usuario: { usuario },
  } = useAppSelector((state) => state);

  const dispatch = useAppDispatch();

  const handleFilterCurso = (idCurso?: number) =>
    navigate({
      ...filter,
      idCurso,
      idSerie: undefined,
      idTurma: undefined,
      idMateria: undefined,
    });

  const handleFilterSerie = (idSerie?: number) =>
    navigate({
      ...filter,
      idSerie,
      idTurma: undefined,
      idMateria: undefined,
    });

  const handleFilterTurma = (idTurma?: number) =>
    navigate({
      ...filter,
      idTurma,
      idMateria: undefined,
    });

  const handleFilterMateria = (idMateria?: number) =>
    navigate({
      ...filter,
      idMateria,
    });

  const handleFilterEtapa = (etapa?: number) =>
    navigate({
      ...filter,
      etapa: etapa ?? configuracoes.bim_site,
    });

  const {
    Component,
    navigate,
    filter,
    selecionados: { materia, turma, serie, etapa },
  } = useFilter<IFilterAvaliacao>({
    pathname: "/avaliacao/",
    queryKeys: ["idCurso", "idSerie", "idTurma", "idMateria", "etapa"],
    initialValue: {
      etapa: configuracoes.bim_site,
    },
    options: {
      curso: {
        onClick: handleFilterCurso,
      },
      serie: {
        onClick: handleFilterSerie,
      },
      turma: {
        onClick: handleFilterTurma,
      },
      materia: {
        onClick: handleFilterMateria,
      },
      etapa: {
        onClick: handleFilterEtapa,
      },
    },
  });

  const [rowsPerPage, setRowsPerPage] = useState<number>(rowsPerPageOptions[0]);
  const [page, setPage] = useState<number>(0);
  const [avaliacoes, setAvaliacoes] = useState<IAvaliacao[]>([]);
  const [selecionadas, setSelecionadas] = useState<number[]>([]);
  const [copiadas, setCopiadas] = useSessionStorage<number[]>(
    "avaliacoes-copiadas",
    []
  );
  const [avaliacao, setAvaliacao] = useState<IAvaliacao>();
  const [avaCtr, setAvaCtr] = useState<IAvaCtr>();
  const [codigo, setCodigo] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [colando, setColando] = useState(false);
  const [editing, setEditing] = useState<number>();

  const isSm = useMobile("sm");
  const isMd = useMobile("md");

  const disabled = setDisabled({ configuracoes, avaCtr, avaliacoes });

  const {
    show: showCadastro,
    RenderDialog: DialogCadastro,
    hide: hideCadastro,
  } = useDialog({
    title: "Cadastro de Avaliações",
    options: {
      modal: true,
      maxWidth: "md",
      scroll: "paper",
    },
  });

  const { show: handleDelete, RenderDialog: DeleteDialog } =
    useConfirmDialog<number>({
      defaults: {
        title: "Excluir a avaliação?",
        content: "As notas dos alunos digitadas também serão removidas",
      },
      onConfirmed: async (payload, hide) => {
        try {
          await api.delete(`avaliacao/${payload}`);
          handleFilter({ page, rowsPerPage, filter });
        } catch (error) {
          snack.warning(getError(error));
        } finally {
          hide();
        }
      },
    });

  const handleFilter = async ({
    page,
    rowsPerPage,
    filter,
  }: {
    page: number;
    rowsPerPage: number;
    filter: IFilterAvaliacao;
  }) => {
    try {
      if (
        filter.idCurso &&
        filter.idSerie &&
        filter.idTurma &&
        filter.idMateria &&
        filter.etapa
      ) {
        setLoading(true);
        if (calculos.length == 0) dispatch(getCalculos());

        const { data } = await api.get<[IAvaliacao[]]>("avaliacao", {
          cache: { ignoreCache: true },
          params: {
            select: [
              "uid",
              "descricao",
              "data",
              "codigo",
              "valor",
              "calculo",
              "professor.id",
              "professor.nome",
              "usuario.id",
              "usuario.nome",
            ],
            where: {
              idTurma: filter.idTurma,
              idMateria: filter.idMateria,
              bimestre: filter.etapa,
            },
            orderBy: {
              codigo: "ASC",
            },
            take: rowsPerPage,
            skip: rowsPerPage * page,
          },
        });

        if (Array.isArray(data) && Array.isArray(data[0])) {
          setAvaliacoes(data[0]);
          setSelecionadas([]);
        }

        const { data: avaCtrs } = await api.get<[IAvaCtr[]]>(
          "controle-avaliacao",
          {
            cache: { ignoreCache: true },
            params: {
              select: ["id", "concluido", "dataConc", "recalcular"],
              where: {
                idCurso: filter.idCurso,
                idSerie: filter.idSerie,
                idTurma: filter.idTurma,
                idMateria: filter.idMateria,
                bimestre: filter.etapa,
              },
            },
          }
        );

        if (avaCtrs && Array.isArray(avaCtrs.at(0)))
          setAvaCtr(avaCtrs.at(0)?.at(0));
        setLoading(false);
      } else {
        setAvaCtr(undefined);
        setAvaliacoes([]);
      }
    } catch (error) {
      setLoading(false);
      snack.error(getError(error));
    }
  };

  useEffect(() => {
    handleFilter({ page, rowsPerPage, filter });
  }, [page, rowsPerPage, filter]);

  const handleCloseCadastro = (reload?: boolean) => {
    setAvaliacao(undefined);
    hideCadastro();
    if (reload) {
      handleFilter({
        filter,
        page,
        rowsPerPage,
      });
    }
  };

  const handleAdd = async () => {
    if (!serie) {
      snack.error("Serie não encontrada");
      return;
    }
    if (!serie.idSistemaAvaliacao) {
      snack.error("ID do sistema de avaliação não encontrado");
      return;
    }

    setAvaliacao(undefined);

    const { data } = await api.get<{ codigo?: string }>(
      `avaliacao/proximo-codigo/${filter.idTurma}/bimestre/${filter.etapa}/materia/${filter.idMateria}`,
      { cache: { ignoreCache: true } }
    );

    if (!data || !data.codigo) {
      throw new Error("Erro ao obter o código da avaliação");
    }

    setCodigo(data.codigo);

    dispatch(getSistema(serie.idSistemaAvaliacao));
    showCadastro();
  };

  const handleEdit = async (idAvaliacao: number, index: number) => {
    try {
      setEditing(index);
      const { data } = await api.get<IAvaliacao | null | undefined>(
        `avaliacao/${idAvaliacao}`,
        {
          params: {
            select: [
              ...Object.keys(yupAvaliacao.fields),
              "uid",
              "codigo",
              "anoLetivo",
              "mensagem.copiaEmail",
              "mensagem.enviarEm",
              "mensagem.excluido",
              "mensagem.exportado",
              "mensagem.id",
              "mensagem.idMensagemCategoria",
              "mensagem.idProfessor",
              "mensagem.idUsuario",
              "mensagem.permiteResposta",
              "mensagem.programarEnvio",
              "mensagem.respostaAte",
              "mensagem.respostaComAnexo",
              "mensagem.texto",
              "mensagem.mensagemAtribuicoes",
              "mensagem.mensagemLinks",
              "mensagem.mensagemArquivos.ordem",
              "mensagem.mensagemArquivos.idArquivo",
              "mensagem.mensagemArquivos.idMensagem",
              "mensagem.mensagemArquivos.arquivo.caminhoArquivo",
              "mensagem.mensagemArquivos.arquivo.hash",
              "mensagem.mensagemArquivos.arquivo.nomeArquivo",
              "mensagem.perguntasFormatadas",
              "mensagem.perguntasFormatadas.id",
              "mensagem.perguntasFormatadas.nome",
            ],
          },
        }
      );
      if (data) {
        if (!serie) {
          snack.error("Serie não encontrada");
          return;
        }
        if (!serie.idSistemaAvaliacao) {
          snack.error("ID do sistema de avaliação não encontrado");
          return;
        }
        dispatch(getSistema(serie.idSistemaAvaliacao));

        if (mensagemCategorias.length == 0) {
          dispatch(getCategorias());
        }

        setAvaliacao(data);
        showCadastro();
      }
    } catch (error) {
      snack.error(getError(error));
    } finally {
      setEditing(undefined);
    }
  };

  const handleSelectAll = (checked: boolean) => {
    if (checked) {
      setSelecionadas([]);
    } else {
      setSelecionadas(avaliacoes.map((m) => m.uid ?? 0));
    }
  };

  const handleSelect = (idAvaliacao: number) => {
    if (selecionadas.includes(idAvaliacao)) {
      setSelecionadas((p) => p.filter((f) => f != idAvaliacao));
    } else {
      setSelecionadas((p) => [...p, idAvaliacao]);
    }
  };

  const handleColar = async () => {
    try {
      if (
        !filter.idCurso ||
        !filter.idSerie ||
        !filter.idTurma ||
        !filter.idMateria
      ) {
        return;
      }

      if (loading || copiadas.length == 0) return;

      if (avaliacoes.length > 0) {
        throw new Error(
          "Cópia não permitida! Nesta turma de destino já existem avaliações."
        );
      } else if (disabled) {
        throw new Error(
          "Você não possui permissão para realizar esta operação no momento. Verifique se o sistema está liberado ou se o bimestre está aberto."
        );
      }

      setColando(true);

      const paramsPaste: IParamsAvaliacaoPaste = {
        bimestre: filter.etapa,
        idCurso: filter.idCurso,
        idSerie: filter.idSerie,
        idTurma: filter.idTurma,
        idMateria: filter.idMateria,
        idAvaliacoes: copiadas,
        idProfessor:
          usuario?.tipoUsuario == EUserType.Professor && usuario.id
            ? usuario.id
            : null,
        idUsuario:
          usuario?.tipoUsuario == EUserType.UsuarioSistema && usuario.id
            ? usuario.id
            : null,
      };

      await api.post("avaliacao/colar", paramsPaste, {
        cache: { ignoreCache: true },
      });

      setColando(false);
      setCopiadas([]);
      handleFilter({ page, rowsPerPage, filter });
    } catch (error) {
      setColando(false);
      snack.error(getError(error));
    }
  };

  return (
    <Main
      title="Avaliações"
      subtitle={getSubtitle({ turma, materia })}
      loading={loading}
      rightbarChildren={
        <Filter
          filter={filter}
          Component={Component}
          setCopiadas={setCopiadas}
          handleColar={handleColar}
          selecionadas={selecionadas}
          copiadas={copiadas}
          temAvaliacoes={avaliacoes.length > 0}
          colando={colando}
          turma={turma}
          materia={materia}
        />
      }
      rightbarIcon={<FilterIcon />}
    >
      <DeleteDialog />
      <DialogCadastro>
        {loadingSistema && <LinearProgress />}
        {sistemaAvaliacao && etapa && (
          <CadastroAvaliacao
            handleClose={handleCloseCadastro}
            item={
              avaliacao ?? {
                idCurso: filter.idCurso,
                idSerie: filter.idSerie,
                idTurma: filter.idTurma,
                idMateria: filter.idMateria,
                anoLetivo: configuracoes.ano_site.toString(),
                codigo,
              }
            }
            sistemaAvaliacao={sistemaAvaliacao}
            etapa={etapa}
          />
        )}
      </DialogCadastro>
      {avaliacoes.length > 0 && (
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    color="primary"
                    indeterminate={
                      selecionadas.length > 0 &&
                      selecionadas.length < avaliacoes.length
                    }
                    checked={
                      avaliacoes.length > 0 &&
                      selecionadas.length === avaliacoes.length
                    }
                    onChange={() =>
                      handleSelectAll(
                        avaliacoes.length > 0 &&
                          selecionadas.length === avaliacoes.length
                      )
                    }
                    inputProps={{
                      "aria-label": "selecionar todas as avaliações",
                    }}
                  />
                </TableCell>
                <TableCell>Descrição</TableCell>
                {!isSm && <TableCell align="right">Data</TableCell>}
                <TableCell align="right">Código</TableCell>
                <TableCell align="right">Valor</TableCell>
                {!isSm && <TableCell>Cálculo</TableCell>}
                {!isSm && !isMd && <TableCell>Criado por</TableCell>}
                <TableCell align="right">Ações</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {avaliacoes.map((m, index) => (
                <TableRowComponent
                  key={`avaliacao-${m.uid}`}
                  avaliacao={m}
                  selecionadas={selecionadas}
                  disabled={disabled}
                  editing={editing == index}
                  handleEdit={(id) => handleEdit(id, index)}
                  handleDelete={handleDelete}
                  handleSelect={handleSelect}
                />
              ))}
            </TableBody>
          </Table>
          <Pagination
            rowsPerPageOptions={rowsPerPageOptions}
            count={avaliacoes.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={(_, newPage: number) => setPage(newPage)}
            onChangeRowsPerPage={({ target: { value } }) => {
              setPage(0);
              setRowsPerPage(parseInt(value, 10));
            }}
          />
        </TableContainer>
      )}
      {(!filter.idCurso ||
        !filter.idSerie ||
        !filter.idTurma ||
        !filter.idMateria) &&
        !loading && (
          <EmptyState
            type="search"
            title="Selecione no filtro"
            subtitle="Selecione ao menos uma turma, matéria e uma etapa para apresentar as avaliações"
          />
        )}
      {avaliacoes.length == 0 &&
        !loading &&
        filter.idCurso &&
        filter.idSerie &&
        filter.idTurma &&
        filter.idMateria && (
          <EmptyState
            type="404"
            title="Nenhuma avaliação encontrada!"
            subtitle="O Filtro selecionado não encontrou nenhuma avaliação"
          />
        )}
      {filter.idCurso &&
        filter.idSerie &&
        filter.idTurma &&
        filter.idMateria && (
          <Float
            disabled={disabled}
            onClick={handleAdd}
            title="Adicionar avaliação"
          />
        )}
    </Main>
  );
};
