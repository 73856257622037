import React, { FC } from "react";
import { IMensagemArquivo, IMensagemLink } from "@deltasge/marauders-map";

import { Grid } from "@mui/material";
import { Anexo } from "components/Anexo";

export const DetalhesAnexos: FC<{
  mensagemArquivos: IMensagemArquivo[];
  mensagemLinks: IMensagemLink[];
}> = ({ mensagemArquivos, mensagemLinks }) => {
  return (
    <Grid container spacing={2}>
      {mensagemLinks.map((link) => (
        <Grid item key={`link-${link.id}`} xs={12} sm={6}>
          <Anexo isLink={true} url={link.url} title={link.url} />
        </Grid>
      ))}
      {mensagemArquivos.map((anexo) => {
        return (
          <Grid
            key={`anexo-${anexo.idArquivo}-${anexo.idMensagem}`}
            item
            xs={12}
            sm={6}
          >
            <Anexo
              url={anexo.arquivo?.caminhoArquivo ?? ""}
              title={anexo.arquivo?.nomeArquivo ?? ""}
              isS3File
            />
          </Grid>
        );
      })}
    </Grid>
  );
};
