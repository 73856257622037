import React, { FC } from "react";

import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material";

export const UserTypeListItem: FC<{
  icon: React.ReactNode;
  handleClick: () => void;
  subtitle?: string;
  title: string;
}> = ({ icon, handleClick, subtitle, title }) => (
  <ListItemButton onClick={handleClick}>
    <ListItemIcon>{icon}</ListItemIcon>
    <ListItemText primary={title} secondary={subtitle} />
  </ListItemButton>
);
