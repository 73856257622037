import React, { FC, useState } from "react";
import { format, set } from "date-fns";
import { EUserType, IUser } from "@deltasge/marauders-map";
import PerfectScrollbar from "react-perfect-scrollbar";

import {
  Alert,
  Autocomplete,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  FormControlLabel,
  List,
  Radio,
  RadioGroup,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  TextField,
} from "@mui/material";

import {
  EmojiPeopleOutlined as AlunoIcon,
  Group as ClienteIcon,
  AccountCircle as UsuarioIcon,
  AccountBox as FuncionarioIcon,
} from "@mui/icons-material";
import { AccountSupervisor as LoginExtraIcon } from "mdi-material-ui";

import { snack } from "components/GlobalSnackbar";
import { apiConfig, history } from "configs";
import { useAppSelector } from "store";
import { getError, loadS3File } from "utils";

import { UserTypeListItem } from "./UserTypeListItem";
import { UserListItem } from "./UserListItem";

export const NovaConversa: FC<{
  handleClose: () => void;
  idSetores: string[];
}> = ({ handleClose, idSetores }) => {
  const {
    usuario: { usuario: usuarioLogado },
  } = useAppSelector((state) => state);

  const [activeStep, setActiveStep] = useState(0);
  const [tipoUsuario, setTipoUsuario] = useState<EUserType>();
  const [loading, setLoading] = useState(false);
  const [usuarios, setUsuarios] = useState<IUser[]>([]);
  const [usuario, setUsuario] = useState<IUser | null>(null);
  const [idSetor, setIdSetor] = useState<string | undefined>(
    usuarioLogado?.tipoUsuario == EUserType.Professor ? "professor" : undefined
  );

  const handleEnviarPara = async (tu: EUserType) => {
    setLoading(true);
    setTipoUsuario(tu);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setUsuario(null);
    try {
      const updatedAt = format(
        set(new Date(), {
          month: 0,
          date: 1,
          hours: 0,
          minutes: 0,
          seconds: 0,
          milliseconds: 0,
        }),
        "yyyy-MM-dd'T'HH:mm:ss.SSS"
      );
      const { data } = await apiConfig.get<IUser[]>("v2/usuarios", {
        params: {
          where: {
            escolaId: usuarioLogado?.escolaId,
            updatedAt: { $gte: updatedAt },
            tipoUsuario: tu,
          },
          orderBy: {
            nome: "ASC",
            displayName: "ASC",
          },
          select: [
            "deltaId",
            "photoUrl",
            "photoS3",
            "nome",
            "displayName",
            "email",
            "firebaseEmail",
            "codigo",
          ],
          limit: 5000,
        },
      });
      if (data && data.length > 0) {
        const users = data.filter((f) => f.deltaId != usuarioLogado?.deltaId);
        setUsuarios(
          await Promise.all(
            users.map(async (m) => {
              if (m.photoS3 && m.photoUrl) {
                const { processed } = await loadS3File(m.photoUrl, true);
                m.photoUrl = processed;
              }
              return m;
            })
          )
        );
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      snack.error(getError(error));
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleChat = () => {
    handleClose();
    if (usuario?.deltaId) {
      const deltaId = btoa(usuario?.deltaId);
      history.push(`/chat/setor/${idSetor}/${deltaId}`);
    }
  };

  let label = "";
  if (tipoUsuario == EUserType.Aluno) {
    label = "Aluno";
  } else if (tipoUsuario == EUserType.Responsavel) {
    label = "Responsável";
  } else if (tipoUsuario == EUserType.Professor) {
    label = "Professor";
  } else if (tipoUsuario == EUserType.LoginExtra) {
    label = "Login Extra do Aluno";
  } else if (tipoUsuario == EUserType.UsuarioSistema) {
    label = "Usuário do sistema";
  }

  return (
    <>
      <PerfectScrollbar>
        <DialogContent dividers style={{ paddingBottom: 0 }}>
          <Stepper activeStep={activeStep} orientation="vertical">
            {usuarioLogado?.tipoUsuario != EUserType.Professor && (
              <Step>
                <StepLabel>Enviar como</StepLabel>
                <StepContent>
                  <RadioGroup
                    row
                    name="idSetor"
                    value={idSetor ?? ""}
                    onChange={({
                      target: { value },
                    }: React.ChangeEvent<HTMLInputElement>) => {
                      setIdSetor(value);
                      setActiveStep((prevActiveStep) => prevActiveStep + 1);
                    }}
                  >
                    {idSetores.includes("secretaria") && (
                      <FormControlLabel
                        value="secretaria"
                        control={<Radio />}
                        label="Secretaria"
                      />
                    )}
                    {idSetores.includes("coordenacao") && (
                      <FormControlLabel
                        value="coordenacao"
                        control={<Radio />}
                        label="Coordenação"
                      />
                    )}
                    {idSetores.includes("tesouraria") && (
                      <FormControlLabel
                        value="tesouraria"
                        control={<Radio />}
                        label="Tesouraria"
                      />
                    )}
                    {idSetores.includes("diretoria") && (
                      <FormControlLabel
                        value="diretoria"
                        control={<Radio />}
                        label="Diretoria"
                      />
                    )}
                  </RadioGroup>
                  {idSetor && (
                    <Button
                      size="small"
                      onClick={() =>
                        setActiveStep((prevActiveStep) => prevActiveStep + 1)
                      }
                    >
                      Próximo
                    </Button>
                  )}
                </StepContent>
              </Step>
            )}
            <Step>
              <StepLabel>Enviar para</StepLabel>
              <StepContent>
                <List>
                  <UserTypeListItem
                    title="Alunos"
                    subtitle="Alunos autenticados no DeltaClass 2"
                    icon={<AlunoIcon />}
                    handleClick={() => handleEnviarPara(EUserType.Aluno)}
                  />
                  <UserTypeListItem
                    title="Responsáveis"
                    subtitle="Responsáveis autenticados no DeltaClass 2"
                    icon={<ClienteIcon />}
                    handleClick={() => handleEnviarPara(EUserType.Responsavel)}
                  />
                  <UserTypeListItem
                    title="Logins Extra do aluno"
                    subtitle="Logins Extras autenticados no DeltaClass 2"
                    icon={<LoginExtraIcon />}
                    handleClick={() => handleEnviarPara(EUserType.LoginExtra)}
                  />
                  <UserTypeListItem
                    title="Professores"
                    subtitle="Professores autenticados no Delta Educador"
                    icon={<FuncionarioIcon />}
                    handleClick={() => handleEnviarPara(EUserType.Professor)}
                  />
                  <UserTypeListItem
                    title="Usuários do sistema"
                    subtitle="Usuários autenticados no Delta SGE ou Delta Educador"
                    icon={<UsuarioIcon />}
                    handleClick={() =>
                      handleEnviarPara(EUserType.UsuarioSistema)
                    }
                  />
                </List>
              </StepContent>
            </Step>
            <Step>
              <StepLabel>{"Conversar com um ".concat(label)}</StepLabel>
              <StepContent
                sx={{
                  pb: 2,
                }}
              >
                {loading && <CircularProgress />}
                {!loading && usuarios.length == 0 && (
                  <Alert
                    severity="info"
                    title="Ops!"
                  >{`Nenhum ${label} encontrado`}</Alert>
                )}
                {!loading && usuarios.length > 0 && (
                  <Autocomplete<IUser>
                    options={usuarios}
                    getOptionLabel={(option) =>
                      `${option.codigo} - ${option.displayName ?? option.nome}`
                    }
                    renderOption={(props, option) => (
                      <UserListItem user={option} props={props} />
                    )}
                    value={usuario}
                    onChange={(event, newValue) => setUsuario(newValue)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={`Escolha um ${label}`}
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password",
                        }}
                      />
                    )}
                  />
                )}
              </StepContent>
            </Step>
          </Stepper>
        </DialogContent>
      </PerfectScrollbar>
      <DialogActions>
        <Button
          color="secondary"
          onClick={handleBack}
          disabled={activeStep == 0}
        >
          Voltar
        </Button>
        <Button color="primary" onClick={handleChat} disabled={usuario == null}>
          Conversar
        </Button>
      </DialogActions>
    </>
  );
};
