import { AxiosInstance, AxiosResponse } from "axios";
import { api } from "configs";
import { useEffect, useState } from "react";

export const useApi = <T1, T2 = T1>({
  url,
  params = {},
  refresh = null,
  trataDados,
  apiDestino = api,
  onError = (error: any) => {
    // eslint-disable-next-line no-console
    console.log("useApiOne=>", error);
  },
}: {
  url: string | null;
  params?: any;
  refresh?: string | null;
  trataDados?: (dados: T1) => T2;
  apiDestino?: AxiosInstance;
  onError?: (error: any) => void;
}): {
  data: T2 | null;
  loading: boolean;
  error: any;
  carregar: () => Promise<void>;
} => {
  const [data, setData] = useState<T2 | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const carregar = async () => {
    setLoading(true);
    setError(null);

    if (url) {
      apiDestino
        .get<AxiosResponse<T1>>(url, {
          params,
          cache: { ignoreCache: true },
        })
        .then((res) => {
          if (trataDados) setData(trataDados(res.data as unknown as T1));
          else setData(res.data as unknown as T2);
          setLoading(false);
        })
        .catch((error) => {
          setError(error);
          onError(error);
          setLoading(false);
        });
    } else {
      setData(null);
      setLoading(false);
    }
  };

  useEffect(() => {
    carregar();
  }, [url, refresh]);

  return { data, loading, error, carregar };
};
