import React, { FC } from "react";
import { IMensagemAtribuicao } from "@deltasge/marauders-map";
import { Tab } from "@mui/material";
import { TabList } from "@mui/lab";

import { history } from "configs";
import { utilMensagem as util } from "../../utilMensagem";

export const Tabs: FC<{
  mensagemAtribuicoes: IMensagemAtribuicao[];
  tab: string;
  idMensagem: number;
}> = ({ mensagemAtribuicoes, tab, idMensagem }) => {
  return (
    <TabList
      value={tab}
      onChange={(_, tab) => {
        history.push(`/publicacoes/detalhes/${idMensagem}/tab/${tab}`);
        // setTab(tab);
      }}
      aria-label="Tabs"
      textColor="inherit"
      indicatorColor="secondary"
    >
      <Tab label="Detalhes" value="1" />
      {util.showTabDetalhesMensagem({
        atr: mensagemAtribuicoes,
        tipo: "aluno",
      }) && <Tab label="Alunos" value="2" />}
      {util.showTabDetalhesMensagem({
        atr: mensagemAtribuicoes,
        tipo: "responsavel",
      }) && <Tab label="Responsáveis" value="3" />}
      {util.showTabDetalhesMensagem({
        atr: mensagemAtribuicoes,
        tipo: "loginExtra",
      }) && <Tab label="Logins do Aluno" value="4" />}
      {util.showTabDetalhesMensagem({
        atr: mensagemAtribuicoes,
        tipo: "professor",
      }) && <Tab label="Professores" value="5" />}
    </TabList>
  );
};
