import { IMensagemCategoria } from "@deltasge/marauders-map";

import { createReducer, PayloadAction } from "@reduxjs/toolkit";

import { ActionTypes } from "./types";

interface IState {
  loading: boolean;
  error: boolean;
  data: IMensagemCategoria[];
}

const INITIAL_STATE: IState = {
  loading: true,
  error: false,
  data: [],
};

export const reducers = createReducer(INITIAL_STATE, {
  [ActionTypes.REQUEST]: () => ({ loading: true, error: false, data: [] }),
  [ActionTypes.SUCCESS]: (
    state,
    action: PayloadAction<IMensagemCategoria[]>
  ) => ({ loading: false, error: false, data: action.payload }),
  [ActionTypes.FAILURE]: () => ({ loading: false, error: true, data: [] }),
  [ActionTypes.SET]: (state, action: PayloadAction<IMensagemCategoria[]>) => ({
    loading: false,
    error: false,
    data: action.payload,
  }),
});
