import React, { FC, useEffect, useState } from "react";
import { parseISO, set } from "date-fns";
import Axios from "axios";

import {
  EUserType,
  IAluno,
  IFiltroMateria,
  IFiltroTurma,
  IMatricula,
  IOcorrencia,
  IOcorrenciaPayload,
  ITurma,
  yupOcorrencia,
} from "@deltasge/marauders-map";

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Filter as FilterIcon } from "mdi-material-ui";

import { EmptyState } from "components/EmptyStates";
import { Float } from "components/Buttons/Float";
import { snack } from "components/GlobalSnackbar";
import { Pagination } from "components/Pagination";
import { api } from "configs";
import { useConfirmDialog } from "hooks/useDialogConfirm";
import { useDialog } from "hooks/useDialog";
import { useMobile } from "hooks/useMobile";
import { useFilter } from "hooks/useFilter";
import { IFilterOcorrencia } from "interfaces";
import { Main } from "pages/_layouts/Main";
import { getError } from "utils";
import { useAppSelector } from "store";

import { Alunos } from "./components/Alunos";
import { Filter } from "./components/Filter";
import { ListItem } from "./components/ListItem";
import { CadastroOcorrencia } from "./Cadastro/CadastroOcorrencia";

const getSubtitle = ({
  turma,
  materia,
}: {
  turma?: IFiltroTurma;
  materia?: IFiltroMateria;
}): string => {
  if (!turma) return "Nenhuma turma selecionada";
  return ` Turma ${turma.tituloTurma}`.concat(
    ` - ${materia?.nome ?? "Todas as matérias"}`
  );
};

export const Ocorrencias: FC = () => {
  const CancelToken = Axios.CancelToken;
  const source = CancelToken.source();
  const rowsPerPageOptions = [25, 50, 100];

  const {
    usuario: { usuario },
    escola,
    configuracoes: {
      configOnline: { configuracoes },
    },
  } = useAppSelector((state) => state);

  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(rowsPerPageOptions[0]);
  const [loading, setLoading] = useState(false);
  const [ocorrencias, setOcorrencias] = useState<[IOcorrencia[], number]>([
    [],
    0,
  ]);
  const [ocorrencia, setOcorrencia] = useState<IOcorrenciaPayload>();

  const handleFilterCurso = (idCurso?: number) =>
    navigate({
      ...filter,
      idCurso,
      idSerie: undefined,
      idTurma: undefined,
      idMateria: undefined,
    });

  const handleFilterSerie = (idSerie?: number) =>
    navigate({
      ...filter,
      idSerie,
      idTurma: undefined,
      idMateria: undefined,
    });

  const handleFilterTurma = (idTurma?: number) =>
    navigate({
      ...filter,
      idTurma,
    });

  const handleFilterMateria = (idMateria?: number) =>
    navigate({
      ...filter,
      idMateria,
    });

  const handleFilterDataIni = (dataIni?: Date) =>
    navigate({
      ...filter,
      dataIni,
    });

  const handleFilterDataFim = (dataFim?: Date) =>
    navigate({
      ...filter,
      dataFim,
    });

  const {
    Component,
    navigate,
    filter,
    selecionados: { turma, materia },
  } = useFilter<IFilterOcorrencia>({
    pathname: "/ocorrencias/",
    queryKeys: [
      "idCurso",
      "idSerie",
      "idTurma",
      "idMateria",
      "dataFim",
      "dataIni",
      "idAluno",
      "idUsuario",
      "nomeUsuario",
    ],
    options: {
      curso: {
        onClick: handleFilterCurso,
        mostrarTodos: true,
      },
      serie: {
        onClick: handleFilterSerie,
        mostrarTodos: true,
      },
      turma: {
        onClick: handleFilterTurma,
        mostrarTodos: true,
      },
      materia: {
        onClick: handleFilterMateria,
        mostrarTodos: true,
      },
      dataIni: {
        onClick: handleFilterDataIni,
      },
      dataFim: {
        onClick: handleFilterDataFim,
      },
    },
  });

  const [matriculas, setMatriculas] = useState<IMatricula[]>([]);
  const isSm = useMobile("sm");
  const isMd = useMobile("md");
  const {
    show: showCadastro,
    RenderDialog: DialogCadastro,
    hide: hideCadastro,
  } = useDialog({
    title: "Cadastro de Ocorrências",
    options: {
      modal: true,
      maxWidth: "md",
      scroll: "paper",
    },
  });

  const {
    show: showAlunos,
    RenderDialog: DialogAlunos,
    hide: hideAlunos,
  } = useDialog({
    title: "Escolha o(s) aluno(s)",
    options: {
      maxWidth: "sm",
      scroll: "paper",
    },
  });

  const { show: showDeleteDialog, RenderDialog: DeleteDialog } =
    useConfirmDialog<number>({
      defaults: {
        title: "Excluir a ocorrência?",
      },
      onConfirmed: async (payload, hide) => {
        try {
          await api.delete(`ocorrencia/${payload}`);
          handleFilter();
        } catch (error) {
          snack.warning((error as Error).toString());
        } finally {
          hide();
        }
      },
    });
  const handleFilter = async () => {
    try {
      if (filter.idTurma) {
        setLoading(true);
        const where: Record<string, unknown> = {
          anoLetivo: escola.anoSite,
        };
        if (filter.idMateria) {
          where.idMateria = filter.idMateria;
        }
        if (usuario?.tipoUsuario == EUserType.Professor) {
          where.idProfessor = usuario.id;
          if (filter.idMateria) {
            delete where.idProfessor;
            where.idMateria = filter.idMateria;
          }
        }
        if (filter.idAluno) {
          where.idAluno = filter.idAluno;
        }

        if (filter.dataIni && filter.dataFim) {
          where["data.between"] = [filter.dataIni, filter.dataFim];
        }
        where["aluno.matriculas.idTurma"] = filter.idTurma;

        const params = {
          select: [
            "id",
            "tipoOcorrencia.nome",
            "aluno.nome",
            "createdAt",
            "historico",
            "data",
            "hora",
            "restrita",
            "ondeVisualizou",
            "dataVisualizacao",
            "materia.id",
            "materia.nome",
            "professor.nome",
            "usuario.nome",
          ],
          order: {
            createdAt: "DESC",
          },
          where,
          take: rowsPerPage,
          skip: rowsPerPage * page,
        };
        const { data } = await api.get<[IOcorrencia[], number]>("ocorrencia", {
          cancelToken: source.token,
          params,
          cache: { ignoreCache: true },
        });
        if (Array.isArray(data) && Array.isArray(data[0])) {
          setOcorrencias(data);
        }
        setLoading(false);
      } else {
        setOcorrencias([[], 0]);
      }
    } catch (err) {
      setLoading(false);
      if (!Axios.isCancel(err)) {
        snack.warning(getError(err));
      }
    }
  };

  const getAlunos = async () => {
    if (filter.idTurma) {
      try {
        setLoading(true);
        const { data } = await api.get<ITurma | undefined>(
          `turma/${filter.idTurma}`,
          {
            cache: {
              ignoreCache: true,
            },
            params: {
              select: [
                "id",
                "matriculas.id",
                "matriculas.nrChamada",
                "matriculas.situacao",
                "matriculas.aluno.nome",
                "matriculas.aluno.uid",
                "matriculas.aluno.codigo",
                "matriculas.aluno.imagemAluno.caminhoArquivo",
              ],
              order: {
                matriculas: { nrChamada: "ASC" },
              },
            },
          }
        );
        if (data && Array.isArray(data.matriculas)) {
          setMatriculas(data.matriculas);
        }
        setLoading(false);
      } catch (error) {
        snack.error(getError(error));
        setLoading(false);
      }
    }
  };

  const onChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const onChangeRowsPerPage = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setPage(0);
    setRowsPerPage(parseInt(value, 10));
  };

  const handleEdit = async (id: number) => {
    try {
      const { data } = await api.get<IOcorrencia | undefined>(
        `ocorrencia/${id}`,
        {
          params: {
            select: [
              "id",
              ...Object.keys(yupOcorrencia.fields),
              "aluno.uid",
              "aluno.nome",
            ],
          },
        }
      );
      if (data && data.aluno?.uid && data.bimestre && filter.idTurma) {
        let dData = parseISO(data.data);
        if (data.hora && data.hora.trim().split(":").length > 1) {
          dData = set(dData, {
            hours: parseInt(data.hora.trim().split(":")[0], 10),
            minutes: parseInt(data.hora.trim().split(":")[1], 10),
          });
        }

        setOcorrencia({
          ...data,
          alunos: [{ id: data.aluno?.uid, nome: data.aluno.nome }],
          data: dData,
          idCategoriaOcorrencia: null,
          idProfessor:
            usuario?.tipoUsuario == EUserType.Professor
              ? usuario?.id ?? null
              : null,
          idUsuario:
            usuario?.tipoUsuario == EUserType.UsuarioSistema
              ? usuario?.id ?? null
              : null,
          idTiposOcorrencia: [data.idTipoOcorrencia],
          idTurma: filter.idTurma,
          bimestre: data.bimestre,
        });
        showCadastro();
      }
    } catch (error) {
      snack.error(getError(error));
    }
  };

  const handleCloseCadastro = (reload?: boolean) => {
    setOcorrencia(undefined);
    hideCadastro();
    if (reload) handleFilter();
  };

  const handleCloseAlunos = (alunos?: IAluno[]) => {
    hideAlunos();
    if (alunos && alunos.length > 0 && filter.idTurma) {
      setOcorrencia({
        anoLetivo: configuracoes.ano_site.toString(),
        alunos: alunos.map((m) => ({ id: m.uid ?? 0, nome: m.nome })),
        data: new Date(),
        idCategoriaOcorrencia: null,
        idProfessor:
          usuario?.tipoUsuario == EUserType.Professor
            ? usuario?.id ?? null
            : null,
        idUsuario:
          usuario?.tipoUsuario == EUserType.UsuarioSistema
            ? usuario?.id ?? null
            : null,
        idTiposOcorrencia: [],
        idTurma: filter.idTurma,
        bimestre: configuracoes.bim_site,
        idMateria: null,
        historico: "",
        restrita: false,
      });
      showCadastro();
    }
  };

  useEffect(() => {
    handleFilter();
  }, [
    filter.idTurma,
    filter.idMateria,
    filter.dataIni,
    filter.dataFim,
    filter.idAluno,
    filter.idUsuario,
    page,
    rowsPerPage,
  ]);

  useEffect(() => {
    getAlunos();
  }, [filter.idTurma]);
  return (
    <Main
      title="Ocorrências"
      subtitle={getSubtitle({ turma, materia })}
      loading={loading}
      rightbarChildren={
        <Filter
          matriculas={matriculas}
          filter={filter}
          navigate={navigate}
          Component={Component}
        />
      }
      rightbarIcon={<FilterIcon />}
    >
      <DeleteDialog />
      <DialogAlunos>
        <Alunos handleClose={handleCloseAlunos} matriculas={matriculas} />
      </DialogAlunos>
      <DialogCadastro>
        {ocorrencia && (
          <CadastroOcorrencia
            handleClose={handleCloseCadastro}
            item={ocorrencia}
          />
        )}
      </DialogCadastro>
      {ocorrencias[0].length > 0 && (
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Ocorrência</TableCell>
                <TableCell>Aluno</TableCell>
                <TableCell>Criado Por</TableCell>
                {!isSm && !isMd && <TableCell>Matéria</TableCell>}
                {!isSm && <TableCell align="right">Data</TableCell>}
                {!isSm && !isMd && (
                  <TableCell align="right">Imprimir</TableCell>
                )}
                <TableCell align="right">Ações</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {ocorrencias[0].map((item, i) => (
                <ListItem
                  key={`ocorrencia-${i}`}
                  item={item}
                  handleDelete={showDeleteDialog}
                  handleEdit={handleEdit}
                />
              ))}
            </TableBody>
          </Table>
          <Pagination
            rowsPerPageOptions={rowsPerPageOptions}
            count={ocorrencias[1]}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={onChangePage}
            onChangeRowsPerPage={onChangeRowsPerPage}
          />
        </TableContainer>
      )}
      {!loading && ocorrencias[0].length === 0 && filter.idTurma && (
        <EmptyState
          type="404"
          title="Nenhuma ocorrência encontrada!"
          subtitle="O Filtro selecionado não encontrou nenhuma ocorrência"
        />
      )}
      {filter.idTurma && (
        <Float onClick={showAlunos} title="Adicionar ocorrência" />
      )}
      {!filter.idTurma && !loading && (
        <EmptyState
          type="search"
          title="Selecione no filtro"
          subtitle="Selecione ao menos uma turma para apresentar as ocorrências"
        />
      )}
    </Main>
  );
};
