import React, { FC, useState, useEffect } from "react";
import Axios from "axios";
import { useParams } from "react-router-dom";
import { IMensagem, IDestinatarioMensagem } from "@deltasge/marauders-map";

import { Box } from "@mui/material";
import { TabContext, TabPanel } from "@mui/lab";
import { Filter as FilterIcon } from "mdi-material-ui";

import { api, history } from "configs";
import { snack } from "components/GlobalSnackbar";
import { useMobile } from "hooks/useMobile";
import { useConfirmDialog } from "hooks/useDialogConfirm";
import { Main } from "pages/_layouts/Main";
import { utilMensagem as util } from "../utilMensagem";
import { TabDetalhes } from "./components/TabDetalhes";
import { TabAlunos } from "./components/TabAlunos";
import { Tabs } from "./components/Tabs";
import { TabResponsaveis } from "./components/TabResponsaveis";
import { Filter } from "./components/Filter";
import { TabProfessores } from "./components/TabProfessores";
import { TabLoginExtra } from "./components/TabLoginExtra";
import { BtnImprimir } from "./components/BtnImprimir";
import { BtnDelete } from "./components/BtnDelete";
import { useAppSelector } from "store";

export interface IFilter {
  pergunta: number;
  devolvido?: boolean;
}

export const DetalhesMensagem: FC = () => {
  const { idMensagem, tab, destinatario } = useParams<{
    idMensagem: string;
    tab: string;
    destinatario?: string;
  }>();

  const {
    mensagemCategoria: { data: categorias },
  } = useAppSelector((state) => state);

  const CancelToken = Axios.CancelToken;
  const source = CancelToken.source();

  const [hasError, setHasError] = useState(false);
  const [mensagem, setMensagem] = useState<IMensagem | undefined>();
  const [alunos, setAlunos] = useState<IDestinatarioMensagem[]>([]);
  const [responsaveis, setResponsaveis] = useState<IDestinatarioMensagem[]>([]);
  const [professores, setProfessores] = useState<IDestinatarioMensagem[]>([]);
  const [loginExtras, setLoginExtras] = useState<IDestinatarioMensagem[]>([]);
  const [filter, setFilter] = useState<IFilter>({
    pergunta: 0,
  });

  const isSm = useMobile("sm");

  const { show: showDeleteDialog, RenderDialog: DeleteDialog } =
    useConfirmDialog<number>({
      defaults: {
        title: "Excluir a mensagem?",
        content:
          "Os anexos e as respostas também serão excluidos. Esta ação não pode ser revertida!!!",
      },
      onConfirmed: async (payload, hide) => {
        try {
          await api.delete(`educador/mensagem/${payload}`);
          history.push(`/mensagens`);
        } catch (error) {
          snack.warning((error as Error).toString());
        } finally {
          hide();
        }
      },
    });

  useEffect(() => {
    const getMensagem = async (id: number) => {
      const msg = await util.getMensagem({
        idMensagem: id,
        cancelToken: source.token,
        para: "detalhes",
      });
      if (msg) {
        msg.mensagemCategoria = categorias.find(
          (f) => f.id == msg.idMensagemCategoria
        );
        setMensagem(msg);
      } else {
        setHasError(true);
      }
    };
    if (idMensagem && !mensagem) {
      getMensagem(parseInt(idMensagem, 10));
    }
    return () => source.cancel();
  }, []);

  const buttons: React.ReactNode[] = [];
  if (
    mensagem &&
    mensagem.permiteResposta &&
    mensagem.perguntasFormatadas &&
    mensagem.perguntasFormatadas.length > 0
  ) {
    buttons.push(<BtnImprimir mensagem={mensagem} />);
  }
  if (tab == "1" && idMensagem) {
    buttons.push(
      <BtnDelete
        handleClick={() => {
          showDeleteDialog(parseInt(idMensagem, 10));
        }}
      />
    );
  }

  return (
    <TabContext value={tab}>
      <DeleteDialog />
      <Main
        maxWidth={false}
        title="Detalhes da publicação"
        loading={!mensagem && !hasError}
        useMargin={tab == "1"}
        buttons={buttons}
        searchElement={
          isSm
            ? undefined
            : mensagem?.mensagemAtribuicoes && (
                <Tabs
                  tab={tab}
                  mensagemAtribuicoes={mensagem?.mensagemAtribuicoes}
                  idMensagem={mensagem?.id ?? 0}
                />
              )
        }
        rightbarChildren={
          tab != "1" && !!mensagem && !destinatario ? (
            <Filter mensagem={mensagem} filter={filter} setFilter={setFilter} />
          ) : undefined
        }
        rightbarIcon={<FilterIcon />}
      >
        {!hasError && mensagem && mensagem.mensagemAtribuicoes && (
          <Box height="100%">
            {isSm && (
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  tab={tab}
                  mensagemAtribuicoes={mensagem?.mensagemAtribuicoes}
                  idMensagem={mensagem?.id ?? 0}
                />
              </Box>
            )}
            <TabPanel value="1" sx={{ padding: 0 }}>
              <TabDetalhes mensagem={mensagem} />
            </TabPanel>
            {util.showTabDetalhesMensagem({
              atr: mensagem.mensagemAtribuicoes,
              tipo: "aluno",
            }) && (
              <TabPanel value="2" sx={{ padding: 0, height: "100%" }}>
                <TabAlunos
                  mensagemAtribuicoes={mensagem.mensagemAtribuicoes}
                  source={source}
                  setAlunos={setAlunos}
                  alunos={alunos}
                  mensagem={mensagem}
                  filter={filter}
                />
              </TabPanel>
            )}
            {util.showTabDetalhesMensagem({
              atr: mensagem.mensagemAtribuicoes,
              tipo: "responsavel",
            }) && (
              <TabPanel
                value="3"
                sx={{
                  padding: 0,
                  height: (theme) =>
                    `calc(100% - ${
                      isSm ? theme.mixins.toolbar.minHeight ?? 0 : 0
                    }px)`,
                }}
              >
                <TabResponsaveis
                  mensagemAtribuicoes={mensagem.mensagemAtribuicoes}
                  source={source}
                  setResponsaveis={setResponsaveis}
                  responsaveis={responsaveis}
                  mensagem={mensagem}
                  filter={filter}
                />
              </TabPanel>
            )}
            {util.showTabDetalhesMensagem({
              atr: mensagem.mensagemAtribuicoes,
              tipo: "loginExtra",
            }) && (
              <TabPanel value="4" sx={{ padding: 0, height: "100%" }}>
                <TabLoginExtra
                  mensagemAtribuicoes={mensagem.mensagemAtribuicoes}
                  source={source}
                  setLoginExtras={setLoginExtras}
                  loginExtras={loginExtras}
                  mensagem={mensagem}
                  filter={filter}
                />
              </TabPanel>
            )}
            {util.showTabDetalhesMensagem({
              atr: mensagem.mensagemAtribuicoes,
              tipo: "professor",
            }) && (
              <TabPanel value="5" sx={{ padding: 0, height: "100%" }}>
                <TabProfessores
                  mensagemAtribuicoes={mensagem.mensagemAtribuicoes}
                  source={source}
                  setProfessores={setProfessores}
                  professores={professores}
                  mensagem={mensagem}
                  filter={filter}
                />
              </TabPanel>
            )}
          </Box>
        )}
      </Main>
    </TabContext>
  );
};
