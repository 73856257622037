import React, { FC, useEffect, useState } from "react";
import {
  EUserType,
  IMatricula,
  ITurma,
  utils,
  IOcorrenciaPayload,
} from "@deltasge/marauders-map";
import { useParams } from "react-router-dom";

import { Alert, IconButton, List, Paper, Tooltip } from "@mui/material";
import {
  CheckboxBlankOutline as CheckboxBlankIcon,
  CheckboxMarked as CheckboxMarkedIcon,
  MinusBoxOutline as MinusBoxIcon,
  FolderStar as OcorrenciaIcon,
} from "mdi-material-ui";

import { api } from "configs";
import { snack } from "components/GlobalSnackbar";
import { Main } from "pages/_layouts/Main";
import { getError } from "utils";
import { ListItem } from "./components/ListItem";
import { CadastroOcorrencia } from "pages/Ocorrencias/Cadastro/CadastroOcorrencia";
import { useDialog } from "hooks/useDialog";
import { useAppSelector } from "store";

export const Turma: FC = () => {
  const {
    usuario: { usuario },
    configuracoes: {
      etapas,
      configOnline: { configuracoes },
    },
    treeView: { cursos },
  } = useAppSelector((state) => state);

  const [turma, setTurma] = useState<ITurma>();
  const [loading, setLoading] = useState(false);
  const [matriculasSelecionadas, setMatriculasSelecionadas] = useState<
    IMatricula[]
  >([]);
  const [ocorrencia, setOcorrencia] = useState<IOcorrenciaPayload>();

  const { id } = useParams<{ id: string }>();

  const {
    RenderDialog: OcorrenciaDialog,
    hide: hideOcorrenciaDialog,
    show: showOcorrenciaDialog,
  } = useDialog({
    title: "Criar ocorrência",
  });

  const handleSelect = (matricula: IMatricula) => {
    const index = matriculasSelecionadas.findIndex((f) => f.id == matricula.id);
    if (index >= 0) {
      setMatriculasSelecionadas((f) => [
        ...f.filter((f) => f.id != matricula.id),
      ]);
    } else {
      setMatriculasSelecionadas((f) => [...f.concat(matricula)]);
    }
  };

  const handleOcorrencia = (matriculas: IMatricula[]) => {
    const etapa = utils.getEtapaPorData({
      etapas,
      data: new Date(),
      idTurma: matriculas[0].idTurma,
      cursos,
    });
    if (!etapa) {
      snack.warning(
        "Não foi possível encontrar a etapa do curso desta turma para a data atual"
      );
      return;
    }

    const alunos = matriculas
      .filter((f) => f.aluno != undefined)
      .map((m) => ({ id: m.aluno?.uid, nome: m.aluno?.nome })) as {
      id: number;
      nome: string;
    }[];

    setOcorrencia({
      idMateria: null,
      idCategoriaOcorrencia: null,
      anoLetivo: configuracoes.ano_site.toString(),
      restrita: false,
      historico: "",
      bimestre: etapa?.etapa,
      data: new Date(),
      alunos,
      idTiposOcorrencia: [],
      idTurma: matriculas[0].idTurma,
      idProfessor:
        usuario?.tipoUsuario == EUserType.Professor && usuario.id
          ? usuario.id
          : null,
      idUsuario:
        usuario?.tipoUsuario == EUserType.UsuarioSistema
          ? usuario?.id ?? null
          : null,
    });
    showOcorrenciaDialog();
  };

  const handleCloseOcorrencia = () => {
    hideOcorrenciaDialog();
    if (matriculasSelecionadas.length > 0) {
      setMatriculasSelecionadas([]);
    }
    setOcorrencia(undefined);
  };

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const { data } = await api.get<ITurma | undefined>(`turma/${id}`, {
          params: {
            select: [
              "id",
              "tituloTurma",
              "limiteSala",
              "totalMatriculado",
              "totalEstudando",
              "totalDesistente",
              "totalTransferido",
              "matriculas.id",
              "matriculas.codigo",
              "matriculas.nrChamada",
              "matriculas.situacao",
              "matriculas.dataSaida",
              "matriculas.dataEntrada",
              "matriculas.historico",
              "matriculas.confirmou",
              "matriculas.idTurma",
              "matriculas.aluno.nome",
              "matriculas.aluno.uid",
              "matriculas.aluno.codigo",
              "matriculas.aluno.imagemAluno.caminhoArquivo",
            ],
            order: {
              matriculas: { nrChamada: "ASC" },
            },
          },
        });
        if (data) setTurma(data);
        setLoading(false);
      } catch (error) {
        snack.error(getError(error));
        setLoading(false);
      }
    })();
  }, [id]);

  return (
    <Main
      title={turma ? turma.tituloTurma : "Turma"}
      loading={loading}
      buttons={[
        <Tooltip key="btn-check" title="Marcar todos os alunos">
          <>
            <IconButton
              disabled={!turma?.matriculas}
              onClick={() => {
                if (matriculasSelecionadas.length == 0) {
                  setMatriculasSelecionadas(turma?.matriculas ?? []);
                } else {
                  setMatriculasSelecionadas([]);
                }
              }}
            >
              {matriculasSelecionadas.length == 0 ? (
                <CheckboxBlankIcon />
              ) : matriculasSelecionadas.length == turma?.matriculas?.length ? (
                <CheckboxMarkedIcon />
              ) : (
                <MinusBoxIcon />
              )}
            </IconButton>
          </>
        </Tooltip>,
        <Tooltip
          key="btn-ocorrencia"
          title="Criar ocorrencia com os alunos selecionados"
        >
          <>
            <IconButton
              disabled={matriculasSelecionadas.length == 0}
              onClick={() => handleOcorrencia(matriculasSelecionadas)}
            >
              <OcorrenciaIcon />
            </IconButton>
          </>
        </Tooltip>,
      ]}
    >
      <OcorrenciaDialog>
        {ocorrencia && (
          <CadastroOcorrencia
            handleClose={handleCloseOcorrencia}
            item={ocorrencia}
            goToList
          />
        )}
      </OcorrenciaDialog>
      {turma?.matriculas?.length == 0 && !loading && (
        <Alert severity="info">Nenhuma matricula encontrada</Alert>
      )}
      {turma?.matriculas && turma?.matriculas.length > 0 && (
        <List component={Paper}>
          {turma.matriculas.map((m, i, a) => (
            <ListItem
              key={`matricula-${m.id}`}
              matricula={m}
              divider={i + 1 < a.length}
              handleSelect={handleSelect}
              selecionadas={matriculasSelecionadas}
              handleOcorrencia={handleOcorrencia}
            />
          ))}
        </List>
      )}
    </Main>
  );
};
