import React, { FC } from "react";

import {
  Alert as AlertMUI,
  AlertColor,
  AlertTitle,
  SxProps,
  Theme,
  Typography,
} from "@mui/material";
import { alpha } from "@mui/system/colorManipulator";

export const Alert: FC<{
  severity?: AlertColor;
  sxBackground?: SxProps<Theme>;
  message?: string;
  title?: string;
  imageSrc: string;
}> = ({ message, title, imageSrc, sxBackground, severity }) => {
  return (
    <AlertMUI
      icon={false}
      severity={severity}
      sx={{
        paddingTop: "50vh",
        backgroundImage: `url(${imageSrc})`,
        height: "100%",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundBlendMode: "lighten",
        backgroundColor: (theme) => alpha(theme.palette.background.paper, 0.7),
        backgroundSize: (theme) => theme.spacing(44),
        ...sxBackground,
      }}
    >
      {title && <AlertTitle>{title}</AlertTitle>}
      {message && (
        <Typography variant="body1" textAlign="center">
          {message}
        </Typography>
      )}
    </AlertMUI>
  );
};
