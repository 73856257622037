import React, { FC, useEffect, useState } from "react";
import { EUserType, IAulasChamada, IDiaAula } from "@deltasge/marauders-map";

import { Paper, Table, TableBody, TableContainer } from "@mui/material";
import { Filter as FilterIcon } from "mdi-material-ui";

import { api } from "configs";
import { EmptyState } from "components/EmptyStates";
import { snack } from "components/GlobalSnackbar";
import { useDialog } from "hooks/useDialog";
import { useFilter } from "hooks/useFilter";
import { IFilterConteudoMinistrado } from "interfaces";
import { Main } from "pages/_layouts/Main";
import { useAppSelector } from "store";
import { getError } from "utils";

import { Filter } from "./components/Filter";
import { useApi } from "hooks/useApi";
import { TableRowAula } from "./components/TableRowAula";
import { EditAula } from "./components/EditAula";

export const ConteudoMinistradoAulas: FC = () => {
  const {
    configuracoes: {
      configOnline: { configuracoes },
    },
    usuario: { usuario },
  } = useAppSelector((state) => state);

  const idProfessor: number | null =
    usuario?.tipoUsuario == EUserType.Professor ? usuario?.id ?? null : null;
  const mostrarTodos = usuario?.tipoUsuario != EUserType.Professor;

  const handleFilterCurso = (idCurso?: number) =>
    navigate({
      ...filter,
      idCurso,
      idSerie: undefined,
      idTurma: undefined,
      idMateria: undefined,
    });

  const handleFilterSerie = (idSerie?: number) =>
    navigate({
      ...filter,
      idSerie,
      idTurma: undefined,
      idMateria: undefined,
    });

  const handleFilterTurma = (idTurma?: number) =>
    navigate({
      ...filter,
      idTurma,
    });

  const handleFilterMateria = (idMateria?: number) =>
    navigate({
      ...filter,
      idMateria,
    });

  const handleFilterEtapa = (etapa?: number) =>
    navigate({
      ...filter,
      etapa: etapa ?? configuracoes.bim_site,
    });

  const {
    Component,
    navigate,
    filter,
    selecionados: { curso, materia, turma, etapa },
  } = useFilter<IFilterConteudoMinistrado>({
    pathname: "/conteudo-ministrado/",
    queryKeys: [
      "idCurso",
      "idSerie",
      "idTurma",
      "idMateria",
      "etapa",
      "diaSemAula",
      "tarefa",
    ],
    initialValue: {
      etapa: configuracoes.bim_site,
    },
    options: {
      curso: {
        onClick: handleFilterCurso,
        mostrarTodos,
      },
      serie: {
        onClick: handleFilterSerie,
        mostrarTodos,
      },
      turma: {
        onClick: handleFilterTurma,
        mostrarTodos,
      },
      materia: {
        onClick: handleFilterMateria,
        mostrarTodos,
      },
      etapa: {
        onClick: handleFilterEtapa,
        mostrarTodos: false,
      },
    },
  });

  const [diaAulaChamada, setDiaAulaChamada] = useState<IAulasChamada>();
  const [diasAulasChamada, setDiasAulasChamada] = useState<IAulasChamada[]>([]);

  const { data: conteudoMinistradoAulas, loading } = useApi<IAulasChamada[]>({
    url:
      filter.idTurma &&
      etapa?.id &&
      (filter.idMateria || usuario?.tipoUsuario === EUserType.UsuarioInterno)
        ? `/educador/conteudoministradoaulas/${filter.idTurma}/periodo/${
            etapa?.id
          }/professor/${idProfessor ? idProfessor : null}/materia/${
            filter.idMateria ? filter.idMateria : null
          }`
        : null,
    onError: () => snack.error("Problema ao carregar as aulas"),
    refresh: `${filter.idTurma}_${filter.idMateria}_${
      filter.idEtapa
    }_${idProfessor?.toString()}`,
  });

  useEffect(() => {
    if (conteudoMinistradoAulas) {
      setDiasAulasChamada(conteudoMinistradoAulas);
    } else {
      setDiasAulasChamada([]);
    }
  }, [conteudoMinistradoAulas]);

  const {
    RenderDialog: DialogConteudo,
    show: showDialogConteudo,
    hide: hideDialogConteudo,
  } = useDialog({
    title: "Editar conteúdo",
    options: {
      maxWidth: "sm",
    },
  });

  const handleSubmitAula = async (diaAula: Partial<IDiaAula>) => {
    try {
      await api.patch(`diaaula/${diaAula.id}`, {
        descricao: diaAula.descricao,
        idProfessor:
          usuario?.tipoUsuario == EUserType.Professor ? usuario?.id : null,
        idUsuario:
          usuario?.tipoUsuario == EUserType.Professor ? null : usuario?.id,
      });
      //carregarConteudoMinistradoAulas();

      setDiasAulasChamada((prev) => [
        ...prev.map((dac: IAulasChamada) => {
          if (
            (dac.idDiaAulas && dac.idDiaAulas == diaAula.id) ||
            (dac.idCurso === diaAulaChamada?.idCurso &&
              dac.idSerie === diaAulaChamada?.idSerie &&
              dac.idTurma === diaAulaChamada?.idTurma &&
              dac.idMateria === diaAulaChamada?.idMateria &&
              dac.data === diaAulaChamada?.data &&
              dac.aula === diaAulaChamada.aula)
          )
            return {
              ...dac,
              idDiaAulas: diaAula.id,
              descricaoChamada: diaAula.descricao,
            };
          return dac;
        }),
      ]);
    } catch (error) {
      snack.error(getError(error));
    }
    hideDialogConteudo();
  };

  return (
    <Main
      title="Conteúdo ministrado"
      subtitle={`${turma?.tituloTurma ?? "Todas as turmas"} - ${
        materia?.nome ?? "Turmas as Matérias"
      }`}
      loading={loading}
      rightbarChildren={
        <Filter Component={Component} filter={filter} navigate={navigate} />
      }
      rightbarIcon={<FilterIcon />}
    >
      <DialogConteudo>
        {diaAulaChamada && (
          <EditAula
            grau={curso?.grau?.toString()}
            aula={diaAulaChamada}
            close={hideDialogConteudo}
            submit={handleSubmitAula}
          />
        )}
      </DialogConteudo>
      {(!filter.idTurma ||
        !filter.etapa ||
        (!filter.idMateria &&
          usuario?.tipoUsuario != EUserType.UsuarioInterno)) &&
        !loading && (
          <EmptyState
            type="search"
            title="Selecione no filtro"
            subtitle="Selecione ao menos uma turma, matéria e uma etapa para apresentar o conteúdo ministrado"
          />
        )}
      {diasAulasChamada.length > 0 && (
        <Paper>
          <TableContainer>
            <Table aria-label="conteudo ministrado" size="small">
              <TableBody>
                {diasAulasChamada
                  .filter(
                    (f) =>
                      filter.diaSemAula == undefined ||
                      (filter.diaSemAula && f.diasemaula) ||
                      (!filter.diaSemAula && !f.diasemaula)
                  )
                  .filter(
                    (f) =>
                      filter.tarefa == undefined ||
                      (filter.tarefa && f.tarefaChamada != undefined) ||
                      (!filter.tarefa && f.tarefaChamada == undefined)
                  )
                  .map((m) => (
                    <TableRowAula
                      key={`aula-${m.data}-${m.cstId}-${m.materiaNome}-${m.dia}-${m.aula}-${m.idDiaAulas}-Duplicda`}
                      aula={m}
                      materia={filter.idMateria ? true : false}
                      handleEdit={(m) => {
                        setDiaAulaChamada(m);
                        showDialogConteudo();
                      }}
                    />
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      )}
      {filter.idTurma &&
        filter.etapa &&
        diasAulasChamada?.length == 0 &&
        !loading && (
          <EmptyState
            type="404"
            title="Nenhum conteúdo ministrado encontrado!"
            subtitle="O Filtro selecionado não encontrou nenhum conteúdo ministrado"
          />
        )}
    </Main>
  );
};
