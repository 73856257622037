import React, { FC, useState } from "react";
import { EUserType } from "@deltasge/marauders-map";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Radio,
  Typography,
} from "@mui/material";
import { ChevronDown as ExpandMoreIcon } from "mdi-material-ui";

import { Environment } from "configs";
import { IFilterAula } from "interfaces";
import { useAppSelector } from "store";

export const Filter: FC<{
  Component: () => JSX.Element;
  filter: IFilterAula;
  navigate: (params: IFilterAula) => void;
}> = ({ filter, navigate, Component }) => {
  const [panel, setPanel] = useState({
    professor: false,
    data: false,
    todosFazerFeitos: false,
    filtrarSemDescricao: false,
  });
  const {
    treeView: { professores },
    usuario: { usuario },
  } = useAppSelector((state) => state);

  const professor = professores.find((f) => f.id == filter.idProfessor);

  return (
    <Grid
      container
      spacing={2}
      sx={{
        width: ({ spacing }) => spacing(Environment.DRAWER_WIDTH),
        pl: 1,
        pt: 2,
      }}
    >
      <Box
        sx={{
          width: ({ spacing }) => spacing(Environment.DRAWER_WIDTH),
          display: "block",
        }}
      >
        <Component />
        {usuario?.tipoUsuario != EUserType.Professor && (
          <Accordion
            elevation={0}
            expanded={panel.professor}
            onChange={() =>
              setPanel((prev) => ({ ...prev, professor: !panel.professor }))
            }
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Grid container>
                <Grid item xs={12} component={Typography} variant="subtitle2">
                  Professor
                </Grid>
                <Grid item xs={12} component={Typography} variant="caption">
                  {!professor
                    ? "TODOS OS PROFESSORES"
                    : professor.nome?.rUpper()}
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <List dense>
                <ListItemButton
                  onClick={() => {
                    navigate({
                      ...filter,
                      idProfessor: undefined,
                    });
                    setPanel((prev) => ({ ...prev, professor: false }));
                  }}
                >
                  <ListItemIcon>
                    <Radio checked={undefined == filter.idProfessor} />
                  </ListItemIcon>
                  <ListItemText primary="TODOS OS PROFESSORES" />
                </ListItemButton>
                {professores.map((p) => (
                  <ListItemButton
                    key={`professor-${p.id}`}
                    onClick={() => {
                      navigate({
                        ...filter,
                        idProfessor: p.id,
                      });
                      setPanel((prev) => ({ ...prev, professor: false }));
                    }}
                  >
                    <ListItemIcon>
                      <Radio checked={p.id == filter.idProfessor} />
                    </ListItemIcon>
                    <ListItemText
                      primary={p.nome?.rUpper()}
                      primaryTypographyProps={{
                        noWrap: true,
                      }}
                    />
                  </ListItemButton>
                ))}
              </List>
            </AccordionDetails>
          </Accordion>
        )}
        <Accordion
          elevation={0}
          expanded={panel.todosFazerFeitos}
          onChange={() =>
            setPanel((prev) => ({
              ...prev,
              todosFazerFeitos: !panel.todosFazerFeitos,
            }))
          }
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Grid container>
              <Grid item xs={12} component={Typography} variant="subtitle2">
                Apresentar aulas
              </Grid>
              <Grid item xs={12} component={Typography} variant="caption">
                {!filter.todosFazerFeitos || filter.todosFazerFeitos == 0
                  ? "Todos"
                  : filter.todosFazerFeitos == 1
                  ? "Fazer"
                  : "Feito"}
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <List dense>
              <ListItemButton
                onClick={() => {
                  navigate({
                    ...filter,
                    todosFazerFeitos: undefined,
                  });
                  setPanel((prev) => ({ ...prev, todosFazerFeitos: false }));
                }}
              >
                <ListItemIcon>
                  <Radio
                    checked={
                      undefined == filter.todosFazerFeitos ||
                      0 == filter.todosFazerFeitos
                    }
                  />
                </ListItemIcon>
                <ListItemText primary="Todos" />
              </ListItemButton>
              <ListItemButton
                onClick={() => {
                  navigate({
                    ...filter,
                    todosFazerFeitos: 1,
                  });
                  setPanel((prev) => ({ ...prev, todosFazerFeitos: false }));
                }}
              >
                <ListItemIcon>
                  <Radio checked={1 == filter.todosFazerFeitos} />
                </ListItemIcon>
                <ListItemText primary="Fazer" />
              </ListItemButton>
              <ListItemButton
                onClick={() => {
                  navigate({
                    ...filter,
                    todosFazerFeitos: 2,
                  });
                  setPanel((prev) => ({ ...prev, todosFazerFeitos: false }));
                }}
              >
                <ListItemIcon>
                  <Radio checked={2 == filter.todosFazerFeitos} />
                </ListItemIcon>
                <ListItemText primary="Feito" />
              </ListItemButton>
            </List>
          </AccordionDetails>
        </Accordion>
        <Accordion
          elevation={0}
          expanded={panel.filtrarSemDescricao}
          onChange={() =>
            setPanel((prev) => ({
              ...prev,
              filtrarSemDescricao: !panel.filtrarSemDescricao,
            }))
          }
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Grid container>
              <Grid item xs={12} component={Typography} variant="subtitle2">
                Conteúdo Ministrado
              </Grid>
              <Grid item xs={12} component={Typography} variant="caption">
                {!filter.filtrarSemDescricao || filter.filtrarSemDescricao == 0
                  ? "Todos"
                  : filter.filtrarSemDescricao == 1
                  ? "Vazio"
                  : "Feito"}
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <List dense>
              <ListItemButton
                onClick={() => {
                  navigate({
                    ...filter,
                    filtrarSemDescricao: undefined,
                  });
                  setPanel((prev) => ({ ...prev, filtrarSemDescricao: false }));
                }}
              >
                <ListItemIcon>
                  <Radio
                    checked={
                      undefined == filter.filtrarSemDescricao ||
                      0 == filter.filtrarSemDescricao
                    }
                  />
                </ListItemIcon>
                <ListItemText primary="Todos" />
              </ListItemButton>
              <ListItemButton
                onClick={() => {
                  navigate({
                    ...filter,
                    filtrarSemDescricao: 1,
                  });
                  setPanel((prev) => ({ ...prev, filtrarSemDescricao: false }));
                }}
              >
                <ListItemIcon>
                  <Radio checked={1 == filter.filtrarSemDescricao} />
                </ListItemIcon>
                <ListItemText primary="Vazio" />
              </ListItemButton>
              <ListItemButton
                onClick={() => {
                  navigate({
                    ...filter,
                    filtrarSemDescricao: 2,
                  });
                  setPanel((prev) => ({ ...prev, filtrarSemDescricao: false }));
                }}
              >
                <ListItemIcon>
                  <Radio checked={2 == filter.filtrarSemDescricao} />
                </ListItemIcon>
                <ListItemText primary="Feito" />
              </ListItemButton>
            </List>
          </AccordionDetails>
        </Accordion>
      </Box>
    </Grid>
  );
};
