import React, { FC } from "react";
import {
  Backdrop as BackdropMUI,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";

export const Backdrop: FC<{
  open: boolean;
}> = ({ open }) => {
  return (
    <BackdropMUI
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={open}
    >
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12}>
          <CircularProgress color="inherit" />
        </Grid>
        <Grid item xs={12}>
          <Typography>Gerando dia de aula</Typography>
        </Grid>
      </Grid>
    </BackdropMUI>
  );
};
