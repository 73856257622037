import React, { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Main } from "pages/_layouts/Main";
import {
  EUserType,
  IAlunosChamada,
  IAulasChamada,
  IDiaAula,
  ITurma,
} from "@deltasge/marauders-map";
import { api } from "configs";
import { snack } from "components/GlobalSnackbar";
import { getError } from "utils";
import { format, parseISO } from "date-fns";
import { List, Paper } from "@mui/material";
import { ListItem } from "./components/ListItem";
import { useAppSelector } from "store";

export const Chamada: FC = () => {
  const {
    escola: { anoSite },
    usuario: { usuario },
  } = useAppSelector((state) => state);
  const { idTurma, data } = useParams<{
    idTurma: string;
    data: string;
  }>();

  const [turma, setTurma] = useState<ITurma>();
  const [alunos, setAlunos] = useState<IAlunosChamada[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const { data: turma } = await api.get<ITurma>(`turma/${idTurma}`);
        setTurma(turma);

        if (turma && !turma.chamadaFixada) {
          api.put(`turma/${turma.id}`, {
            ...turma,
            chamadaFixada: true,
          });
        }

        const { data: aulas } = await api.get<IAulasChamada[]>(
          `/educador/flexchamada/${data}/professor/null?idTurma=${turma.id}`,
          { cache: { ignoreCache: true } }
        );
        const faltantes = aulas.filter((f) => f.idDiaAulas == null);

        for (const aula of faltantes) {
          await api.post<IDiaAula | undefined | null>("diaaula", {
            anoLetivo: anoSite,
            data: data,
            aula: aula.aula,
            idCurso: aula.idCurso,
            idSerie: aula.idSerie,
            idTurma: aula.idTurma,
            idMateria: aula.idMateria,
            diaSemAula: false,
            inutilizaDia: false,
            idProfessor:
              usuario?.tipoUsuario === EUserType.Professor ? usuario?.id : null,
            idUsuario:
              usuario?.tipoUsuario === EUserType.UsuarioSistema
                ? usuario?.id
                : null,
            descricao: "",
            idMensagem: null,
            tarefa: "",
            id: null,
          });
        }

        const { data: alunos } = await api.get<IAlunosChamada[] | undefined>(
          `/educador/chamada/${idTurma}/data/${data}`
        );
        if (alunos) {
          setAlunos(alunos);
        }
        setLoading(false);
      } catch (e) {
        snack.error(getError(e));
        setLoading(false);
      }
    })();
  }, [idTurma]);

  const handlePresenca = async ({
    presenca,
    idMatricula,
  }: {
    idMatricula: number;
    presenca: boolean;
  }) => {
    let index: number | undefined;
    try {
      index = alunos.findIndex((f) => f.idMatricula == idMatricula);
      if (index != undefined && index >= 0) {
        alunos[index].presenca = presenca;
        setAlunos([...alunos]);

        const dataStr = format(parseISO(data), "yyyy-MM-dd");
        if (presenca) {
          await api.delete(
            `/falta-diaria/${idTurma}/data/${dataStr}/matricula/${idMatricula}`
          );
        } else {
          await api.post(`/falta-diaria`, {
            idMatricula,
            idTurma,
            data: dataStr,
          });
        }
      }
    } catch (error) {
      if (index && index >= 0) {
        alunos[index].presenca = presenca;
        setAlunos([...alunos]);
      }
      snack.error(getError(error));
    }
  };

  return (
    <Main
      loading={loading}
      title="Chamada"
      subtitle={`Todas as matérias - ${
        turma ? turma.tituloTurma : ""
      } - ${format(parseISO(data), "dd/MM/yyyy")}`}
    >
      {alunos && (
        <List component={Paper}>
          {alunos.map((aluno, i, a) => (
            <ListItem
              key={"iAluno" + i}
              divider={i + 1 < a.length}
              aluno={aluno}
              handlePresenca={handlePresenca}
              idTurma={parseInt(idTurma, 10)}
            />
          ))}
        </List>
      )}
    </Main>
  );
};
