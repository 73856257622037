import React, { FC } from "react";
import { Link, useLocation } from "react-router-dom";

import {
  ListItem,
  ListItemIcon,
  ListItemText,
  SvgIconProps,
} from "@mui/material";

export const SidebarItem: FC<{
  icon?: FC<SvgIconProps>;
  label?: string;
  toggle: () => void;
  path: string;
  leftSpace?: number;
  group?: number;
  role?: number;
}> = ({ path, icon: Icon, label = "", toggle, leftSpace = 0 }) => {
  const location = useLocation();

  const isSelectedRoute =
    location?.pathname === path ||
    (path !== "/" && location?.pathname?.startsWith(path));

  return (
    <ListItem
      sx={{ pl: leftSpace * 2 }}
      button
      component={Link}
      onClick={toggle}
      to={path}
      selected={isSelectedRoute}
      dense
    >
      <ListItemIcon>{Icon && <Icon />}</ListItemIcon>
      <ListItemText primary={label} />
    </ListItem>
  );
};
