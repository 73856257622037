import React, { FC } from "react";
import { IMensagem } from "@deltasge/marauders-map";

import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";

import { Environment } from "configs";
import { IFilter } from "../Detalhes";

export const Filter: FC<{
  mensagem: IMensagem;
  filter: IFilter;
  setFilter: (filter: IFilter) => void;
}> = ({ mensagem, filter, setFilter }) => {
  const showPerguntaFormatadas =
    mensagem.perguntasFormatadas && mensagem.perguntasFormatadas.length > 0;

  const showRespostaAnexos =
    mensagem.permiteResposta &&
    (!mensagem.perguntasFormatadas || mensagem.perguntasFormatadas.length == 0);

  return (
    <Box
      sx={{
        width: ({ spacing }) => spacing(Environment.DRAWER_WIDTH),
        display: "block",
      }}
    >
      {showPerguntaFormatadas && (
        <FormControl sx={{ padding: 2 }}>
          <FormLabel id="perguntas-radio-buttons">Quem respondeu</FormLabel>
          <RadioGroup
            aria-labelledby="perguntas-radio-buttons"
            name="perguntas"
            value={filter.pergunta}
            onChange={({ target: { value } }) => {
              setFilter({ ...filter, pergunta: parseInt(value, 10) });
            }}
          >
            <FormControlLabel
              value={0}
              control={<Radio />}
              label="TODAS AS RESPOSTA"
            />
            {(mensagem.perguntasFormatadas ?? []).map((m) => (
              <FormControlLabel
                key={`pergunta-${m.id}`}
                value={m.id}
                control={<Radio />}
                label={m.nome}
              />
            ))}
          </RadioGroup>
        </FormControl>
      )}
      {showRespostaAnexos && (
        <FormControl sx={{ padding: 2 }}>
          <FormLabel id="perguntas-radio-buttons">Quem entregou</FormLabel>
          <RadioGroup
            aria-labelledby="perguntas-radio-buttons"
            name="devolvido"
            value={
              filter.devolvido == undefined
                ? "todos"
                : filter.devolvido == true
                ? "sim"
                : "nao"
            }
            onChange={({ target: { value } }) => {
              setFilter({
                ...filter,
                devolvido:
                  value == "sim" ? true : value == "nao" ? false : undefined,
              });
            }}
          >
            <FormControlLabel
              value={"todos"}
              control={<Radio />}
              label="TODOS"
            />
            <FormControlLabel
              value={"nao"}
              control={<Radio />}
              label="Entregue"
            />
            <FormControlLabel
              value={"sim"}
              control={<Radio />}
              label="Devolvido"
            />
          </RadioGroup>
        </FormControl>
      )}
    </Box>
  );
};
