import React, { FC, useState } from "react";
import { useLocation } from "react-router-dom";

import { IRoute, history } from "configs";
import {
  Collapse,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import {
  ArrowDropUp as ArrowUpIcon,
  ArrowDropDown as ArrowDownIcon,
} from "@mui/icons-material";

export const SidebarListCollapsable: FC<{
  route: IRoute;
  children: React.ReactElement;
  leftSpace?: number;
}> = ({ route, children, leftSpace = 0 }) => {
  const location = useLocation();
  const open = location?.pathname?.startsWith(route.routes ? route.path : "");
  const [collapse, setCollapse] = useState(!!open);

  return (
    <>
      <ListItemButton
        onClick={() => {
          setCollapse(!collapse);
          if (route.component && collapse) {
            history.push(route.path);
          }
        }}
        dense
        sx={{ pl: leftSpace * 2 }}
      >
        <ListItemIcon>{route.icon && <route.icon />}</ListItemIcon>
        <ListItemText primary={route.label} />
        {collapse ? <ArrowUpIcon /> : <ArrowDownIcon />}
      </ListItemButton>
      <Collapse in={collapse}>{children}</Collapse>
    </>
  );
};
