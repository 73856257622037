export enum ActionTypes {
  SET = "@modelo-diario-padrao/SET",
}

export interface IModeloDiarioPadrao {
  recuperacao: 1 | 0;
  preencherDias: 1 | 0;
  mostrarObservacoes: 1 | 0;
  idModelo?: number;
  frente: boolean;
}
