import React, { FC } from "react";

import { Container, LinearProgress, Typography, Box } from "@mui/material";

export const LoadingWithMessage: FC<{
  message: string;
}> = ({ message }) => {
  return (
    <Container>
      <LinearProgress />
      <Box mt={2}>
        <Typography component="p" align="center">
          {message}
        </Typography>
      </Box>
    </Container>
  );
};
