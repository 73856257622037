import React, { FC, useRef, useState } from "react";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { PaperComponent } from "components/PaperComponent";

export interface Props {
  hide: () => void;
  title?: string;
  content?: string;
  cancelText?: string;
  confirmText?: string;
  onConfirm: Function;
  onCancel?: Function;
}

export const ConfirmDialog: FC<Props> = ({
  hide,
  title = "Deseja executar essa ação?",
  content,
  cancelText = "Não",
  confirmText = "Sim",
  onConfirm,
  onCancel,
}) => {
  const renderCounter = useRef(0);
  renderCounter.current = renderCounter.current + 1;
  const idDialog = `confirm-dialog-${renderCounter.current}`;

  const [loading, setLoading] = useState(false);

  const handleComplete = async () => {
    setLoading(true);
    await onConfirm();
  };

  const handleCancel = async () => {
    if (onCancel) {
      await onCancel();
    }
  };

  return (
    <Dialog
      open
      onClose={hide}
      maxWidth="sm"
      aria-labelledby={idDialog}
      PaperComponent={(props) => PaperComponent(props, idDialog)}
    >
      <DialogTitle sx={{ cursor: "move" }} id={idDialog}>
        {title}
      </DialogTitle>
      {content && (
        <DialogContent>
          <Typography>{content}</Typography>
        </DialogContent>
      )}
      <DialogActions>
        <Button variant="text" color="inherit" onClick={handleCancel}>
          {cancelText}
        </Button>
        <LoadingButton
          color="primary"
          variant="text"
          loading={loading}
          onClick={handleComplete}
        >
          {confirmText}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
