import React, { FC } from "react";
import {
  IDNColumn,
  IDNRowInfo,
  IDNRown,
  IDNTableInfo,
  ISistemaAvaliacao,
  utils,
} from "@deltasge/marauders-map";
import {
  TableCell,
  TableRow as TableRowMUI,
  Theme,
  alpha,
} from "@mui/material";
import { SystemCssProperties } from "@mui/system";
import { TextEdit } from "./TextEdit";

const handleCellCSS = ({
  isSelected,
  editing,
  disabled,
  status,
}: {
  isSelected: boolean;
  editing: boolean;
  disabled: boolean;
  status?: "pending" | "resolved" | "rejected";
}) => {
  return (theme: Theme) => {
    const style: SystemCssProperties<Theme> = {};

    if (status == "pending") {
      style.outline = `1px solid ${theme.palette.warning.main}`;
      style.outlineOffset = "-1px";
    } else if (status == "rejected") {
      style.outline = `1px solid ${theme.palette.error.main}`;
      style.outlineOffset = "-1px";
    } else if (status == "resolved") {
      style.outline = `1px solid ${theme.palette.success.main}`;
      style.outlineOffset = "-1px";
    } else if (isSelected) {
      style.outline = `2px solid ${theme.palette.primary.main}`;
      style.outlineOffset = "-2px";
    } else {
      style.borderLeft = `1px solid ${theme.palette.divider}`;
    }
    if (editing) {
      style.background = theme.palette.background.paper;
    }
    if (disabled) {
      style.background = theme.palette.divider;
    }
    style.userSelect = "none";
    return style;
  };
};

const handleRowCSS = (theme: Theme) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: alpha(theme.palette.common.black, 0.01),
  },
  "&.Mui-selected, &.Mui-selected:hover": {
    backgroundColor: `${alpha(theme.palette.primary.main, 0.15)} !important`,
  },
  "& td:first-of-type": {
    borderLeft: "none",
  },
  "&:last-child td": {
    borderBottom: "none",
  },
});

export const TableRowBody: FC<{
  row: IDNRown;
  columns: IDNColumn[];
  rowSelected: boolean;
  indexRowSelected: number;
  indexCellSelected: number;
  showInput: boolean;
  sistemaAvaliacao: ISistemaAvaliacao;
  handleCellClick: (select: [number, number]) => void;
  handleDoubleClick: (
    event: React.MouseEvent<HTMLTableCellElement, MouseEvent>,
    select: [number, number]
  ) => void;
  handleSubmit: (params: {
    value: string;
    row: IDNRown;
    column: IDNColumn;
    keyPress?: string;
  }) => Promise<boolean>;
  handleCancel: (props: { id: keyof IDNRown; indexRow: number }) => void;
  autoFocus: boolean;
  info: IDNTableInfo<IDNRown>;
}> = ({
  row,
  columns,
  indexCellSelected,
  rowSelected,
  indexRowSelected,
  handleCellClick,
  handleDoubleClick,
  showInput,
  handleSubmit,
  handleCancel,
  sistemaAvaliacao,
  autoFocus,
  info,
}) => {
  return (
    <TableRowMUI hover selected={rowSelected} sx={handleRowCSS}>
      {columns
        .filter((f) => f.visivel)
        .map((column, index) => {
          const isSelected = index == indexCellSelected && rowSelected;
          const cell = row[column.id];
          let cellInfo: IDNRowInfo | undefined;
          if (info[indexRowSelected] && info[indexRowSelected][column.id]) {
            cellInfo = info[indexRowSelected][column.id];
          }

          const isNumericFormat =
            utils.digitacao.isNumericValue(column.id, cell) &&
            column.id != "faltas";
          return (
            <TableCell
              align={column?.align}
              key={`cell-${column.id}-${cellInfo ? cellInfo.status : ""}`}
              sx={handleCellCSS({
                disabled: !row.liberado,
                isSelected,
                editing: showInput && isSelected && column.liberado,
                status: cellInfo?.status || undefined,
              })}
              onClick={
                isSelected
                  ? undefined
                  : () => handleCellClick([index, indexRowSelected])
              }
              onDoubleClick={(e) =>
                handleDoubleClick(e, [index, indexRowSelected])
              }
              padding={
                showInput && isSelected && column.liberado ? "none" : "normal"
              }
            >
              <TextEdit
                autoFocus={autoFocus}
                isNumeric={utils.digitacao.isNumericValue(column.id, cell)}
                usavirgula={sistemaAvaliacao.usavirgula}
                isNumericFormat={isNumericFormat}
                text={cell}
                enabled={
                  showInput && isSelected && column.liberado && row.liberado
                }
                cancel={() =>
                  handleCancel({ indexRow: indexRowSelected, id: column.id })
                }
                submit={async (value, key) => {
                  const result = await handleSubmit({
                    value,
                    row,
                    column,
                    keyPress: key,
                  });
                  return result;
                }}
              />
            </TableCell>
          );
        })}
    </TableRowMUI>
  );
};
