import React, { FC, useState } from "react";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { ChevronDown as ExpandMoreIcon } from "mdi-material-ui";

import { Environment } from "configs";
import { IFilterConteudoMinistrado } from "interfaces";

export const Filter: FC<{
  Component: () => JSX.Element;
  filter: IFilterConteudoMinistrado;
  navigate: (params: IFilterConteudoMinistrado) => void;
}> = ({ Component, filter, navigate }) => {
  const [panel, setPanel] = useState({
    tarefa: false,
    diaSemAula: false,
  });

  return (
    <Box
      sx={{
        width: ({ spacing }) => spacing(Environment.DRAWER_WIDTH),
        display: "block",
      }}
    >
      <Component />
      <Accordion
        elevation={0}
        expanded={panel.diaSemAula}
        onChange={() =>
          setPanel((prev) => ({ ...prev, diaSemAula: !panel.diaSemAula }))
        }
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Grid container>
            <Grid item xs={12} component={Typography} variant="subtitle2">
              Mostrando
            </Grid>
            <Grid item xs={12} component={Typography} variant="caption">
              {filter.diaSemAula == undefined
                ? "Todos os dias"
                : Boolean(filter.diaSemAula) == true
                ? "Dias sem aula"
                : "Dias com aula"}
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <FormControl>
            <RadioGroup
              aria-labelledby="radio-buttons-dia-sem-aula"
              name="radio-buttons-dia-sem-aula"
              value={filter.diaSemAula ?? ""}
              onChange={({ target: { value } }) => {
                navigate({
                  ...filter,
                  diaSemAula: value == "" ? undefined : value == "true",
                });
                setPanel((prev) => ({ ...prev, diaSemAula: false }));
              }}
            >
              <FormControlLabel
                value=""
                control={<Radio />}
                label="Todos os dias"
              />
              <FormControlLabel
                value="true"
                control={<Radio />}
                label="Dias sem aula"
              />
              <FormControlLabel
                value="false"
                control={<Radio />}
                label="Dias com aula"
              />
            </RadioGroup>
          </FormControl>
        </AccordionDetails>
      </Accordion>
      <Accordion
        elevation={0}
        expanded={panel.tarefa}
        onChange={() =>
          setPanel((prev) => ({ ...prev, tarefa: !panel.tarefa }))
        }
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Grid container>
            <Grid item xs={12} component={Typography} variant="subtitle2">
              Tarefa
            </Grid>
            <Grid item xs={12} component={Typography} variant="caption">
              {filter.tarefa == undefined
                ? "Todos os dias"
                : Boolean(filter.tarefa) == true
                ? "Dias com tarefa"
                : "Dias sem tarefa"}
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <FormControl>
            <RadioGroup
              aria-labelledby="radio-buttons-dia-aula-tarefa"
              name="radio-buttons-dia-aula-tarefa"
              value={filter.tarefa ?? ""}
              onChange={({ target: { value } }) => {
                navigate({
                  ...filter,
                  tarefa: value == "" ? undefined : value == "true",
                });
                setPanel((prev) => ({ ...prev, tarefa: false }));
              }}
            >
              <FormControlLabel
                value=""
                control={<Radio />}
                label="Todos os dias"
              />
              <FormControlLabel
                value="true"
                control={<Radio />}
                label="Dias com tarefa"
              />
              <FormControlLabel
                value="false"
                control={<Radio />}
                label="Dias sem tarefa"
              />
            </RadioGroup>
          </FormControl>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};
