import React, { FC, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { EUserType, IFiltroTurma, IMateria } from "@deltasge/marauders-map";
import Qs from "qs";

import {
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";

import PerfectScrollbar from "react-perfect-scrollbar";

import { IParamsHandleTurma, ITurmaMateriaState, TAcao } from "interfaces";
import { EmptyState } from "components/EmptyStates";
import { Main } from "pages/_layouts/Main";
import { useAppDispatch, useAppSelector } from "store";
import { request as getPapeis } from "store/modules/relatorios/configuracoes/papeis";
import { request as getModelos } from "store/modules/relatorios/diarios/modelos";
import { useSessionStorage } from "hooks/useSessionStorage";
import { useDialog } from "hooks/useDialog";
import { Diario } from "pages/Home/components/Diario";
import { impressaoRapidaDiario } from "pages/Home/util";

import { Item } from "./components/Item";

import image1 from "assets/images/turma1.jpg";
import image2 from "assets/images/turma2.jpg";
import image3 from "assets/images/turma3.jpg";
import image4 from "assets/images/turma4.jpg";
import image5 from "assets/images/turma5.jpg";
import image6 from "assets/images/turma6.jpg";
import image7 from "assets/images/turma7.jpg";
import image8 from "assets/images/turma8.jpg";
import image9 from "assets/images/turma9.jpg";
import image10 from "assets/images/turma10.jpg";
import { format } from "date-fns";

const images = [
  image1,
  image2,
  image3,
  image4,
  image5,
  image6,
  image7,
  image8,
  image9,
  image10,
];

export const Turmas: FC = () => {
  const {
    treeView,
    configPapelPadrao,
    configDiarioPadrao,
    modelosDiarios: { data: modelosDiarios },
    tamanhoPapeis: { data: tamanhoPapeis },
    configuracoes: {
      configOnline: { configuracoes },
    },
    usuario: { usuario },
  } = useAppSelector((state) => state);
  const dispatch = useAppDispatch();
  const history = useHistory();

  const [diarioDownPrint] = useSessionStorage<number>("DiarioDownPrint", 0);
  const [turmaMateria, setTurmaMateria] = useState<ITurmaMateriaState>();
  const [turmaSelecionada, setTurmaSelecionada] = useState<{
    turma: IFiltroTurma;
    acao: TAcao;
    impressaoRapida?: boolean;
  }>();

  const {
    RenderDialog: DialogDiario,
    show: showDiario,
    hide: hideDiario,
  } = useDialog({
    title: "Diário de classe".concat(
      turmaMateria
        ? ` - ${turmaMateria.tituloTurma} - ${turmaMateria.nomeMateria}`
        : ""
    ),
    options: {
      modal: true,
      maxWidth: "md",
      scroll: "paper",
    },
  });

  const {
    RenderDialog: DialogMateria,
    show: showMateria,
    hide: hideMateria,
  } = useDialog({
    title: "Selecione uma matéria",
    options: {
      modal: true,
      maxWidth: "xs",
    },
  });

  useEffect(() => {
    (async () => {
      if (modelosDiarios.length == 0 || tamanhoPapeis.length == 0) {
        dispatch(getPapeis());
        dispatch(getModelos());
      }
    })();
  }, []);

  const handleTurma = async ({
    turma,
    acao,
    impressaoRapida,
  }: IParamsHandleTurma) => {
    if (
      !turma.materias ||
      !turma.tituloTurma ||
      !turma.idCurso ||
      !turma.idSerie ||
      !turma.id
    ) {
      return;
    }
    if (["aulas", "chamadas"].includes(acao)) {
      const query = Qs.stringify({
        idCurso: turma.idCurso,
        idSerie: turma.idSerie,
        idTurma: turma.id,
      });
      if (acao == "aulas") {
        history.push(
          `/?data=${format(new Date(), "yyy-MM-dd")} 12:00:00&${query}`
        );
      } else if (acao == "chamadas") {
        history.push(
          `/chamada-dia/${format(new Date(), "yyy-MM-dd")} 12:00:00/turma/${
            turma.id
          }`
        );
      }
    } else if (
      turma.materias.length == 1 &&
      turma.materias[0].id &&
      turma.materias[0].nome
    ) {
      const query = Qs.stringify({
        idCurso: turma.idCurso,
        idSerie: turma.idSerie,
        idTurma: turma.id,
        idMateria: turma.materias[0].id,
        etapa: configuracoes.bim_site,
      });
      if (acao == "diario" && !impressaoRapida) {
        setTurmaMateria({
          idCurso: turma.idCurso,
          idSerie: turma.idSerie,
          idTurma: turma.id,
          tituloTurma: turma.tituloTurma,
          nomeMateria: turma.materias[0].nome,
          idMateria: turma.materias[0].id,
        });
        showDiario();
      } else if (acao == "diario" && impressaoRapida) {
        await impressaoRapidaDiario({
          idCurso: turma.idCurso,
          idSerie: turma.idSerie,
          idTurma: turma.id,
          idMateria: turma.materias[0].id,
        });
      } else if (acao == "avaliacao") {
        history.push(`/avaliacao/?${query}`);
      } else if (acao == "notas") {
        history.push(`/notas/?${query}`);
      } else {
        history.push(`/conteudo-ministrado/?${query}`);
      }
    } else if (turma.materias && turma.materias.length > 0) {
      setTurmaSelecionada({ turma, acao, impressaoRapida });
      showMateria();
    }
  };

  const handleTurmaMateria = async ({
    turma,
    materia,
    acao,
    impressaoRapida,
  }: {
    turma: IFiltroTurma;
    materia: Partial<IMateria>;
    acao: TAcao;
    impressaoRapida?: boolean;
  }) => {
    hideMateria();
    if (
      !materia.id ||
      !materia.nome ||
      !turma.tituloTurma ||
      !turma.idCurso ||
      !turma.idSerie ||
      !turma.id
    ) {
      return;
    }
    const query = Qs.stringify({
      idCurso: turma.idCurso,
      idSerie: turma.idSerie,
      idTurma: turma.id,
      idMateria: materia.id,
      etapa: configuracoes.bim_site,
    });
    if (acao == "diario" && !impressaoRapida) {
      setTurmaMateria({
        idCurso: turma.idCurso,
        idSerie: turma.idSerie,
        idTurma: turma.id,
        idMateria: materia.id,
        nomeMateria: materia.nome,
        tituloTurma: turma.tituloTurma,
      });
      showDiario();
    } else if (acao == "diario" && impressaoRapida) {
      await impressaoRapidaDiario({
        idCurso: turma.idCurso,
        idSerie: turma.idSerie,
        idTurma: turma.id,
        idMateria: materia.id,
      });
    } else if (acao == "avaliacao") {
      history.push(`/avaliacao/?${query}`);
    } else if (acao == "notas") {
      history.push(`/notas/?${query}`);
    } else {
      history.push(`/conteudo-ministrado/?${query}`);
    }
  };

  const turmas: IFiltroTurma[] = [];
  (treeView.cursos ?? []).map((curso) => {
    (curso.series ?? []).map((serie) => {
      if (serie.turmas.length > 0) {
        turmas.push(...serie.turmas);
      }
    });
  });

  const showImpressaoRapida =
    !!configDiarioPadrao?.idModelo && !!configPapelPadrao?.width;

  return (
    <Main title="Turmas">
      <DialogMateria>
        {turmaSelecionada && turmaSelecionada.turma.materias && (
          <PerfectScrollbar>
            <List>
              {turmaSelecionada.turma.materias.map((m) => (
                <ListItem disableGutters key={`materia_${m.id}`}>
                  <ListItemButton
                    onClick={() =>
                      handleTurmaMateria({
                        turma: turmaSelecionada.turma,
                        materia: m,
                        acao: turmaSelecionada.acao,
                        impressaoRapida: turmaSelecionada.impressaoRapida,
                      })
                    }
                  >
                    <ListItemText primary={m.nome} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </PerfectScrollbar>
        )}
      </DialogMateria>
      <DialogDiario>
        {turmaMateria && (
          <Diario
            turmaMateria={{
              idCurso: turmaMateria.idCurso,
              idSerie: turmaMateria.idSerie,
              idTurma: turmaMateria.idTurma,
              idMateria: turmaMateria.idMateria,
            }}
            close={hideDiario}
          />
        )}
      </DialogDiario>
      {turmas.length > 0 && (
        <Grid spacing={2} container justifyContent="center" alignItems="center">
          {turmas.map((turma, index) => (
            <Item
              image={images[index % images.length]}
              key={turma.id}
              turma={turma}
              diarioDownPrint={diarioDownPrint}
              handleTurma={handleTurma}
              showImpressaoRapida={showImpressaoRapida}
              isProfessor={usuario?.tipoUsuario == EUserType.Professor}
            />
          ))}
        </Grid>
      )}
      {turmas.length == 0 && (
        <EmptyState type="404" title="Nenhuma turma encontrada!" />
      )}
    </Main>
  );
};
