import packageJson from "../../../../../package.json";
import {
  EUserType,
  IConfiguracoesEducador,
  IEscola,
  IMensagemCategoria,
  IOpcoesConfiguracoesEducador,
  ITreeViewTurma,
} from "@deltasge/marauders-map";

import { takeLatest, select, put, call } from "redux-saga/effects";

import { snack } from "components/GlobalSnackbar";
import { api, setToken, setBaseUrl } from "configs/axios";
import { history } from "configs/history";
import { RootState } from "store/modules/root_reducer";
import { resetStore } from "store/modules/app";

import { ActionTypes } from "./types";
import { failure, success } from "./actions";
import { set as setEscola } from "store/modules/escola";
import {
  ConfiguracoesEducador,
  set as setConfiguracoes,
} from "store/modules/configuracoes";
import { set as setTreeView } from "store/modules/tree-view";
import { set as setMensagemCategoria } from "store/modules/categorias";
import { IVersaoApp, set as setVersao } from "store/modules/versao";
import { Environment, cacheDados } from "configs";
import { setUserId, setUserProperties } from "firebase/analytics";
import { analytics } from "App";

function* request() {
  try {
    const { Authorization = "" } = api.defaults.headers.common;
    const {
      usuario: { usuario },
    }: RootState = yield select();

    if (!Authorization) {
      if (!usuario || !usuario.token) {
        yield put(failure());
        history.push("/login");
        return;
      }
      setToken(usuario.token);
      setBaseUrl(usuario.escolaId);
      setUserId(analytics, usuario.deltaId);
      setUserProperties(analytics, { escola: usuario.escolaId });
      setUserProperties(analytics, { tipoUsuario: usuario.tipoUsuario });
      setUserProperties(analytics, { aplicativos: usuario.aplicativos });
    }

    let anoSite = new Date().getFullYear().toString();
    const cache24h = 60 * 24;
    const keyEscola = `escola-${usuario?.escolaId}`;
    const keyConfig = `config-${usuario?.escolaId}`;
    const keyTreeview = `treeview-${usuario?.escolaId}`;
    const keyVersao = `versao-${usuario?.escolaId}`;
    const keyMensagemCategoria = `mensagem-categoria-${usuario?.escolaId}`;

    const cacheEscola = cacheDados.get<IEscola>(keyEscola);
    if (
      cacheEscola &&
      typeof cacheEscola != "string" &&
      Object.keys(cacheEscola).length > 0
    ) {
      yield put(setEscola(cacheEscola as IEscola));
    } else {
      const { data } = yield call(api.get, `/escola/`);
      if (data && data[0]) {
        anoSite = (data[0][0] as IEscola).anoSite;
        yield put(setEscola(data[0][0] as IEscola));
        cacheDados.set(keyEscola, data[0][0] as IEscola, cache24h);
      }
    }

    const cacheConfig = cacheDados.get<ConfiguracoesEducador>(keyConfig);
    if (
      cacheConfig &&
      typeof cacheConfig != "string" &&
      Object.keys(cacheConfig).length > 0
    ) {
      yield put(setConfiguracoes(cacheConfig));
    } else {
      const opcoes: Partial<IOpcoesConfiguracoesEducador> = {
        tiposAvaliacoes: true,
        etapas: true,
        configOnline: true,
      };

      const { data: configuracoes } = yield call(
        api.get,
        `/educador/configuracoes/${anoSite}`,
        {
          clearCacheEntry: true,
          params: { opcoes },
          baseURL: `${Environment.API_URL}/api/v2/${usuario?.escolaId}`,
        }
      );
      if (configuracoes) {
        const { configOnline, tiposAvaliacoes, etapas } =
          configuracoes as Partial<IConfiguracoesEducador>;
        if (!etapas || !tiposAvaliacoes || !configOnline) {
          throw new Error(
            "Etapas, Tipos de avaliações e Configurações do Online são obrigatórios "
          );
        }
        yield put(setConfiguracoes({ configOnline, etapas, tiposAvaliacoes }));
        cacheDados.set(
          keyConfig,
          configuracoes as Partial<IConfiguracoesEducador>,
          cache24h
        );
      }
    }

    const cacheTreeview = cacheDados.get<ITreeViewTurma>(keyTreeview);
    if (
      cacheTreeview &&
      typeof cacheTreeview != "string" &&
      Object.keys(cacheTreeview).length > 0
    ) {
      yield put(setTreeView(cacheTreeview));
    } else {
      const { data: turmas } = yield call(
        api.get,
        `/turma/tree-view/${anoSite}`.concat(
          usuario?.tipoUsuario == EUserType.Professor
            ? `/professor/${usuario.id}`
            : ""
        )
      );
      if (turmas) {
        yield put(setTreeView(turmas as ITreeViewTurma));
        cacheDados.set(keyTreeview, turmas as ITreeViewTurma, cache24h);
      }
    }

    const cacheVersao = cacheDados.get<IVersaoApp>(keyVersao);
    if (
      cacheVersao &&
      typeof cacheVersao != "string" &&
      Object.keys(cacheVersao).length > 0
    ) {
      yield put(setVersao(cacheVersao));
    } else {
      const { data: versao } = yield call(api.get, `/versao/`);
      if (versao) {
        versao.app = packageJson.version;
        yield put(setVersao(versao));
        cacheDados.set(keyVersao, versao as ITreeViewTurma, cache24h);
      }
    }

    const cacheMensagemCategoria =
      cacheDados.get<IMensagemCategoria[]>(keyMensagemCategoria);
    if (
      cacheMensagemCategoria &&
      typeof cacheMensagemCategoria != "string" &&
      Object.keys(cacheMensagemCategoria).length > 0
    ) {
      yield put(setMensagemCategoria(cacheMensagemCategoria));
    } else {
      const { data } = yield call(api.get, "educador/mensagem-categoria");
      if (!data || !Array.isArray(data) || !Array.isArray(data.at(0))) {
        throw new Error("Nenhuma categoria de mensagem encontrada");
      }
      yield put(setMensagemCategoria(data[0] as IMensagemCategoria[]));
      cacheDados.set(
        keyMensagemCategoria,
        data[0] as IMensagemCategoria[],
        cache24h
      );
    }

    yield put(success());
  } catch (error) {
    yield put(failure());
    yield put(resetStore());

    snack.warning((error as Error).toString());
    history.push("/login");
  }
}

export const sagas = takeLatest([ActionTypes.REQUEST], request);
