import React, { FC } from "react";
import { IMensagem, IMensagemLink } from "@deltasge/marauders-map";

import {
  IconButton,
  List,
  ListProps,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
} from "@mui/material";
import {
  Paperclip as FileIcon,
  Link as LinkIcon,
  CloseCircleOutline as DeleteIcon,
} from "mdi-material-ui";

import { useMobile } from "hooks/useMobile";
import { IPreMensagemArquivo } from "../../utilMensagem";

export interface Props {
  mensagemArquivos: IPreMensagemArquivo[];
  mensagemLinks: IMensagemLink[];
  handleEditLink: (link: IMensagemLink) => void;
  set: (key: keyof IMensagem, value: unknown) => void;
  listProps?: ListProps;
}
export const Anexos: FC<Props> = ({
  mensagemArquivos,
  mensagemLinks,
  set,
  handleEditLink,
  listProps,
}) => {
  const isSm = useMobile("sm");
  return (
    <List {...listProps}>
      {mensagemArquivos.map((m, i) => (
        <Paper
          key={`arquivos-${i}`}
          variant="outlined"
          sx={{ marginBottom: 1 }}
        >
          <ListItem
            dense={isSm}
            disablePadding
            secondaryAction={
              <IconButton
                onClick={() => {
                  if (m.file) {
                    set(
                      "mensagemArquivos",
                      mensagemArquivos.filter(
                        (f) => f.file?.name != (m.file?.name ?? "")
                      )
                    );
                  } else {
                    set(
                      "mensagemArquivos",
                      mensagemArquivos.map((p) => ({
                        ...p,
                        deletedAt:
                          p.arquivo?.caminhoArquivo == m.arquivo?.caminhoArquivo
                            ? new Date()
                            : m.deletedAt,
                      }))
                    );
                  }
                }}
              >
                <DeleteIcon />
              </IconButton>
            }
          >
            <ListItemButton>
              <ListItemIcon>
                <FileIcon />
              </ListItemIcon>
              <ListItemText
                primary={m.arquivo?.caminhoArquivo ?? m.file?.name ?? ""}
                primaryTypographyProps={{
                  noWrap: true,
                  whiteSpace: "normal",
                }}
              />
            </ListItemButton>
          </ListItem>
        </Paper>
      ))}
      {mensagemLinks.map((link, i) => (
        <Paper key={`links-${i}`} variant="outlined" sx={{ marginBottom: 1 }}>
          <ListItem
            dense={isSm}
            disablePadding
            secondaryAction={
              <IconButton
                onClick={() => {
                  set(
                    "mensagemLinks",
                    mensagemLinks.map((current) => ({
                      ...current,
                      deletedAt:
                        current.url == link.url ? new Date() : link.deletedAt,
                    }))
                  );
                }}
              >
                <DeleteIcon />
              </IconButton>
            }
          >
            <ListItemButton onClick={() => handleEditLink(link)}>
              <ListItemIcon>
                <LinkIcon />
              </ListItemIcon>
              <ListItemText
                primary={link.url}
                primaryTypographyProps={{
                  noWrap: true,
                  whiteSpace: "normal",
                }}
              />
            </ListItemButton>
          </ListItem>
        </Paper>
      ))}
    </List>
  );
};
