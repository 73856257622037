import React, { FC, useContext, useEffect, useState } from "react";

import { Zoom as MUIZoom } from "@mui/material";
import { ScrollContext } from "context/ScrollContext";

export const ZoomOnScroll: FC<{
  children: React.ReactElement<any, any>;
}> = ({ children }) => {
  const [hide, setHide] = useState(true);
  const {
    position: { prev, curr },
  } = useContext(ScrollContext);

  useEffect(() => {
    const isShow = curr.top > prev.top;
    if (isShow !== hide) setHide(isShow);
  }, [hide, curr.top, prev.top]);

  return (
    <MUIZoom in={!hide} timeout={500} unmountOnExit>
      {children}
    </MUIZoom>
  );
};
