import React, { FC, useEffect, useState } from "react";
import { differenceInMinutes, format, parseISO, set } from "date-fns";
import { ptBR } from "date-fns/locale";
import {
  ECursoGrau,
  EUserType,
  IAulasChamada,
  IDiaAula,
} from "@deltasge/marauders-map";
import { Grid, GridSize } from "@mui/material";
import { Filter as FilterIcon } from "mdi-material-ui";

import { Float } from "components/Buttons/Float";
import { EmptyState } from "components/EmptyStates";
import { snack } from "components/GlobalSnackbar";
import { api, history } from "configs";
import { useApi } from "hooks/useApi";
import { useDialog } from "hooks/useDialog";
import { useFilter } from "hooks/useFilter";
import { useMobile } from "hooks/useMobile";
import { IFilterAula } from "interfaces";
import { Main } from "pages/_layouts/Main";
import { useAppDispatch, useAppSelector } from "store";
import { request as getCategorias } from "store/modules/categorias";
import { request as getModelos } from "store/modules/relatorios/diarios/modelos";
import { request as getPapeis } from "store/modules/relatorios/configuracoes/papeis";
import { getError } from "utils";

import { Backdrop } from "./components/Backdrop";
import { DiaAulaCadastro } from "./components/DiaAulaCadastro";
import { Diario } from "./components/Diario";
import { Filter } from "./components/Filter";
import { Item } from "./components/Item";
import { impressaoRapidaDiario } from "./util";

import image1 from "assets/images/turma1.jpg";
import image2 from "assets/images/turma2.jpg";
import image3 from "assets/images/turma3.jpg";
import image4 from "assets/images/turma4.jpg";
import image5 from "assets/images/turma5.jpg";
import image6 from "assets/images/turma6.jpg";
import image7 from "assets/images/turma7.jpg";
import image8 from "assets/images/turma8.jpg";
import image9 from "assets/images/turma9.jpg";
import image10 from "assets/images/turma10.jpg";

const images = [
  image1,
  image2,
  image3,
  image4,
  image5,
  image6,
  image7,
  image8,
  image9,
  image10,
];

export const Home: FC = () => {
  const {
    escola: { anoSite },
    usuario: { usuario },
    configDiarioPadrao,
    configPapelPadrao,
    mensagemCategoria: { data: mensagemCategorias },
    modelosDiarios: { data: modelosDiarios },
    tamanhoPapeis: { data: tamanhoPapeis },
    layout: {
      rightbar: { isOpen: rightbarIsOpen },
    },
    configuracoes: {
      etapas,
      configOnline: { configuracoes },
    },
  } = useAppSelector((state) => state);

  const dispatch = useAppDispatch();

  const [aula, setAula] = useState<IAulasChamada>();

  const handleFilterCurso = (idCurso?: number) =>
    navigate({
      ...filter,
      idCurso,
      idSerie: undefined,
      idTurma: undefined,
    });

  const handleFilterSerie = (idSerie?: number) =>
    navigate({
      ...filter,
      idSerie,
      idTurma: undefined,
    });

  const handleFilterTurma = (idTurma?: number) =>
    navigate({
      ...filter,
      idTurma,
    });

  const handleFilterData = (data?: Date) => {
    if (data) {
      navigate({
        ...filter,
        data: set(data, { hours: 12, minutes: 0, seconds: 0 }),
      });
    }
  };

  const {
    Component,
    navigate,
    filter,
    selecionados: { curso, serie, turma },
  } = useFilter<IFilterAula>({
    pathname: "/",
    queryKeys: [
      "data",
      "idCurso",
      "idSerie",
      "idTurma",
      "idMateria",
      "idProfessor",
      "filtrarSemDescricao",
      "todosFazerFeitos",
    ],
    initialValue: {
      data: set(new Date(), { hours: 12, minutes: 0, seconds: 0 }),
      idProfessor:
        usuario?.tipoUsuario == EUserType.Professor
          ? usuario?.id ?? undefined
          : undefined,
      todosFazerFeitos: 0,
      filtrarSemDescricao: 0,
    },
    options: {
      curso: {
        onClick: handleFilterCurso,
        mostrarTodos: true,
      },
      serie: {
        onClick: handleFilterSerie,
        mostrarTodos: true,
      },
      turma: {
        onClick: handleFilterTurma,
        mostrarTodos: true,
      },
      data: {
        onClick: handleFilterData,
        allowsToClean: false,
      },
    },
  });

  const {
    RenderDialog: DialogCadastroAula,
    show: showCadastro,
    hide: hideCadastro,
  } = useDialog({
    title: `Cadastrar aula para o ${turma?.tituloTurma} no dia ${format(
      filter.data,
      "dd/MM/yyyy"
    )} - ${format(filter.data, "EEEE", {
      locale: ptBR,
    }).rUpper()}`,
    options: {
      modal: true,
    },
  });

  const {
    RenderDialog: DialogDiario,
    show: showDiario,
    hide: hideDiario,
  } = useDialog({
    title: `Diário de classe - ${
      aula ? aula.turmaDescricao.concat(" - ").concat(aula.materiaNome) : ""
    }`,
    options: {
      modal: true,
    },
  });

  const idProfessor =
    usuario?.tipoUsuario == EUserType.Professor
      ? usuario?.id ?? undefined
      : filter.idProfessor;

  const [gerandoAula, setGerandoAula] = useState(false);
  const query =
    (curso ? "idCurso=" + curso.id : "") +
    (serie ? "&idSerie=" + serie.id : "") +
    (turma ? "&idTurma=" + turma.id : "");

  const {
    data: aulas,
    loading,
    carregar: carregarAulas,
  } = useApi<IAulasChamada[]>({
    url: filter.data
      ? `/educador/flexchamada/${format(
          filter.data,
          "yyy-MM-dd HH:mm:ss"
        )}/professor/${idProfessor ? idProfessor : null}${
          query ? "?" + query : ""
        }`
      : null,
    onError: () => snack.error("Problema ao carregar as aulas"),
    refresh: filter.idProfessor?.toString(),
  });

  let aulasProfessor = idProfessor
    ? aulas?.filter(
        (a) =>
          a.idProfessorMateria === idProfessor ||
          a.idProfessorChamada === idProfessor
      )
    : aulas;
  if (aulasProfessor && (filter.todosFazerFeitos ?? 0) > 0) {
    if (filter.todosFazerFeitos === 1) {
      aulasProfessor = aulasProfessor.filter((a) => a.idDiaAulas === null);
    }
    if (filter.todosFazerFeitos === 2) {
      aulasProfessor = aulasProfessor.filter((a) => a.idDiaAulas !== null);
    }
  }

  if (aulasProfessor && (filter.filtrarSemDescricao ?? 0) > 0) {
    if (filter.filtrarSemDescricao === 1) {
      aulasProfessor = aulasProfessor.filter(
        (a) =>
          a.idDiaAulas === null ||
          a.descricaoChamada === null ||
          a.descricaoChamada === undefined ||
          a.descricaoChamada.length <= 3
      );
    }
    if (filter.filtrarSemDescricao === 2) {
      aulasProfessor = aulasProfessor.filter(
        (a) =>
          a.idDiaAulas && a.descricaoChamada && a.descricaoChamada.length > 3
      );
    }
  }

  if (aulasProfessor && curso) {
    aulasProfessor = aulasProfessor.filter((a) => a.idCurso === curso.id);
  }

  if (aulasProfessor && turma) {
    aulasProfessor = aulasProfessor.filter((a) => a.idTurma === turma.id);
  }

  const showImpressaoRapida =
    !!configDiarioPadrao?.idModelo && !!configPapelPadrao?.width;

  useEffect(() => {
    (async () => {
      if (modelosDiarios.length == 0 || tamanhoPapeis.length == 0) {
        dispatch(getModelos());
        dispatch(getPapeis());
      }
      if (mensagemCategorias.length == 0) {
        dispatch(getCategorias());
      }
    })();
  }, []);

  const handleChamadaDia = async (aula: IAulasChamada) => {
    history.push(
      `/chamada-dia/${format(filter.data, "yyy-MM-dd HH:mm:ss")}/turma/${
        aula.idTurma
      }`
    );
  };

  const handleAula = async (aula: IAulasChamada) => {
    try {
      let idDiaAulas = aula.idDiaAulas;
      if (!aula.idDiaAulas) {
        setGerandoAula(true);
        const { data } = await api.post<IDiaAula | undefined | null>(
          "diaaula",
          {
            anoLetivo: anoSite,
            data: format(filter.data, "yyy-MM-dd HH:mm:ss"),
            aula: aula.aula,
            idCurso: aula.idCurso,
            idSerie: aula.idSerie,
            idTurma: aula.idTurma,
            idMateria: aula.idMateria,
            diaSemAula: false,
            inutilizaDia: false,
            idProfessor:
              usuario?.tipoUsuario === EUserType.Professor ? usuario?.id : null,
            idUsuario:
              usuario?.tipoUsuario === EUserType.UsuarioSistema
                ? usuario?.id
                : null,
            descricao: "",
            idMensagem: null,
            tarefa: "",
            id: null,
          }
        );

        if (!data) {
          throw new Error("Erro ao gerar o dia de aula");
        }
        idDiaAulas = data.id;
        setGerandoAula(false);
      }
      history.push(`/gerenciar-aula/${idDiaAulas}`);
    } catch (error) {
      snack.error(getError(error));
    }
  };

  const handleDiario = async (
    aula: IAulasChamada,
    showImpressaoRapida?: boolean
  ): Promise<void> => {
    if (!showImpressaoRapida) {
      setAula(aula);
      showDiario();
      return;
    }

    try {
      await impressaoRapidaDiario({
        idCurso: aula.idCurso,
        idSerie: aula.idSerie,
        idTurma: aula.idTurma,
        idMateria: aula.idMateria,
      });
    } catch (error) {
      snack.error(getError(error));
    }
  };

  const handleShowCadastro = () => {
    const grau = curso?.grau;
    const serieDbf =
      serie && serie.serie ? parseInt(serie.serie, 10) : undefined;
    if (!grau || !serieDbf) {
      snack.error("Erro ao encontrar o grau e a serie da turma selecionada");
      return;
    }
    if (
      configuracoes.blockfreqcontinf &&
      (grau == ECursoGrau.Infantil ||
        (grau == ECursoGrau.Fundamental && serieDbf <= 5))
    ) {
      snack.warning(
        "Inserir aulas fora da grade está desabilitado, contate a secretaria."
      );
      return;
    }
    if (
      configuracoes.blockfreqcont &&
      (grau == ECursoGrau.Medio ||
        (grau == ECursoGrau.Fundamental && serieDbf > 5 && serieDbf < 9))
    ) {
      snack.warning(
        "Inserir aulas fora da grade está desabilitado, contate a secretaria."
      );
      return;
    }
    showCadastro();
  };

  const isMd = useMobile("md");
  const isLg = useMobile("lg");
  const isXl = useMobile("xl");

  const mdSize: GridSize = !isMd && rightbarIsOpen ? 12 : 5;
  const lgSize: GridSize = isLg && rightbarIsOpen ? 4 : 3;
  const xlSize: GridSize = isXl && rightbarIsOpen ? 4 : 3;

  let etapa = configuracoes.bim_site;
  if (filter.idCurso && filter.data) {
    const etapaCurso = etapas.find(
      (f) =>
        f.idsCursos?.includes(filter.idCurso ?? 0) &&
        parseISO(f.dataEtapaIni ?? "").getTime() <= filter.data.getTime() &&
        parseISO(f.dataEtapaFim ?? "").getTime() >= filter.data.getTime()
    );
    etapa = etapaCurso?.etapa ?? configuracoes.bim_site;
  }

  return (
    <Main
      maxWidth={false}
      loading={loading}
      title="Aulas"
      rightbarChildren={
        <Filter filter={filter} navigate={navigate} Component={Component} />
      }
      rightbarIcon={<FilterIcon />}
    >
      <DialogCadastroAula>
        <DiaAulaCadastro
          filtro={filter}
          close={hideCadastro}
          carregarAulas={carregarAulas}
        />
      </DialogCadastroAula>
      <DialogDiario>
        {aula && (
          <Diario
            turmaMateria={{
              idCurso: aula.idCurso,
              idSerie: aula.idSerie,
              idTurma: aula.idTurma,
              idMateria: aula.idMateria,
            }}
            close={hideDiario}
          />
        )}
      </DialogDiario>
      <Backdrop open={gerandoAula} />
      {!filter.data && !loading && (
        <EmptyState
          type="search"
          title="Selecione no filtro"
          subtitle="Selecione ao menos a data para apresentar as aulas do dia"
        />
      )}
      {aulasProfessor?.length == 0 && !loading && (
        <EmptyState
          type="404"
          title={`Não há aula na grade para o dia ${format(
            filter.data,
            "dd/MM/yyyy"
          )}`
            .concat(filter.idTurma ? " para esta turma" : "")
            .concat(
              usuario?.tipoUsuario != EUserType.Professor ? " ou professor" : ""
            )}
          subtitle={"Selecione no filtro ao lado outra data".concat(
            filter.idTurma
              ? " ou turma. Ou clique no botão abaixo para adicionar uma aula"
              : ". Ou selecione uma turma e clique em adicionar aula"
          )}
        />
      )}
      {aulasProfessor && aulasProfessor.length > 0 && (
        <Grid spacing={2} container alignItems="stretch">
          {aulasProfessor.map((aula, i) => {
            let percentage: number | undefined = 0;
            let isFim = false;
            if (aula.horaFim.indexOf(":") > 0) {
              const horaFim = aula.horaFim.split(":");
              const data = set(filter.data, {
                hours: parseInt(horaFim[0], 10),
                minutes: parseInt(horaFim[1], 10),
              });
              isFim = new Date() > data;
            }
            if (isFim) percentage = 100;
            if (aula.horaIni.indexOf(":") > 0 && !isFim) {
              const horaIni = aula.horaIni.split(":");
              const data = set(filter.data, {
                hours: parseInt(horaIni[0], 10),
                minutes: parseInt(horaIni[1], 10),
              });
              const difference = differenceInMinutes(new Date(), data);
              if (difference > 0) {
                percentage = (difference * 100) / aula.duracao;
              }
            }
            const image = images[i % images.length];
            return (
              <Item
                etapa={etapa}
                mdSize={mdSize}
                lgSize={lgSize}
                xlSize={xlSize}
                image={image}
                isProfessor={usuario?.tipoUsuario == EUserType.Professor}
                aula={aula}
                handleAula={handleAula}
                handleChamadaDia={handleChamadaDia}
                handleDiario={handleDiario}
                aulaTerminou={isFim}
                key={`${i}-${mdSize}-${lgSize}-${xlSize}`}
                percentage={percentage}
                showImpressaoRapida={showImpressaoRapida}
              />
            );
          })}
        </Grid>
      )}
      {filter.idTurma && <Float onClick={() => handleShowCadastro()} />}
    </Main>
  );
};
