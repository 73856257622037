import { TextFieldProps } from "@mui/material";
import { UseFormRegister } from "react-hook-form";

export interface IUseApplyProps {
  register: UseFormRegister<any>;
  errors: Record<string, any>;
  schema?: any;
}

export type ApplyProps<K> = (
  id: keyof K,
  schema?: any
) => TextFieldProps & { [key: string]: any };

export const useApplyProps = <K = any>({
  register,
  errors,
  schema,
}: IUseApplyProps): ApplyProps<K> => {
  return (key: keyof K) => {
    const id = String(key);

    let yup: { [key: string]: any } = {};
    if (schema && schema.fields[id]) {
      yup = { label: schema.fields[id].spec.label };
    }
    const { ref: inputRef, ...rest } = register(id);
    return {
      ...yup,
      ...rest,
      inputRef,
      id: id,
      name: id,
      error: !!errors[id]?.message,
      helperText: errors[id]?.message,
    };
  };
};
