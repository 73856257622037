import React, {
  FC,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  OAuthProvider,
  deleteUser,
  AuthError,
  reauthenticateWithPopup,
  User,
} from "firebase/auth";

import {
  Button,
  ButtonProps,
  CircularProgress,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  ThemeProvider,
  Tooltip,
} from "@mui/material";
import { RemoveCircleOutline as RemoveCircleOutlineIcon } from "@mui/icons-material";
import {
  Apple as IppleIcon,
  Google as GoogleIcon,
  AccountRemove as RemoveIcon,
} from "mdi-material-ui";

import { googleTheme, iosTheme } from "assets/themes/iconSocial";
import { snack } from "components/GlobalSnackbar";
import { Avatar } from "components/Avatar";
import { apiConfig, api, setToken, cacheDados, cachePhotoUrl } from "configs";
import { usePushNotification } from "context/PushNotificationContext";
import type {
  WindowWebKitIOS,
  IAuthenticationService,
  ISignOutProps,
  ISocialUser,
  ISocialUserComponentProps,
  IAuthenticatedUserComponentProps,
} from "interfaces";

import { persistor, useAppDispatch, useAppSelector } from "store";
import { request as logoff } from "store/modules/auth/logoff";
import { set as setUsuario } from "store/modules/auth/usuario";
import { getError, isWebKit } from "utils";
import { useConfirmDialog } from "hooks/useDialogConfirm";
import { IUser } from "@deltasge/marauders-map";

export const AuthenticationContext = createContext<IAuthenticationService>({
  signing: false,
  signOuting: false,
  loadingUser: false,
  signInGoogle: async () => {},
  signInApple: async () => {},
  signOut: async () => {},
  getAuthenticatedUser: async () => {},
  AppleButton: () => <></>,
  GoogleButton: () => <></>,
  SocialUserComponent: () => null,
  AuthenticatedUserComponent: () => null,
});

export const useAuthentication = () => useContext(AuthenticationContext);

export const ProviderAuthentication: FC = ({ children }) => {
  const [signOuting, setSignOuting] = useState(false);
  const [signing, setSigning] = useState(false);
  const [loadingUser, setLoadingUser] = useState(false);
  const [user, setUser] = useState<ISocialUser>();

  const auth = getAuth();

  const { isSupported, permission, token } = usePushNotification();

  const {
    usuario: { usuario },
  } = useAppSelector((state) => state);
  const dispatch = useAppDispatch();

  const { show, RenderDialog } = useConfirmDialog<IUser>({
    defaults: {
      title: "Remover vinculo com a rede social",
      content:
        "Esta ação não removerá nenhuma informação do Sistema Delta SGE, apenas o vinculo entre as contas. Deseja continuar mesmo assim?",
    },
    onConfirmed: async (payload, hide) => {
      await removeAccount(payload);
      hide();
    },
  });

  const signInGoogle = useCallback(async () => {
    try {
      setSigning(true);
      if (isWebKit) {
        await (
          window as WindowWebKitIOS
        ).webkit.messageHandlers.signInGoogle.postMessage("signInGoogle");
      } else {
        const provider = new GoogleAuthProvider();
        const { user } = await signInWithPopup(auth, provider);
        setUser(user);
      }
    } catch (error) {
      snack.error(getError(error));
    } finally {
      setSigning(false);
    }
  }, [auth]);

  const signInApple = useCallback(async () => {
    try {
      setSigning(true);
      if (isWebKit) {
        await (
          window as WindowWebKitIOS
        ).webkit.messageHandlers.signInApple.postMessage("signInApple");
      } else {
        const provider = new OAuthProvider("apple.com");
        provider.addScope("email");
        provider.addScope("name");
        const result = await signInWithPopup(auth, provider);
        setUser(result.user);
      }
    } catch (error) {
      snack.error(getError(error));
    } finally {
      setSigning(false);
    }
  }, [auth]);

  const signOut = useCallback(
    async (props: ISignOutProps | undefined = {}) => {
      try {
        setSignOuting(true);
        if (props.before) props.before();
        if (isWebKit) {
          await (
            window as WindowWebKitIOS
          ).webkit.messageHandlers.signOut.postMessage("signOut");
        } else {
          await auth.signOut();
        }

        if (isSupported && token && permission == "granted") {
          if (
            usuario &&
            usuario.pushTokens &&
            usuario.pushTokens.includes(token)
          ) {
            const pushTokens = usuario.pushTokens.filter((f) => f != token);
            await apiConfig.put(`/usuarios/usuario/${usuario.deltaId}`, {
              ...usuario,
              pushTokens,
            });
          }
          await api.delete(
            `dados-dispositivo/tipo-usuario/${usuario?.tipoUsuario}/token/${token}/usuario/${usuario?.id}`
          );
        }

        setUser(undefined);
        setToken();
        cachePhotoUrl.flush();
        cacheDados.flush();

        await persistor.purge();
        dispatch(logoff());
        if (props.after) props.after();
      } catch (error) {
        snack.error(getError(error));
      } finally {
        setSignOuting(false);
      }
    },
    [isSupported, token, permission, dispatch, usuario, auth]
  );

  const getAuthenticatedUser = useCallback(async () => {
    try {
      if (isWebKit) {
        await (
          window as WindowWebKitIOS
        ).webkit.messageHandlers.getAuthenticatedUser.postMessage(
          "getAuthenticatedUser"
        );
      } else {
        const { currentUser } = auth;
        if (currentUser) setUser(currentUser);
      }
    } catch (error) {
      snack.error(getError(error));
    } finally {
      setLoadingUser(false);
    }
  }, [auth]);

  const signInFromWebKit = (evt: Event) => {
    try {
      const strUser = (evt as CustomEvent).detail;
      const user = JSON.parse(strUser) as ISocialUser;
      setUser(user);
    } catch (error) {
      snack.error(getError(error));
    }
  };

  const getAuthenticatedUserFromWebKit = (evt: Event) => {
    try {
      const strUser = (evt as CustomEvent).detail as string | undefined;
      if (strUser && strUser != "" && strUser.startsWith("{")) {
        const user = JSON.parse(strUser) as ISocialUser;
        setUser(user);
      }
    } catch (error) {
      snack.error(getError(error));
    }
  };

  const removeAccount = async (usuario: IUser) => {
    try {
      if (isWebKit) {
        await (
          window as WindowWebKitIOS
        ).webkit.messageHandlers.removeAccount.postMessage("removeAccount");
        await (
          window as WindowWebKitIOS
        ).webkit.messageHandlers.signOut.postMessage("signOut");
      } else {
        const { currentUser } = auth;
        if (currentUser) {
          try {
            await deleteUser(currentUser);
          } catch (error) {
            if ((error as AuthError).code == "auth/requires-recent-login") {
              let newUser: User | null = null;
              if (
                currentUser.providerData.find(
                  (f) => f.providerId == "google.com"
                )
              ) {
                const provider = new OAuthProvider("google.com");
                newUser = (await reauthenticateWithPopup(currentUser, provider))
                  .user;
              } else {
                const provider = new OAuthProvider("apple.com");
                newUser = (await reauthenticateWithPopup(currentUser, provider))
                  .user;
              }
              if (newUser) await deleteUser(currentUser);
            } else {
              throw error;
            }
          }
        }
        await auth.signOut();
      }
      const payload = { ...usuario };
      payload.firebaseEmail = null;
      payload.firebaseId = null;
      await apiConfig.put(`usuarios/usuario/${payload.deltaId}`, payload);

      setUser(undefined);
      setToken();
      cachePhotoUrl.flush();
      cacheDados.flush();

      await persistor.purge();
      dispatch(logoff());
    } catch (error) {
      snack.error(getError(error));
    }
  };

  useEffect(() => {
    if (!user) {
      setLoadingUser(true);
      window.setTimeout(() => {
        getAuthenticatedUser();
      }, 1500);
    }
    window.addEventListener("signInGoogle", signInFromWebKit);
    window.addEventListener("signInApple", signInFromWebKit);
    window.addEventListener(
      "getAuthenticatedUser",
      getAuthenticatedUserFromWebKit
    );

    return () => {
      window.removeEventListener("signInGoogle", signInFromWebKit);
      window.removeEventListener("signInApple", signInFromWebKit);
      window.removeEventListener(
        "getAuthenticatedUser",
        getAuthenticatedUserFromWebKit
      );
    };
  }, []);

  useEffect(() => {
    if (
      user &&
      usuario &&
      user.uid != usuario.firebaseId &&
      !usuario.firebaseId
    ) {
      (async () => {
        const payload: IUser = {
          ...usuario,
          firebaseEmail: user.email,
          firebaseId: user.uid,
          displayName: user.displayName,
          photoUrl: user.photoURL,
        };
        const { data: usuarioConfig } = await apiConfig.put<IUser>(
          `usuarios/usuario/${usuario.deltaId}`,
          payload
        );
        dispatch(setUsuario(usuarioConfig));
      })();
    }
  }, [user, usuario, dispatch]);

  const AppleButton = useCallback(
    (props?: ButtonProps): JSX.Element => (
      <ThemeProvider theme={iosTheme}>
        <Button startIcon={<IppleIcon />} {...props} onClick={signInApple}>
          Continuar com a Apple
        </Button>
      </ThemeProvider>
    ),
    [signInApple]
  );

  const GoogleButton = useCallback(
    (props?: ButtonProps): JSX.Element => (
      <ThemeProvider theme={googleTheme}>
        <Button startIcon={<GoogleIcon />} {...props} onClick={signInGoogle}>
          Continuar com a Google
        </Button>
      </ThemeProvider>
    ),
    [signInGoogle]
  );

  const SocialUserComponent = useCallback(
    ({
      showSignOut = true,
      signOut: customSignOut,
    }: ISocialUserComponentProps): JSX.Element | null => {
      if (!user) return null;
      return (
        <List>
          <ListItem dense>
            <ListItemAvatar>
              <Avatar
                displayName={user.displayName ?? ""}
                src={user.photoURL ?? undefined}
              />
            </ListItemAvatar>
            <ListItemText
              primary={user.displayName ?? ""}
              secondary={user.email ?? ""}
            />
            {showSignOut && (
              <ListItemSecondaryAction>
                {signOuting ? (
                  <CircularProgress />
                ) : (
                  showSignOut && (
                    <Tooltip
                      title="Sair desta conta"
                      aria-label="remove-tooltip"
                    >
                      <IconButton
                        edge="end"
                        aria-label="remove-button"
                        onClick={async () => {
                          if (customSignOut) await customSignOut();
                          else await signOut();
                        }}
                      >
                        <RemoveCircleOutlineIcon />
                      </IconButton>
                    </Tooltip>
                  )
                )}
              </ListItemSecondaryAction>
            )}
          </ListItem>
        </List>
      );
    },
    [signOut, signOuting, user]
  );

  const AuthenticatedUserComponent = useCallback(
    ({
      onClick,
      showRemoveLinkAccount = false,
    }: IAuthenticatedUserComponentProps): JSX.Element | null => {
      if (!usuario) return null;
      if (!onClick)
        return (
          <ListItem>
            <ListItemAvatar>
              <Avatar
                displayName={usuario.displayName ?? usuario.nome.rUpper()}
                src={usuario.photoUrl}
                photoS3={usuario.photoS3}
              />
            </ListItemAvatar>
            <ListItemText
              primary={usuario.displayName ?? usuario?.nome}
              secondary={usuario.email}
            />
            {showRemoveLinkAccount && usuario.firebaseId && (
              <Tooltip
                title="Remover vinculo com a rede social"
                aria-label="remove-vinculo"
              >
                <IconButton
                  color="error"
                  edge="end"
                  aria-label="remove-vinculo-button"
                  onClick={() => show(usuario)}
                >
                  <RemoveIcon />
                </IconButton>
              </Tooltip>
            )}
          </ListItem>
        );
      return (
        <ListItem disablePadding>
          <ListItemButton onClick={onClick}>
            <ListItemAvatar>
              <Avatar
                displayName={usuario.displayName ?? usuario.nome.rUpper()}
                src={usuario.photoUrl}
                photoS3={usuario.photoS3}
              />
            </ListItemAvatar>
            <ListItemText
              primary={usuario.displayName ?? usuario?.nome}
              secondary={usuario.email}
            />
            {showRemoveLinkAccount && usuario.firebaseId && (
              <Tooltip
                title="Remover vinculo com a rede social"
                aria-label="remove-vinculo"
              >
                <IconButton
                  color="error"
                  edge="end"
                  aria-label="remove-vinculo-button"
                  onClick={() => show(usuario)}
                >
                  <RemoveIcon />
                </IconButton>
              </Tooltip>
            )}
          </ListItemButton>
        </ListItem>
      );
    },
    [show, usuario]
  );

  return (
    <AuthenticationContext.Provider
      value={{
        AppleButton,
        GoogleButton,
        signInApple,
        signInGoogle,
        signOut,
        user,
        signOuting,
        signing,
        loadingUser,
        getAuthenticatedUser,
        SocialUserComponent,
        AuthenticatedUserComponent,
      }}
    >
      <RenderDialog />
      {children}
    </AuthenticationContext.Provider>
  );
};
