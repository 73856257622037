import { useEffect } from "react";
import { logEvent } from "firebase/analytics";
import { useLocation } from "react-router-dom";
import { analytics } from "App";

export const useFirebaseRoutesAnalytics = (): null => {
  const location = useLocation();
  useEffect(() => {
    logEvent(analytics, "screen_view", {
      firebase_screen: `${window.location.hostname}${location.pathname}`,
      firebase_screen_class: "firebase-routes-analytics",
    });
  }, [location]);

  return null;
};
