import React, { FC, useState } from "react";
import { IFiltroCurso } from "@deltasge/marauders-map";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Radio,
  TextField,
  Typography,
} from "@mui/material";
import { ChevronDown as ExpandMoreIcon } from "mdi-material-ui";

interface Props {
  selecionado?: IFiltroCurso;
  cursos?: IFiltroCurso[];
  onClick: (id?: number) => void;
  render: "accordion" | "autocomplete";
  mostrarTodos: boolean;
}

export const FilterCurso: FC<Props> = ({
  selecionado,
  onClick,
  render,
  cursos,
  mostrarTodos,
}) => {
  const [panel, setPanel] = useState(false);

  const handleClick = (id?: number) => {
    onClick(id);
    if (render == "accordion") setPanel(false);
  };

  if (render == "accordion") {
    return (
      <Accordion
        elevation={0}
        expanded={panel}
        onChange={() => setPanel(!panel)}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Grid container>
            <Grid item xs={12} component={Typography} variant="subtitle2">
              Curso
            </Grid>
            <Grid item xs={12} component={Typography} variant="caption">
              {!selecionado ? "TODOS OS CURSOS" : selecionado.nome?.rUpper()}
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <List dense>
            {mostrarTodos && (
              <ListItemButton onClick={() => handleClick()}>
                <ListItemIcon>
                  <Radio checked={undefined == selecionado} />
                </ListItemIcon>
                <ListItemText primary="TODOS OS CURSOS" />
              </ListItemButton>
            )}
            {cursos?.map((curso) => (
              <ListItemButton
                key={`filter-curso-${curso.id}`}
                onClick={() => handleClick(curso.id)}
              >
                <ListItemIcon>
                  <Radio checked={curso.id == selecionado?.id} />
                </ListItemIcon>
                <ListItemText
                  primary={curso.nome?.rUpper()}
                  primaryTypographyProps={{
                    noWrap: true,
                  }}
                />
              </ListItemButton>
            ))}
          </List>
        </AccordionDetails>
      </Accordion>
    );
  }

  return (
    <Autocomplete
      sx={{ m: 1 }}
      disablePortal
      id="filter-curso"
      options={cursos ?? []}
      value={selecionado}
      renderInput={(params) => <TextField {...params} label="Curso" />}
      getOptionLabel={(option) => option.nome?.rUpper() ?? ""}
      onChange={(event, value) => handleClick(value?.id)}
      noOptionsText="Selecione um curso"
      clearText="Todos os cursos"
      openText="Selecione um curso"
      renderOption={(props, option) => (
        <ListItem {...props}>
          <ListItemText
            key={option.id}
            primary={option.nome?.rUpper()}
            primaryTypographyProps={{
              noWrap: true,
            }}
          />
        </ListItem>
      )}
    />
  );
};
