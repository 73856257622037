import { styled, SxProps, Theme } from "@mui/material/styles";
import { Environment } from "configs";

export const container: SxProps<Theme> = {
  display: "flex",
  flexGrow: 1,
};

export const drawer: SxProps<Theme> = {
  width: (theme) => theme.spacing(Environment.DRAWER_WIDTH),
  "& .MuiDrawer-paper": {
    width: (theme) => theme.spacing(Environment.DRAWER_WIDTH),
    overflow: "hidden",
  },
};

export const list: SxProps<Theme> = { width: "100%" };

export const ToolbarWrapper = styled("div")(({ theme }) => ({
  ...theme.mixins.toolbar,
}));

export const Image = styled("img")(({ theme }) => ({
  width: theme.spacing(30),
}));
