import React, { useContext, useEffect, FC, useState } from "react";
import { Socket } from "socket.io-client";
import { useAppSelector } from "store";
import { IO } from "./IO";

const SocketContext = React.createContext<Socket | undefined>(undefined);

export const useSocket = () => {
  return useContext(SocketContext);
};

const useProviderSocket = () => {
  const {
    usuario: { usuario },
  } = useAppSelector((state) => state);
  const [socket, setSocket] = useState<Socket>();
  const [connected, setConnected] = useState(false);
  const [error, setError] = useState();

  useEffect(() => {
    if (usuario && !socket) {
      const { client } = IO.getInstance(usuario);
      if (client) {
        client?.on("error", (data) => {
          // eslint-disable-next-line no-console
          console.log(data);
          setError(data);
        });
        client?.on("connect", () => {
          setConnected(true);
        });
        client?.on("disconnect", (data) => {
          // eslint-disable-next-line no-console
          console.log("Disconnected", data);
          setConnected(false);
        });
        setSocket(client);
      }
    }
    return () => {
      socket?.off("connect");
      socket?.off("disconnect");
      socket?.off("pong");
    };
  }, [usuario]);

  return { socket, error, connected };
};

const ProviderSocket: FC = ({ children }) => {
  const { socket } = useProviderSocket();
  return (
    <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
  );
};

export default ProviderSocket;
