import { createReducer } from "@reduxjs/toolkit";
import { ActionTypes } from "./types";

interface IState {
  loading: boolean;
  error: boolean;
}

const INITIAL_STATE: IState = {
  loading: true,
  error: false,
};

export const reducers = createReducer(INITIAL_STATE, {
  [ActionTypes.REQUEST]: () => ({ loading: true, error: false }),
  [ActionTypes.SUCCESS]: () => ({ loading: false, error: false }),
  [ActionTypes.FAILURE]: () => ({ loading: false, error: true }),
  [ActionTypes.CLEAN]: () => ({ loading: true, error: false }),
});
