import React, { FC, useState } from "react";
import { Box, Button, ButtonGroup, Divider } from "@mui/material";
import {
  ContentCopy as CopyIcon,
  ContentPaste as PasteIcon,
  PostOutline as ReportIcon,
  Close as CleanIcon,
  TableCheck as NotasIcon,
} from "mdi-material-ui";

import { Environment, history } from "configs";
import { useAppSelector } from "store";
import { LoadingButton } from "@mui/lab";
import { snack } from "components/GlobalSnackbar";
import { getError } from "utils";
import {
  EUserType,
  IFiltroMateria,
  IFiltroTurma,
} from "@deltasge/marauders-map";
import { IFilterAvaliacao } from "interfaces";

export const Filter: FC<{
  Component: () => JSX.Element;
  filter: IFilterAvaliacao;
  setCopiadas: (value: number[]) => void;
  handleColar: () => void;
  selecionadas: number[];
  copiadas: number[];
  temAvaliacoes: boolean;
  colando: boolean;
  turma?: IFiltroTurma;
  materia?: IFiltroMateria;
}> = ({
  Component,
  filter,
  selecionadas,
  temAvaliacoes,
  colando,
  copiadas,
  setCopiadas,
  handleColar,
  turma,
  materia,
}) => {
  const {
    escola,
    usuario: { usuario },
    configuracoes: {
      configOnline: { configuracoes },
    },
  } = useAppSelector((state) => state);

  const [loading, setLoading] = useState(false);

  const handleRelatorio = async () => {
    try {
      if (!filter.idMateria) {
        throw new Error("Código da matéria não encontrado");
      }

      setLoading(true);
      const codProfessor =
        usuario?.tipoUsuario == EUserType.Professor ? usuario.codigo : "000";
      const cst = `${turma?.cursoDbf}${turma?.serieDbf}${turma?.codigoDbf}`;
      const location = encodeURIComponent(
        `${escola.onlineExt}professor/api/relatorios/avaliacoes.php?turma=${cst}&materia=${materia?.codigo}&bimestre=${filter.etapa}&professor=${codProfessor}&ano_letivo=${configuracoes.ano_site}`
      );
      setLoading(false);
      window.open(
        `${escola.onlineExt}api/autorizacao/?Location=${location}&Authorization=${usuario?.token}`
      );
    } catch (error) {
      setLoading(false);
      snack.error(getError(error));
    }
  };

  const handleDigitacao = () => {
    history.push(
      `/notas?idCurso=${filter.idCurso}&idSerie=${filter.idSerie}&idTurma=${filter.idTurma}&idMateria=${filter.idMateria}&etapa=${filter.etapa}`
    );
  };

  return (
    <Box
      sx={{
        width: ({ spacing }) => spacing(Environment.DRAWER_WIDTH),
        display: "block",
      }}
    >
      <Component />
      <Divider />
      <Box sx={{ p: 2 }}>
        <ButtonGroup fullWidth>
          <Button
            startIcon={<CopyIcon />}
            disabled={selecionadas.length == 0}
            onClick={() => setCopiadas(selecionadas)}
          >
            Copiar
          </Button>
          <LoadingButton
            loading={colando}
            startIcon={<PasteIcon />}
            color="success"
            variant="outlined"
            disabled={temAvaliacoes || copiadas.length == 0}
            onClick={handleColar}
          >
            Colar
          </LoadingButton>
          <Button
            startIcon={<CleanIcon />}
            color="warning"
            disabled={copiadas.length == 0}
            onClick={() => setCopiadas([])}
          >
            Limpar
          </Button>
        </ButtonGroup>
        <LoadingButton
          loading={loading}
          variant="outlined"
          fullWidth
          sx={{ mt: 2 }}
          startIcon={<ReportIcon />}
          disabled={!temAvaliacoes}
          onClick={handleRelatorio}
        >
          Relatório
        </LoadingButton>
        <Button
          startIcon={<NotasIcon />}
          variant="outlined"
          fullWidth
          sx={{ mt: 2 }}
          disabled={!temAvaliacoes}
          onClick={handleDigitacao}
        >
          Digitação de notas
        </Button>
      </Box>
    </Box>
  );
};
