import React, { FC } from "react";
import { Route, Router, Switch, Redirect } from "react-router-dom";

import { history, IRoute, routes } from "configs";

import { Interno } from "pages/_layouts/Interno";
import { NotFound } from "pages/_layouts/NotFound";
import { Login } from "pages/Login";
import { CustomRoute } from "./components/CustomRoute";
import { useAppSelector } from "store";
import {
  ImageAlbum as FotoIcon,
  PlaylistCheck as TarefaIcon,
  ClipboardClock as AvaliacaoIcon,
  AccountGroup as ReuniaoIcon,
  Information as MensagensGeraisIcon,
} from "mdi-material-ui";
import { EMensagemCategoriaTipo } from "@deltasge/marauders-map";

const recursive = (arr: IRoute[]): IRoute[] => {
  const accumulated: IRoute[] = [];
  arr.forEach((a) => {
    const { routes: subroutes, ...rest } = a;
    accumulated.push(rest);
    if (subroutes) {
      accumulated.push(...recursive(subroutes));
    }
  });
  return accumulated;
};

const mapRoutes = recursive(routes)
  .filter((f) => !!f.component)
  .map((r, k) => <CustomRoute key={`${r.path}-${k}`} exact {...r} />);

export const Routes: FC = () => {
  const {
    mensagemCategoria: { data },
  } = useAppSelector((state) => state);

  const publicacoes = routes.find((f) => f.path == "/publicacoes");
  if (publicacoes && publicacoes.routes?.length == 0 && data.length > 0) {
    publicacoes.routes = data.map((area) => ({
      showOnSidebar: true,
      label: area.nome,
      path: `/publicacoes/${area.id}/categoria`,
      icon:
        area.tipo == EMensagemCategoriaTipo["Conteúdo para avaliações"]
          ? AvaliacaoIcon
          : area.tipo == EMensagemCategoriaTipo.Fotos
          ? FotoIcon
          : area.tipo == EMensagemCategoriaTipo["Tarefas de Casa"]
          ? TarefaIcon
          : area.tipo == EMensagemCategoriaTipo.Reuniões
          ? ReuniaoIcon
          : MensagensGeraisIcon,
    }));
  }

  return (
    <Router history={history}>
      <Switch>
        <Route exact path="/login" component={Login} />
        <Route exact path="/login/:cliente" component={Login} />
        <Interno>
          <Switch>
            {mapRoutes}
            <Route path="*" component={NotFound} />
          </Switch>
        </Interno>
        <Route path="*" component={() => <Redirect to="/login" />} />
      </Switch>
    </Router>
  );
};
