import { useState } from "react";
import { useAppSelector } from "store";

export function useSessionStorage<T>(key: string, initialValue: T, level = 3) {
  // Dados diferenciais da chave com  escola e do usuário logado
  const { escola, usuario } = useAppSelector((state) => state);
  if (level === 1) {
    key = `1-${key}`;
  } else if (level === 2) {
    key = `${escola.codigo}-${key}`;
  } else {
    key = `${escola.codigo}_${usuario.usuario?.deltaId}-${key}`;
  }

  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState<T>(() => {
    if (typeof window === "undefined") {
      return initialValue;
    }
    try {
      // Get from local storage by key
      const item = window.sessionStorage.getItem(key);
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // If error also return initialValue
      // console.log(error);
      return initialValue;
    }
  });
  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value: T | ((val: T) => T)) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      if (typeof window !== "undefined") {
        window.sessionStorage.setItem(key, JSON.stringify(valueToStore));
      }
    } catch (error) {
      // A more advanced implementation would handle the error case
      // console.log(error);
    }
  };
  return [storedValue, setValue] as const;
}
