import React, { FC, useState } from "react";
import { IAvaCtr, IAvaliacao, IDNColumn } from "@deltasge/marauders-map";
import {
  Box,
  Checkbox,
  TableCell,
  TableRow as TableRowMUI,
  Theme,
  Tooltip,
} from "@mui/material";
import { TableCellDescription } from "./TableCellDescription";
import { useDialog } from "hooks/useDialog";
import { OpcoesNotas } from "./Opcoes";

const sxTableCell =
  ({ column, showCursor }: { column: IDNColumn; showCursor: boolean }) =>
  (theme: Theme) => ({
    maxWidth: column.widthMUI ? theme.spacing(column.widthMUI) : "auto",
    borderLeft: `1px solid ${theme.palette.divider}`,
    "&:first-of-type": {
      borderLeft: "none",
    },
    cursor: showCursor ? "pointer" : "inherit",
  });

export const TableRowHead: FC<{
  columns: IDNColumn[];
  handlePublicar: (column: IDNColumn) => void;
  handleColar: (params: {
    colar: IAvaliacao;
    copiar: IAvaliacao;
  }) => Promise<void>;
  handleImportar: (params: {
    file: File;
    idAvaliacao: number;
  }) => Promise<void>;
  avaCtr?: IAvaCtr;
}> = ({ columns, handlePublicar, handleColar, handleImportar, avaCtr }) => {
  const [avaliacao, setAvaliacao] = useState<IAvaliacao>();

  const { RenderDialog, show, hide } = useDialog({
    title: "Opções",
    options: {
      maxWidth: "xs",
    },
  });

  const handleClick = (
    event: React.MouseEvent<HTMLTableCellElement, MouseEvent>,
    column: IDNColumn
  ) => {
    event.preventDefault();
    event.stopPropagation();

    setAvaliacao(column.avaliacao);
    show();
  };

  return (
    <TableRowMUI>
      {avaliacao && (
        <RenderDialog>
          <OpcoesNotas
            avaliacao={avaliacao}
            handleClose={hide}
            handleColar={handleColar}
            handleImportar={handleImportar}
            disabled={avaCtr?.concluido ?? true}
          />
        </RenderDialog>
      )}
      {columns
        .filter((f) => f.visivel)
        .map((column) => {
          const avaliacao = column.avaliacao;
          return (
            <TableCell
              padding={avaliacao ? "checkbox" : "normal"}
              onClick={
                avaliacao && (!avaCtr?.concluido ?? true)
                  ? (event) => handleClick(event, column)
                  : undefined
              }
              key={`column-${column.id}`}
              align={column.align}
              sx={sxTableCell({
                column,
                showCursor: !!avaliacao && (!avaCtr?.concluido ?? true),
              })}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                {avaliacao && (
                  <Tooltip
                    title={
                      avaliacao.liberado
                        ? "Remover publicação das notas"
                        : "Publicar notas"
                    }
                  >
                    <Checkbox
                      sx={{ mr: 1 }}
                      checked={avaliacao.liberado}
                      disabled={avaCtr?.concluido ?? true}
                      onClick={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        handlePublicar(column);
                      }}
                    />
                  </Tooltip>
                )}
                <TableCellDescription
                  column={column}
                  showDetalhes={!!avaliacao && (!avaCtr?.concluido ?? true)}
                />
              </Box>
            </TableCell>
          );
        })}
    </TableRowMUI>
  );
};
