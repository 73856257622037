import React, { FC } from "react";

import { Fab } from "@mui/material";
import { Add as AddIcon } from "@mui/icons-material";

import { ZoomOnScroll } from "components/ZoomOnScroll";
import { Environment } from "configs";
import { useAppSelector } from "store";

export const Add: FC<{
  handleAdd: () => void;
}> = ({ handleAdd }) => {
  const rightbarIsOpen = useAppSelector(
    (state) => state.layout.rightbar.isOpen
  );

  return (
    <ZoomOnScroll>
      <Fab
        onClick={handleAdd}
        key="btn-add-conversa"
        color="secondary"
        sx={({ spacing }) => ({
          position: "fixed",
          bottom: spacing(2),
          right: !rightbarIsOpen
            ? spacing(2)
            : spacing(Environment.DRAWER_WIDTH + 2),
        })}
      >
        <AddIcon />
      </Fab>
    </ZoomOnScroll>
  );
};
