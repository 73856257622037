import React, { FC, useEffect, useState } from "react";
import { EUserType, IChatGroup, IUser } from "@deltasge/marauders-map";

import { Alert, Box, LinearProgress, List, Paper } from "@mui/material";
import {
  Filter as FilterIcon,
  FilterCheck as FilteredIcon,
} from "mdi-material-ui";

import { apiConfig, apiPush } from "configs";
import { Add } from "./components/Add";
import { ChatFilter } from "./components/ChatFilter";
import { snack } from "components/GlobalSnackbar";
import { NovaConversa } from "./components/NovaConversa";
import { useDialog } from "hooks/useDialog";
import { Main } from "pages/_layouts/Main";
import { useAppSelector } from "store";
import { getError, loadS3File } from "utils";
import { ListItem } from "./components/ListItem";
import { useSnackbar } from "notistack";
import { ChatUndo } from "./components/ChatUndo";
import { isBefore, parseISO } from "date-fns";

export type Setor =
  | "secretaria"
  | "coordenacao"
  | "tesouraria"
  | "diretoria"
  | "professor";
export interface IChatFilter {
  arquivadas: boolean;
  idSetor?: Setor;
  usuario?: IUser;
}

export const Chats: FC = () => {
  const [loading, setLoading] = useState(false);
  const [chats, setChats] = useState<IChatGroup[]>([]);
  const [filter, setFilter] = useState<IChatFilter>({
    arquivadas: false,
  });
  const [usuarios, setUsuarios] = useState<IUser[]>([]);

  const { closeSnackbar, enqueueSnackbar } = useSnackbar();

  const {
    usuario: { usuario },
  } = useAppSelector((state) => state);

  const getChats = async (deltaId: string) => {
    try {
      setLoading(true);
      const { data } = await apiPush.get<IChatGroup[]>("/api/v3/chat-group/", {
        params: {
          where: {
            users: deltaId,
            idEscola: usuario?.escolaId,
            idMensagem: null,
            chats: {
              $ne: [],
              // $elemMatch: {
              //   createdAt: { $gt: "$deleted" },
              // },
            },
          },
          orderBy: {
            "chats.updatedAt": -1,
          },
          select: {
            idEscola: 1,
            idSetor: 1,
            users: 1,
            archived: 1,
            deleted: 1,
            chats: { $slice: -1 },
          },
        },
      });
      if (data && data.length > 0) {
        const usersStr: (string | undefined)[] = [];
        data.map((m) => usersStr.push(...m.users));

        const { data: users } = await apiConfig.get<IUser[]>("v2/usuarios", {
          params: {
            where: {
              deltaId: { $in: usersStr },
            },
            select: [
              "deltaId",
              "photoUrl",
              "photoS3",
              "nome",
              "displayName",
              "email",
              "firebaseEmail",
              "codigo",
            ],
          },
        });
        const allUsers: IUser[] = [];
        if (users && users.length > 0) {
          allUsers.push(
            ...(await Promise.all(
              users.map(async (user) => {
                if (user.photoS3 && user.photoUrl) {
                  const { processed } = await loadS3File(user.photoUrl, true);
                  user.photoUrl = processed;
                }
                return user;
              })
            ))
          );
          setUsuarios(allUsers);
        }
        setChats(
          data.map((chat) => {
            const chatUsers = allUsers.filter((user) => {
              const result = chat.users.filter((deltaId) =>
                user.deltaId.includes(deltaId ?? "")
              );
              return result.length > 0;
            });
            chat.chatUsers = chatUsers;
            return chat;
          })
        );
      }
    } catch (error) {
      snack.warning(getError(error));
    } finally {
      setLoading(false);
    }
  };

  const handleChat = async ({
    chat,
    action,
    add = true,
  }: {
    chat: IChatGroup;
    action: "archived" | "deleted";
    add?: boolean;
  }) => {
    try {
      const item = chats.find((f) => f._id == chat._id);
      if (!usuario) {
        throw new Error(
          "Não foi possível encontrar seu usuário nesta conversa"
        );
      }
      if (!item) {
        throw new Error("Não foi possível encontrar esta conversa");
      }
      await apiPush.put(`/api/v3/chat-group/archive-delete/${chat._id}`, {
        deltaId: usuario.deltaId,
        add,
        action,
      });
      if (!item[action] && add) {
        item[action] = [{ deltaId: usuario.deltaId, at: new Date() }];
      } else {
        const index = item[action].findIndex(
          (f) => f.deltaId == usuario.deltaId
        );
        if (index >= 0 && add) {
          item[action][index] = { deltaId: usuario.deltaId, at: new Date() };
        } else if (index >= 0 && !add) {
          item[action] = item[action].filter(
            (f) => f.deltaId != usuario.deltaId
          );
        } else {
          item[action].push({ deltaId: usuario.deltaId, at: new Date() });
        }
      }
      setChats([
        ...chats.map((m) => {
          if (m._id == chat._id) return chat;
          return m;
        }),
      ]);
      if (add) {
        enqueueSnackbar(
          <ChatUndo
            handleClick={() => {
              closeSnackbar("ChatHandleDesfazer");
              handleChat({ chat, action, add: false });
            }}
            action={action}
          />,
          {
            key: "ChatHandleDesfazer",
          }
        );
      }
    } catch (error) {
      snack.warning(getError(error));
    }
  };

  useEffect(() => {
    if (usuario?.deltaId && chats.length == 0) {
      getChats(usuario.deltaId);
    }
  }, []);

  const {
    RenderDialog: DialogNovaConversa,
    show: showNovaConversa,
    hide: hideNovaConverva,
  } = useDialog({
    title: "Nova Conversa",
    options: {
      maxWidth: "sm",
    },
  });

  const idSetores: string[] = [];
  if (usuario?.secretaria) {
    idSetores.push("secretaria");
  }
  if (usuario?.coordenacao) {
    idSetores.push("coordenacao");
  }
  if (usuario?.tesouraria) {
    idSetores.push("tesouraria");
  }
  if (usuario?.diretoria) {
    idSetores.push("diretoria");
  }

  const wasFiltered = filter.arquivadas || !!filter.idSetor || !!filter.usuario;

  const filteredChat = chats.filter((f) => {
    let arquivadas = true;
    let setor = true;
    let user = true;
    let excluidas = true;

    const deleted = f.deleted.find((d) => d.deltaId == usuario?.deltaId);
    if (!deleted) excluidas = true;
    else {
      excluidas = !!f.chats?.find((chat) => {
        const createdAt = parseISO((chat.createdAt || "").toString());
        const deletedAt = parseISO(deleted.at.toString());
        return isBefore(deletedAt, createdAt);
      });
    }

    if (filter.arquivadas) {
      arquivadas = !!f.archived.find((f) => f.deltaId == usuario?.deltaId);
    } else {
      arquivadas = !f.archived.find((f) => f.deltaId == usuario?.deltaId);
    }

    setor = !filter.idSetor || filter.idSetor == f.idSetor;

    user =
      !filter.usuario ||
      !!f.chatUsers?.find((u) => u.deltaId == filter.usuario?.deltaId);

    return arquivadas && setor && user && excluidas;
  });

  return (
    <Main
      maxWidth={false}
      title="Conversas"
      rightbarChildren={
        <ChatFilter usuarios={usuarios} filter={filter} setFilter={setFilter} />
      }
      rightbarIcon={wasFiltered ? <FilteredIcon /> : <FilterIcon />}
    >
      <Box p={1} height="100%">
        <DialogNovaConversa>
          <NovaConversa handleClose={hideNovaConverva} idSetores={idSetores} />
        </DialogNovaConversa>
        {!loading && chats.length == 0 && (
          <Alert severity="info">Nenhuma conversa</Alert>
        )}
        {loading && <LinearProgress />}
        {!loading && chats.length > 0 && (
          <List component={Paper}>
            {filteredChat.map((chat, index, arr) => (
              <ListItem
                key={chat._id}
                item={chat}
                divider={index + 1 < arr.length}
                handleChat={handleChat}
              />
            ))}
          </List>
        )}
        <Add
          handleAdd={() => {
            if (
              idSetores.length > 0 ||
              usuario?.tipoUsuario == EUserType.Professor
            ) {
              showNovaConversa();
            } else {
              snack.warning("Seu usuário não tem nenhum perfil configurado");
            }
          }}
        />
      </Box>
    </Main>
  );
};
