import { SxProps, Theme } from "@mui/material";
import { red, green } from "@mui/material/colors";

export const submit: SxProps<Theme> = {
  padding: (theme) => theme.spacing(1, 3),
  " > * ": {
    marginLeft: 1,
  },
};

export const submitPaper: SxProps<Theme> = {
  float: "right",
  marginTop: 2,
};

export const fieldset: SxProps<Theme> = {
  border: (theme) =>
    theme.palette.mode !== "dark"
      ? "1px solid rgba(0, 0, 0, 0.12)"
      : "1px solid rgba(255, 255, 255, 0.12)",
  borderRadius: (theme) => theme.spacing(0.5),
};

export const list: SxProps<Theme> = {
  height: (theme) => theme.spacing(50),
};

export const checked: SxProps<Theme> = {
  color: green[600],
};

export const unchecked: SxProps<Theme> = {
  color: red[600],
};
