import React, { FC, useRef } from "react";

import {
  DialogTitle,
  useMediaQuery,
  useTheme,
  Breakpoint,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { BootstrapDialog } from "./styles";
import { PaperComponent } from "components/PaperComponent";

export interface PropsToolbarDialog {
  hide: () => void;
  title?: string;
}

export interface Props {
  hide: () => void;
  title?: string;
  maxWidth?: Breakpoint | false;
  modal?: boolean;
  autoWidth?: boolean;
  scroll?: "paper" | "body" | undefined;
  fullScreen?: boolean;
  RenderToolbar?: FC<PropsToolbarDialog>;
}

const ToolbarDialog: FC<{
  hide: () => void;
  title?: string;
}> = ({ hide, title }) => (
  <Toolbar>
    <IconButton edge="start" color="inherit" onClick={hide} aria-label="close">
      <CloseIcon />
    </IconButton>
    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
      {title}
    </Typography>
  </Toolbar>
);

export const DialogWrapper: FC<Props> = ({
  hide,
  children,
  title = "",
  maxWidth = "md",
  modal = false,
  autoWidth = false,
  scroll,
  fullScreen = false,
  RenderToolbar,
}) => {
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));

  const renderCounter = useRef(0);
  renderCounter.current = renderCounter.current + 1;
  const idDialog = `dialog-${renderCounter.current}`;

  return (
    <BootstrapDialog
      fullScreen={fullScreen || isSm}
      open
      onClose={hide}
      maxWidth={!autoWidth ? maxWidth : false}
      disableEscapeKeyDown={modal}
      scroll={scroll}
      aria-labelledby={idDialog}
      PaperComponent={
        !fullScreen && !isSm
          ? (props) => PaperComponent(props, idDialog)
          : undefined
      }
    >
      {(fullScreen || isSm) && (
        <AppBar
          sx={{
            position: "relative",
            paddingRight: "0 !important",
          }}
          elevation={0}
          color="transparent"
        >
          {RenderToolbar && <RenderToolbar hide={hide} title={title} />}
          {!RenderToolbar && <ToolbarDialog hide={hide} title={title} />}
        </AppBar>
      )}
      {!fullScreen && !isSm && (
        <DialogTitle sx={{ cursor: "move" }} id={idDialog}>
          {title}
        </DialogTitle>
      )}
      <>{children}</>
    </BootstrapDialog>
  );
};
