export enum ActionTypes {
  SET = "@config-papeis-padrao/SET",
}

export interface IConfigPapelPadrao {
  width: number;
  height: number;
  marginTop: number;
  marginBottom: number;
  marginLeft: number;
  marginRight: number;
}
