import React, { FC, useState } from "react";
import {
  ESituacaoMatricula,
  IAlunoAplicativo,
  IMatricula,
  utils,
} from "@deltasge/marauders-map";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Badge,
  Box,
  Grid,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Radio,
  Typography,
} from "@mui/material";
import {
  ChevronDown as ExpandMoreIcon,
  ArrowRightThick as SituacaoIcon,
} from "mdi-material-ui";

import { Avatar } from "components/Avatar";
import { Environment } from "configs";
import { IFilterOcorrencia } from "interfaces";

export const Filter: FC<{
  Component: () => JSX.Element;
  filter: IFilterOcorrencia;
  navigate: (params: IFilterOcorrencia) => void;
  matriculas: IMatricula[];
}> = ({ filter, Component, navigate, matriculas }) => {
  const [panel, setPanel] = useState(false);

  return (
    <Box
      sx={{
        width: ({ spacing }) => spacing(Environment.DRAWER_WIDTH),
        display: "block",
      }}
    >
      <Component />
      <Accordion
        elevation={0}
        expanded={panel}
        onChange={() => setPanel(!panel)}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Grid container>
            <Grid item xs={12} component={Typography} variant="subtitle2">
              Aluno
            </Grid>
            <Grid item xs={12} component={Typography} variant="caption">
              {!filter.idAluno
                ? "TODOS OS ALUNOS"
                : matriculas.find((f) => f.aluno?.uid == filter.idAluno)?.aluno
                    ?.nome}
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <List dense>
            <ListItemButton
              onClick={() => {
                navigate({ ...filter, idAluno: undefined });
              }}
            >
              <ListItemIcon>
                <Radio checked={undefined == filter.idAluno} />
              </ListItemIcon>
              <ListItemText primary="TODOS OS ALUNOS" />
            </ListItemButton>
            {matriculas.map((m) => (
              <ListItemButton
                key={`mtricula_filter_${m.id}`}
                onClick={() => {
                  navigate({ ...filter, idAluno: m.aluno?.uid });
                }}
              >
                <ListItemIcon>
                  <Radio checked={m.aluno?.uid == filter.idAluno} />
                </ListItemIcon>
                <ListItemAvatar>
                  <Badge
                    overlap="circular"
                    badgeContent={
                      !utils.matriculaAtiva(
                        m.situacao as ESituacaoMatricula
                      ) ? (
                        <SituacaoIcon color="secondary" />
                      ) : undefined
                    }
                  >
                    <Avatar
                      key={"matricula_aluno_avatar_" + m.id}
                      displayName={m.aluno?.nome}
                      src={
                        (m.aluno as IAlunoAplicativo).photoUrl ??
                        m.aluno?.imagemAluno?.caminhoArquivo
                      }
                    />
                  </Badge>
                </ListItemAvatar>
                <ListItemText
                  primary={`${m.nrChamada} - ${m.aluno?.nome?.rUpper()}`}
                  primaryTypographyProps={{
                    noWrap: true,
                  }}
                />
              </ListItemButton>
            ))}
          </List>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};
