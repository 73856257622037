import React from "react";
import { Paper, PaperProps } from "@mui/material";
import Draggable from "react-draggable";

export function PaperComponent(props: PaperProps, id: string) {
  return (
    <Draggable handle={`#${id}`} cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}
