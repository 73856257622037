import React, { FC } from "react";
import { IconButton, Paper, Theme } from "@mui/material";
import { ColorPartial } from "@mui/material/styles/createPalette";
import { Check as CheckIcon } from "mdi-material-ui";
export const ColorCircle: FC<{
  color?: ColorPartial;
  size?: number;
  selected?: boolean;
  onClick?: (color: ColorPartial) => void;
}> = ({ color, size = 5, selected = false, onClick }) => {
  const sx = (theme: Theme) => ({
    background: color ? color[600] : undefined,
    width: theme.spacing(size),
    height: theme.spacing(size),
    borderRadius: "50%",
    p: 1,
    color:
      color && color[600]
        ? theme.palette.getContrastText(color[600])
        : "inherit",
  });
  if (onClick && color) {
    return (
      <IconButton onClick={() => onClick(color)}>
        <Paper elevation={3} sx={sx}>
          {selected && <CheckIcon />}
        </Paper>
      </IconButton>
    );
  }
  return (
    <Paper elevation={3} sx={sx}>
      {selected && <CheckIcon />}
    </Paper>
  );
};
