import { createTheme } from "@mui/material/styles";
import { grey, green, red, lime, blueGrey } from "@mui/material/colors";
import { ITheme } from "store/modules/layout";

export const defaultTheme = ({
  primary,
  secondary,
  inputType,
  mode: initMode,
}: ITheme) => {
  const darkOS = window.matchMedia("(prefers-color-scheme: dark)").matches;
  const mode = initMode == "auto" ? (darkOS ? "dark" : "light") : initMode;
  return createTheme({
    palette: {
      mode,
      primary: primary ?? (darkOS ? lime : green),
      secondary: secondary ?? (darkOS ? blueGrey : red),
      grey,
    },
    typography: {
      fontFamily: "Roboto",
    },
    components: {
      MuiTextField: {
        defaultProps: {
          variant: inputType ?? "filled",
          fullWidth: true,
          size: "small",
        },
      },
      MuiButton: {
        defaultProps: {
          variant: "outlined",
          color: "primary",
        },
      },
      MuiLinearProgress: {
        defaultProps: {
          variant: "indeterminate",
          color: "secondary",
        },
      },
      MuiTabs: {
        defaultProps: {
          indicatorColor: "primary",
          textColor: "primary",
        },
      },
      MuiDialog: {
        defaultProps: {
          maxWidth: "md",
          fullWidth: true,
        },
      },
      MuiSelect: {
        defaultProps: {
          variant: inputType ?? "filled",
          fullWidth: true,
          size: "small",
        },
      },
      MuiFormControl: {
        defaultProps: {
          variant: inputType ?? "filled",
          fullWidth: true,
          size: "small",
        },
      },
    },
  });
};
