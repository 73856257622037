import Axios from "axios";
import sha256 from "crypto-js/sha256";
import Resizer from "react-image-file-resizer";
import { api, cachePhotoUrl, Environment } from "configs";
import { format } from "date-fns";
import { EUserType, IArquivo, S3PresignedPost } from "@deltasge/marauders-map";
import { store } from "store";
import { getError } from "./error";
import { isImage } from "./is-image";

interface LoadS3 {
  processed?: string;
  error?: string;
}

const resizeFile = (
  file: File,
  { max, quality }: { max?: number; quality?: number } = {}
) => {
  const formats = ["apng", "avif", "gif", "jpeg", "png", "svg+xml", "webp"];
  const compressFormat = file.type.replace("image/", "");
  if (!formats.includes(compressFormat)) {
    throw new Error(
      `A imagem deve ser um dos tipos: ${formats.join(
        ","
      )} e encontrou o tipo: ${compressFormat}`
    );
  }
  return new Promise<File>((resolve) => {
    Resizer.imageFileResizer(
      file,
      max ?? 1024,
      max ?? 1024,
      compressFormat,
      quality ?? 100,
      0,
      (uri) => {
        if (uri && uri instanceof File) resolve(uri);
      },
      "file"
    );
  });
};

const buildHashFile = async (file: File): Promise<string> => {
  const result_base64 = await new Promise((resolve) => {
    const fileReader = new FileReader();
    fileReader.onload = () => resolve(fileReader.result);
    fileReader.readAsDataURL(file);
  });

  return sha256(result_base64 as string).toString();
};

const sendS3File = async ({
  file: selectedFile,
}: {
  file: File;
}): Promise<IArquivo> => {
  const {
    usuario: { usuario },
    escola: { anoSite: anoLetivo },
  } = store.getState();

  if (isImage(selectedFile)) {
    selectedFile = new File(
      [await resizeFile(selectedFile, { max: 1024 })],
      selectedFile.name,
      {
        type: selectedFile.type,
        lastModified: selectedFile.lastModified,
      }
    );
  }

  const hash = await buildHashFile(selectedFile);

  const {
    data: [arquivos],
  } = await api.get<[IArquivo[], number]>("arquivo", {
    params: { hash },
  });

  if (arquivos && arquivos.length > 0) {
    return arquivos[0];
  }

  const name = format(new Date(), "yyyyMMdd_Hmmss").concat(
    `_${selectedFile.name
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replace(/\s/g, "")}`
  );

  const file = new File([selectedFile], name, {
    type: selectedFile.type,
    lastModified: selectedFile.lastModified,
  });

  const { data: s3Pre } = await api.post<S3PresignedPost>(
    `s3/upload/${Environment.KEY_S3_MENSAGENS}/${anoLetivo}`,
    {
      type: file.type,
      size: file.size,
      name: file.name,
    }
  );

  const formData = new FormData();
  Object.entries(s3Pre.fields).forEach(([key, value]) =>
    formData.append(key, value)
  );
  formData.append("file", file);

  await Axios.post(s3Pre.url, formData);

  const { data: arquivo } = await api.post<IArquivo>("arquivo", {
    caminhoArquivo: s3Pre.fields["key"],
    categoria: Environment.KEY_S3_MENSAGENS,
    extensaoArquivo: file.name.split(".").pop() ?? "",
    hash,
    idIncUsuarioProfessor:
      usuario?.tipoUsuario == EUserType.Professor ? usuario.id ?? null : null,
    idIncUsuarioSecretaria:
      usuario?.tipoUsuario == EUserType.UsuarioSistema
        ? usuario.id ?? null
        : null,
    nomeArquivo: file.name,
  });

  return arquivo;
};

const loadS3File = async (
  src: string,
  isPhotoUrl?: boolean
): Promise<LoadS3> => {
  try {
    if (isPhotoUrl) {
      const url = cachePhotoUrl.get<string>(src);
      if (url) return { processed: url };
    }

    const cache24h = 60 * 24;
    const { data } = await api.get<{ url: string }>(
      `s3/object/?key=${encodeURIComponent(src)}&expireInMinutes=${cache24h}`
    );
    if (!data || !data.url) throw new Error("Erro ao buscar a URL");

    if (isPhotoUrl) {
      cachePhotoUrl.set(src, data.url, cache24h);
    }

    return { processed: data.url };
  } catch (error) {
    return { error: getError(error) };
  }
};
const sendS3PhotoUser = async (selectedFile: File) => {
  const {
    usuario: { usuario },
  } = store.getState();

  const ext = selectedFile.name.split(".").pop();
  if (!ext) {
    throw new Error("Não foi possível encontrar a extensão da imagem");
  }
  if (!usuario?.deltaId) {
    throw new Error("Não foi possível encontrar o deltaId de seu usuário");
  }

  const name = `${usuario?.deltaId.toLowerCase()}.${ext}`;
  const file = new File([selectedFile], name, {
    type: selectedFile.type,
    lastModified: selectedFile.lastModified,
  });

  const { data: s3Pre } = await api.post<S3PresignedPost>(`s3/upload/fotos/`, {
    type: file.type,
    size: file.size,
    name: file.name,
  });

  const formData = new FormData();
  Object.entries(s3Pre.fields).forEach(([key, value]) =>
    formData.append(key, value)
  );
  formData.append("file", file);

  await Axios.post(s3Pre.url, formData);

  return s3Pre.fields["key"];
};
export { sendS3File, loadS3File, sendS3PhotoUser };
