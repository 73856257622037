import React, { FC } from "react";
import { IMensagem } from "@deltasge/marauders-map";
import {
  Grid,
  IconButton,
  ListItem as MUIListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  TextBoxSearch as DetailIcon,
  AlertDecagram as IsNewIcon,
} from "mdi-material-ui";
import {
  differenceInMinutes,
  format,
  formatDistance,
  parseISO,
} from "date-fns";
import { ptBR } from "date-fns/locale";
export const ListItemRecebida: FC<{
  item: IMensagem;
  divider?: boolean;
  handleDetalhes: (idMensagem?: number) => void;
}> = ({ item, divider = false, handleDetalhes }) => {
  const atribuicao =
    item.mensagemAtribuicoes != null ? item.mensagemAtribuicoes[0] : null;
  const isNew =
    !item.mensagemVisualizacoes || item.mensagemVisualizacoes.length == 0;
  let enviadoPor = "";
  let turma = "";
  if (atribuicao?.turma) {
    turma = atribuicao?.turma?.tituloTurma;
  } else if (atribuicao?.serie) {
    turma = `Todas as turmas do(a) ${atribuicao?.serie?.descricao}`;
  } else if (atribuicao?.curso) {
    turma = ` Todas as séries do ${atribuicao?.curso?.nome}`;
  } else {
    turma = "Todos os cursos";
  }
  if (item.usuario) {
    enviadoPor = item.usuario.nome;
  } else if (item?.professor) {
    enviadoPor = item.professor.nome;
  } else {
    enviadoPor = "Não identificado";
  }

  let respostaAte: Date | undefined = undefined;
  if (item.respostaAte) {
    respostaAte = parseISO(item.respostaAte.toString());
  }

  return (
    <MUIListItem divider={divider}>
      {isNew && (
        <ListItemIcon sx={{ minWidth: ({ spacing }) => spacing(4) }}>
          <IsNewIcon fontSize="small" color="secondary" />
        </ListItemIcon>
      )}
      <Grid container spacing={1}>
        <Grid item xs={6} sm={3}>
          <Typography variant="caption">Categoria</Typography>
          <Typography variant="caption" fontWeight="bold" component="p">
            {item.mensagemCategoria?.nome}
          </Typography>
        </Grid>
        <Grid item xs={6} sm={3}>
          <Typography variant="caption">Enviado</Typography>
          <Tooltip
            title={format(
              parseISO((item.createdAt ?? "") as string),
              "dd/MM/yyyy H:mm:ss"
            )}
          >
            <Typography variant="caption" fontWeight="bold" component="p">
              {formatDistance(
                parseISO((item.createdAt ?? "") as string),
                new Date(),
                { addSuffix: true, locale: ptBR }
              )}
            </Typography>
          </Tooltip>
        </Grid>
        <Grid item xs={6} sm={3}>
          <Typography variant="caption">Turma</Typography>
          <Typography variant="caption" fontWeight="bold" component="p">
            {turma}
          </Typography>
        </Grid>
        <Grid item xs={6} sm={3}>
          <Typography variant="caption">Enviado por</Typography>
          <Typography variant="caption" fontWeight="bold" component="p">
            {enviadoPor}
          </Typography>
        </Grid>
        {respostaAte && (
          <Grid item xs={6} sm={3}>
            <Typography variant="caption">
              {differenceInMinutes(respostaAte, Date.now()) > 0
                ? "Entrega"
                : "Entregue"}
            </Typography>
            <Tooltip title={format(respostaAte, "dd/MM/yyyy H:mm:ss")}>
              <Typography
                variant="caption"
                fontWeight="bold"
                component="p"
                color={
                  differenceInMinutes(respostaAte, Date.now()) > 0
                    ? "primary"
                    : "inherit"
                }
              >
                {formatDistance(respostaAte, new Date(), {
                  addSuffix: true,
                  locale: ptBR,
                })}
              </Typography>
            </Tooltip>
          </Grid>
        )}
        <Grid item xs={12}>
          {item.titulo && (
            <Typography variant="body1" noWrap>
              {item.titulo}
            </Typography>
          )}
          {item.texto && !item.titulo && (
            <div dangerouslySetInnerHTML={{ __html: item.texto ?? "" }} />
          )}
        </Grid>
      </Grid>
      <ListItemSecondaryAction>
        <Tooltip title="Detalhes">
          <IconButton onClick={() => handleDetalhes(item.id)}>
            <DetailIcon />
          </IconButton>
        </Tooltip>
      </ListItemSecondaryAction>
    </MUIListItem>
  );
};
