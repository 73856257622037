import React, { FC, useEffect, useState } from "react";
import Axios from "axios";
import {
  IMensagem,
  IMensagemCategoria,
  IFiltroCurso,
  IFiltroMateria,
  IFiltroSerie,
  IFiltroTurma,
} from "@deltasge/marauders-map";
import { LinearProgress } from "@mui/material";
import { CadastroMensagem } from "./CadastroMensagem";
import { utilMensagem as util } from "../utilMensagem";

export const Cadastro: FC<{
  idMensagem?: number;
  curso?: IFiltroCurso;
  serie?: IFiltroSerie;
  turma?: IFiltroTurma;
  materia?: IFiltroMateria;
  categoria?: IMensagemCategoria;
  type?: "normal" | "pergunta" | "programada";
  formId: string;
  handleClose: () => void;
  idMensagemCategoria: number;
}> = ({
  idMensagem,
  type = "normal",
  formId,
  handleClose,
  idMensagemCategoria,
}) => {
  const CancelToken = Axios.CancelToken;
  const source = CancelToken.source();

  const [loading, setLoading] = useState(false);
  const [mensagem, setMensagem] = useState<IMensagem | undefined>();

  useEffect(() => {
    const getMensagem = async (id: number) => {
      setLoading(true);
      const msg = await util.getMensagem({
        idMensagem: id,
        cancelToken: source.token,
      });
      if (msg) {
        setMensagem(msg);
      }
      setLoading(false);
    };
    if (idMensagem) {
      getMensagem(idMensagem);
    }

    return () => source.cancel();
  }, [idMensagem]);

  return loading ? (
    <LinearProgress />
  ) : (
    <CadastroMensagem
      mensagem={mensagem}
      permiteResposta={type == "pergunta"}
      programarEnvio={type == "programada"}
      formId={formId}
      handleClose={handleClose}
      idMensagemCategoria={mensagem?.idMensagemCategoria ?? idMensagemCategoria}
    />
  );
};
