import React, { FC } from "react";
import ReactApexCharts from "react-apexcharts";
import { DialogContent } from "@mui/material";
import { DadosGrafico } from "@deltasge/marauders-map";
import { useMobile } from "hooks/useMobile";

export const Grafico: FC<{ data: DadosGrafico }> = ({ data }) => {
  const isSm = useMobile("sm");
  if (isSm && data.options) {
    data.options.plotOptions = {
      bar: {
        horizontal: true,
        dataLabels: {
          position: "top",
        },
      },
    };
  }
  return (
    <DialogContent
      dividers
      sx={{ padding: (theme) => `${theme.spacing(4)} !important` }}
    >
      <ReactApexCharts
        height="100%"
        width="100%"
        options={data.options}
        series={data.series}
        type="bar"
      />
    </DialogContent>
  );
};
